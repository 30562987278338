/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Card, Col, Row } from "antd";
import React, { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import DataTableExtensions from "react-data-table-component-extensions";

import { reqs } from "../../../Actions/gdpr";

import styles from "./index.module.scss";

function GdprReqs() {
  const dispatch = useDispatch();
  const isProccess = useSelector((state) => state.gdpr?.isProcess);
  const [reqsdistributions, setReqsVolutions] = useState([]);
  const reqsdistributionData = useSelector((state) => state.gdpr.reqsData)?.[2]
    ?.buckets;

  const data = reqsdistributions;

  useEffect(() => {
    let arr = [];
    if (reqsdistributions?.length === 0) {
      for (let i = 0; i < reqsdistributionData?.length; i++) {
        let obj = {
          groupName: reqsdistributionData[i]?.key,
          count: reqsdistributionData[i]?.[3]?.buckets[0]?.doc_count,
          agentName: reqsdistributionData[i]?.[3]?.sum_other_doc_count,
          date: reqsdistributionData[i]?.doc_count,
        };
        arr.push(obj);
      }
      setReqsVolutions(arr);
    }
  }, [reqsdistributionData]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(reqs());
  };

  const columns = [
    {
      name: "Ip Addess",

      selector: (row) => row.groupName,
      sortable: true,
    },
    {
      name: "Count",
      selector: (row) => row.count,
      sortable: true,
    },
    {
      name: "Doc Count",
      selector: (row) => row.agentName,
      sortable: true,
    },
    {
      name: "IMEI Number",
      selector: (row) => row.date,
      sortable: true,
    },
  ];
  const tableData = {
    columns,
    data,
  };

  return (
    <div>
      <Card className={styles.sellerCard}>
        <div className={styles.bestSeller}>
          <p>Recent Events Table</p>
        </div>

        <DataTableExtensions
      filterDigit={0}
          filterPlaceholder="Search"
          print={false}
          export={false}
          data={data}
          {...tableData}
        >
          {isProccess ? (
            <Row justify="center">
              <Col>
                <ThreeDots color="#00000" height={50} width={50} />
              </Col>
            </Row>
          ) : (
            <DataTable highlightOnHover data={data} columns={columns} />
          )}
        </DataTableExtensions>
      </Card>
    </div>
  );
}

export default GdprReqs;
