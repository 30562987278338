/* eslint-disable jsx-a11y/anchor-is-valid */

import { useSelector } from "react-redux";
import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import { Row, Col, Button, Spin, Breadcrumb } from "antd";

import { allFilterData } from "../../../reducers/alerts";

import styles from "./index.module.scss";

function ThreatHuntSecondModal({
  modalTitle,
  isVisible,
  setIsVisible,
  isFirstModal,
  setIsFirstModal,
}) {
  const data = useSelector((state) => allFilterData(state));

  const handleCancel = () => {
    setIsVisible(false);
  };

  const handleBack = () => {
    setIsVisible(false);
    setIsFirstModal(!isFirstModal);
  };
  return (
    <DraggableModalProvider>
      <DraggableModal
        title="Threat Hunting Second Level"
        visible={isVisible}
        className={styles.thirdTableModalWidth}
        footer={false}
        initialWidth={1300}
        initialHeight={700}
        onCancel={handleCancel}
      >
        <Breadcrumb>
          <Breadcrumb.Item onClick={handleBack}>
            Threat Hunting First Level
          </Breadcrumb.Item>
          <Breadcrumb.Item> {modalTitle}</Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.critModalContent}>
          {data?.isAlertLoading ? (
            <Row justify="center" className={styles.loaderContent}>
              <Spin size="large" />
            </Row>
          ) : (
            <div className={styles.critSecAlert}>
              <div>
                {data?.threatHuntSecond?.length > 0 ? (
                  <div>
                    <table className={styles.xdrLvlSecondTable}>
                      <tbody className={styles.xdrLvlSecondTableBody}>
                        {data.threatHuntSecond?.map((item, index) => (
                          <p className={styles.fourthModalColor} key={index}>
                            <p className={styles.dnsstatPTag}>
                              <b>Agent name :</b>{" "}
                              {item?._source?.agent?.name || "-"}
                            </p>
                            <p className={styles.dnsstatPTag}>
                              <b>Agent IP :</b>{" "}
                              {item?._source?.agent?.ip || "-"}
                            </p>
                            <p className={styles.dnsstatPTag}>
                              <b>Timestamp :</b>{" "}
                              {item?._source?.timestamp || "-"}
                            </p>
                            {item?._source?.data?.win != null ||
                            item?._source?.data?.dnsstat != null ? (
                              item?._source?.data?.win ? (
                                item?._source?.data?.win?.system?.message
                                  ?.split(/\n/)
                                  .map((item1, index) => (
                                    <tr key={index}>
                                      <td>
                                        {item1
                                          ? item1.replaceAll(`"`, "")
                                          : "-"}
                                      </td>
                                    </tr>
                                  ))
                              ) : (
                                <>
                                  <p className={styles.dnsstatPTag}>
                                    <b> Agent name :</b>
                                    {item?._source?.agent?.name || "-"}
                                  </p>
                                  <p className={styles.dnsstatPTag}>
                                    <b> Alerts :</b>
                                    {item?._source?.data?.dnsstat?.alerts[0] ||
                                      "-"}
                                  </p>
                                  <p className={styles.dnsstatPTag}>
                                    <b> category :</b>
                                    {item?._source?.data?.dnsstat?.category ||
                                      "-"}
                                  </p>
                                  <p className={styles.dnsstatPTag}>
                                    <b> query :</b>
                                    {item?._source?.data?.dnsstat?.query || "-"}
                                  </p>
                                  <p className={styles.dnsstatPTag}>
                                    <b>Rule description :</b>
                                    {item?._source?.rule?.description || "-"}
                                  </p>
                                  <p className={styles.dnsstatPTag}>
                                    <b> Seen by ISC :</b>
                                    {item?._source?.data?.dnsstat
                                      ?.seen_by_isc || "-"}
                                  </p>
                                  <p className={styles.dnsstatPTag}>
                                    <b> Seen by WEB :</b>
                                    {item?._source?.data?.dnsstat
                                      ?.seen_by_web || "-"}
                                  </p>
                                  <p className={styles.dnsstatPTag}>
                                    <b>Seen by you :</b>
                                    {item?._source?.data?.dnsstat
                                      ?.seen_by_you || "-"}
                                  </p>
                                  <p className={styles.dnsstatPTag}>
                                    <b>Timestamp :</b>
                                    {item?._source?.timestamp || "-"}
                                  </p>
                                </>
                              )
                            ) : (
                              Object.keys(item?._source?.data)?.map(
                                (data, index) => {
                                  return (
                                    <>
                                      <p
                                        key={index}
                                        className={styles.dnsstatPTag}
                                      >
                                        <b>{data} :</b>
                                        {item?._source?.data[data] || "-"}
                                      </p>
                                      <p className={styles.dnsstatPTag}>
                                        <b>Rule description :</b>
                                        {item?._source?.rule?.description ||
                                          "-"}
                                      </p>
                                      <p className={styles.dnsstatPTag}>
                                        <b>Timestamp :</b>
                                        {item?._source?.timestamp || "-"}
                                      </p>
                                    </>
                                  );
                                }
                              )
                            )}
                          </p>
                        ))}
                      </tbody>
                    </table>
                    <Row className={styles.buttonRow} justify="center">
                      <Col xxl={3}>
                        <Button
                          className={styles.backButton}
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <Row justify="center"> NO DATA FOUND </Row>
                )}
              </div>
            </div>
          )}
        </div>
      </DraggableModal>
    </DraggableModalProvider>
  );
}

export default ThreatHuntSecondModal;
