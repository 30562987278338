/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker, Row, Col, Select, notification, Button, Spin } from "antd";

import SiteTable from "./SiteTable";
import EDRModal from "./Modals/EDRModal";
import TopRiskModal from "./Modals/TopRiskModal";
import SiteHostModal from "./Modals/SiteHostModal";
import { agentList } from "../../Actions/overView";
import { getSiteLabels } from "../../Actions/alert";
import SignatureModal from "./Modals/SignatureModal";
import MitreFirstModal from "./Modals/MitreFirstModal";
import { allFilterData } from "../../reducers/alerts";
import EventFirstModal from "./Modals/EventFirstModal";
import CriticalFirstModal from "./Modals/CriticalFirstModal";
import { getFilterTableDataApi } from "../../Actions/allSite";
import { downloadReadableStreamFile } from "../../common/connect";
import { commonBody, downloadOption } from "../../common/constant";
import ThreatHuntingFirstModal from "./Modals/ThreatHuntingFirstModal";

import styles from "./index.module.scss";

function FilterDataTable() {
  const dispatch = useDispatch();

  const [siteLabels, setSiteLabels] = useState(null);
  const [exportType, setExportType] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [tblSelectedSite, setTblSelectedSite] = useState(null);

  const [endDate, setEndDate] = useState(
    moment().utc().format("yyyy-MM-DDTHH:mm:ss")
  );
  const [startDate, setStartDate] = useState(
    moment().utc().subtract(1, "days").format("yyyy-MM-DDTHH:mm:ss")
  );

  const [isEventModal, setIsEventModal] = useState(false);
  const [isEdrModal, setIsEdrModal] = useState(false);
  const [isMitreModal, setMitreModal] = useState(false);
  const [isTopRiskModal, setIsTopRiskModal] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [isCRTModal, setIsCRTModal] = useState(false);
  const [isSiteModal, setIsSiteModal] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isThreatHuntModal, setThreatHuntModal] = useState(false);
  const [isDownloadLoader, setIsDownloadLoader] = useState(false);
  const [isSignatureModal, setIsSignatureModal] = useState(false);

  const data = useSelector((state) => allFilterData(state));
  const siteList = useSelector((state) => state.allSite.filterTableData);
  const siteLabelsData = useSelector((state) => state.alerts.siteLabelsData);
  const isSiteLableLoading = useSelector((state) => state.alerts.isProcess);
  const isSiteLoading = useSelector((state) => state.allSite.isProcess);
  const isAgentSelected = useSelector((state) => state.overview.isProcess);

  const Loader = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );

  useEffect(() => {
    // dispatch(getFilterTableDataApi("", "", startDate, endDate));
    dispatch(getSiteLabels(startDate, endDate));
  }, []);

  const filterDropdown = (arr, label, value) => {
    const dropdownArray = [];
    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i];
      dropdownArray.push({
        value: obj[value],
        label: obj[label],
      });
    }
    return dropdownArray;
  };

  const handleChange = (value) => {
    setSelectedAgent(value);
  };

  const handleDownload = () => {
    if (!selectedSite) {
      notification.error({
        message: "Please Select Network",
      });
      return;
    }

    if (!exportType) {
      notification.error({
        message: "Please Select File Type",
      });
      return;
    }

    setIsDownloadLoader(true);

    const onSuccess = (link) => {
      setIsDownloadLoader(false);
      window.URL.revokeObjectURL(link);
    };

    downloadReadableStreamFile(
      selectedSite || tblSelectedSite,
      selectedAgent,
      exportType,
      startDate,
      endDate,
      exportType,
      onSuccess
    );
  };

  const onChange = (value) => {
    setStartDate(moment(value).format("yyyy-MM-DDTHH:mm:ss"));
  };

  const onSecondChange = (value) => {
    setEndDate(moment(value).format("yyyy-MM-DDTHH:mm:ss"));
  };

  const handleClick = async () => {
    console.log('clicked on apply and get selected', selectedSite);
    if (!selectedSite) {
      notification.error({
        message: "Please Select Network",
      }); 
      return;
    }
    setStartDate(startDate);
    setEndDate(endDate);
    setIsApplied(true);
    dispatch(
      getFilterTableDataApi(selectedSite, selectedAgent, startDate, endDate)
    );
  };

  const handleReset = () => {
    setDisable(true);

    if (isApplied) {
      // dispatch(getFilterTableDataApi("", "", commonBody.gte, commonBody.lte));
      setIsApplied(false);
      setSiteLabels(null);
      setSelectedSite(null);
      setSelectedAgent(null);
      setExportType(null);
      setStartDate(commonBody.gte);
      setEndDate(commonBody.lte);
    }
    setTimeout(() => {
      setDisable(false);
    }, 1000);
  };

  const handleSiteChange = (item) => {
    dispatch(agentList(item, "", startDate, endDate));
    setSelectedSite(item);
    setSelectedAgent(null);
  };

  const dropdownOverlay = (
    <Row justify="center">
      <Spin size="small" />
    </Row>
  );

  const modifiedSiteList =
    siteLabelsData?.length > 0 &&
    siteLabelsData?.map((value, index) => ({
      namespace: value,
    }));

  return (
    <div>
      <Row justify="center" className={styles.filterTableRow}>
        <Col
          xs={22}
          md={22}
          lg={22}
          xl={22}
          xxl={23}
          className={styles.filterContent}
        >
          <div className={`${styles.filterType} ${styles.siteDropdown}`}>
            <b>Network:</b>
            <Select
              placeholder="Select Network"
              style={{ width: "60%" }}
              className={styles.selectDropdown}
              onSelect={handleSiteChange}
              value={selectedSite}
              dropdownRender={(menu) =>
                isSiteLableLoading ? dropdownOverlay : menu
              }
              options={
                modifiedSiteList?.length
                  ? filterDropdown(modifiedSiteList, "namespace", "namespace")
                  : []
              }
            />
          </div>
          <div className={styles.filterType}>
            <b>Host:</b>
            <Select
              placeholder="Search Agent"
              style={{ width: "60%" }}
              value={selectedAgent}
              onSelect={handleChange}
              className={styles.selectDropdown}
              showSearch
              dropdownRender={(menu) =>
                isAgentSelected ? dropdownOverlay : menu
              }
              filterOption={(input, option) =>
                (option?.label ?? "").includes(
                  input?.toLowerCase()?.toUpperCase()
                )
              }
              optionFilterProp="children"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  ?.toLowerCase()
                  ?.toUpperCase()
                  ?.localeCompare(
                    (optionB?.label ?? "")?.toLowerCase()?.toUpperCase()
                  )
              }
              options={
                data?.hostList?.length
                  ? filterDropdown(data?.hostList, "name", "name")
                  : []
              }
            />
          </div>
          <div className={styles.timeContent}>
            <b>UTC Time:</b>
            <DatePicker
              showTime
              onChange={onChange}
              defaultValue={moment(startDate)}
              value={moment(startDate)}
            />
            <b>to</b>
            <DatePicker
              showTime
              onChange={onSecondChange}
              defaultValue={moment(endDate)}
              value={moment(endDate)}
            />
          </div>

          <div onClick={handleClick} className={styles.applyBtn}>
            <b>Apply</b>
          </div>
          <Button
            disabled={disable}
            onClick={handleReset}
            className={styles.exportBtnModal}
          >
            Reset
          </Button>
        </Col>
        <Col
          xs={22}
          md={22}
          lg={22}
          xl={22}
          xxl={23}
          className={styles.exportContent}
        >
          <div>
            <Select
              placeholder="Export Data"
              style={{ width: "60%" }}
              className={styles.radioBtn}
              onSelect={setExportType}
              value={exportType}
              options={downloadOption}
            />
          </div>
          <div onClick={() => handleDownload()} className={styles.applyBtn}>
            {isDownloadLoader ? <Spin indicator={Loader} /> : <b>Download</b>}
          </div>
        </Col>
        {isApplied ? (
          <Col xs={22} md={22} lg={22} xl={22} xxl={23}>
            {isSiteLoading ? (
              <Row justify="center">
                <Spin size="large" />
              </Row>
            ) : (
              <SiteTable
                data={data}
                defaultData={siteList}
                siteLabels={siteLabels}
                setIsSiteModal={setIsSiteModal}
                isSiteModal={isSiteModal}
                setTblSelectedSite={setTblSelectedSite}
                setIsCRTModal={setIsCRTModal}
                isCRTModal={isCRTModal}
                isMitreModal={isMitreModal}
                setMitre={setMitreModal}
                setThreatHunt={setThreatHuntModal}
                setIsEdr={setIsEdrModal}
                isTopRisk={isTopRiskModal}
                setIsTopRisk={setIsTopRiskModal}
                isEdrModal={isEdrModal}
                siteName={selectedSite || tblSelectedSite}
                isThreatHunt={isThreatHuntModal}
                isSignatureModal={isSignatureModal}
                setIsSignatureModal={setIsSignatureModal}
                selectedAgent={selectedAgent}
                startDate={startDate}
                endDate={endDate}
                setIsEventModal={setIsEventModal}
                isEventModal={isEventModal}
              />
            )}
          </Col>
        ) : (
          <div className={styles.noDataText}>
            <p>Please select Network.</p>
          </div>
        )}
      </Row>

      <SiteHostModal
        isVisible={isSiteModal}
        setIsVisible={setIsSiteModal}
        siteName={selectedSite || tblSelectedSite}
        agent={selectedAgent}
        startDate={startDate}
        endDate={endDate}
      />

      <TopRiskModal
        isVisible={isTopRiskModal}
        setIsVisible={setIsTopRiskModal}
        siteName={selectedSite || tblSelectedSite}
        agent={selectedAgent}
        startDate={startDate}
        endDate={endDate}
      />

      <CriticalFirstModal
        startDate={startDate}
        endDate={endDate}
        selectedAgent={selectedAgent}
        siteName={selectedSite || tblSelectedSite}
        isVisible={isCRTModal}
        setIsVisible={setIsCRTModal}
      />

      <EDRModal
        startDate={startDate}
        endDate={endDate}
        selectedAgent={selectedAgent}
        siteName={selectedSite || tblSelectedSite}
        isVisible={isEdrModal}
        setIsVisible={setIsEdrModal}
      />

      <EventFirstModal
        startDate={startDate}
        endDate={endDate}
        selectedAgent={selectedAgent}
        siteName={selectedSite || tblSelectedSite}
        isVisible={isEventModal}
        setIsVisible={setIsEventModal}
      />

      <MitreFirstModal
        startDate={startDate}
        endDate={endDate}
        selectedAgent={selectedAgent}
        siteName={selectedSite || tblSelectedSite}
        isVisible={isMitreModal}
        setIsVisible={setMitreModal}
      />

      <SignatureModal
        isVisible={isSignatureModal}
        setIsVisible={setIsSignatureModal}
        siteName={selectedSite || tblSelectedSite}
        selectedAgent={selectedAgent}
        startDate={startDate}
        endDate={endDate}
      />

      <ThreatHuntingFirstModal
        isVisible={isThreatHuntModal}
        setIsVisible={setThreatHuntModal}
        siteName={selectedSite || tblSelectedSite}
        selectedAgent={selectedAgent}
        startDate={startDate}
        endDate={endDate}
      />
    </div>
  );
}

export default FilterDataTable;
