import { notification } from "antd";

import { getLoginUser } from "../common/localStorage";
import { FILTER_TABLE_DASHBOARD_FAIL, FILTER_TABLE_DASHBOARD_REQUEST, FILTER_TABLE_DASHBOARD_SUCCESS } from "./actionConstant";
import { TABLE_DATA_COUNT } from "../apiUrl";
import { functionalityApiCall } from "../common/connect";


const filterTableDashboardRequest = () => ({
  type: FILTER_TABLE_DASHBOARD_REQUEST,
});
const filterTableDashboardSuccess = (data) => ({
  type: FILTER_TABLE_DASHBOARD_SUCCESS,
  data,
});
const filterTableDashboardFailure = () => ({
  type: FILTER_TABLE_DASHBOARD_FAIL,
});

export const getFilterTableDataApi =
  (namespace, agentName, startDate, endDate) => (dispatch) => {
    dispatch(filterTableDashboardRequest());
    const token = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(filterTableDashboardSuccess(response));
      };
      const url = TABLE_DATA_COUNT;

      const onFailure = (response) => {
        dispatch(filterTableDashboardFailure(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };

      const data = {
        gte: startDate,
        lte: endDate,
        size: "200",
      };


      if (namespace) data.namespace = namespace;
      if (agentName) data.agentfilter = agentName;
      if (startDate && endDate) {
        data.gte = startDate;
        data.lte = endDate;
      }

      functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
    }
  };
