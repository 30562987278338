/* eslint-disable no-unused-vars */
import { notification } from "antd";

import {
  ACTIVE_VULNS_FAIL,
  ACTIVE_VULNS_REQUEST,
  ACTIVE_VULNS_SUCCESS,
  VULNPIE_FAIL,
  VULNPIE_REQUEST,
  VULNPIE_SUCCESS,
  VULNTABLE_FAIL,
  VULNTABLE_REQUEST,
  VULNTABLE_SUCCESS,
} from "./actionConstant";
import { commonBody } from "../common/constant";
import { getLoginUser } from "../common/localStorage";
import { functionalityApiCall } from "../common/connect";
import { ACTIVE_VULNS, VULNPIE, VULNTABLE } from "../apiUrl";

const vulanPieRequest = () => ({
  type: VULNPIE_REQUEST,
});
const vulanPieSuccess = (data) => ({
  type: VULNPIE_SUCCESS,
  data,
});
const vulanPieFail = () => ({
  type: VULNPIE_FAIL,
});

const activeVulnsRequest = () => ({
  type: ACTIVE_VULNS_REQUEST,
});
const activeVulnsSuccess = (data) => ({
  type: ACTIVE_VULNS_SUCCESS,
  data,
});
const activeVulnsFail = () => ({
  type: ACTIVE_VULNS_FAIL,
});

const vulnTableRequest = () => ({
  type: VULNTABLE_REQUEST,
});
const vulnTableSuccess = (data) => ({
  type: VULNTABLE_SUCCESS,
  data,
});
const vulnTableFail = () => ({
  type: VULNTABLE_FAIL,
});

const data = commonBody;

export const vulnPie = () => (dispatch) => {
  dispatch(vulanPieRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(vulanPieSuccess(response));
    };
    const url = VULNPIE;

    const onFailure = (response) => {
      dispatch(vulanPieFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const activeVuln = () => (dispatch) => {
  dispatch(activeVulnsRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(activeVulnsSuccess(response));
    };
    const url = ACTIVE_VULNS;

    const onFailure = (response) => {
      dispatch(activeVulnsFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const vulnTable = () => (dispatch) => {
  dispatch(vulnTableRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(vulnTableSuccess(response));
    };
    const url = VULNTABLE;

    const onFailure = (response) => {
      dispatch(vulnTableFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};
