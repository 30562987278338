/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Card, Col, Row } from "antd";
import React, { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import DataTableExtensions from "react-data-table-component-extensions";

import { topDnsQuery } from "../../../Actions/network";

import styles from "./index.module.scss";

function TopDnsQuery() {
  const dispatch = useDispatch();

  const isProccess = useSelector((state) => state.network?.isProcess);
  const [getActiveVulns, setGetAttackActics] = useState([]);
  const topDnsQueryData = useSelector(
    (state) => state.network.topdnsqueryData?.[2]?.buckets
  );

  const data = getActiveVulns;

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(topDnsQuery());
  };

  useEffect(() => {
    let arr = [];

    if (topDnsQueryData?.length > 0) {
      for (let i = 0; i < topDnsQueryData?.length; i++) {
        let obj = {
          groupName: topDnsQueryData[i]?.key,
          count: topDnsQueryData[i]?.doc_count,
          agentName: topDnsQueryData[i]?.[3]?.buckets[0]?.key,
          docCount: topDnsQueryData[i]?.[3]?.buckets[0]?.doc_count,
        };
        arr.push(obj);
      }
      setGetAttackActics(arr);
    }
  }, [topDnsQueryData]);

  const columns = [
    {
      name: "Group Name",

      selector: (row) => (row.groupName ? row.groupName : "-"),
      sortable: true,
    },
    {
      name: "Count ",
      selector: (row) => (row.count ? row.count : "-"),
      sortable: true,
    },
    {
      name: "key",
      selector: (row) => (row.agentName ? row.agentName : "-"),
      sortable: true,
    },
    {
      name: "DOC Count",
      selector: (row) => (row.docCount ? row.docCount : "-"),
      sortable: true,
    },
  ];
  const tableData = {
    columns,
    data,
  };

  return (
    <Row justify="center">
      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
        <Card className={styles.sellerCard}>
          <div className={styles.bestSeller}>
            <p>Top Dns Query</p>
          </div>

          <DataTableExtensions
      filterDigit={0}
            filterPlaceholder="Search"
            print={false}
            export={false}
            data={data}
            {...tableData}
          >
            {isProccess ? (
              <Row justify="center">
                <Col>
                  <ThreeDots color="#00000" height={50} width={50} />
                </Col>
              </Row>
            ) : (
              <DataTable highlightOnHover data={data} columns={columns} />
            )}
          </DataTableExtensions>
        </Card>
      </Col>
    </Row>
  );
}

export default TopDnsQuery;
