import {
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOG_OUT,
  REGISTER_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
} from "../Actions/actionConstant";

const intialState = {
  loginUser: {},
  isProcess: false,
  message: "",
  userDetails: [],
  token: null,
};

export const loginReducer = (state = intialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isProcess: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isProcess: false,
        token: action.data,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isProcess: false,
      };
    case REGISTER_REQUEST:
      return {
        ...state,
        isProcess: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isProcess: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isProcess: false,
      };
    case LOG_OUT:
      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
};
