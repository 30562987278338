/* eslint-disable  */
import { useState } from "react";
import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Spin, Breadcrumb } from "antd";

import EventThirdModal from "./EventThirdModal";
import { allFilterData } from "../../../reducers/alerts";
import { getEventThirdLvl } from "../../../Actions/alert";

import styles from "./index.module.scss";

function EventSecModal({
  endDate,
  startDate,
  siteName,
  selectedAgent,
  modalTitle,
  isVisible,
  setIsVisible,
  isEventFirstModal,
  setIsEventFirstModal,
}) {
  const dispatch = useDispatch();
  const data = useSelector((state) => allFilterData(state));
  const [eventThirdAlertTitle, setEventThirdAlertTitle] = useState("");
  const [isEventThirdLvlModal, setIsEventThirdLvlModal] = useState(false);

  const handleEventThirdModal = (label) => {
    setEventThirdAlertTitle(label);
    dispatch(
      getEventThirdLvl(
        label,
        modalTitle,
        siteName,
        selectedAgent,
        startDate,
        endDate
      )
    );
    setIsVisible(!isVisible);
    setIsEventThirdLvlModal(true);
  };

  const handleEventSecondClose = () => {
    setIsVisible(!isVisible);
    setEventThirdAlertTitle("");
    setIsEventFirstModal(!isEventFirstModal);
  };

  const handleEventShotsCancel = () => {
    setEventThirdAlertTitle("");
    setIsVisible(!isVisible);
  };

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          title="Event Second Level"
          visible={isVisible}
          className={styles.thirdTableModalWidth}
          footer={false}
          initialWidth={1300}
          initialHeight={700}
          onCancel={handleEventShotsCancel}
        >
          <Breadcrumb>
            <Breadcrumb.Item onClick={handleEventSecondClose}>
              {" "}
              Event Security Alerts
            </Breadcrumb.Item>
            <Breadcrumb.Item>{modalTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.critModalContent}>
            {data?.isAlertLoading ? (
              <Row justify="center" className={styles.loaderContent}>
                <Spin size="large" />
              </Row>
            ) : (
              <div>
                {data?.eventSec?.length ? (
                  <table className={styles.surucataSecLevelTable}>
                    <thead>
                      <tr>
                        <th className={styles.leftTr}>Agent</th>
                        <th className={styles.rightTr}>Ip address</th>
                        <th className={styles.rightTr}>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.eventSec?.map((item, index) => (
                        <tr
                          key={index}
                          className={index % 2 ? "" : styles.suricataTable}
                        >
                          <td
                            className={styles.leftTr}
                            onClick={() => handleEventThirdModal(item.key)}
                            style={{
                              cursor: "pointer",
                              color: "blue",
                            }}
                          >
                            {item?.key ? item.key : "-"}
                          </td>
                          <td className={styles.rightTr}>
                            {item?.[4]?.buckets?.[0]?.key || "-"}
                          </td>
                          <td className={styles.rightTr}>
                            {item?.doc_count ? item?.doc_count : "-"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Row justify="center">NO DATA FOUND</Row>
                )}
                <Row className={styles.buttonRow} justify="center">
                  <Col xxl={3}>
                    <Button
                      className={styles.backButton}
                      onClick={handleEventSecondClose}
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </DraggableModal>
      </DraggableModalProvider>
      <EventThirdModal
        modalTitle={eventThirdAlertTitle}
        eventFirstTitle={modalTitle}
        isVisible={isEventThirdLvlModal}
        setIsVisible={setIsEventThirdLvlModal}
        setIsEventSecModal={setIsVisible}
        isEventSectModal={isVisible}
        endDate={endDate}
        startDate={startDate}
        siteName={siteName}
        selectedAgent={selectedAgent}
        setIsEventFirstModal={setIsEventFirstModal}
      />
    </>
  );
}

export default EventSecModal;
