import { notification } from "antd";

import {
  MOSTACTIVE,
  COMMONALERTS,
  REQSEVOLUTION,
  REQSDISTRIBUTION,
} from "../apiUrl";
import {
  TOP_REQS_FAIL,
  MOST_ACTIVE_FAIL,
  TOP_REQS_SUCCESS,
  TOP_REQS_REQUEST,
  COMMON_ALERTS_FAIL,
  MOST_ACTIVE_REQUEST,
  MOST_ACTIVE_SUCCESS,
  REQS_EVOLUTION_FAIL,
  COMMON_ALERTS_REQUEST,
  COMMON_ALERTS_SUCCESS,
  REQS_EVOLUTION_REQUEST,
  REQS_EVOLUTION_SUCCESS,
  REQS_DISTRIBUTION_FAIL,
  REQS_DISTRIBUTION_REQUEST,
  REQS_DISTRIBUTION_SUCCESS,
} from "./actionConstant";
import { commonBody } from "../common/constant";
import { getLoginUser } from "../common/localStorage";
import { functionalityApiCall } from "../common/connect";

const commonalertsRequest = () => ({
  type: COMMON_ALERTS_REQUEST,
});
const commonalertsSuccess = (data) => ({
  type: COMMON_ALERTS_SUCCESS,
  data,
});
const commonalertsFail = () => ({
  type: COMMON_ALERTS_FAIL,
});
const topreqsRequest = () => ({
  type: TOP_REQS_REQUEST,
});
const topreqsSuccess = (data) => ({
  type: TOP_REQS_SUCCESS,
  data,
});
const topreqsFail = () => ({
  type: TOP_REQS_FAIL,
});

const reqseVolutioneRequest = () => ({
  type: REQS_EVOLUTION_REQUEST,
});
const reqseVolutionSuccess = (data) => ({
  type: REQS_EVOLUTION_SUCCESS,
  data,
});
const reqseVolutionFail = () => ({
  type: REQS_EVOLUTION_FAIL,
});

const mostactiveRequest = () => ({
  type: MOST_ACTIVE_REQUEST,
});
const mostactiveSuccess = (data) => ({
  type: MOST_ACTIVE_SUCCESS,
  data,
});
const mostactiveFail = () => ({
  type: MOST_ACTIVE_FAIL,
});

const reqsdistributionRequest = () => ({
  type: REQS_DISTRIBUTION_REQUEST,
});
const reqsdistributionSuccess = (data) => ({
  type: REQS_DISTRIBUTION_SUCCESS,
  data,
});
const reqsdistributionFail = () => ({
  type: REQS_DISTRIBUTION_FAIL,
});

const data = commonBody;

export const commonAlerts = () => (dispatch) => {
  dispatch(commonalertsRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(commonalertsSuccess(response));
    };
    const url = COMMONALERTS;

    const onFailure = (response) => {
      dispatch(commonalertsFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const topReqs = () => (dispatch) => {
  dispatch(topreqsRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(topreqsSuccess(response));
    };
    const url = COMMONALERTS;

    const onFailure = (response) => {
      dispatch(topreqsFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const mostActive = (requestData) => (dispatch) => {
  dispatch(mostactiveRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(mostactiveSuccess(response));
    };
    const url = MOSTACTIVE;

    const onFailure = (response) => {
      dispatch(mostactiveFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const reqseVolution = (requestData) => (dispatch) => {
  dispatch(reqseVolutioneRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(reqseVolutionSuccess(response));
    };
    const url = REQSEVOLUTION;

    const onFailure = (response) => {
      dispatch(reqseVolutionFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};
export const reqsDistribution = (requestData) => (dispatch) => {
  dispatch(reqsdistributionRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(reqsdistributionSuccess(response));
    };
    const url = REQSDISTRIBUTION;

    const onFailure = (response) => {
      dispatch(reqsdistributionFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};
