/* eslint-disable  */

import { useState } from "react";
import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Spin, Breadcrumb } from "antd";

import CriticalFourthModal from "./CriticalFourthModal";
import { allFilterData } from "../../../reducers/alerts";
import { getCriticalsFourthLvl } from "../../../Actions/alert";

import styles from "./index.module.scss";
import { InfoCircleOutlined } from "@ant-design/icons";

function CriticalThirdModal({
  isVisible,
  modalTitle,
  critFirstTitle,
  isCritSecModal,
  setIsVisible,
  setIsCritSecModal,
  siteName,
  startDate,
  endDate,
  setCritFirstModal,
}) {
  const dispatch = useDispatch();
  const data = useSelector((state) => allFilterData(state));
  const [isCritFourthModal, setIsCritFourthModal] = useState(false);
  const [timeStamp, setTimeStamp] = useState();
  const [thirdTItle, setThirdTItle] = useState("");

  const handlCriticalThirdBack = () => {
    setIsVisible(false);
    setIsCritSecModal(!isCritSecModal);
  };

  const handleThirdClose = () => {
    setIsVisible(false);
  };
  const handleFourthLvl = (title, timeStamp) => {
    setThirdTItle(title);
    setTimeStamp(timeStamp);
    dispatch(getCriticalsFourthLvl(title, timeStamp, modalTitle, siteName));
    setIsVisible(false);
    setIsCritFourthModal(true);
  };
  const handleCritFirst = () => {
    setIsVisible(false);
    setCritFirstModal(true);
  };

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          title={"Critical Security Alerts"}
          visible={isVisible}
          className={styles.thirdTableModalWidth}
          initialWidth={1300}
          initialHeight={700}
          footer={false}
          onCancel={handleThirdClose}
        >
          <Breadcrumb>
            <Breadcrumb.Item onClick={handleCritFirst}>
              {" "}
              Critical Security Alerts
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={handlCriticalThirdBack}>
              {JSON.stringify(critFirstTitle)?.replaceAll(`"`, "")}
            </Breadcrumb.Item>
            <Breadcrumb.Item>{modalTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.critModalContent}>
            {data?.isAlertLoading ? (
              <Row justify="center" className={styles.loaderContent}>
                <Spin size="large" />
              </Row>
            ) : (
              <div>
                {data?.critThird?.length > 0 ? (
                  <div>
                    <table className={styles.xdrLvlSecondTable}>
                      <tbody className={styles.xdrLvlSecondTableBody}>
                        {data.critThird?.map((item, index) => (
                          <p className={styles.fourthModalColor} key={index}>
                            <div>
                              <div>
                                <span>
                                  Timestamp : {item?._source?.timestamp}
                                </span>
                                <span>
                                  Agent Name :{" "}
                                  {item?._source?.agent?.name || "-"}
                                </span>
                                <span>
                                  Agent IP :{" "}
                                  {item?._source?.agent?.ip
                                    ? item?._source?.agent?.ip
                                    : "-"}
                                </span>
                                <span>
                                  Rule Description :{" "}
                                  {item?._source?.rule?.description
                                    ? item?._source?.rule?.description
                                    : "-"}
                                </span>
                              </div>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleFourthLvl(
                                    item?._id,
                                    item?._source?.timestamp
                                  )
                                }
                              >
                                <InfoCircleOutlined />
                              </div>
                            </div>
                            {item?._source?.data?.win?.system?.message
                              ?.split(/\n/)
                              ?.map((item1, index) => {
                                if (item1?.search("RuleName") === 0) {
                                  return;
                                }
                                return (
                                  <tr>
                                    <td>
                                      <span>
                                        {item1
                                          ? item1?.replaceAll(`"`, "")
                                          : "-"}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                          </p>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <Row justify="center"> NO DATA FOUND </Row>
                )}
                <Row className={styles.buttonRow} justify="center">
                  <Col xxl={3}>
                    <Button
                      className={styles.backButton}
                      onClick={handlCriticalThirdBack}
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </DraggableModal>
      </DraggableModalProvider>
      <CriticalFourthModal
        isCritFourthModal={isCritFourthModal}
        setIsCritFourthModal={setIsCritFourthModal}
        critFirstTitle={critFirstTitle}
        modalTitle={modalTitle}
        thirdTItle={thirdTItle}
        setIsVisible={setIsVisible}
        timeStamp={timeStamp}
        setCritFirstModal={setCritFirstModal}
        setIsCritSecModal={setIsCritSecModal}
        siteName={siteName}
        startDate={startDate}
        endDate={endDate}
      />
    </>
  );
}

export default CriticalThirdModal;
