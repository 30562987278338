import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Input, Button, Select, notification } from "antd";

import { registerUser } from "../../../Actions/user";

import styles from "./index.module.scss";

function SignUp() {
  const { Option } = Select;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onFinish = (values) => {
    if (
      values.username !== "" &&
      values.password !== "" &&
      values.roles !== ""
    ) {
      try {
        const successCallBack = (response) => {
          notification.success({ topRight: 10, message: response });
          navigate("/");
        };
        dispatch(registerUser(values, successCallBack));
      } catch (error) {
        notification.error({ topRight: 10, message: error });
      }
    } else {
      notification.error({ topRight: 10, message: "please fill-up Form" });
    }
  };

  const handleChange = (value) => {};

  return (
    <div>
      <Row justify="center" className={styles.loginMain}>
        <Col xs={24} md={18} lg={16} xxl={8} xl={10}>
          <div className={styles.headerMain}>
            <p>CLIRSec Responder</p>
          </div>
          <div className={styles.loginName}>
            <p>SIGN UP</p>
          </div>
          <Form name="login-form" onFinish={onFinish}>
            <Form.Item
              name="username"
              rules={[{ required: true, message: "" }]}
            >
              <Input placeholder="Username" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: "" }]}
            >
              <Input placeholder="Password" type="password" />
            </Form.Item>

            <Form.Item name="roles" rules={[{ required: true, message: "" }]}>
              <Select
                className={styles.selectValue}
                defaultValue="Manager"
                onChange={handleChange}
              >
                <Option value="Manager">Manager</Option>
                <Option value="Oparator">Oparator</Option>

                <Option value="Admin">Admin</Option>
              </Select>
            </Form.Item>

            <Form.Item className={styles.buttonMain}>
              <div>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.loginButton}
                  onClick={() => {
                    // navigate('/')
                  }}
                >
                  Sign up
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default SignUp;
