import thunk from "redux-thunk";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createStore, compose, applyMiddleware } from "redux";

import reducers from "./reducers/index";

const customMiddleWare = (store) => (next) => (action) => {
  next(action, store);
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};
const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  compose(applyMiddleware(thunk, customMiddleWare))
);

export default store;
