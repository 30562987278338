import React from 'react'
import { useNavigate } from 'react-router-dom'
import { TailSpin } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, Input, Button, notification } from 'antd'

import { loginUser } from '../../../Actions/user'
import companyLogo from '../../../Assets/png/company.png'

import styles from './index.module.scss'

function Login() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userProcess = useSelector((state) => state.user?.isProcess)
  const onFinish = (values) => {
    if (values.username !== '' && values.password !== '') {
      try {
        const successCallBack = (response) => {
          navigate('/')
        }
        dispatch(loginUser(values.username, values.password, successCallBack))
      } catch (error) {
        notification.error({ topRight: 10, message: error })
      }
    } else {
      notification.error({ topRight: 10, message: 'please fill-up Form' })
    }
  }

  return (
    <div>
      <Row justify="center" className={styles.loginMain}>
        <Col xs={24} md={18} lg={16} xxl={8} xl={10}>
          <div className={styles.headerMain}>
            <img src={companyLogo} alt="" height="120px" width="300px" />
          </div>
          <div className={styles.loginName}>
            <p>LOG IN</p>
          </div>
          <Form name="login-form" onFinish={onFinish}>
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Enter User name' }]}
            >
              <Input placeholder="Username" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Enter Password' }]}
            >
              <Input placeholder="Password" type="password" />
            </Form.Item>

            <Form.Item className={styles.buttonMain}>
              <div>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.loginButton}
                >
                  {userProcess ? (
                    <Row justify="center">
                      <Col>
                        <TailSpin color="#fff" height={30} width={30} />
                      </Col>
                    </Row>
                  ) : (
                    ' Login'
                  )}
                </Button>
              </div>
            </Form.Item>
            <div className={styles.RegisterButton}>
              {/* <Button
                type="link" 
                onClick={() => {
                  handleRegister();
                }}
              >
                <p>Don't have an Account? Register Now</p>
              </Button> */}
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default Login
