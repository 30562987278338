import { notification } from "antd";

import {
  GDPR_REQS,
  GDPR_TOPREQS,
  GDPR_TOPAGENTS,
  GDPR_REQSBY_AGENTS,
} from "../apiUrl";
import {
  GDPR_REQS_FAIL,
  GDPR_REQS_REQUEST,
  GDPR_TOPREQS_FAIL,
  GDPR_REQS_SUCCESS,
  GDPR_TOPAGENTS_FAIL,
  GDPR_TOPREQS_REQUEST,
  GDPR_TOPREQS_SUCCESS,
  GDPR_TOPAGENTS_REQUEST,
  GDPR_TOPAGENTS_SUCCESS,
  GDPR_REQSBY_AGENTS_FAIL,
  GDPR_REQSBY_AGENTS_REQUEST,
  GDPR_REQSBY_AGENTS_SUCCESS,
} from "./actionConstant";
import { commonBody } from "../common/constant";
import { getLoginUser } from "../common/localStorage";
import { functionalityApiCall } from "../common/connect";

const topAgentRequest = () => ({
  type: GDPR_TOPAGENTS_REQUEST,
});
const topAgentSuccess = (data) => ({
  type: GDPR_TOPAGENTS_SUCCESS,
  data,
});
const topAgentFail = () => ({
  type: GDPR_TOPAGENTS_FAIL,
});

const reqsRequest = () => ({
  type: GDPR_REQS_REQUEST,
});
const reqsSuccess = (data) => ({
  type: GDPR_REQS_SUCCESS,
  data,
});
const reqsFail = () => ({
  type: GDPR_REQS_FAIL,
});
const topReqsRequest = () => ({
  type: GDPR_TOPREQS_REQUEST,
});
const topReqsSuccess = (data) => ({
  type: GDPR_TOPREQS_SUCCESS,
  data,
});
const topReqsFail = () => ({
  type: GDPR_TOPREQS_FAIL,
});
const reqsbyagentsRequest = () => ({
  type: GDPR_REQSBY_AGENTS_REQUEST,
});
const reqsbyagentsSuccess = (data) => ({
  type: GDPR_REQSBY_AGENTS_SUCCESS,
  data,
});
const reqsbyagentsFail = () => ({
  type: GDPR_REQSBY_AGENTS_FAIL,
});

const data = commonBody;

export const topAgent = () => (dispatch) => {
  dispatch(topAgentRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(topAgentSuccess(response));
    };
    const url = GDPR_TOPAGENTS;

    const onFailure = (response) => {
      dispatch(topAgentFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const reqs = () => (dispatch) => {
  dispatch(reqsRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(reqsSuccess(response));
    };
    const url = GDPR_REQS;

    const onFailure = (response) => {
      dispatch(reqsFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};
export const topReqs = () => (dispatch) => {
  dispatch(topReqsRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(topReqsSuccess(response));
    };
    const url = GDPR_TOPREQS;

    const onFailure = (response) => {
      dispatch(topReqsFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};
export const reqsByAgents = () => (dispatch) => {
  dispatch(reqsbyagentsRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(reqsbyagentsSuccess(response));
    };
    const url = GDPR_REQSBY_AGENTS;

    const onFailure = (response) => {
      dispatch(reqsbyagentsFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};
