import { useState, Fragment } from "react";
import { Row, Tabs, Modal, Spin } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

import { getIpDdrApi } from "../../../Actions/alert";
import { allFilterData } from "../../../reducers/alerts";

import style from "./index.module.scss";
import styles from "../index.module.scss";

function NtaReportTable({ tableData, selectedSite }) {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector((state) => allFilterData(state));
  const items = [
    {
      label: "DNS",
      key: "1",
      children: tableData,
    },
    {
      label: "NXDOMAIN",
      key: "2",
      children: tableData,
    },
    {
      label: "OUTBOUND",
      key: "3",
      children: tableData,
    },
    {
      label: "LARGEST EXFILTRATION",
      key: "4",
      children: tableData,
    },
    {
      label: "LONGEST DURATION",
      key: "5",
      children: tableData,
    },
    {
      label: "SMB .bat Manifest",
      key: "6",
      children: tableData,
    },
    {
      label: "SMB .exe Manifest",
      key: "7",
      children: tableData,
    },
    {
      label: "Suricata Priority 1",
      key: "8",
      children: tableData,
    },
    {
      label: "Suricata Priority 2",
      key: "9",
      children: tableData,
    },
    {
      label: "Suricata Priority 3",
      key: "10",
      children: tableData,
    },
    {
      label: "Zeek (HTTP)",
      key: "11",
      children: tableData,
    },
    {
      label: "Zeek (JA3)",
      key: "12",
      children: tableData,
    },
    {
      label: "Zeek (Notice)",
      key: "13",
      children: tableData,
    },
    {
      label: "Zeek (SSL)",
      key: "14",
      children: tableData,
    },
  ];

  const addSpace = (str) => {
    return str
      ?.split(" ")
      .join("\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0")
      ?.trimStart();
  };

  const handleOpen = (item) => {
    setIsVisible(true);
    dispatch(getIpDdrApi(selectedSite, item));
  };

  const handleClose = () => {
    setIsVisible(false);
  };
  return (
    <Fragment>
      {tableData && selectedSite ? (
        <Tabs
          type="card"
          className={style.tabMain}
          moreIcon={<MoreOutlined />}
          centered={true}
          animated={{ inkBar: true, tabPane: true }}
          defaultActiveKey="1"
        >
          {items?.map((item, index) => {
            return (
              <Tabs.TabPane
                //  className={style.tabPaneMain}
                tab={item?.label}
                key={item?.key}
              >
                {item.label === "DNS" ? (
                  <table key={index} className={style.tableMain}>
                    <thead className={style.theadMain}>
                      <tr className={style.headMain}>
                        <th>count</th>
                        <th>DNS Query</th>
                        <th>Record</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.children?.[0]?._source?.data?.dns?.["dump"]
                        ?.length > 0 ? (
                        item?.children?.[0]?._source?.data?.dns?.["dump"]
                          ?.split(/\n/)
                          ?.map((item1, index) =>
                            item1 !== "" ? (
                              <tr className={style.trMain} key={index}>
                                <td className={style.tdMain}>
                                  {addSpace(item1)?.slice(0, 8) || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {addSpace(item1)
                                    ?.replace(/^.{8}/g, "")
                                    ?.split(/\t/)
                                    ?.slice(0, -1)?.[0] || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {addSpace(item1)
                                    ?.replace(/^.{4}/g, "")
                                    ?.split(/\t/)?.[1] || "-"}
                                </td>
                              </tr>
                            ) : null
                          )
                      ) : (
                        <Row className={style.noRecord} justify="center">
                          NO DATA FOUND
                        </Row>
                      )}
                    </tbody>
                  </table>
                ) : item.label === "NXDOMAIN" ? (
                  <table key={index} className={style.tableMain}>
                    <thead className={style.theadMain}>
                      <tr className={style.headMain}>
                        <th>count</th>
                        <th>NXDOMAIN Query</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.children?.[0]?._source?.data?.dns?.nxdomain?.[
                        "dump"
                      ]?.length > 0 ? (
                        item?.children?.[0]?._source?.data?.dns?.nxdomain?.[
                          "dump"
                        ]
                          ?.split(/\n/)
                          ?.map((item1, index) =>
                            item1 !== "" ? (
                              <tr className={style.trMain} key={index}>
                                <td className={style.tdMain}>
                                  {addSpace(item1)?.slice(0, 8) || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {addSpace(item1)
                                    ?.replace(/^.{8}/g, "")
                                    ?.split(/\t/)
                                    .slice(0, -1)?.[0] || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {addSpace(item1)
                                    ?.replace(/^.{4}/g, "")
                                    ?.split(/\t/)?.[1] || "-"}
                                </td>
                              </tr>
                            ) : null
                          )
                      ) : (
                        <Row className={style.noRecord} justify="center">
                          NO DATA FOUND
                        </Row>
                      )}
                    </tbody>
                  </table>
                ) : item.label === "OUTBOUND" ? (
                  <table key={index} className={style.tableMain}>
                    <thead className={style.theadMain}>
                      <tr className={style.headMain}>
                        <th>count</th>
                        <th>Source IP</th>
                        <th>Destination IP</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.children?.[0]?._source?.data?.outbound?.["conn"]?.[
                        "count"
                      ]?.length > 0 ? (
                        item?.children?.[0]?._source?.data?.outbound?.[
                          "conn"
                        ]?.["count"]
                          ?.split(/\n/)
                          .map((item1, index) =>
                            item1 !== "" ? (
                              <tr className={style.trMain} key={index}>
                                <td className={style.tdMain}>
                                  {addSpace(item1)?.slice(0, 8) || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {addSpace(item1)
                                    ?.replace(/^.{8}/g, "")
                                    ?.split(/\t/)
                                    .slice(0, -1)?.[0] || "-"}
                                </td>
                                <td
                                  style={{ cursor: "pointer", color: "blue" }}
                                  onClick={() =>
                                    handleOpen(
                                      addSpace(item1)
                                        ?.replace(/^.{4}/g, "")
                                        ?.split(/\t/)?.[1]
                                    )
                                  }
                                  className={style.tdMain}
                                >
                                  {addSpace(item1)
                                    ?.replace(/^.{4}/g, "")
                                    ?.split(/\t/)?.[1] || "-"}
                                </td>
                              </tr>
                            ) : null
                          )
                      ) : (
                        <Row className={style.noRecord} justify="center">
                          NO DATA FOUND
                        </Row>
                      )}
                    </tbody>
                  </table>
                ) : item.label === "LARGEST EXFILTRATION" ? (
                  <table key={index} className={style.tableMain}>
                    <thead className={style.theadMain}>
                      <tr className={style.headMain}>
                        <th>Source IP</th>
                        <th>Destination IP</th>
                        <th>Bytes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.children?.[0]?._source?.data?.outbound?.[
                        "largest"
                      ]?.["exfiltration"]?.length > 0 ? (
                        item?.children?.[0]?._source?.data?.outbound?.[
                          "largest"
                        ]?.["exfiltration"]
                          ?.split(/\n/)
                          ?.map((item1, index) =>
                            item1 !== "" ? (
                              <tr className={style.trMain} key={index}>
                                <td className={style.tdMain}>
                                  {addSpace(item1)
                                    ?.split(/\t/)
                                    ?.slice(0, -1)?.[0] || "-"}
                                </td>
                                <td
                                  style={{ cursor: "pointer", color: "blue" }}
                                  onClick={() =>
                                    handleOpen(
                                      addSpace(item1)
                                        ?.replace(/^.{4}/g, "")
                                        ?.split(/\t/)?.[1]
                                    )
                                  }
                                  className={style.tdMain}
                                >
                                  {addSpace(item1)
                                    ?.replace(/^.{4}/g, "")
                                    ?.split(/\t/)?.[1] || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {addSpace(item1)
                                    ?.replace(/^.{4}/g, "")
                                    ?.split(/\t/)?.[2] || "-"}
                                </td>
                              </tr>
                            ) : null
                          )
                      ) : (
                        <Row className={style.noRecord} justify="center">
                          NO DATA FOUND
                        </Row>
                      )}
                    </tbody>
                  </table>
                ) : item.label === "LONGEST DURATION" ? (
                  <table key={index} className={style.tableMain}>
                    <thead className={style.theadMain}>
                      <tr className={style.headMain}>
                        <th>Source IP</th>
                        <th>Destination IP</th>
                        <th>Duration</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.children?.[0]?._source?.data?.outbound?.[
                        "longest"
                      ]?.["duration"]?.length > 0 ? (
                        item?.children?.[0]?._source?.data?.outbound?.[
                          "longest"
                        ]?.["duration"]
                          ?.split(/\n/)
                          ?.map((item1, index) =>
                            item1 !== "" ? (
                              <tr className={style.trMain} key={index}>
                                <td className={style.tdMain}>
                                  {addSpace(item1)
                                    ?.split(/\t/)
                                    ?.slice(0, -1)?.[0] || "-"}
                                </td>
                                <td
                                  style={{ cursor: "pointer", color: "blue" }}
                                  onClick={() =>
                                    handleOpen(
                                      addSpace(item1)
                                        ?.replace(/^.{4}/g, "")
                                        ?.split(/\t/)?.[1]
                                    )
                                  }
                                  className={style.tdMain}
                                >
                                  {addSpace(item1)
                                    ?.replace(/^.{4}/g, "")
                                    ?.split(/\t/)?.[1] || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {addSpace(item1)
                                    ?.replace(/^.{4}/g, "")
                                    ?.split(/\t/)?.[2] || "-"}
                                </td>
                              </tr>
                            ) : null
                          )
                      ) : (
                        <Row className={style.noRecord} justify="center">
                          NO DATA FOUND
                        </Row>
                      )}
                    </tbody>
                  </table>
                ) : item.label === "SMB .bat Manifest" ? (
                  <table key={index} className={style.tableMain}>
                    <thead className={style.theadMain}>
                      <tr className={style.headMain}>
                        <th>Source IP</th>
                        <th>File Manifest</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.children?.[0]?._source?.data?.smb?.["bat"]?.[
                        "manifest"
                      ]?.length > 0 ? (
                        item?.children?.[0]?._source?.data?.smb?.["bat"]?.[
                          "manifest"
                        ]
                          ?.split(/\n/)
                          ?.map((item1, index) =>
                            item1 !== "" ? (
                              <tr className={style.trMain} key={index}>
                                <td className={style.tdMain}>
                                  {addSpace(item1)?.split(/\t/)?.[0] || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {item1
                                    ?.replace(/^.{4}/g, "")
                                    ?.split(/\t/)?.[1]
                                    ?.replace(/\\\\/g, " ") || "-"}
                                </td>
                              </tr>
                            ) : null
                          )
                      ) : (
                        <Row className={style.noRecord} justify="center">
                          NO DATA FOUND
                        </Row>
                      )}
                    </tbody>
                  </table>
                ) : item.label === "SMB .exe Manifest" ? (
                  <table key={index} className={style.tableMain}>
                    <thead className={style.theadMain}>
                      <tr className={style.headMain}>
                        <th>Source IP</th>
                        <th>File Manifest</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.children?.[0]?._source?.data?.smb?.["exe"]?.[
                        "manifest"
                      ]?.length > 0 ? (
                        item?.children?.[0]?._source?.data?.smb?.["exe"]?.[
                          "manifest"
                        ]
                          ?.split(/\n/)
                          ?.map((item1, index) =>
                            item1 !== "" ? (
                              <tr className={style.trMain} key={index}>
                                <td className={style.tdMain}>
                                  {addSpace(item1)?.split(/\t/)?.[0] || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {item1
                                    ?.replace(/^.{4}/g, "")
                                    ?.split(/\t/)?.[1]
                                    ?.replace(/\\\\/g, " ") || "-"}
                                </td>
                              </tr>
                            ) : null
                          )
                      ) : (
                        <Row className={style.noRecord} justify="center">
                          NO DATA FOUND
                        </Row>
                      )}
                    </tbody>
                  </table>
                ) : item.label === "Zeek (HTTP)" ? (
                  <table key={index} className={style.tableMain}>
                    <thead className={style.theadMain}>
                      <tr className={style.headMain}>
                        <th>count</th>
                        <th>Source IP</th>
                        <th>HTTP</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.children?.[0]?._source?.data?.zeek?.["http"]
                        ?.length > 0 ? (
                        item?.children?.[0]?._source?.data?.zeek?.["http"]
                          ?.split(/\n/)
                          ?.map((item1, index) =>
                            item1 !== "" ? (
                              <tr className={style.trMain} key={index}>
                                <td className={style.tdMain}>
                                  {addSpace(item1)?.slice(0, 8) || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {addSpace(item1)
                                    ?.replace(/^.{8}/g, "")
                                    ?.split(/\t/)
                                    ?.slice(0, -1)?.[0] || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {addSpace(item1)
                                    ?.replace(/^.{4}/g, "")
                                    ?.split(/\t/)?.[1] || "-"}
                                </td>
                              </tr>
                            ) : null
                          )
                      ) : (
                        <Row className={style.noRecord} justify="center">
                          NO DATA FOUND
                        </Row>
                      )}
                    </tbody>
                  </table>
                ) : item.label === "Zeek (JA3)" ? (
                  <table key={index} className={style.tableMain}>
                    <thead className={style.theadMain}>
                      <tr className={style.headMain}>
                        <th>count</th>
                        <th>Source IP</th>
                        <th>JA3</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.children?.[0]?._source?.data?.zeek?.["ja3"]
                        ?.length > 0 ? (
                        item?.children?.[0]?._source?.data?.zeek?.["ja3"]
                          ?.split(/\n/)
                          ?.map((item1, index) =>
                            item1 !== "" ? (
                              <tr className={style.trMain} key={index}>
                                <td className={style.tdMain}>
                                  {addSpace(item1)?.slice(0, 8) || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {addSpace(item1)
                                    ?.replace(/^.{8}/g, "")
                                    ?.split(/\t/)
                                    ?.slice(0, -1)?.[0] || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {addSpace(item1)
                                    ?.replace(/^.{4}/g, "")
                                    ?.split(/\t/)?.[1] || "-"}
                                </td>
                              </tr>
                            ) : null
                          )
                      ) : (
                        <Row className={style.noRecord} justify="center">
                          NO DATA FOUND
                        </Row>
                      )}
                    </tbody>
                  </table>
                ) : item.label === "Zeek (SSL)" ? (
                  <table key={index} className={style.tableMain}>
                    <thead className={style.theadMain}>
                      <tr className={style.headMain}>
                        <th>count</th>
                        <th>Source IP</th>
                        <th>Domain</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.children?.[0]?._source?.data?.zeek?.["ssl"]
                        ?.length > 0 ? (
                        item?.children?.[0]?._source?.data?.zeek?.["ssl"]
                          ?.split(/\n/)
                          ?.map((item1, index) =>
                            item1 !== "" ? (
                              <tr className={style.trMain} key={index}>
                                <td className={style.tdMain}>
                                  {addSpace(item1)?.slice(0, 8) || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {addSpace(item1)
                                    ?.replace(/^.{8}/g, "")
                                    ?.split(/\t/)
                                    ?.slice(0, -1)?.[0] || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {addSpace(item1)
                                    ?.replace(/^.{4}/g, "")
                                    ?.split(/\t/)?.[1] || "-"}
                                </td>
                              </tr>
                            ) : null
                          )
                      ) : (
                        <Row className={style.noRecord} justify="center">
                          NO DATA FOUND
                        </Row>
                      )}
                    </tbody>
                  </table>
                ) : item.label === "Zeek (Notice)" ? (
                  <table key={index} className={style.tableMain}>
                    <thead className={style.theadMain}>
                      <tr className={style.headMain}>
                        <th>count</th>
                        <th>Description</th>
                        <th>List</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.children?.[0]?._source?.data?.zeek?.["notices"]
                        ?.length > 0 ? (
                        item?.children?.[0]?._source?.data?.zeek?.["notices"]
                          ?.split(/\n/)
                          ?.map((item1, index) =>
                            item1 !== "" ? (
                              <tr className={style.trMain} key={index}>
                                <td className={style.tdMain}>
                                  {addSpace(item1)?.slice(0, 8) || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {item1
                                    ?.replace(/^.{8}/g, "")
                                    ?.split("    ")?.[0]
                                    ?.split(/\t/)?.[0] || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {item1
                                    ?.replace(/^.{8}/g, "")
                                    ?.split("    ")?.[0]
                                    ?.split(/\t/)?.[1] || "-"}
                                </td>
                              </tr>
                            ) : (
                              "NO DATA FOUND"
                            )
                          )
                      ) : (
                        <Row className={style.noRecord} justify="center">
                          NO DATA FOUND
                        </Row>
                      )}
                    </tbody>
                  </table>
                ) : item.label === "Suricata Priority 1" ? (
                  <table key={index} className={style.tableMain}>
                    <thead className={style.theadMain}>
                      <tr className={style.headMain}>
                        <th>count</th>
                        <th>Description</th>
                        <th>List</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.children?.[0]?._source?.data?.suricata?.[
                        "priority"
                      ]?.["1"]?.length > 0 ? (
                        item?.children?.[0]?._source?.data?.suricata?.[
                          "priority"
                        ]?.["1"]
                          ?.split(/\n/)
                          ?.map((item1, index) =>
                            item1 !== "" ? (
                              <tr className={style.trMain} key={index}>
                                <td className={style.tdMain}>
                                  {addSpace(item1)?.slice(0, 8) || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {item1
                                    ?.replace(/^.{8}/g, "")
                                    ?.split("    ")?.[0]
                                    ?.split(/\t/)?.[0] || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {item1
                                    ?.replace(/^.{8}/g, "")
                                    ?.split("    ")?.[0]
                                    ?.split(/\t/)?.[1] || "-"}
                                </td>
                              </tr>
                            ) : null
                          )
                      ) : (
                        <Row className={style.noRecord} justify="center">
                          NO DATA FOUND
                        </Row>
                      )}
                    </tbody>
                  </table>
                ) : item.label === "Suricata Priority 2" ? (
                  <table key={index} className={style.tableMain}>
                    <thead className={style.theadMain}>
                      <tr className={style.headMain}>
                        <th>count</th>
                        <th>Description</th>
                        <th>List</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.children?.[0]?._source?.data?.suricata?.[
                        "priority"
                      ]?.["2"]?.length > 0 ? (
                        item?.children?.[0]?._source?.data?.suricata?.[
                          "priority"
                        ]?.["2"]
                          ?.split(/\n/)
                          ?.map((item1, index) =>
                            item1 !== "" ? (
                              <tr className={style.trMain} key={index}>
                                <td className={style.tdMain}>
                                  {addSpace(item1)?.slice(0, 8) || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {item1
                                    ?.replace(/^.{8}/g, "")
                                    ?.split("    ")?.[0]
                                    ?.split(/\t/)?.[0] || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {item1
                                    ?.replace(/^.{8}/g, "")
                                    ?.split("    ")?.[0]
                                    ?.split(/\t/)?.[1] || "-"}
                                </td>
                              </tr>
                            ) : null
                          )
                      ) : (
                        <Row className={style.noRecord} justify="center">
                          NO DATA FOUND
                        </Row>
                      )}
                    </tbody>
                  </table>
                ) : item.label === "Suricata Priority 3" ? (
                  <table key={index} className={style.tableMain}>
                    <thead className={style.theadMain}>
                      <tr className={style.headMain}>
                        <th>count</th>
                        <th>Description</th>
                        <th>List</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.children?.[0]?._source?.data?.suricata?.[
                        "priority"
                      ]?.["3"]?.length > 0 ? (
                        item?.children?.[0]?._source?.data?.suricata?.[
                          "priority"
                        ]?.["3"]
                          ?.split(/\n/)
                          ?.map((item1, index) =>
                            item1 !== "" ? (
                              <tr className={style.trMain} key={index}>
                                <td className={style.tdMain}>
                                  {addSpace(item1)?.slice(0, 9) || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {item1
                                    ?.replace(/^.{8}/g, "")
                                    ?.split("    ")?.[0]
                                    ?.split(/\t/)?.[0] || "-"}
                                </td>
                                <td className={style.tdMain}>
                                  {item1
                                    ?.replace(/^.{8}/g, "")
                                    ?.split("    ")?.[0]
                                    ?.split(/\t/)?.[1] || "-"}
                                </td>
                              </tr>
                            ) : null
                          )
                      ) : (
                        <Row className={style.noRecord} justify="center">
                          NO DATA FOUND
                        </Row>
                      )}
                    </tbody>
                  </table>
                ) : (
                  <Row justify="center">NO DATA TO DISPLAY</Row>
                )}
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      ) : (
        <>
          <div className={styles.noDataText}>
            <p>Please select network</p>
          </div>
        </>
      )}
      <Modal
        title="IP Address Information"
        open={isVisible}
        footer={null}
        onCancel={handleClose}
        className={styles.siteInfoModalSize}
      >
        <div className={styles.critModalContent}>
          {data?.isAlertLoading ? (
            <Row justify="center" className={styles.loaderContent}>
              <Spin size="large" />
            </Row>
          ) : (
            <div>
              <div className={styles.critThirdAlertModal}>
                {data?.ipaDdrinfo !== null || {} ? (
                  <table className={style.hostListInfoTable}>
                    <tbody>
                      <>
                        <tr className={styles.suricataTable}>
                          <th>IP</th>
                          <td>: {data?.ipaDdrinfo?.ip || "-"}</td>
                        </tr>
                        <tr>
                          <th>host Name</th>
                          <td style={{ color: "black" }}>
                            : {data?.ipaDdrinfo?.hostname || "-"}
                          </td>
                        </tr>
                        <tr className={styles.suricataTable}>
                          <th>Anycast</th>
                          <td>: {data?.ipaDdrinfo?.anycast ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                          <th>City</th>
                          <td>: {data?.ipaDdrinfo?.city || "-"}</td>
                        </tr>
                        <tr className={styles.suricataTable}>
                          <th>Region</th>
                          <td>: {data?.ipaDdrinfo?.region || "-"}</td>
                        </tr>

                        <tr>
                          <th>country</th>
                          <td>: {data?.ipaDdrinfo?.country || "-"}</td>
                        </tr>

                        <tr className={styles.suricataTable}>
                          <th>Location </th>
                          <td>: {data?.ipaDdrinfo?.loc || "-"}</td>
                        </tr>
                        <tr>
                          <th>Organisation</th>
                          <td>: {data?.ipaDdrinfo?.org || "-"}</td>
                        </tr>
                        <tr className={styles.suricataTable}>
                          <th>Postal Code </th>
                          <td>: {data?.ipaDdrinfo?.postal || "-"}</td>
                        </tr>
                        <tr>
                          <th>Timezone </th>
                          <td>: {data?.ipaDdrinfo?.timezone || "-"}</td>
                        </tr>

                        <tr className={styles.suricataTable}>
                          <th>Country Name </th>
                          <td>: {data?.ipaDdrinfo?.country_name || "-"}</td>
                        </tr>
                        <tr>
                          <th>Is EU </th>
                          <td>: {data?.ipaDdrinfo?.isEU ? "Yes" : "No"}</td>
                        </tr>
                        <tr className={styles.suricataTable}>
                          <th> Currency Code </th>
                          <td>
                            : {data?.ipaDdrinfo?.country_currency?.code || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th> Currency Symbol </th>
                          <td>
                            : 
                            {data?.ipaDdrinfo?.country_currency?.symbol || "-"}
                          </td>
                        </tr>
                        <tr className={styles.suricataTable}>
                          <th>Continent Name </th>
                          <td>: {data?.ipaDdrinfo?.continent?.name || "-"}</td>
                        </tr>
                      </>
                    </tbody>
                  </table>
                ) : (
                  <Row justify="center"> NO DATA FOUND </Row>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </Fragment>
  );
}

export default NtaReportTable;
