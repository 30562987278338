/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Card, Col, Row } from "antd";
import { ThreeDots } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import DataTableExtensions from "react-data-table-component-extensions";

import { topAgent } from "../../../Actions/gdpr";

import styles from "./index.module.scss";

function TopReqs(props) {
  const dispatch = useDispatch();
  const isProcess = useSelector((state) => state.gdpr?.isProcess);
  const mostActiveData = useSelector(
    (state) => state?.gdpr?.topAgent?.[2].buckets
  ); 
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(topAgent());
  };

  const columns = [
    {
      name: "Ip Addess",

      selector: (row) => row.key,
      sortable: true,
    },

    {
      name: "IMEI Number",
      selector: (row) => row.doc_count,
      sortable: true,
    },
  ];
  const tableData = {
    columns,
    mostActiveData,
  };

  return (
    <div>
      <Card className={styles.sellerCard}>
        <div className={styles.bestSeller}>
          <p>Recent Events Table</p>
        </div>

        <DataTableExtensions
      filterDigit={0}
          filterPlaceholder="Search"
          print={false}
          export={false}
          data={mostActiveData}
          {...tableData}
        >
          {isProcess ? (
            <Row justify="center">
              <Col>
                <ThreeDots color="#00000" height={50} width={50} />
              </Col>
            </Row>
          ) : (
            <DataTable
              highlightOnHover
              data={mostActiveData}
              columns={columns}
            />
          )}
        </DataTableExtensions>
      </Card>
    </div>
  );
}

export default TopReqs;
