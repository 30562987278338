import React from "react";

import styles from "./index.module.scss";
import { Row } from "antd";
const TableCom = ({ data, headerName, isModal, handleOpen, isUser }) => {
  return (
    <div>
      <table className={styles.tableMain}>
        <thead className={styles.theadMain}>
          <tr className={styles.headMain}>
            {headerName?.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data?.map((item, index) => {
              return (
                <tr className={styles.trMain} key={index}>
                  {isUser ? (
                    <td
                      onClick={() => handleOpen(item?.key)}
                      className={!isModal ? styles.tdMain : styles.color}
                    >
                      {item?.key}
                    </td>
                  ) : (
                    <td className={!isModal ? styles.tdMain : styles.color}>
                      {item?.key}
                    </td>
                  )}

                  <td className={styles.tdMain}>{item?.doc_count}</td>
                </tr>
              );
            })
          ) : (
            <Row className={styles.noRecord} justify="center">
              NO DATA FOUND
            </Row>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableCom;
