import axios from "axios";
import momentTZ from "moment-timezone";
import moment from "moment";

import { EXPORT_CSV_DATA } from "../apiUrl";
import { getLoginUser } from "./localStorage";

export function apiCall(requestMethod, url, data, onSuccess, onFailure) {
  var formData = {
    method: requestMethod,
    url: url,
  };

  var formBody = data;

  if (data !== undefined && data !== "") {
    formData["data"] = formBody;
  }
  try {
    axios(formData)
      .then((response) => response)
      .then((responseJson) => {
        if (responseJson) {
          onSuccess(responseJson);
        } else {
          onFailure(responseJson);
        }
      })
      .catch((e) => {
        onFailure(e.response.data);
      });
  } catch (e) {
    console.log("server disconnected");
  }
}
export function functionalityApiCall(
  requestMethod,
  url,
  data,
  onSuccess,
  onFailure,
  token
) {
  var body = {
    method: requestMethod,
    url: url,
    data: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };
  try {
    axios(body)
      .then((response) => response)
      .then((responseJson) => {
        if (responseJson) {
          onSuccess(responseJson);
        } else {
          onFailure(responseJson);
        }
      })
      .catch((e) => {
        onFailure(e);
      });
  } catch (e) {
    console.log("server disconnected");
  }
}

export function deleteApiCall(url, onSuccess, token) {
  var formData = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    fetch(url, formData, 500).then((response) => {
      response.json().then((responseJson) => {
        if (responseJson.status === 200) {
          onSuccess(responseJson);
        }
      });
    });
  } catch (e) {
    console.log("server disconnected");
  }
}

export function downloadReadableStreamFile(
  site,
  dropdownData,
  fileFormat,
  startDate,
  endDate,
  exportType,
  onSuccess,
  onFailure,
  id,
  ts
) {
  const formData = {
    method: "POST",

    headers: {
      Authorization: "Bearer " + getLoginUser(),
      "Content-Type": "application/json",
    },
    responseType: "blob",
  };

  let formBody = {
    namespace: site,
    gte: startDate,
    lte: endDate,
    size: "5000",
    agentfilter: dropdownData,
  };
  if (id) formBody.id = id;
  if (ts) formBody.ts = ts;

  let form = { ...formBody };

  formData.gte = momentTZ(moment(formBody.gte))
    .tz("America/New_York")
    .utc()
    .format("yyyy-MM-DDTHH:mm:ss");
  formData.lte = momentTZ(moment(formBody.lte))
    .tz("America/New_York")
    .utc()
    .format("yyyy-MM-DDTHH:mm:ss");
  if (form !== undefined && form !== "") {
    formData["body"] = JSON.stringify(form);
  }
  fetch(EXPORT_CSV_DATA + `/${fileFormat}`, formData, 500)
    .then((response) => {
      response
        .blob()
        .then((blob) => {
          const newBlob = new Blob([blob]);
          const blobUrl = window.URL.createObjectURL(newBlob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.setAttribute(
            "download",
            `${
              "export-" +
              moment.utc().format("DD-MM-YYYYTHH-mm-ss") +
              "." +
              exportType
            }`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          onSuccess(blobUrl);
        })
        .catch((error) => {
          onFailure(error);
        });
    })
    .catch((error) => {
      onFailure(error);
    });
}
