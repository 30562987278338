/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState } from "react";
import { Card, Col, Row } from "antd";
import React, { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import DataTableExtensions from "react-data-table-component-extensions";

import { uniqSrcDest } from "../../../Actions/network";

import styles from "./index.module.scss";

function UniqSrcDest(props) {
  const dispatch = useDispatch();

  const isProccess = useSelector((state) => state.network?.isProcess);
  const [getUniqsrcdest, setGetAttackActics] = useState([]);
  const uniqsrcdestData = useSelector((state) => state.network.uniqsrcdestData);
  const data = getUniqsrcdest;

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(uniqSrcDest());
  };

  useEffect(() => {
    let arr = [];

    if (uniqsrcdestData !== null) {
      Object.keys(uniqsrcdestData).map(function (key, index) {
        let obj = {
          key: index + 1,
          value: key,
        };
        arr.push(obj);
      });

      setGetAttackActics(arr);
    }
  }, [uniqsrcdestData]);

  const columns = [
    {
      name: "Key",
      selector: (row) => row.key,
      sortable: true,
    },
    {
      name: "Value",
      selector: (row) => row.value,
      sortable: true,
    },
  ];
  const tableData = {
    columns,
    data,
  };

  return (
    <Row justify="center">
      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
        <Card className={styles.sellerCard}>
          <div className={styles.bestSeller}>
            <p>Uniq Src Dest</p>
          </div>

          <DataTableExtensions
      filterDigit={0}
            filterPlaceholder="Search"
            print={false}
            export={false}
            data={data}
            {...tableData}
          >
            {isProccess ? (
              <Row justify="center">
                <Col>
                  <ThreeDots color="#00000" height={50} width={50} />
                </Col>
              </Row>
            ) : (
              <DataTable highlightOnHover data={data} columns={columns} />
            )}
          </DataTableExtensions>
        </Card>
      </Col>
    </Row>
  );
}

export default UniqSrcDest;
