import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Login from "../pages/Authentication/Login/Login";
import SignUp from "../pages/Authentication/signup/SignUp";
import ForgotPassword from "../pages/Authentication/ForgotPassword/ForgotPassword";

const PublicRoute = ( ) => {
  useEffect(() => {
    if (window.location.pathname === "/") {
      window.location.pathname = "/login";
    }
  }, []);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/sign-up" element={<SignUp />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default PublicRoute;
