import { useSelector } from "react-redux";
import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import { Row, Spin, Col, Button, Breadcrumb } from "antd";

import { allFilterData } from "../../../reducers/alerts";

import styles from "./index.module.scss";

function MitreSecModal({
  isVisible,
  modalTitle,
  setIsVisible,
  isMitreFirstModal,
  setIsMitreFirstModal,
}) {
  const data = useSelector((state) => allFilterData(state));

  const handleBack = () => {
    setIsVisible(!isVisible);
    setIsMitreFirstModal(!isMitreFirstModal);
  };

  const handleCancel = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          title="MITRE Second Level"
          visible={isVisible}
          onCancel={handleCancel}
          className={styles.thirdTableModalWidth}
          footer={false}
          initialWidth={1300}
          initialHeight={700}
        >
          <div className={styles.critModalContent}>
            <Breadcrumb>
              <Breadcrumb.Item>MITRE Tactics</Breadcrumb.Item>
              <Breadcrumb.Item>{modalTitle}</Breadcrumb.Item>
            </Breadcrumb>
            {data?.isAlertLoading ? (
              <Row justify="center" className={styles.loaderContent}>
                <Spin size="large" />
              </Row>
            ) : data.mitreSecond?.length ? (
              <div>
                <table className={styles.surucataSecLevelTable}>
                  <thead>
                    <tr>
                      <th style={{ cursor: "pointer" }}>Name </th>

                      <th>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.mitreSecond?.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          className={index % 2 ? "" : styles.suricataTable}
                        >
                          <td style={{ cursor: "pointer" }}>
                            {item?.key
                              ? JSON.stringify(item.key)?.replaceAll(`"`, "")
                              : "-"}
                          </td>
                          <td>{item?.doc_count}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Row className={styles.buttonRow} justify="center">
                  <Col xxl={3}>
                    <Button className={styles.backButton} onClick={handleBack}>
                      Back
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <Row justify="center">NO DATA FOUND</Row>
            )}
          </div>
        </DraggableModal>
      </DraggableModalProvider>
    </>
  );
}

export default MitreSecModal;
