import React from "react";
import { Col, Row } from "antd";

import TopReqs from "./topReqs/TopReqs";
import MostActive from "./mostActive/MostActive";
import CommonAlert from "./commonAlert/CommonAlert";
import ReqsVolution from "./reqsVolution/ReqsVolution";
import ReqsDistribution from "./reqsDistribution/ReqsDistribution";

import styles from "./index.module.scss";

function Hippa() {
  return (
    <div>
      <Row justify="center" className={styles.mainRow}>
        <Col xxl={22}>
          <Row justify="space-between">
            <Col className={styles.titleName}>
              <p>Common Alert</p>
            </Col>
            <Col xxl={24}>
              <CommonAlert />
            </Col>
          </Row>
          <Row justify="space-between" className={styles.mainRow}>
            <Col className={styles.titleName}>
              <p>Top Reqs</p>
            </Col>
            <Col xxl={24}>
              <TopReqs />
            </Col>
            <Col xxl={24} className={styles.mainRow}>
              <MostActive />
            </Col>
            <Col xxl={24} className={styles.mainRow}>
              <ReqsVolution />
            </Col>
            <Col xxl={24} className={styles.mainRow}>
              <ReqsDistribution />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Hippa;
