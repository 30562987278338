import {
  MOST_ACTIVE_FAIL,
  MOST_ACTIVE_REQUEST,
    MOST_ACTIVE_SUCCESS,
    COMMON_ALERTS_FAIL,
    COMMON_ALERTS_REQUEST,
  COMMON_ALERTS_SUCCESS,
  TOP_REQS_FAIL,
  TOP_REQS_REQUEST,
  TOP_REQS_SUCCESS,
  REQS_EVOLUTION_REQUEST,
  REQS_EVOLUTION_SUCCESS,
  REQS_EVOLUTION_FAIL,
  REQS_DISTRIBUTION_REQUEST,
  REQS_DISTRIBUTION_SUCCESS,
  REQS_DISTRIBUTION_FAIL,
} from "../Actions/actionConstant";

const initialState = {
  status: "",
  message: null,
  commonalerts: [],
  mostactive: [],
  reqsevolution:[],
  reqsdistribution: [],
  topreqs:[],
  isProcess: false,
};
export const hippa = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case COMMON_ALERTS_REQUEST:
      return {
        ...state,
        isProcess: true,
        commonalerts: null,
      };

    case COMMON_ALERTS_SUCCESS:
      return {
        ...state,
        isProcess: false,
        commonalerts: data.data,
      };

    case COMMON_ALERTS_FAIL:
      return {
        ...state,
        commonalerts: null,
        isProcess: false,
      };

    case TOP_REQS_REQUEST:
      return {
        ...state,
        isProcess: true,
        topreqs: null,
      };

    case TOP_REQS_SUCCESS:
      return {
        ...state,
        isProcess: false,
        topreqs: data.data,
      };

    case TOP_REQS_FAIL:
      return {
        ...state,
        topreqs: null,
        isProcess: false,
      };

      case MOST_ACTIVE_REQUEST:
        return {
          ...state,
          isProcess: true,
          mostactive: null,
        };
  
      case MOST_ACTIVE_SUCCESS:
        return {
          ...state,
          isProcess: false,
          mostactive: data.data,
        };
  
      case MOST_ACTIVE_FAIL:
        return {
          ...state,
          mostactive: null,
          isProcess: false,
        };


      case REQS_EVOLUTION_REQUEST:
        return {
          ...state,
          isProcess: true,
          reqsevolution: null,
        };
  
      case REQS_EVOLUTION_SUCCESS:
        return {
          ...state,
          isProcess: false,
          reqsevolution: data.data,
        };
  
      case REQS_EVOLUTION_FAIL:
        return {
          ...state,
          reqsevolution: null,
          isProcess: false,
        };


      case REQS_DISTRIBUTION_REQUEST:
        return {
          ...state,
          isProcess: true,
          reqsdistribution: null,
        };
  
      case REQS_DISTRIBUTION_SUCCESS:
        return {
          ...state,
          isProcess: false,
          reqsdistribution: data.data,
        };
  
      case REQS_DISTRIBUTION_FAIL:
        return {
          ...state,
          reqsdistribution: null,
          isProcess: false,
        };

    default:
      return state;
  }
};
