/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Col, Row } from "antd";
import { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";

import { topAgents } from "../../../Actions/Pcidss";

import styles from "./index.module.scss";

function TopAgents() {
  const dispatch = useDispatch();
  const isProcess = useSelector((state) => state.network?.isProcess);
  const topAgentsData = useSelector(
    (state) => state.pcidss.topAgents?.[2]?.buckets
  ); 

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(topAgents());
  };

  return (
    <div className={styles.mainDiv}>
      {topAgentsData?.map((item, index) => {
        return (
          <Card className={styles.progressCard} key={index}>
            <div className={styles.cardMain}>
              <div className={styles.salesMain}>
                <p className={styles.salesTxt}>{item.key}</p>
              </div>
              {isProcess ? (
                <Row justify="center">
                  <Col>
                    <ThreeDots color="#00000" height={50} width={50} />
                  </Col>
                </Row>
              ) : (
                <div className={styles.alertMain}>{item.doc_count}</div>
              )}
            </div>
          </Card>
        );
      })}
    </div>
  );
}

export default TopAgents;
