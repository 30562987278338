/* eslint-disable react-hooks/exhaustive-deps */

import * as XLSX from "xlsx";
import moment from "moment";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { Row, Col, Select, Button, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";

import InventoryTable from "./inventoryTable";
import { filterDropdown, startDate, endDate } from "../../common/constant";
import { getSentineloneAgentAPI } from "../../Actions/inventory";
import { getSiteLabels } from "../../Actions/alert";

import styles from "./index.module.scss";

const Inventory = () => {
  const dispatch = useDispatch();

  const sentAgentList = useSelector(
    (state) => state.inventory.sentineloneAgentList
  );
  const siteLabelsData = useSelector((state) => state.alerts.siteLabelsData);
  const isSiteLableLoading = useSelector((state) => state.alerts.isProcess);

  const [selectedSite, setSelectedSite] = useState(null);
  const [isApplied, setIsApplied] = useState(false);

  useEffect(() => {
    dispatch(getSiteLabels(startDate, endDate));
  }, []);
  const handleSiteChange = (item) => {
    setSelectedSite(item);
  };

  const handleApply = () => {
    if (!selectedSite) {
      return toast.error("Please Select Host");
    }
    setIsApplied(true);
    dispatch(getSentineloneAgentAPI(selectedSite));
  };

  const exportToExcel = () => {
    const exportData = sentAgentList?.map((item) => ({
      agentname: item.agentname,
      wazuh: item.wazuh,
      sentinelone: item.sentinelone,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `AgentInventory_${moment.utc().format("YYYY-MM-DDThh:mm:ss")}.csv`
    );
  };

  const dropdownOverlay = (
    <Row justify="center">
      <Spin size="small" />
    </Row>
  );

  const modifiedSiteList = siteLabelsData?.map((value, index) => ({
    namespace: value,
  }));

  return (
    <div>
      <Row justify="center" gutter={20} className={styles.filterTableRow}>
        <Col span={6} className={styles.filterContent}>
          <div className={`${styles.filterType} ${styles.siteDropdown}`}>
            <b>Network:</b>
            <Select
              placeholder="Select Network"
              style={{ width: "75%" }}
              className={styles.selectDropdown}
              onSelect={handleSiteChange}
              dropdownRender={(menu) =>
                isSiteLableLoading ? dropdownOverlay : menu
              }
              options={
                modifiedSiteList?.length
                  ? filterDropdown(modifiedSiteList, "namespace", "namespace")
                  : []
              }
            />
          </div>
        </Col>
        <Col xl={3} span={3} className={styles.filterContent}>
          <Button onClick={() => handleApply()} className={styles.applyBtn}>
            Apply
          </Button>
        </Col>
        {isApplied && (
          <Col xl={3} span={3} className={styles.filterContent}>
            <Button onClick={exportToExcel} className={styles.exportBtn}>
              Export
            </Button>
          </Col>
        )}

        <Col xs={22} md={22} lg={22} xl={22} xxl={23}>
          {isApplied ? (
            <InventoryTable selectedSite={selectedSite} />
          ) : (
            <div className={styles.noDataText}>
              <p>Please select network</p>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Inventory;
