/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Col, Row } from "antd";
import Chart from "react-google-charts";
import { ThreeDots } from "react-loader-spinner";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { topTactics } from "../../../Actions/mitreDashboard";

import styles from "./index.module.scss";
function TopActics(props) {
  const dispatch = useDispatch();
  const topicSpinner = useSelector((state) => state.mitre?.isProcess);
  const topTactic = useSelector((state) => state.mitre.topTactics);
  const [alertVolveData, SetAlertVolveData] = useState();

  useEffect(() => {
    if (topTactic) {
      let data = topTactic?.[2]?.buckets;
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        arr.push([data[i].key, data[i].doc_count]);
      }
      arr.unshift(["date", "value"]);
      SetAlertVolveData([...arr]);
    }
  }, [topTactic]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(topTactics());
  };
  const options = {
    is3D: true,
  };

  return (
    <div className={styles.mainDiv}>
      <Card className={styles.progressCard}>
        <div className={styles.cardMain}>
          <div className={styles.salesMain}>
            <p className={styles.salesTxt}>{props.title}</p>
          </div>
          <div className={styles.chartsMain}>
            {topicSpinner ? (
              <Row justify="center">
                <Col>
                  <ThreeDots color="#00000" height={50} width={50} />
                </Col>
              </Row>
            ) : (
              <Chart
                chartType="Line"
                data={alertVolveData}
                options={options}
                width="100%"
                height="300px"
                legendToggle={false}
                legend_toggle={false}
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default TopActics;
