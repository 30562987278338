import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import { capitalize } from "lodash";
import { useSelector } from "react-redux";
import { Row, Col, Button, Breadcrumb, Spin } from "antd";

import styles from "./index.module.scss";

function InventoryInfoModal({ isOpen, setIsOpen }) {
  const agentInventoryListData = useSelector(
    (state) => state.inventory.agentInventoryList
  );

  const handleCancel = () => {
    setIsOpen(!isOpen);
  };

  const handleBack = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          title="Agent Inventory List"
          open={isOpen}
          footer={null}
          initialWidth={600}
          initialHeight={'auto'}
          onCancel={handleCancel}
          className={styles.inventoryInfoModal}
        >
          <div className={styles.critModalContent}>
            <Breadcrumb>
              <Breadcrumb.Item> Agent Inventory List</Breadcrumb.Item>
            </Breadcrumb>
            {!agentInventoryListData ? (
              <Row justify="center" className={styles.loaderContent}>
                <Spin size="large" />
              </Row>
            ) : (
              <div>
                {agentInventoryListData ? (
                  <table
                    style={{ marginTop: "15px" }}
                    className={styles.hostListInfoTable}
                  >
                    <tbody>
                      <>
                        <tr className={styles.suricataTable}>
                          <th>CSRAM</th>
                          <td
                            className={
                              agentInventoryListData?.["CSRAM.exe"] ===
                              "Running"
                                ? styles.activeColorname
                                : styles.otherColorName
                            }
                          >
                            {capitalize(
                              agentInventoryListData?.["CSRAM.exe"]
                            ) || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>EDR</th>
                          <td
                            className={
                              agentInventoryListData?.[
                                "SentinelServiceHost.exe"
                              ] === "Running"
                                ? styles.activeColorname
                                : styles.otherColorName
                            }
                          >
                            {capitalize(
                              agentInventoryListData?.[
                                "SentinelServiceHost.exe"
                              ]
                            ) || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>SIEM</th>
                          <td
                            className={
                              agentInventoryListData?.Wazuh === "Running"
                                ? styles.activeColorname
                                : styles.otherColorName
                            }
                          >
                            {capitalize(agentInventoryListData?.Wazuh) || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Last Scanned</th>
                          <td>
                            {capitalize(agentInventoryListData?.last_scanned) ||
                              "-"}
                          </td>
                        </tr>

                        <tr>
                          <th>Sysmon</th>
                          <td
                            className={
                              agentInventoryListData?.sysmon === "Running"
                                ? styles.activeColorname
                                : styles.otherColorName
                            }
                          >
                            {capitalize(agentInventoryListData?.sysmon) || "-"}
                          </td>
                        </tr>
                      </>
                    </tbody>
                  </table>
                ) : (
                  <Row justify="center">NO DATA FOUND</Row>
                )}
                <Row className={styles.buttonRow} justify="center">
                  <Col xxl={8}>
                    <Button className={styles.backButton} onClick={handleBack}>
                      Back
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </DraggableModal>
      </DraggableModalProvider>
    </>
  );
}

export default InventoryInfoModal;
