import {
  TOP_SRCIP_FAIL,
  TOP_DEST_IP_FAIL,
  SRC_TO_DEST_FAIL,
  DNS_QUERIES_FAIL,
  UNIQ_SRC_DEST_FAIL,
  NETWORK_EVTS_FAIL,
  TOP_SRCIP_SUCCESS,
  TOP_DNS_QUERY_FAIL,
  TOP_SRCIP_REQUEST,
  TOP_DEST_IP_SUCCESS,
  SRC_TO_DEST_REQUEST,
  TOP_QUERY_NAME_FAIL,
  SRC_TO_DEST_SUCCESS,
  TOP_DEST_IP_REQUEST,
  DNS_QUERIES_REQUEST,
  DNS_QUERIES_SUCCESS,
  NETWORK_EVTS_REQUEST,
  NETWORK_EVTS_SUCCESS,
  UNIQ_SRC_DEST_REQUEST,
  UNIQ_SRC_DEST_SUCCESS,
  TOP_DNS_QUERY_REQUEST,
  TOP_QUERY_NAME_REQUEST,
  TOP_QUERY_NAME_SUCCESS,
  TOP_DNS_QUERY_SUCCESS,
  NETWORK_TABLE_SUCCESS,
  NETWORK_TABLE_REQUEST,
  CONNECTION_COUNTRIES_SUCCESS,
  CONNECTION_COUNTRIES_REQUEST,
  CONNECTION_COUNTRIES_FAIL,
  NETWORK_TABLE_FAIL,
} from "../Actions/actionConstant";

const initialState = {
  status: "",
  message: null,
  isProcess: false,
  networkData: [],
  topsrcipData: [],
  topdestipData: [],
  srctodestData: [],
  dnsqueriesData: [],
  uniqsrcdestData: [],
  topdnsqueryData: [],
  topquerynameData: [],
  networktableData: [],
  connectioncountriesData: [],
};
export const network = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case NETWORK_EVTS_REQUEST:
      return {
        ...state,
        isProcess: true,
        networkData: null,
      };

    case NETWORK_EVTS_SUCCESS:
      return {
        ...state,
        isProcess: false,
        networkData: data.data,
      };

    case NETWORK_EVTS_FAIL:
      return {
        ...state,
        networkData: null,
        isProcess: false,
      };

    case DNS_QUERIES_REQUEST:
      return {
        ...state,
        isProcess: true,
        dnsqueriesData: null,
      };

    case DNS_QUERIES_SUCCESS:
      return {
        ...state,
        isProcess: false,
        dnsqueriesData: data.data,
      };

    case DNS_QUERIES_FAIL:
      return {
        ...state,
        dnsqueriesData: null,
        isProcess: false,
      };

    case UNIQ_SRC_DEST_REQUEST:
      return {
        ...state,
        isProcess: true,
        uniqsrcdestData: null,
      };

    case UNIQ_SRC_DEST_SUCCESS:
      return {
        ...state,
        isProcess: false,
        uniqsrcdestData: data.data,
      };

    case UNIQ_SRC_DEST_FAIL:
      return {
        ...state,
        uniqsrcdestData: null,
        isProcess: false,
      };

    case TOP_SRCIP_REQUEST:
      return {
        ...state,
        isProcess: true,
        topsrcipData: null,
      };

    case TOP_SRCIP_SUCCESS:
      return {
        ...state,
        isProcess: false,
        topsrcipData: data.data,
      };

    case TOP_SRCIP_FAIL:
      return {
        ...state,
        topsrcipData: null,
        isProcess: false,
      };

    case TOP_DEST_IP_REQUEST:
      return {
        ...state,
        isProcess: true,
        topdestipData: null,
      };

    case TOP_DEST_IP_SUCCESS:
      return {
        ...state,
        isProcess: false,
        topdestipData: data.data,
      };

    case TOP_DEST_IP_FAIL:
      return {
        ...state,
        topdestipData: null,
        isProcess: false,
      };

    case CONNECTION_COUNTRIES_REQUEST:
      return {
        ...state,
        isProcess: true,
        connectioncountriesData: null,
      };

    case CONNECTION_COUNTRIES_SUCCESS:
      return {
        ...state,
        isProcess: false,
        connectioncountriesData: data.data,
      };

    case CONNECTION_COUNTRIES_FAIL:
      return {
        ...state,
        connectioncountriesData: null,
        isProcess: false,
      };

    case TOP_DNS_QUERY_REQUEST:
      return {
        ...state,
        isProcess: true,
        topdnsqueryData: null,
      };

    case TOP_DNS_QUERY_SUCCESS:
      return {
        ...state,
        isProcess: false,
        topdnsqueryData: data.data,
      };

    case TOP_DNS_QUERY_FAIL:
      return {
        ...state,
        topdnsqueryData: null,
        isProcess: false,
      };

    case NETWORK_TABLE_REQUEST:
      return {
        ...state,
        isProcess: true,
        networktableData: null,
      };

    case NETWORK_TABLE_SUCCESS:
      return {
        ...state,
        isProcess: false,
        networktableData: data.data,
      };

    case NETWORK_TABLE_FAIL:
      return {
        ...state,
        networktableData: null,
        isProcess: false,
      };

    case SRC_TO_DEST_REQUEST:
      return {
        ...state,
        isProcess: true,
        srctodestData: null,
      };

    case SRC_TO_DEST_SUCCESS:
      return {
        ...state,
        isProcess: false,
        srctodestData: data.data,
      };

    case SRC_TO_DEST_FAIL:
      return {
        ...state,
        srctodestData: null,
        isProcess: false,
      };

    case TOP_QUERY_NAME_REQUEST:
      return {
        ...state,
        isProcess: true,
        topquerynameData: null,
      };

    case TOP_QUERY_NAME_SUCCESS:
      return {
        ...state,
        isProcess: false,
        topquerynameData: data.data,
      };

    case TOP_QUERY_NAME_FAIL:
      return {
        ...state,
        topquerynameData: null,
        isProcess: false,
      };

    default:
      return state;
  }
};
