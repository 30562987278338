/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { useSelector } from "react-redux";
import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import { Row, Col, Spin, Button, Breadcrumb } from "antd";

import { allFilterData } from "../../../reducers/alerts";

import styles from "./index.module.scss";

function SiteInfoModal({
  isVisible,
  setIsVisible,
  modalTitle,
  isFirstModal,
  setIsFirstModal,
}) {
  const data = useSelector((state) => allFilterData(state));
  const handelCancel = () => {
    setIsVisible(!isVisible);
  };

  const handleBack = () => {
    setIsFirstModal(!isFirstModal);
    setIsVisible(!isVisible);
  };
  return (
    <DraggableModalProvider>
      <DraggableModal
        title="Network Info"
        visible={isVisible}
        footer={false}
        onCancel={handelCancel}
        className={styles.thirdTableModalWidth}
      >
        <Breadcrumb>
          <Breadcrumb.Item onClick={handleBack}> Host List</Breadcrumb.Item>
          <Breadcrumb.Item>{modalTitle}</Breadcrumb.Item>
        </Breadcrumb>

        <div className={styles.critModalContent}>
          {data?.isOverViewLoading ? (
            <Row justify="center" className={styles.loaderContent}>
              <Spin size="large" />
            </Row>
          ) : (
            <div className={styles.siteInfoModal}>
              <div className={styles.critThirdAlertModal}>
                {data?.hostsInfoData?.length > 0 ? (
                  data.hostsInfoData?.map((item, index) => (
                    <table
                      style={{ marginTop: "15px" }}
                      className={styles.hostListInfoTable}
                    >
                      <tbody>
                        <>
                          <tr
                            key={index}
                            className={index % 2 ? "" : styles.suricataTable}
                          >
                            <th>Group</th>
                            <td style={{ color: "black" }}>
                              {item?.group?.["0"] || "-"}
                            </td>
                          </tr>
                          <tr>
                            <th>Name</th>
                            <td>{item?.name ? item?.name : "-"}</td>
                          </tr>
                          <tr>
                            <th>Manager</th>
                            <td>{item?.manager ? item?.manager : "-"}</td>
                          </tr>
                          <tr>
                            <th>Agent Ip</th>
                            <td>{item?.ip ? item?.ip : "-"}</td>
                          </tr>
                          <tr>
                            <th>Uname</th>
                            <td>{item?.os?.name || "-"}</td>
                          </tr>

                          <tr>
                            <th>Platform</th>
                            <td>{item?.os?.platform || "-"}</td>
                          </tr>

                          <tr>
                            <th>Registration Date</th>
                            <td>
                              {moment(item?.dateAdd).format("LLL") || "-"}
                            </td>
                          </tr>
                          <tr>
                            <th>Last Seen</th>
                            <td>
                              {moment(item?.lastKeepAlive).format("LLL") || "-"}
                            </td>
                          </tr>
                          <tr>
                            <th>Agent Version</th>
                            <td>{item?.version || "-"}</td>
                          </tr>
                          <tr>
                            <th>Agent ID</th>
                            <td>{item?.id || "-"}</td>
                          </tr>

                          <tr>
                            <th>Status</th>
                            <td
                              className={
                                item?.status === "active"
                                  ? styles.activeColorname
                                  : styles.otherColorName
                              }
                            >
                              {item?.status || "-"}
                            </td>
                          </tr>
                        </>
                      </tbody>
                    </table>
                  ))
                ) : (
                  <Row justify="center"> NO DATA FOUND </Row>
                )}
              </div>
              <Row className={styles.buttonRow} justify="center">
                <Col xxl={8}>
                  <Button className={styles.backButton} onClick={handleBack}>
                    Back
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </DraggableModal>
    </DraggableModalProvider>
  );
}

export default SiteInfoModal;
