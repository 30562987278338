import {
  ADD_WEEKLY_REPORT_FAIL,
  ADD_WEEKLY_REPORT_REQUEST,
  ADD_WEEKLY_REPORT_SUCCESS,
} from "../Actions/actionConstant";

const initialState = {
  status: "",
  srcToDest: [],
  isProcess: false,
  message: null,
};
export const reportDetails = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case ADD_WEEKLY_REPORT_REQUEST:
      return {
        ...state,
        srcToDest: null,
        isProcess: true,
      };

    case ADD_WEEKLY_REPORT_SUCCESS:
      return {
        ...state,
        srcToDest: data.data,
        isProcess: false,
      };

    case ADD_WEEKLY_REPORT_FAIL:
      return {
        ...state,
        srcToDest: null,
        isProcess: false,
      };
    default:
      return state;
  }
};
