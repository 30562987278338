import {
  GET_CLOUDS_FAIL,
  GET_CLOUDS_REQUEST,
  GET_CLOUDS_SUCCESS,
  GET_FULL_EVENTS_FAIL,
  GET_FULL_EVENTS_REQUEST,
  GET_FULL_EVENTS_SUCCESS,
  GET_TOP_CLIENT_IP_FAIL,
  GET_TOP_CLIENT_IP_REQUEST,
  GET_TOP_CLIENT_IP_SUCCESS,
  GET_TOP_OPERATION_FAIL,
  GET_TOP_OPERATION_REQUEST,
  GET_TOP_OPERATION_SUCCESS,
  GET_TOP_RULES_FAIL,
  GET_TOP_RULES_REQUEST,
  GET_TOP_RULES_SUCCESS,
} from "../Actions/actionConstant";

const initialState = {
  status: "",
  cloudsData: [],
  topClientIpData: [],
  topRulesData: [],
  topOperationData: [],
  fullEventsData: [],
  isProcess: false,
  message: null,
};
export const cloudsDetails = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case GET_CLOUDS_REQUEST:
      return {
        ...state,
        cloudsData: null,
        isProcess: true,
      };

    case GET_CLOUDS_SUCCESS:
      return {
        ...state,
        cloudsData: data.data,
        isProcess: false,
      };

    case GET_CLOUDS_FAIL:
      return {
        ...state,
        cloudsData: null,
        isProcess: false,
      };
    case GET_TOP_CLIENT_IP_REQUEST:
      return {
        ...state,
        topClientIpData: null,
        isProcess: true,
      };

    case GET_TOP_CLIENT_IP_SUCCESS:
      return {
        ...state,
        topClientIpData: data.data,
        isProcess: false,
      };

    case GET_TOP_CLIENT_IP_FAIL:
      return {
        ...state,
        topClientIpData: null,
        isProcess: false,
      };
    case GET_TOP_RULES_REQUEST:
      return {
        ...state,
        topRulesData: null,
        isProcess: true,
      };

    case GET_TOP_RULES_SUCCESS:
      return {
        ...state,
        topRulesData: data.data,
        isProcess: false,
      };

    case GET_TOP_RULES_FAIL:
      return {
        ...state,
        topRulesData: null,
        isProcess: false,
      };
    case GET_TOP_OPERATION_REQUEST:
      return {
        ...state,
        topOperationData: null,
        isProcess: true,
      };

    case GET_TOP_OPERATION_SUCCESS:
      return {
        ...state,
        topOperationData: data.data,
        isProcess: false,
      };

    case GET_TOP_OPERATION_FAIL:
      return {
        ...state,
        topOperationData: null,
        isProcess: false,
      };

    case GET_FULL_EVENTS_REQUEST:
      return {
        ...state,
        fullEventsData: null,
        isLoader: true,
      };

    case GET_FULL_EVENTS_SUCCESS:
      return {
        ...state,
        fullEventsData: data.data,
        isLoader: false,
      };

    case GET_FULL_EVENTS_FAIL:
      return {
        ...state,
        fullEventsData: null,
        isLoader: false,
      };
    default:
      return state;
  }
};
