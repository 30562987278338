import React from "react";
import { Col, Row } from "antd";

import TopActics from "./topTactics/TopActics";
import DataTableCom from "./dataTable/DataTable";
import AttacktActic from "./attacktActic/AttacktActic";
import RuleLvlAttack from "./ruleLvlAttack/RuleLvlAttack";
import RuleLvltActic from "./ruleLvltActic/RuleLvltActic";

import styles from "./index.module.scss";

function MitreDashboard() {
  return (
    <div>
      <Row justify="center">
        <Col xs={22} md={22} lg={22} xl={22} xxl={22}>
          <Row justify="space-between" className={styles.row}>
            <Col xs={24} md={24} lg={11} xl={11} xxl={11}>
              <DataTableCom />
            </Col>
            <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
              <AttacktActic />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify="center">
        <Col xs={22} md={22} lg={22} xl={22} xxl={22}>
          <Row justify="space-between" className={styles.row}>
            <Col xs={24} md={24} lg={11} xl={11} xxl={11}>
              <RuleLvlAttack />
            </Col>
            <Col xs={24} md={22} lg={12} xl={12} xxl={12}>
              <RuleLvltActic />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify="center" className={styles.row}>
        <Col xs={22} md={22} lg={22} xl={22} xxl={22}>
          <TopActics title="Top Tactics" />
        </Col>
      </Row>
    </div>
  );
}

export default MitreDashboard;
