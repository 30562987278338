/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Card, Col, Row } from "antd";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";

import { network } from "../../../Actions/network";

import styles from "./index.module.scss";

function NetworkeVts(props) {
  const dispatch = useDispatch();
  const isProcess = useSelector((state) => state.network?.isProcess);
  const networkData = useSelector((state) => state.network.networkData);

  useEffect(() => {
    dispatch(network());
  }, []);

  return (
    <div>
      <Card className={styles.progressCard}>
        <div className={styles.cardMain}>
          <div className={styles.salesMain}>
            <p className={styles.salesTxt}>{props.title}</p>
          </div>
          {isProcess ? (
            <Row justify="center">
              <Col>
                <ThreeDots color="#00000" height={50} width={50} />
              </Col>
            </Row>
          ) : (
            <div className={styles.alertMain}>{networkData}</div>
          )}
        </div>
      </Card>   
    </div>
  );
}

export default NetworkeVts;
