import { FILTER_TABLE_DASHBOARD_FAIL, FILTER_TABLE_DASHBOARD_REQUEST, FILTER_TABLE_DASHBOARD_SUCCESS  } from "../Actions/actionConstant";

const initialState = {
  isSiteLoading: true,
  filterTableData: [],
};

export const allSite = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case FILTER_TABLE_DASHBOARD_REQUEST:
      return {
        ...state,
        filterTableData: null,
        isProcess: true,
      };

    case FILTER_TABLE_DASHBOARD_SUCCESS:
      return {
        ...state,
        filterTableData: data.data,
        isProcess: false,
      };

    case FILTER_TABLE_DASHBOARD_FAIL:
      return {
        ...state,
        filterTableData: null,
        isProcess: false,
      };
    default:
      return state;
  }
};
