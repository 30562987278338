/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */

import { Select, Row, Col, Spin } from 'antd'
import ForceLayout from 'react-d3-force-layout'
import { CloseOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'

import { links, nodes } from '../../../connections'

import styles from './index.module.scss'

const ConnectionGraph = () => {
  const [linkArr, setLinkArr] = useState()
  const [nodeArr, setNodeArr] = useState([{}])

  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [graphdata, setgraphData] = useState({ nodes: [], links: [] })
  const [findObjData, setFindObjData] = useState({})

  const [selectedSite, setSelectedSite] = useState(null)

  useEffect(() => {
    let arr = []
    let edgesArr = []
    if (nodes) {
      for (let i = 0; i < nodes?.length; i++) {
        const obj = {
          id: nodes[i]?.id,
          group: 1,
          showChildren: true,
          show: true,
        }
        arr.push(obj)
      }
      setNodeArr([...arr])
    }
    if (links) {
      for (let i = 0; i < links?.length; i++) {
        let obj = {
          source: nodes[links[i].source].id,
          target: nodes[links[i].target].id,
          value: 3,
          show: true,
        }
        edgesArr.push(obj)
      }
      setLinkArr(edgesArr)
    }
    const obj = { nodes: arr, links: edgesArr }
    setgraphData(obj)
  }, [])

  const handleCLose = () => {
    setIsModalOpen(false)
  }
  const onClickNode = function (nodeId) {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
    setIsModalOpen(true)
    const findObj = nodes?.find((item) => item.id === nodeId)
    setFindObjData(findObj)
  }

  const filterDropdown = (arr, label, value) => {
    const dropdownArray = []
    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i]
      dropdownArray.push({
        value: obj[value],
        label: obj[label],
      })
    }
    return dropdownArray
  }
  const handleSiteChange = (item) => {
    setSelectedSite(item)
    const arr1 = nodeArr
    const sourceNodeArray = []
    let filterArr = linkArr?.filter((link) => link?.target?.id === item)
    let findNodeObj = arr1?.find((node) => node?.id === item)
    for (let i = 0; i <= arr1.length; i++) {
      for (let j = 0; j <= filterArr.length; j++) {
        if (filterArr[j]?.source?.id === arr1[i]?.id) {
          if (arr1[i]) {
            sourceNodeArray.push(arr1[i])
          }
        }
      }
    }
    sourceNodeArray.push(findNodeObj)
    const obj = {
      nodes: sourceNodeArray,
      links: filterArr,
    }
    setgraphData(obj)
  }
  return (
    <div>
      <Row className={styles.filterTableRow}>
        <Col lg={24} xl={23} xxl={23} className={styles.filterContent}>
          <div className={`${styles.filterType} ${styles.siteDropdown}`}>
            <b>IP Address:</b>
            <Select
              showSearch
              placeholder="Select IP ..."
              style={{ width: '60%' }}
              className={styles.selectDropdown}
              onChange={handleSiteChange}
              value={selectedSite}
              options={
                nodeArr?.length ? filterDropdown(nodeArr, 'id', 'id') : []
              }
            />
          </div>
        </Col>
      </Row>
      <Row justify="center" style={{ gap: '20px' }}>
        <Col xl={17} xxl={17} className={styles.graphMain}>
          {graphdata?.nodes?.length && (
            <ForceLayout
              nodeLinkObject={graphdata}
              colorFunction={(node) => ({ 1: '#1e263d' }[node.group])}
              nodeClicked={(item) => {
                onClickNode(item?.id)
              }}
              connectionStrength={0.09}
              circleIncreseFactor={5}
              circleRadius={20}
              treeView={false}
              fillType="White"
              key={selectedSite} 
            />
          )}
        </Col>
        <Col xl={6} xxl={6}>
          {loading ? (
            <Row justify="center">
              <Spin />
            </Row>
          ) : (
            isModalOpen && (
              <>
                <div className={styles.idDivMain}>
                  <span>{findObjData?.id}</span>
                  <div
                    onClick={handleCLose}
                    style={{ textAlign: 'right', paddingRight: '10px' }}
                  >
                    <CloseOutlined />
                  </div>
                </div>
                <table className={styles.graphModalTable}>
                  <tbody>
                    <tr>
                      <th className={styles.radiusDiv}>cnt</th>
                      <td className={styles.rightRadiusDiv}>
                        {findObjData?.cnt}
                      </td>
                    </tr>
                    <tr>
                      <th>sessions</th>
                      <td>{findObjData?.sessions}</td>
                    </tr>
                    <tr>
                      <th>inresult</th>
                      <td>{findObjData?.inresult}</td>
                    </tr>
                    <tr>
                      <th>type</th>
                      <td>{findObjData?.type}</td>
                    </tr>
                    <tr>
                      <th>network bytes</th>
                      <td>{findObjData?.['network.bytes']}</td>
                    </tr>
                    <tr>
                      <th>total Data Bytes</th>
                      <td>{findObjData?.totDataBytes}</td>
                    </tr>
                    <tr>
                      <th>network packets </th>
                      <td>{findObjData?.['network.packets']}</td>
                    </tr>
                    <tr>
                      <th>node </th>
                      <td>{findObjData?.node?.[0]}</td>
                    </tr>
                    <tr>
                      <th className={styles.bottomradiusDiv}>pos </th>
                      <td className={styles.bottomRightDiv}>
                        {findObjData?.pos}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )
          )}
        </Col>
      </Row>
      {/* <Modal
        title="Connection Graph Description"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <h1 style={{ textAlign: 'center' }}>{findObjData?.id}</h1>
        <table className={styles.graphModalTable}>
          <tbody>
            <tr>
              <th>cnt</th>
              <td>{findObjData?.cnt}</td>
            </tr>
            <tr>
              <th>sessions</th>
              <td>{findObjData?.sessions}</td>
            </tr>
            <tr>
              <th>inresult</th>
              <td>{findObjData?.inresult}</td>
            </tr>
            <tr>
              <th>type</th>
              <td>{findObjData?.type}</td>
            </tr>
            <tr>
              <th>network bytes</th>
              <td>{findObjData?.['network.bytes']}</td>
            </tr>
            <tr>
              <th>total Data Bytes</th>
              <td>{findObjData?.totDataBytes}</td>
            </tr>
            <tr>
              <th>network packets </th>
              <td>{findObjData?.['network.packets']}</td>
            </tr>
            <tr>
              <th>node </th>
              <td>{findObjData?.node?.[0]}</td>
            </tr>
            <tr>
              <th>pos </th>
              <td>{findObjData?.pos}</td>
            </tr>
          </tbody>
        </table>
      </Modal> */}
    </div>
  )
}

export default ConnectionGraph
