import { notification } from "antd";

import {
  REQS_AGENT_FAIL,
  REQS_AGENT_REQUEST,
  REQS_AGENT_SUCCESS,
  REQUIREMENTS_FAIL,
  REQUIREMENTS_REQUEST,
  REQUIREMENTS_SUCCESS,
  TOP_AGENTS_FAIL,
  TOP_AGENTS_REQUEST,
  TOP_AGENTS_SUCCESS,
  TOP_REQUIREMENTS_FAIL,
  TOP_REQUIREMENTS_REQUEST,
  TOP_REQUIREMENTS_SUCCESS,
} from "./actionConstant";
import { commonBody } from "../common/constant";
import { getLoginUser } from "../common/localStorage";
import { functionalityApiCall } from "../common/connect";
import { REQSAGENT, REQUIREMENTS, TOPAGENTS, TOPREQUIREMENTS } from "../apiUrl";

const requirementsRequest = () => ({
  type: REQUIREMENTS_REQUEST,
});
const requirementsSuccess = (data) => ({
  type: REQUIREMENTS_SUCCESS,
  data,
});
const requirementsFail = () => ({
  type: REQUIREMENTS_FAIL,
});

const topAgentsRequest = () => ({
  type: TOP_AGENTS_REQUEST,
});
const topAgentsSuccess = (data) => ({
  type: TOP_AGENTS_SUCCESS,
  data,
});
const topAgentsFail = () => ({
  type: TOP_AGENTS_FAIL,
});

const toprequirementsRequest = () => ({
  type: TOP_REQUIREMENTS_REQUEST,
});
const toprequirementsSuccess = (data) => ({
  type: TOP_REQUIREMENTS_SUCCESS,
  data,
});
const toprequirementsFail = () => ({
  type: TOP_REQUIREMENTS_FAIL,
});

const reqsagentRequest = () => ({
  type: REQS_AGENT_REQUEST,
});
const reqsagentSuccess = (data) => ({
  type: REQS_AGENT_SUCCESS,
  data,
});
const reqsagentFail = () => ({
  type: REQS_AGENT_FAIL,
});

const data = commonBody;

export const Requirements = (requestData) => (dispatch) => {
  dispatch(requirementsRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(requirementsSuccess(response));
    };
    const url = REQUIREMENTS;

    const onFailure = (response) => {
      dispatch(requirementsFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const topAgents = () => (dispatch) => {
  dispatch(topAgentsRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(topAgentsSuccess(response));
    };
    const url = TOPAGENTS;

    const onFailure = (response) => {
      dispatch(topAgentsFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const topRequirements = () => (dispatch) => {
  dispatch(toprequirementsRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(toprequirementsSuccess(response));
    };
    const url = TOPREQUIREMENTS;

    const onFailure = (response) => {
      dispatch(toprequirementsFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const reqsAgent = () => (dispatch) => {
  dispatch(reqsagentRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(reqsagentSuccess(response));
    };
    const url = REQSAGENT;

    const onFailure = (response) => {
      dispatch(reqsagentFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};
