import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Gdpr from "../pages/gdpr/Gdpr";
import Hippa from "../pages/hippa/Hippa";
import Pcidss from "../pages/pcidss/Pcidss";
import Clouds from "../pages/Clouds/Clouds";
import SideBar from "../pages/Layout/Layout";
import Network from "../pages/network/Network";
import Dashboard from "../pages/vulnStats/VulnStats";
import NtaReport from "../pages/NTAReport/NtaReport";
import VulnStats from "../pages/vulnStats/VulnStats";
import MitreDashboard from "../pages/mitreDashboard/MitreDashboard";
import FilterDataTable from "../pages/FilterDashboard/FilterDataTable";
import ConnectionGraph from "../pages/NTAReport/connectionGraph/ConnectionGraph";
import Inventory from "../pages/Inventory/inventory";

const PrivateRoute = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SideBar />}>
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route path="/" element={<FilterDataTable />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/mitreDashboard" element={<MitreDashboard />} />
            <Route path="/vulnStats" element={<VulnStats />} />
            <Route path="/network" element={<Network />} />
            <Route path="/pcidss" element={<Pcidss />} />
            <Route path="/hippa" element={<Hippa />} />
            <Route path="/gdpr" element={<Gdpr />} />
            <Route path="/nta-report" element={<NtaReport />} />
            <Route path="/clouds" element={<Clouds />} />
            <Route path="/graph" element={<ConnectionGraph />} />
            <Route path="/inventory" element={<Inventory />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default PrivateRoute;
