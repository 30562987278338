import demo from "./Assets/jpeg/dummy.jpeg";

export const chartData = [
  ["Age", "Weight"],
  [2, 2],
  [4, 12],
  [6, 6],
  [8, 14],
  [10, 2],
  [12, 8],
  [14, 4],
];

export const queriesData = [
  {
    title: "COMSPEC Variable in Registry Service",
    id: "T1543.003",
    desc: "Lateral Movement",
    desc1: "Privilege Escalation",
  },
  {
    title: "Suspicious Path Usage Detected",
    id: "T1500.002",
    desc: "Persistence",
    desc1: "Privilege Escalation",
  },
  {
    title: "Suspicious Execution Detected using sc.exe",
    id: "",
    desc: "Persistence",
    desc1: "Service Creation",
  },
];

export const sizeNumber = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
];
export const integration = [
  {
    value: "O365",
    label: "O365",
  },
  {
    value: "AWS",
    label: "AWS",
  },
  {
    value: "GCP",
    label: "GCP",
  },
];

export const sizeFilter = [
  {
    value: "5",
    label: "5",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "15",
    label: "15",
  },
  {
    value: "20",
    label: "20",
  },
  {
    value: "25",
    label: "25",
  },
  {
    value: "30",
    label: "30",
  },
  {
    value: "35",
    label: "35",
  },
  {
    value: "40",
    label: "40",
  },
  {
    value: "45",
    label: "45",
  },
  {
    value: "50",
    label: "50",
  },
  {
    value: "55",
    label: "55",
  },
  {
    value: "60",
    label: "60",
  },
  {
    value: "65",
    label: "65",
  },
  {
    value: "70",
    label: "70",
  },
  {
    value: "75",
    label: "75",
  },
  {
    value: "80",
    label: "80",
  },
  {
    value: "85",
    label: "85",
  },
  {
    value: "90",
    label: "90",
  },
  {
    value: "95",
    label: "95",
  },
  {
    value: "100",
    label: "100",
  },
];
export const revenuedata = [
  ["month", "Weight"],
  ["january", 500],
  ["february", 1500],
  ["march", 3500],
  ["april", 4500],
  ["may", 2500],
  ["june", 6000],
  ["july", 5500],
  ["august", 8000],
];

export const productData = [
  {
    photo: demo,
    description: "i phone 6s Black",
    price: "$999",
    sold: "123 items",
    stock: "521 items",
  },
  {
    photo: demo,
    description: "i phone 6s Black",
    price: "$999",
    sold: "123 items",
    stock: "521 items",
  },
  {
    photo: demo,
    description: "i phone 6s Black",
    price: "$999",
    sold: "123 items",
    stock: "521 items",
  },
];

export const srctodest = {
  doc_count_error_upper_bound: 143,
  sum_other_doc_count: 6911,
  buckets: [
    {
      title: "Sales",
      key: "Sysmon - Event 22: DNSEvent (DNS query) by C:\\\\Windows\\\\Sysmon64.exe",
      doc_count: 2347,
    },
    {
      title: "Earn Money",
      key: "Sysmon - Event 22: DNSEvent (DNS query) by C:\\\\Program Files\\\\Google\\\\Chrome\\\\Application\\\\chrome.exe",
      doc_count: 2215,
    },
    {
      title: "Order",
      key: "Sysmon - Event 22: DNSEvent (DNS query) by C:\\\\ProgramData\\\\Microsoft\\\\Windows Defender\\\\Platform\\\\4.18.2203.5-0\\\\MsMpEng.exe",
      doc_count: 1990,
    },
    {
      title: "Order",
      key: "Sysmon - Event 22: DNSEvent (DNS query) by C:\\\\Program Files (x86)\\\\Microsoft\\\\Edge\\\\Application\\\\msedge.exe",
      doc_count: 1326,
    },
  ],
};

export const amountingested = {
  doc_count_error_upper_bound: 0,
  sum_other_doc_count: 0,
  buckets: [
    {
      photo: demo,
      description: "LAPTOP-7MFBENMI",
      value: 3851,
      stock: 14763,
      sold: "123 items",
    },

    {
      photo: demo,
      description: "WinDev2110Eval",
      value: 3851,
      stock: 14763,
      sold: "123 items",
    },
    {
      photo: demo,
      description: "i phone 6s Black",
      value: 999,
      stock: 521,
      sold: "123 items",
    },
    {
      photo: demo,
      description: "i phone 6s Black",
      value: 999,
      stock: 521,
      sold: "123 items",
    },
  ],
};
