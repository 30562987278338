/* eslint-disable   */
import { useState } from 'react'
import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal'
import { Row, Modal, Breadcrumb, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import TopRiskModalInfo from './TopRiskModalInfo'
import { hostsInfo } from '../../../Actions/overView'
import { allFilterData } from '../../../reducers/alerts'

import styles from './index.module.scss'

function TopRiskModal({
  isVisible,
  setIsVisible,
  siteName,
  startDate,
  endDate,
}) {
  const dispatch = useDispatch()
  const data = useSelector((state) => allFilterData(state))
  const handleClose = () => {
    setIsVisible(!isVisible)
  }

  const [infoTitle, setInfoTitle] = useState()
  const [isInfoModal, setIsInfoModal] = useState(false)

  const showHostinfoModal = (item, title) => {
    setIsVisible(false)
    dispatch(hostsInfo(siteName, item, startDate, endDate))
    setIsInfoModal(!isInfoModal)
    setInfoTitle(title)
  }
  return (
    <div>
      <DraggableModalProvider>
        <DraggableModal
          title="Top 10 Active Agents"
          visible={isVisible}
          footer={null}
          initialWidth={1300}
          initialHeight={700}
          onCancel={handleClose}
          className={styles.thirdTableModalWidth}
        >
          <Breadcrumb>
            <Breadcrumb.Item>Most Active Agents</Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.critModalContent}>
            {data?.isOverViewLoading ? (
              <Row justify="center" className={styles.loaderContent}>
                <Spin size="large" />
              </Row>
            ) : (
              <div>
                {data?.topRiskAggregreted?.length ? (
                  <table className={styles.surucataSecLevelTable}>
                    <thead>
                      <tr>
                        <th
                          className={styles.leftTr}
                          style={{ cursor: 'pointer' }}
                        >
                          {' '}
                          Agent Name
                        </th>
                        <th className={styles.rightTr}>Agent IP</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.topRiskAggregreted?.map((item, index) => (
                        <tr
                          key={index}
                          className={index % 2 ? '' : styles.suricataTable}
                        >
                          <td
                            className={styles.leftTr}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (item?.name)
                                showHostinfoModal(item?.id, item?.name)
                            }}
                          >
                            {item?.name ? item.name : '-'}
                          </td>
                          <td className={styles.rightTr}>
                            {item?.ip ? item?.ip : '-'}
                          </td>

                          {/* <td>
                      <Button
                        onClick={() => {
                          if (
                            item?.["3"]?.buckets?.["0"]?.["4"]?.buckets?.["0"]
                              ?.key
                          )
                            showTopRiskinfoModal(
                              item?.["3"]?.buckets?.["0"]?.["4"]?.buckets?.["0"]
                                ?.key,
                              siteName,
                              item.key
                            );
                        }}
                      >
                        More Info
                      </Button>
                    </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Row justify="center">NO DATA FOUND</Row>
                )}
              </div>
            )}
          </div>
        </DraggableModal>
      </DraggableModalProvider>
      <TopRiskModalInfo
        isVisible={isInfoModal}
        modalTitle={infoTitle}
        setIsVisible={setIsInfoModal}
        isFirstModal={isVisible}
        setIsFirstModal={setIsVisible}
      />
    </div>
  )
}

export default TopRiskModal
