import {
  REQS_AGENT_FAIL,
  REQS_AGENT_REQUEST,
  REQS_AGENT_SUCCESS,
  REQUIREMENTS_FAIL,
  REQUIREMENTS_REQUEST,
  REQUIREMENTS_SUCCESS,
  TOP_AGENTS_FAIL,
  TOP_AGENTS_REQUEST,
  TOP_AGENTS_SUCCESS,
  TOP_REQUIREMENTS_FAIL,
  TOP_REQUIREMENTS_REQUEST,
  TOP_REQUIREMENTS_SUCCESS,
} from "../Actions/actionConstant";

const initialState = {
  status: "",
  message: null,
  requirements: [],
  topAgents: [],
  toprequirements: [],
  reqsagent: [],
  isProcess: false,
};
export const pcidss = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case REQUIREMENTS_REQUEST:
      return {
        ...state,
        isProcess: true,
        requirements: null,
      };

    case REQUIREMENTS_SUCCESS:
      return {
        ...state,
        isProcess: false,
        requirements: data.data,
      };

    case REQUIREMENTS_FAIL:
      return {
        ...state,
        requirements: null,
        isProcess: false,
      };
    case TOP_AGENTS_REQUEST:
      return {
        ...state,
        isProcess: true,
        topAgents: null,
      };

    case TOP_AGENTS_SUCCESS:
      return {
        ...state,
        isProcess: false,
        topAgents: data.data,
      };

    case TOP_AGENTS_FAIL:
      return {
        ...state,
        topAgents: null,
        isProcess: false,
      };
    case TOP_REQUIREMENTS_REQUEST:
      return {
        ...state,
        isProcess: true,
        toprequirements: null,
      };

    case TOP_REQUIREMENTS_SUCCESS:
      return {
        ...state,
        isProcess: false,
        toprequirements: data.data,
      };

    case TOP_REQUIREMENTS_FAIL:
      return {
        ...state,
        toprequirements: null,
        isProcess: false,
      };
    case REQS_AGENT_REQUEST:
      return {
        ...state,
        isProcess: true,
        reqsagent: null,
      };

    case REQS_AGENT_SUCCESS:
      return {
        ...state,
        isProcess: false,
        reqsagent: data.data,
      };

    case REQS_AGENT_FAIL:
      return {
        ...state,
        reqsagent: null,
        isProcess: false,
      };

    default:
      return state;
  }
};
