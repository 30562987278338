import {
  TOP_ALERTS_FAIL,
  TOP_ALERTS_REQUEST,
  TOP_ALERTS_SUCCESS,
  CRIT_ALERTS_REQUEST,
  CRIT_ALERTS_SUCCESS,
  CRIT_ALERTS_FAIL,
  HOSTS_UNIQUE_REQUEST,
  HOSTS_UNIQUE_SUCCESS,
  HOSTS_UNIQUE_FAIL,
  UNIQUE_HOSTS_REQUEST,
  UNIQUE_HOSTS_SUCCESS,
  UNIQUE_HOSTS_FAIL,
  VULAN_TOTAL_REQUEST,
  VULAN_TOTAL_SUCCESS,
  VULAN_TOTAL_FAIL,
  RECENT_EVENT_REQUEST,
  RECENT_EVENT_SUCCESS,
  RECENT_EVENT_FAIL,
  UNIQUE_IPS_REQUEST,
  UNIQUE_IPS_SUCCESS,
  UNIQUE_IPS_FAIL,
  AGENTS_LIST_REQUEST,
  AGENTS_LIST_SUCCESS,
  AGENTS_LIST_FAIL,
  HOSTS_INFO_REQUEST,
  HOSTS_INFO_FAIL,
  HOSTS_INFO_SUCCESS,
} from "../Actions/actionConstant";

const initialState = {
  status: "",
  topAlerts: [],
  critAlerts: [],
  hostsUnique: [],
  isProcess: false,
  agentsListData: [],
  hostsInfoData: [],
  uniqueHosts: [],
  vulanTotal: [],
  recentEvent: [],
  uniqueIps: [],
  message: null,
};
export const overView = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case TOP_ALERTS_REQUEST:
      return {
        ...state,
        topAlerts: null,
        isProcess: true,
      };

    case TOP_ALERTS_SUCCESS:
      return {
        ...state,
        topAlerts: data.data,
        isProcess: false,
      };

    case TOP_ALERTS_FAIL:
      return {
        ...state,
        topAlerts: null,
        isProcess: false,
      };
    case CRIT_ALERTS_REQUEST:
      return {
        ...state,
        critAlerts: null,
        isProcess: true,
      };

    case CRIT_ALERTS_SUCCESS:
      return {
        ...state,
        critAlerts: data.data,
        isProcess: false,
      };

    case CRIT_ALERTS_FAIL:
      return {
        ...state,
        critAlerts: null,
        isProcess: false,
      };
    case HOSTS_UNIQUE_REQUEST:
      return {
        ...state,
        hostsUnique: null,
        isProcess: true,
      };

    case HOSTS_UNIQUE_SUCCESS:
      return {
        ...state,
        hostsUnique: data.data,
        isProcess: false,
      };

    case HOSTS_UNIQUE_FAIL:
      return {
        ...state,
        hostsUnique: null,
        isProcess: false,
      };
    case AGENTS_LIST_REQUEST:
      return {
        ...state,
        agentsListData: null,
        isProcess: true,
      };

    case AGENTS_LIST_SUCCESS:
      return {
        ...state,
        agentsListData: data.data,
        isProcess: false,
      };

    case AGENTS_LIST_FAIL:
      return {
        ...state,
        agentsListData: null,
        isProcess: false,
      };
    case HOSTS_INFO_REQUEST:
      return {
        ...state,
        hostsInfoData: null,
        isProcess: true,
      };

    case HOSTS_INFO_SUCCESS:
      return {
        ...state,
        hostsInfoData: data.data,
        isProcess: false,
      };

    case HOSTS_INFO_FAIL:
      return {
        ...state,
        hostsInfoData: null,
        isProcess: false,
      };
    case UNIQUE_HOSTS_REQUEST:
      return {
        ...state,
        uniqueHosts: null,
        isProcess: true,
      };
    case UNIQUE_HOSTS_SUCCESS:
      return {
        ...state,
        uniqueHosts: data.data,
        isProcess: false,
      };
    case UNIQUE_HOSTS_FAIL:
      return {
        ...state,
        uniqueHosts: null,
        isProcess: false,
      };
    case VULAN_TOTAL_REQUEST:
      return {
        ...state,
        vulanTotal: null,
        isProcess: true,
      };
    case VULAN_TOTAL_SUCCESS:
      return {
        ...state,
        vulanTotal: data.data,
        isProcess: false,
      };
    case VULAN_TOTAL_FAIL:
      return {
        ...state,
        vulanTotal: null,
        isProcess: false,
      };
    case RECENT_EVENT_REQUEST:
      return {
        ...state,
        recentEvent: null,
        isProcess: true,
      };
    case RECENT_EVENT_SUCCESS:
      return {
        ...state,
        recentEvent: data.data,
        isProcess: false,
      };

    case RECENT_EVENT_FAIL:
      return {
        ...state,
        recentEvent: null,
        isProcess: false,
      };
    case UNIQUE_IPS_REQUEST:
      return {
        ...state,
        uniqueIps: null,
        isProcess: true,
      };

    case UNIQUE_IPS_SUCCESS:
      return {
        ...state,
        uniqueIps: data.data,
        isProcess: false,
      };

    case UNIQUE_IPS_FAIL:
      return {
        ...state,
        uniqueIps: null,
        isProcess: false,
      };
    default:
      return state;
  }
};
