import {
  TOP_TACTICS_FAIL,
  ALERT_VOLVE_FAIL,
  ATTACK_ACTICS_FAIL,
  TOP_TACTICS_SUCCESS,
  ALERT_VOLVE_REQUST,
  TOP_TACTICS_REQUEST,
  ALERT_VOLVE_SUCCESS,
  RULE_LVL_TACTIC_FAIL,
  RULE_LVL_ATTACK_FAIL,
  ATTACK_ACTICS_REQUEST,
  ATTACK_ACTICS_SUCCESS,
  RULE_LVL_ATTACK_REQUEST,
  RULE_LVL_ATTACK_SUCCESS,
  RULE_LVL_TACTIC_REQUEST,
  RULE_LVL_TACTIC_SUCCESS,
  ALERT_COUNT_MITRE_SUCCESS,
  ALERT_COUNT_MITRE_REQUEST,
  ALERT_COUNT_MITRE_FAIL,
} from "../Actions/actionConstant";

const initialState = {
  status: "",
  message: null,
  topTactics: [],
  uniqueIps: [],
  alertVolve: [],
  isProcess: false,
  vulanTotal: [],
  recentEvent: [],
  uniqueHosts: [],
  attackActics: [],
  rulelvltactic: [],
  ruleLvlAttack: [],
  alertCount: [],
};
export const mitre = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case ALERT_VOLVE_REQUST:
      return {
        ...state,
        isProcess: true,
        alertVolve: null,
      };

    case ALERT_VOLVE_SUCCESS:
      return {
        ...state,
        isProcess: false,
        alertVolve: data.data,
      };

    case ALERT_VOLVE_FAIL:
      return {
        ...state,
        alertVolve: null,
        isProcess: false,
      };

    case TOP_TACTICS_REQUEST:
      return {
        ...state,
        topTactics: null,
        isProcess: true,
      };

    case TOP_TACTICS_SUCCESS:
      return {
        ...state,
        isProcess: false,
        topTactics: data.data,
      };
    case TOP_TACTICS_FAIL:
      return {
        ...state,
        topTactics: null,
        isProcess: false,
      };
    case RULE_LVL_ATTACK_REQUEST:
      return {
        ...state,
        isProcess: true,
        ruleLvlAttack: null,
      };

    case RULE_LVL_ATTACK_SUCCESS:
      return {
        ...state,
        isProcess: false,
        ruleLvlAttack: data.data,
      };
    case RULE_LVL_ATTACK_FAIL:
      return {
        ...state,
        isProcess: false,
        ruleLvlAttack: null,
      };

    case ATTACK_ACTICS_REQUEST:
      return {
        ...state,
        isProcess: true,
        attackActics: null,
      };

    case ATTACK_ACTICS_SUCCESS:
      return {
        ...state,
        isProcess: false,
        attackActics: data.data,
      };
    case ATTACK_ACTICS_FAIL:
      return {
        ...state,
        isProcess: false,
        attackActics: null,
      };

    case RULE_LVL_TACTIC_REQUEST:
      return {
        ...state,
        isProcess: true,
        rulelvltactic: null,
      };

    case RULE_LVL_TACTIC_SUCCESS:
      return {
        ...state,
        isProcess: false,
        rulelvltactic: data.data,
      };
    case RULE_LVL_TACTIC_FAIL:
      return {
        ...state,
        isProcess: false,
        rulelvltactic: null,
      };
    case ALERT_COUNT_MITRE_REQUEST:
      return {
        ...state,
        isProcess: true,
        alertCount: null,
      };

    case ALERT_COUNT_MITRE_SUCCESS:
      return {
        ...state,
        isProcess: false,
        alertCount: data.data,
      };
    case ALERT_COUNT_MITRE_FAIL:
      return {
        ...state,
        isProcess: false,
        alertCount: null,
      };

    default:
      return state;
  }
};
