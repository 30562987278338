/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Card, Col, Row } from "antd";
import Chart from "react-google-charts";
import React, { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";

import { topRequirements } from "../../../Actions/Pcidss";

import styles from "./index.module.scss";

function TopReqirements(props) {
  const dispatch = useDispatch();
  const isProccess = useSelector((state) => state.pcidss?.isProcess);
  const [topRequirement, setTopRequirement] = useState([]);
  const topRequirementsData = useSelector(
    (state) => state.pcidss.toprequirements?.[2]?.buckets
  );
  const data = topRequirements;

  useEffect(() => {
    if (topRequirementsData) {
      let data = topRequirementsData;
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        arr.push([data[i]?.[3]?.buckets[2]?.key, data[i].doc_count]);
      }
      arr.unshift(["key_as_string", "value"]);
      setTopRequirement([...arr]);
    }
  }, [topRequirementsData]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(topRequirements());
  };

  const options = {
    title: "Top requirements",
  };
  return (
    <Row justify="center">
      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
        <Card className={styles.sellerCard}>
          <div className={styles.bestSeller}>
            <p>{props.title}</p>
          </div>

          {isProccess ? (
            <Row justify="center">
              <Col>
                <ThreeDots color="#00000" height={50} width={50} />
              </Col>
            </Row>
          ) : (
            <Chart
              chartType="PieChart"
              data={data}
              options={options}
              width={"100%"}
              height={"400px"}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default TopReqirements;
