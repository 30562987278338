/* eslint-disable  */
import { useSelector } from "react-redux";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute"; 

const Routing = () => {
  const userToken = useSelector((state) => state.user.token);

  if (userToken) return <PrivateRoute />;
  else return <PublicRoute />;
};

export default Routing;
