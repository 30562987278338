import {
  ACTIVE_VULNS_FAIL,
  ACTIVE_VULNS_REQUEST,
  ACTIVE_VULNS_SUCCESS,
  VULNPIE_FAIL,
  VULNPIE_REQUEST,
  VULNPIE_SUCCESS,
  VULNTABLE_FAIL,
  VULNTABLE_REQUEST,
  VULNTABLE_SUCCESS,
} from "../Actions/actionConstant";

const initialState = {
  status: "",
  message: null,
  vulnTable: [],
  vulnPie: [],
  activeVuln: [],
  isProcess: false,
};
export const vulnstats = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case VULNPIE_REQUEST:
      return {
        ...state,
        isProcess: true,
        vulnPie: null,
      };

    case VULNPIE_SUCCESS:
      return {
        ...state,
        isProcess: false,
        vulnPie: data.data,
      };

    case VULNPIE_FAIL:
      return {
        ...state,
        vulnPie: null,
        isProcess: false,
      };

    case ACTIVE_VULNS_REQUEST:
      return {
        ...state,
        isProcess: true,
        activeVuln: null,
      };

    case ACTIVE_VULNS_SUCCESS:
      return {
        ...state,
        isProcess: false,
        activeVuln: data.data,
      };

    case ACTIVE_VULNS_FAIL:
      return {
        ...state,
        activeVuln: null,
        isProcess: false,
      };
    case VULNTABLE_REQUEST:
      return {
        ...state,
        isProcess: true,
        vulnTable: null,
      };

    case VULNTABLE_SUCCESS:
      return {
        ...state,
        isProcess: false,
        vulnTable: data.data,
      };

    case VULNTABLE_FAIL:
      return {
        ...state,
        vulnTable: null,
        isProcess: false,
      };

    default:
      return state;
  }
};
