import { notification } from "antd";

import {
  GET_SENTINELONE_AGENT_REQUEST,
  GET_SENTINELONE_AGENT_SUCCESS,
  GET_SENTINELONE_AGENT_FAIL,
  GET_INVENTORY_AGENT_REQUEST,
  GET_INVENTORY_AGENT_SUCCESS,
  GET_INVENTORY_AGENT_FAIL,
} from "./actionConstant";

import {
  GET_SENTINELONE_AGENT_LIST,
  GET_AGENT_INVENTORY_LIST,
} from "../apiUrl";

import { getLoginUser } from "../common/localStorage";
import { functionalityApiCall } from "../common/connect";

const getSentineloneAgentRequest = () => ({
  type: GET_SENTINELONE_AGENT_REQUEST,
});

const getSentineloneAgentSuccess = (data) => ({
  type: GET_SENTINELONE_AGENT_SUCCESS,
  data,
});

const getSentineloneAgentFail = () => ({
  type: GET_SENTINELONE_AGENT_FAIL,
});

const getInventoryAgentRequest = () => ({
  type: GET_INVENTORY_AGENT_REQUEST,
});

const getInventoryAgentSuccess = (data) => ({
  type: GET_INVENTORY_AGENT_SUCCESS,
  data,
});

const getInventoryAgentFail = () => ({
  type: GET_INVENTORY_AGENT_FAIL,
});

export const getSentineloneAgentAPI = (namespace) => (dispatch) => {
  dispatch(getSentineloneAgentRequest());

  const token = getLoginUser();

  if (token) {
    const onSuccess = (response) => {
      dispatch(getSentineloneAgentSuccess(response));
    };
    const url = GET_SENTINELONE_AGENT_LIST;

    const onFailure = (response) => {
      dispatch(getSentineloneAgentFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    let body = {
      namespace: namespace,
    };

    functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
  }
};

export const getAgentInventoryAPI = (namespace, agentid) => (dispatch) => {
  dispatch(getInventoryAgentRequest());

  const token = getLoginUser();

  if (token) {
    const onSuccess = (response) => {
      dispatch(getInventoryAgentSuccess(response));
    };
    const url = GET_AGENT_INVENTORY_LIST;

    const onFailure = (response) => {
      dispatch(getInventoryAgentFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    let body = {
      namespace: namespace,
      agentid: agentid,
    };

    functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
  }
};
