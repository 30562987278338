/* eslint-disable   */
import { Fragment, useEffect, useState } from "react";
import { Button, Row, Col, Spin, Input, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CloseCircleTwoTone, CheckCircleTwoTone } from "@ant-design/icons";

import InventoryInfoModal from "./inventoryInfoModal";
import { getAgentInventoryAPI } from "../../Actions/inventory";

import styles from "./index.module.scss";

const InventoryTable = ({ selectedSite }) => {
  const dispatch = useDispatch();
  const isSiteLoading = useSelector((state) => state.inventory.isLoader);
  const sentAgentList = useSelector(
    (state) => state.inventory.sentineloneAgentList
  );

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sentineloneCount, setSentineloneCount] = useState(0);
  const [wazuhCount, setWazuhCount] = useState(0);

  useEffect(() => {
    let sentinelOne = 0;
    let wazuh = 0;

    sentAgentList?.forEach((agent) => {
      if (agent.sentinelone === "installed") {
        sentinelOne++;
      }
      if (agent.wazuh === "installed") {
        wazuh++;
      }
    });

    // Update the state variables with the counts
    setSentineloneCount(sentinelOne);
    setWazuhCount(wazuh);
  }, [sentAgentList]);

  const handleInfoClick = (agentid) => {
    setIsOpen(true);
    dispatch(getAgentInventoryAPI(selectedSite, agentid));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = sentAgentList?.filter(
    (item) =>
      (item?.agentname || item?.name) &&
      (item?.agentname || item?.name)
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      title: "Agent Name",
      dataIndex: "key",
      sorter: (a, b) => {
        const aValue = a?.agentname || a?.name;
        const bValue = b?.agentname || b?.name;
        return aValue.localeCompare(bValue);
      },
      render: (text, record) => <span>{record.agentname || record.name}</span>,
      width: 500,
    },
    {
      title: "EDR",
      dataIndex: "sentinelone",
      align: "center",
      sorter: (a, b) => a.sentinelone.localeCompare(b.sentinelone),
      render: (text) => {
        return text === "installed" ? (
          <CheckCircleTwoTone
            twoToneColor="#52c41a"
            style={{ fontSize: "25px" }}
          />
        ) : (
          <CloseCircleTwoTone
            twoToneColor="#ff0000"
            style={{ fontSize: "25px" }}
          />
        );
      },
    },
    {
      title: "SIEM",
      dataIndex: "wazuh",
      align: "center",
      sorter: (a, b) => a.wazuh.localeCompare(b.wazuh),
      render: (text) =>
        text === "installed" ? (
          <CheckCircleTwoTone
            twoToneColor="#52c41a"
            style={{ fontSize: "25px" }}
          />
        ) : (
          <CloseCircleTwoTone
            twoToneColor="#ff0000"
            style={{ fontSize: "25px" }}
          />
        ),
    },
    {
      title: "Action",
      dataIndex: "wazuh",
      align: "center",
      render: (text, record) =>
        text == "installed" ? (
          <Button
            onClick={() => handleInfoClick(record.agentid || record.id)}
            className={styles.moreInfoBtn}
          >
            More Info
          </Button>
        ) : (
          <Button disabled className={styles.moreInfoBtnDisable}>
            More Info
          </Button>
        ),
    },
  ];

  return (
    <Fragment>
      {isSiteLoading ? (
        <Row justify="center" className={styles.loaderContent}>
          <Spin size="large" />
        </Row>
      ) : (
        <Row>
          <Col xl={24} span={24} className={styles.search}>
            <Input
              type="text"
              placeholder="Search Agents"
              value={searchTerm}
              onChange={handleSearch}
              className={styles.searchInput}
            ></Input>
          </Col>
          <Col xl={24} span={24} className={styles.countStyle}>
            <div className={styles.edrCount}>
              <h3>EDR: {sentineloneCount}</h3>
            </div>
            <div className={styles.wazuhCount}>
              <h3>SIEM: {wazuhCount}</h3>
            </div>
          </Col>
          <Col xl={24} span={24}>
            <Table columns={columns} dataSource={filteredData} />
          </Col>
        </Row>
      )}
      <InventoryInfoModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedSite={selectedSite}
      />
    </Fragment>
  );
};

export default InventoryTable;
