import { notification } from "antd";

import {
  TOPSRCIP,
  TOPDESTIP,
  SRCTODEST,
  DNSQUERIES,
  NETWORKEVTS,
  TOPDNSQUERY,
  UNIQSRCDEST,
  TOPQUERYNAME,
  NETWORKTABLE,
  CONNECTIONCOUNTRIES,
} from "../apiUrl";
import {
  TOP_SRCIP_FAIL,
  TOP_DEST_IP_FAIL,
  SRC_TO_DEST_FAIL,
  DNS_QUERIES_FAIL,
  NETWORK_EVTS_FAIL,
  TOP_SRCIP_REQUEST,
  TOP_SRCIP_SUCCESS,
  UNIQ_SRC_DEST_FAIL,
  TOP_DNS_QUERY_FAIL,
  NETWORK_TABLE_FAIL,
  TOP_QUERY_NAME_FAIL,
  TOP_DEST_IP_SUCCESS,
  SRC_TO_DEST_SUCCESS,
  TOP_DEST_IP_REQUEST,
  SRC_TO_DEST_REQUEST,
  DNS_QUERIES_REQUEST,
  DNS_QUERIES_SUCCESS,
  NETWORK_EVTS_REQUEST,
  TOP_DNS_QUERY_REQUEST,
  TOP_DNS_QUERY_SUCCESS,
  NETWORK_EVTS_SUCCESS,
  NETWORK_TABLE_REQUEST,
  NETWORK_TABLE_SUCCESS,
  TOP_QUERY_NAME_REQUEST,
  TOP_QUERY_NAME_SUCCESS,
  UNIQ_SRC_DEST_REQUEST,
  UNIQ_SRC_DEST_SUCCESS,
  CONNECTION_COUNTRIES_FAIL,
  CONNECTION_COUNTRIES_SUCCESS,
  CONNECTION_COUNTRIES_REQUEST,
} from "./actionConstant";
import { commonBody } from "../common/constant";
import { getLoginUser } from "../common/localStorage";
import { functionalityApiCall } from "../common/connect";

const networkRequest = () => ({
  type: NETWORK_EVTS_REQUEST,
});
const networkSuccess = (data) => ({
  type: NETWORK_EVTS_SUCCESS,
  data,
});
const networkFail = () => ({
  type: NETWORK_EVTS_FAIL,
});

const dnsqueriesRequest = () => ({
  type: DNS_QUERIES_REQUEST,
});
const dnsqueriesSuccess = (data) => ({
  type: DNS_QUERIES_SUCCESS,
  data,
});
const dnsqueriesFail = () => ({
  type: DNS_QUERIES_FAIL,
});

const uniqsrcdestRequest = () => ({
  type: UNIQ_SRC_DEST_REQUEST,
});
const uniqsrcdestSuccess = (data) => ({
  type: UNIQ_SRC_DEST_SUCCESS,
  data,
});
const uniqsrcdestFail = () => ({
  type: UNIQ_SRC_DEST_FAIL,
});

const topsrcipRequest = () => ({
  type: TOP_SRCIP_REQUEST,
});
const topsrcipSuccess = (data) => ({
  type: TOP_SRCIP_SUCCESS,
  data,
});
const topsrcipFail = () => ({
  type: TOP_SRCIP_FAIL,
});

const topdestipRequest = () => ({
  type: TOP_DEST_IP_REQUEST,
});
const topdestipSuccess = (data) => ({
  type: TOP_DEST_IP_SUCCESS,
  data,
});
const topdestipFail = () => ({
  type: TOP_DEST_IP_FAIL,
});

const connectioncountriesRequest = () => ({
  type: CONNECTION_COUNTRIES_REQUEST,
});
const connectioncountriesSuccess = (data) => ({
  type: CONNECTION_COUNTRIES_SUCCESS,
  data,
});
const connectioncountriesFail = () => ({
  type: CONNECTION_COUNTRIES_FAIL,
});

const topdnsqueryRequest = () => ({
  type: TOP_DNS_QUERY_REQUEST,
});
const topdnsquerySuccess = (data) => ({
  type: TOP_DNS_QUERY_SUCCESS,
  data,
});
const topdnsqueryFail = () => ({
  type: TOP_DNS_QUERY_FAIL,
});

const networktableRequest = () => ({
  type: NETWORK_TABLE_REQUEST,
});
const networktableSuccess = (data) => ({
  type: NETWORK_TABLE_SUCCESS,
  data,
});
const networktableFail = () => ({
  type: NETWORK_TABLE_FAIL,
});

const srctodestRequest = () => ({
  type: SRC_TO_DEST_REQUEST,
});
const srctodestSuccess = (data) => ({
  type: SRC_TO_DEST_SUCCESS,
  data,
});
const srctodestFail = () => ({
  type: SRC_TO_DEST_FAIL,
});

const topquerynameRequest = () => ({
  type: TOP_QUERY_NAME_REQUEST,
});
const topquerynameSuccess = (data) => ({
  type: TOP_QUERY_NAME_SUCCESS,
  data,
});
const topquerynameFail = () => ({
  type: TOP_QUERY_NAME_FAIL,
});

const data = commonBody;

export const network = (requestData) => (dispatch) => {
  dispatch(networkRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(networkSuccess(response));
    };
    const url = NETWORKEVTS;

    const onFailure = (response) => {
      dispatch(networkFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const dnsQueries = (requestData) => (dispatch) => {
  dispatch(dnsqueriesRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(dnsqueriesSuccess(response));
    };
    const url = DNSQUERIES;

    const onFailure = (response) => {
      dispatch(dnsqueriesFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const uniqSrcDest = (requestData) => (dispatch) => {
  dispatch(uniqsrcdestRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(uniqsrcdestSuccess(response));
    };
    const url = UNIQSRCDEST;

    const onFailure = (response) => {
      dispatch(uniqsrcdestFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const topSrcIp = (requestData) => (dispatch) => {
  dispatch(topsrcipRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(topsrcipSuccess(response));
    };
    const url = TOPSRCIP;

    const onFailure = (response) => {
      dispatch(topsrcipFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const topDestIp = (requestData) => (dispatch) => {
  dispatch(topdestipRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(topdestipSuccess(response));
    };
    const url = TOPDESTIP;

    const onFailure = (response) => {
      dispatch(topdestipFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const connectionCountries = (requestData) => (dispatch) => {
  dispatch(connectioncountriesRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(connectioncountriesSuccess(response));
    };
    const url = CONNECTIONCOUNTRIES;

    const onFailure = (response) => {
      dispatch(connectioncountriesFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const topDnsQuery = (requestData) => (dispatch) => {
  dispatch(topdnsqueryRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(topdnsquerySuccess(response));
    };
    const url = TOPDNSQUERY;

    const onFailure = (response) => {
      dispatch(topdnsqueryFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const networkTable = (requestData) => (dispatch) => {
  dispatch(networktableRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(networktableSuccess(response));
    };
    const url = NETWORKTABLE;

    const onFailure = (response) => {
      dispatch(networktableFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const srcToDest = (requestData) => (dispatch) => {
  dispatch(srctodestRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(srctodestSuccess(response));
    };
    const url = SRCTODEST;

    const onFailure = (response) => {
      dispatch(srctodestFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const topQueryName = (requestData) => (dispatch) => {
  dispatch(topquerynameRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(topquerynameSuccess(response));
    };
    const url = TOPQUERYNAME;

    const onFailure = (response) => {
      dispatch(topquerynameFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};
