/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal'
import { Row, Breadcrumb, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import EventSecModal from './EventSecModal'
import { getEventSecLvl } from '../../../Actions/alert'
import { allFilterData } from '../../../reducers/alerts'

import styles from './index.module.scss'

function EventFirstModal({
  endDate,
  siteName,
  startDate,
  selectedAgent,
  isVisible,
  setIsVisible,
  props,
}) {
  const dispatch = useDispatch()
  const data = useSelector((state) => allFilterData(state))
  const [EventSecAlertTitle, setEventSecAlertTitle] = useState('')
  const [isEventSecModal, setIsEventSecModal] = useState(false)

  const handleEventFirstModal = () => {
    setIsVisible(!isVisible)
  }

  const handleEventSecondModal = (item) => {
    setEventSecAlertTitle(item)
    setIsVisible(!isVisible)
    dispatch(getEventSecLvl(item, siteName, selectedAgent, startDate, endDate))
    setIsEventSecModal(!isEventSecModal)
  }

  return (
    <div>
      <DraggableModalProvider className={styles.CriticalFirstModalWidth}>
        <DraggableModal
          title="Event Security Alerts"
          visible={isVisible}
          footer={null}  
          onCancel={handleEventFirstModal}
         initialWidth={1300}
          initialHeight={700}
          className={styles.thirdTableModalWidth}
        >
          <Breadcrumb>
            <Breadcrumb.Item>Event Security Alerts</Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.critModalContent}>
            {data?.isAlertLoading ? (
              <Row justify="center" className={styles.loaderContent}>
                <Spin size="large" />
              </Row>
            ) : (
              <div>
                {data?.eventFirst?.length ? (
                  <table className={styles.surucataSecLevelTable}>
                    <thead>
                      <tr>
                        <th className={styles.leftTr}>Category</th>
                        <th className={styles.rightTr}>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.eventFirst.map((item, index) => (
                        <tr
                          className={index % 2 ? '' : styles.suricataTable}
                          key={index}
                        >
                          <td
                            className={styles.leftTr}
                            onClick={() => handleEventSecondModal(item.key)}
                            style={{ cursor: 'pointer', color: 'blue' }}
                          >
                            {item?.key ? item.key : '-'}
                          </td>
                          <td className={styles.rightTr}>
                            {item?.doc_count ? item.doc_count : '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Row justify="center">NO DATA FOUND</Row>
                )}
              </div>
            )}
          </div>
        </DraggableModal>
      </DraggableModalProvider>

      <EventSecModal
        siteName={siteName}
        selectedAgent={selectedAgent}
        startDate={startDate}
        endDate={endDate}
        modalTitle={EventSecAlertTitle}
        isVisible={isEventSecModal}
        setIsVisible={setIsEventSecModal}
        setIsEventFirstModal={setIsVisible}
        isEventFirstModal={isVisible}
      />
    </div>
  )
}

export default EventFirstModal
