import { 
    GET_NTA_REPORT_FAIL,
    GET_NTA_REPORT_REQUEST,
    GET_NTA_REPORT_SUCCESS,
  } from "../Actions/actionConstant";
  
  const initialState = {
    status: "",
    ntaReportData: [],
    isProcess: false,
    message: null,
  };
  export const ntaReportDetails = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
      case GET_NTA_REPORT_REQUEST:
        return {
          ...state,
          ntaReportData: null,
          isProcess: true,
        };
  
      case GET_NTA_REPORT_SUCCESS:
        return {
          ...state,
          ntaReportData: data.data,
          isProcess: false,
        };
  
      case GET_NTA_REPORT_FAIL:
        return {
          ...state,
          ntaReportData: null,
          isProcess: false,
        };
      default:
        return state;
    }
  };
  