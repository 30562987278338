import Routing from "./routing";

import "react-data-table-component-extensions/dist/index.css";
import "ant-design-draggable-modal/dist/index.css";
import 'antd/dist/antd.min.css';
import "./index.css";
import "./App.scss";

function App() {
  return <Routing />;
}

export default App;
