import { toast } from "react-toastify";

import {
  LOG_OUT,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  REGISTER_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
} from "./actionConstant";
import { apiCall } from "../common/connect";
import { LOGIN_URL, REGISTER_URL } from "../apiUrl";
import { setLoginUser } from "../common/localStorage";

const loginRequested = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  data,
});

const loginFailure = (data) => ({
  type: LOGIN_FAIL,
  data,
});
const registerRequested = () => ({
  type: REGISTER_REQUEST,
});

export const registerSuccess = (data) => ({
  type: REGISTER_SUCCESS,
  data,
});

const registerFailure = (data) => ({
  type: REGISTER_FAIL,
  data,
});

export const logOut = () => ({
  type: LOG_OUT,
});
export const loginUser = (email, password, successCallBack) => (dispatch) => {
  dispatch(loginRequested());

  var body = {
    username: email,
    password: password,
  };

  const onSuccess = async (response) => {
    dispatch(loginSuccess(response.data.access_token));
    setLoginUser(response.data.access_token);
    successCallBack(response.data.access_token);
    toast.success("Login Successful.");
  };

  const onFailure = (response) => {
    toast.error(response?.message);

    dispatch(loginFailure(response));
  };

  apiCall("POST", LOGIN_URL, body, onSuccess, onFailure, dispatch);
};
export const registerUser = (values, successCallBack) => (dispatch) => {
  dispatch(registerRequested());

  const onSuccess = async (response) => {
    dispatch(registerSuccess(response.data.access_token));
    successCallBack(response.data.access_token);
  };

  const onFailure = (response) => {
    dispatch(registerFailure(response));
    toast.error("Error");
  };

  apiCall("POST", REGISTER_URL, values, onSuccess, onFailure, dispatch);
};
