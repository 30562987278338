import moment from "moment";
//current last 7 days date
// export const startDate = moment()
//   .subtract(7, "days")
//   .format("yyyy-MM-DDTHH:mm:ss");

// let endTime = moment().set("hours", 20).set("minutes", 40);
// export const endDate = moment(endTime)
//   .subtract(1, "days")
//   .format("yyyy-MM-DDTHH:mm:ss");

export const startDate = moment()
  .subtract(1, "days")
  .utc()
  .format("yyyy-MM-DDTHH:mm:ss");

export const endDate = moment().utc().format("yyyy-MM-DDTHH:mm:ss");

export const commonBody = {
  // namespace: "windows_testing",
  gte: startDate,
  lte: endDate,
  size: 500,
};

export const siteFilterDropdown = (arr) => {
  const dropdownArray = [];
  for (let i = 0; i < arr.length; i++) {
    let obj = arr[i];
    dropdownArray.push({
      value: obj,
      label: obj,
    });
  }
  return dropdownArray;
};

export const filterDropdown = (arr, label, value) => {
  const dropdownArray = [];
  for (let i = 0; i < arr.length; i++) {
    let obj = arr[i];
    dropdownArray.push({
      value: obj[value],
      label: obj[label],
    });
  }
  return dropdownArray;
};

export const downloadOption = [
  {
    value: "csv",
    label: "CSV",
  },
  {
    value: "xlsx",
    label: "XLSX",
  },
];
