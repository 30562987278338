/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Spin, Breadcrumb, Select } from "antd";

import { sizeFilter } from "../../../staticData";
import { allFilterData } from "../../../reducers/alerts";
import { getCriticalsFourthLvl } from "../../../Actions/alert";
import { downloadReadableStreamFile } from "../../../common/connect";

import styles from "./index.module.scss";

function EventFourthModal({
  eventFirstTitle,
  modalTitle,
  setIsVisible,
  setFourthModal,
  fourthModal,
  id,
  timeStamp,
  endDate,
  startDate,
  siteName,
  setIsEventFirstModal,
  setIsEventSecModal,
}) {
  const dispatch = useDispatch();
  const data = useSelector((state) => allFilterData(state));
  const [isDownloadLoader, setIsDownloadLoader] = useState(false);
  const handleBack = () => {
    setFourthModal(false);
    setIsVisible(true);
  };
  useEffect(() => {
    document
      .getElementById("main-root")
      ?.scrollIntoView({ behavior: "smooth" });
  });

  const handleCancel = () => {
    setFourthModal(false);
  };

  const handleChange = (value) => {
    dispatch(
      getCriticalsFourthLvl(
        id,
        timeStamp,
        modalTitle,
        siteName,
        startDate,
        endDate,
        value
      )
    );
  };

  const handleDownload = () => {
    if (siteName === undefined) {
      toast.error("Select Host Name");
    } else {
      setIsDownloadLoader(true);

      const onSuccess = (link) => {
        setIsDownloadLoader(false);
        window.URL.revokeObjectURL(link);
      };

      downloadReadableStreamFile(
        siteName,
        modalTitle,
        "surroundingevts",
        startDate,
        endDate,
        "csv",
        onSuccess,
        "",
        id,
        timeStamp
      );
    }
  };

  const handleEventFirst = () => {
    setFourthModal(false);
    setIsEventFirstModal(true);
  };

  const handleEventSecond = () => {
    setFourthModal(false);
    setIsEventSecModal(true);
  };

  return (
    <DraggableModalProvider>
      <DraggableModal
        title={"Event Security Alerts"}
        open={fourthModal}
        className={styles.suricataFirstLvlModal}
        footer={false}
        initialWidth={1300}
        initialHeight={700}
        onCancel={handleCancel}
      >
        <Breadcrumb>
          <Breadcrumb.Item onClick={handleEventFirst}>
            Event Security Alerts
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={handleEventSecond}>
            {eventFirstTitle}
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={handleBack}>{modalTitle}</Breadcrumb.Item>
          <Breadcrumb.Item>{id}</Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.critModalContent}>
          <Row justify="space-between" className={styles.selectSize}>
            <Col xl={8} lg={8} md={10} xs={24} sm={24} xxl={6}>
              <div className={`${styles.filterType} ${styles.siteDropdown}`}>
                <b>Select Size:</b>
                <Select
                  defaultValue="10"
                  style={{
                    width: 120,
                  }}
                  allowClear
                  onChange={handleChange}
                  options={sizeFilter}
                />
              </div>
            </Col>
            <Col>
              <Button
                loading={isDownloadLoader}
                className={styles.exportBtnModal}
                onClick={handleDownload}
              >
                Quick Export
              </Button>
            </Col>
          </Row>
          {data?.isAlertLoading ? (
            <Row justify="center" className={styles.loaderContent}>
              <Spin size="large" />
            </Row>
          ) : (
            <>
              <div>
                {data?.critFourth?.length > 0 ? (
                  <div>
                    <table className={styles.xdrLvlSecondTable}>
                      <tbody className={styles.xdrLvlSecondTableBody}>
                        {data.critFourth?.map((item, index) => (
                          <p
                            id={item?._id === id ? "main-root" : ""}
                            className={
                              item?._id === id
                                ? styles.highlightColor
                                : item?._source?.data?.win?.system?.message
                                ? styles.fourthModalColor
                                : null
                            }
                            key={index}
                          >
                            {item?._source?.data?.win?.system?.message
                              ? item?._source?.data?.win?.system?.message
                                  ?.split(/\n/)
                                  .map((item1, index) => (
                                    <tr key={index}>
                                      <td>
                                        {item1
                                          ? item1.replaceAll(`"`, "")
                                          : "-"}
                                      </td>
                                    </tr>
                                  ))
                              : null}
                          </p>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <Row justify="center"> NO DATA FOUND </Row>
                )}
                <Row className={styles.buttonRow} justify="center">
                  <Col xxl={3}>
                    <Button className={styles.backButton} onClick={handleBack}>
                      Back
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
      </DraggableModal>
    </DraggableModalProvider>
  );
}

export default EventFourthModal;
