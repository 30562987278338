import { createSelector } from "reselect";
import {
  CRITICAL_SEC_LVL_FAIL,
  CRITICAL_SEC_LVL_REQUEST,
  CRITICAL_SEC_LVL_SUCCESS,
  CRITICAL_THIRD_LVL_FAIL,
  CRITICAL_THIRD_LVL_REQUEST,
  CRITICAL_THIRD_LVL_SUCCESS,
  SITE_LABELS_FAIL,
  SITE_LABELS_REQUEST,
  SITE_LABELS_SUCCESS,
  SURICATA_FAIL,
  SURICATA_REQUEST,
  SURICATA_SECOND_FAIL,
  SURICATA_SECOND_REQUEST,
  SURICATA_SECOND_SUCCESS,
  SURICATA_SUCCESS,
  SURICATA_THIRD_FAIL,
  SURICATA_THIRD_REQUEST,
  SURICATA_THIRD_SUCCESS,
  CRITICAL_FIRST_LVL_FAIL,
  CRITICAL_FIRST_LVL_REQUEST,
  CRITICAL_FIRST_LVL_SUCCESS,
  XDR_FIRST_LVL_FAIL,
  XDR_FIRST_LVL_REQUEST,
  XDR_FIRST_LVL_SUCCESS,
  XDR_SECOND_LVL_FAIL,
  XDR_SECOND_LVL_REQUEST,
  XDR_SECOND_LVL_SUCCESS,
  THREAT_SECOND_LVL_FAIL,
  THREAT_SECOND_LVL_SUCCESS,
  THREAT_SECOND_LVL_REQUEST,
  THREAT_FIRST_LVL_REQUEST,
  THREAT_FIRST_LVL_SUCCESS,
  THREAT_FIRST_LVL_FAIL,
  MITRE_SECOND_LVL_FAIL,
  MITRE_SECOND_LVL_SUCCESS,
  MITRE_SECOND_LVL_REQUEST,
  MITRE_FIRST_LVL_FAIL,
  MITRE_FIRST_LVL_SUCCESS,
  MITRE_FIRST_LVL_REQUEST,
  EVENT_FIRST_LVL_REQUEST,
  EVENT_FIRST_LVL_SUCCESS,
  EVENT_FIRST_LVL_FAIL,
  EVENT_SEC_LVL_REQUEST,
  EVENT_SEC_LVL_SUCCESS,
  EVENT_SEC_LVL_FAIL,
  EVENT_THIRD_LVL_REQUEST,
  EVENT_THIRD_LVL_SUCCESS,
  EVENT_THIRD_LVL_FAIL,
  CRITICAL_FOURTH_LVL_FAIL,
  CRITICAL_FOURTH_LVL_SUCCESS,
  CRITICAL_FOURTH_LVL_REQUEST,
  GET_IP_IFO_FAIL,
  GET_IP_IFO_SUCCESS,
  GET_IP_IFO_REQUEST,
} from "../Actions/actionConstant";

const initialState = {
  criticalShotFirstLvlData: [],
  suriCataFirstLvlData: [],
  suriCataSecondLvlData: [],
  suriCataThirdLvlData: [],
  criticalShotsSecLvlData: [],
  siteLabelsData: [],
  criticalThirdLvlData: [],
  criticalFourthLvlData: [],
  xdrFirstLvlData: [],
  xdrSecondLvlData: [],
  threatSecondLvlData: [],
  threatFirstLvlData: [],
  isProcess: false,
  message: null,
  mitreSecLvlData: [],
  mitreFirstLvlData: [],
  eventFirstData: [],
  eventSecData: [],
  eventThirdData: [],
  ipInfoData: {},
};

export const alerts = (state = initialState, action) => {
  const { data } = action;

  switch (action.type) {
    case CRITICAL_FIRST_LVL_REQUEST:
      return {
        ...state,
        criticalShotFirstLvlData: null,
        isProcess: true,
      };

    case CRITICAL_FIRST_LVL_SUCCESS:
      return {
        ...state,
        criticalShotFirstLvlData: data.data,
        isProcess: false,
      };

    case CRITICAL_FIRST_LVL_FAIL:
      return {
        ...state,
        criticalShotFirstLvlData: null,
        isProcess: false,
      };
    case SURICATA_REQUEST:
      return {
        ...state,
        suriCataFirstLvlData: null,
        isProcess: true,
      };

    case SURICATA_SUCCESS:
      return {
        ...state,
        suriCataFirstLvlData: data.data,
        isProcess: false,
      };

    case SURICATA_FAIL:
      return {
        ...state,
        suriCataFirstLvlData: null,
        isProcess: false,
      };
    case SURICATA_SECOND_REQUEST:
      return {
        ...state,
        suriCataSecondLvlData: null,
        isProcess: true,
      };

    case SURICATA_SECOND_SUCCESS:
      return {
        ...state,
        suriCataSecondLvlData: data.data,
        isProcess: false,
      };

    case SURICATA_SECOND_FAIL:
      return {
        ...state,
        suriCataSecondLvlData: null,
        isProcess: false,
      };
    case SURICATA_THIRD_REQUEST:
      return {
        ...state,
        suriCataThirdLvlData: null,
        isProcess: true,
      };

    case SURICATA_THIRD_SUCCESS:
      return {
        ...state,
        suriCataThirdLvlData: data.data,
        isProcess: false,
      };

    case SURICATA_THIRD_FAIL:
      return {
        ...state,
        suriCataThirdLvlData: null,
        isProcess: false,
      };
    case CRITICAL_SEC_LVL_REQUEST:
      return {
        ...state,
        criticalShotsSecLvlData: null,
        isProcess: true,
      };

    case CRITICAL_SEC_LVL_SUCCESS:
      return {
        ...state,
        criticalShotsSecLvlData: data.data,
        isProcess: false,
      };

    case CRITICAL_SEC_LVL_FAIL:
      return {
        ...state,
        criticalShotsSecLvlData: null,
        isProcess: false,
      };
    case SITE_LABELS_REQUEST:
      return {
        ...state,
        siteLabelsData: null,
        isProcess: true,
      };

    case SITE_LABELS_SUCCESS:
      return {
        ...state,
        siteLabelsData: data?.data,
        isProcess: false,
      };

    case SITE_LABELS_FAIL:
      return {
        ...state,
        siteLabelsData: null,
        isProcess: false,
      };
    case CRITICAL_THIRD_LVL_REQUEST:
      return {
        ...state,
        criticalThirdLvlData: null,
        isProcess: true,
      };

    case CRITICAL_THIRD_LVL_SUCCESS:
      return {
        ...state,
        criticalThirdLvlData: data.data,
        isProcess: false,
      };

    case CRITICAL_THIRD_LVL_FAIL:
      return {
        ...state,
        criticalThirdLvlData: null,
        isProcess: false,
      };
    case CRITICAL_FOURTH_LVL_REQUEST:
      return {
        ...state,
        criticalFourthLvlData: null,
        isProcess: true,
      };

    case CRITICAL_FOURTH_LVL_SUCCESS:
      return {
        ...state,
        criticalFourthLvlData: data.data,
        isProcess: false,
      };

    case CRITICAL_FOURTH_LVL_FAIL:
      return {
        ...state,
        criticalFourthLvlData: null,
        isProcess: false,
      };
    case XDR_FIRST_LVL_REQUEST:
      return {
        ...state,
        xdrFirstLvlData: null,
        isProcess: true,
      };

    case XDR_FIRST_LVL_SUCCESS:
      return {
        ...state,
        xdrFirstLvlData: data.data,
        isProcess: false,
      };

    case XDR_FIRST_LVL_FAIL:
      return {
        ...state,
        xdrFirstLvlData: null,
        isProcess: false,
      };
    case XDR_SECOND_LVL_REQUEST:
      return {
        ...state,
        xdrSecondLvlData: null,
        isProcess: true,
      };

    case XDR_SECOND_LVL_SUCCESS:
      return {
        ...state,
        xdrSecondLvlData: data.data,
        isProcess: false,
      };

    case XDR_SECOND_LVL_FAIL:
      return {
        ...state,
        xdrSecondLvlData: null,
        isProcess: false,
      };
    case THREAT_FIRST_LVL_REQUEST:
      return {
        ...state,
        threatFirstLvlData: null,
        isProcess: true,
      };

    case THREAT_FIRST_LVL_SUCCESS:
      return {
        ...state,
        threatFirstLvlData: data.data,
        isProcess: false,
      };

    case THREAT_FIRST_LVL_FAIL:
      return {
        ...state,
        threatFirstLvlData: null,
        isProcess: false,
      };
    case THREAT_SECOND_LVL_REQUEST:
      return {
        ...state,
        threatSecondLvlData: null,
        isProcess: true,
      };

    case THREAT_SECOND_LVL_SUCCESS:
      return {
        ...state,
        threatSecondLvlData: data.data,
        isProcess: false,
      };

    case THREAT_SECOND_LVL_FAIL:
      return {
        ...state,
        threatSecondLvlData: null,
        isProcess: false,
      };
    case MITRE_FIRST_LVL_REQUEST:
      return {
        ...state,
        isProcess: true,
        mitreFirstLvlData: null,
      };

    case MITRE_FIRST_LVL_SUCCESS:
      return {
        ...state,
        isProcess: false,
        mitreFirstLvlData: data.data,
      };
    case MITRE_FIRST_LVL_FAIL:
      return {
        ...state,
        isProcess: false,
        mitreFirstLvlData: null,
      };
    case MITRE_SECOND_LVL_REQUEST:
      return {
        ...state,
        isProcess: true,
        mitreSecLvlData: null,
      };

    case MITRE_SECOND_LVL_SUCCESS:
      return {
        ...state,
        isProcess: false,
        mitreSecLvlData: data.data,
      };
    case MITRE_SECOND_LVL_FAIL:
      return {
        ...state,
        isProcess: false,
        mitreSecLvlData: null,
      };
    case EVENT_FIRST_LVL_REQUEST:
      return {
        ...state,
        isProcess: true,
        eventFirstData: null,
      };

    case EVENT_FIRST_LVL_SUCCESS:
      return {
        ...state,
        isProcess: false,
        eventFirstData: data.data,
      };
    case EVENT_FIRST_LVL_FAIL:
      return {
        ...state,
        isProcess: false,
        eventFirstData: null,
      };
    case EVENT_SEC_LVL_REQUEST:
      return {
        ...state,
        isProcess: true,
        eventSecData: null,
      };

    case EVENT_SEC_LVL_SUCCESS:
      return {
        ...state,
        isProcess: false,
        eventSecData: data?.data,
      };
    case EVENT_SEC_LVL_FAIL:
      return {
        ...state,
        isProcess: false,
        eventSecData: null,
      };
    case EVENT_THIRD_LVL_REQUEST:
      return {
        ...state,
        isProcess: true,
        eventThirdData: null,
      };

    case EVENT_THIRD_LVL_SUCCESS:
      return {
        ...state,
        isProcess: false,
        eventThirdData: data?.data,
      };
    case EVENT_THIRD_LVL_FAIL:
      return {
        ...state,
        isProcess: false,
        eventThirdData: null,
      };
    case GET_IP_IFO_REQUEST:
      return {
        ...state,
        isProcess: true,
        ipInfoData: null,
      };

    case GET_IP_IFO_SUCCESS:
      return {
        ...state,
        isProcess: false,
        ipInfoData: data?.data,
      };
    case GET_IP_IFO_FAIL:
      return {
        ...state,
        isProcess: false,
        ipInfoData: null,
      };
    default:
      return state;
  }
};

const mitreToptactics = (state) => state.mitre.topTactics?.[2]?.buckets;
const hostsUniqueData = (state) => state.overview.hostsUnique?.[1]?.value;
const agentsListData = (state) => state.overview.agentsListData;
const hostsInfoData = (state) =>
  state.overview.hostsInfoData?.data?.affected_items;
const critAlertCount = (state) => state.overview.critAlerts;
const criticalShotFirstLvlData = (state) =>
  state.alerts.criticalShotFirstLvlData?.[2]?.buckets;
const criticalShotsecLvlData = (state) =>
  state.alerts.criticalShotsSecLvlData?.[2]?.buckets;
const criticalThirdLvlData = (state) => state.alerts.criticalThirdLvlData;
const criticalFourthLvlData = (state) => state.alerts.criticalFourthLvlData;
const eventFirstData = (state) => state.alerts.eventFirstData?.[3]?.buckets;
const eventSecData = (state) => state.alerts.eventSecData?.[3]?.buckets;
const eventThirdData = (state) => state.alerts.eventThirdData;
const siteLabelsData = (state) => state.alerts.siteLabelsData;
const xdrFirstLvlData = (state) => state.alerts.xdrFirstLvlData?.[2]?.buckets;
const xdrSecondLvlData = (state) => state.alerts.xdrSecondLvlData;

const suriCataFirstLvlData = (state) =>
  state.alerts.suriCataFirstLvlData?.["2"]?.buckets;
const isAlertLoading = (state) => state.alerts.isProcess;
const isCloudsLoading = (state) => state.clouds.isProcess;
const isOverViewLoading = (state) => state.overview.isProcess;
const suriCataSecondLvlData = (state) =>
  state.alerts.suriCataSecondLvlData?.["8"]?.buckets;

const suriCataThirdLvlData = (state) => state.alerts.suriCataThirdLvlData;
const mitreSecondLvlData = (state) =>
  state.alerts.mitreSecLvlData?.["2"]?.buckets;

const mitreFirstLvlData = (state) =>
  state.alerts.mitreFirstLvlData?.["2"]?.buckets;
const mitreThirdLvlData = (state) =>
  state.alerts.mitreThirdLvlData?.["3"]?.buckets;
const mitreFourthLvlData = (state) => state.mitre.mitreFourthLvlData;
const threatHuntSecondLvlData = (state) => state.alerts.threatSecondLvlData;
const threatHuntFirstLvlData = (state) =>
  state.alerts.threatFirstLvlData?.["2"]?.buckets;
const ipaDdrInfoData = (state) => state.alerts.ipInfoData;
const cloudsData = (state) => state.clouds.cloudsData?.[2]?.buckets;

const clientIpData = (state) => state.clouds.topClientIpData?.[2]?.buckets;
const clientRulesData = (state) => state.clouds.topRulesData?.[2]?.buckets;
const clientOprationData = (state) =>
  state.clouds.topOperationData?.[2]?.buckets;

export const allFilterData = createSelector(
  [
    hostsUniqueData,
    isOverViewLoading,
    isAlertLoading,
    mitreToptactics,
    critAlertCount,
    agentsListData,
    criticalShotFirstLvlData,
    criticalShotsecLvlData,
    siteLabelsData,
    criticalThirdLvlData,
    criticalFourthLvlData,
    xdrFirstLvlData,
    xdrSecondLvlData,
    suriCataFirstLvlData,
    suriCataSecondLvlData,
    suriCataThirdLvlData,
    mitreSecondLvlData,
    mitreThirdLvlData,
    mitreFourthLvlData,
    threatHuntSecondLvlData,
    mitreFirstLvlData,
    threatHuntFirstLvlData,
    hostsInfoData,
    eventFirstData,
    eventSecData,
    eventThirdData,
    ipaDdrInfoData,
    cloudsData,
    clientIpData,
    clientRulesData,
    clientOprationData,
    isCloudsLoading,
  ],
  (
    hostUniqueCount,
    isOverViewLoading,
    isAlertLoading,
    mitreToptacticData,
    critAlertCount,
    agentsListData,
    criticalShotFirstLvlList,
    criticalShotsSecLvlList,
    siteLabelsList,
    criticalThirdLvlList,
    criticalFourthLvlList,
    xdrFirstLvlList,
    xdrSecondLvlList,
    suriCataFirstLvlData,
    suriCataSecondLvlData,
    suriCataThirdLvlData,
    mitreSecondLvlData,
    mitreThirdLvlData,
    mitreFourthLvlData,
    threatHuntSecondLvlData,
    mitreFirstLvlData,
    threatHuntFirstLvlData,
    hostsInfoData,
    eventFirstData,
    eventSecData,
    eventThirdData,
    ipaDdrInfoData,
    cloudsData,
    clientIpData,
    clientRulesData,
    clientOprationData,
    isCloudsLoading
  ) => {
    return {
      isOverViewLoading: isOverViewLoading,
      isAlertLoading: isAlertLoading,
      host: hostUniqueCount,
      mitreToptacticData: mitreToptacticData,
      critAlertCount: critAlertCount,
      hostList: agentsListData,
      critFirst: criticalShotFirstLvlList,
      critSec: criticalShotsSecLvlList,
      critThird: criticalThirdLvlList,
      critFourth: criticalFourthLvlList,
      siteLabelsList: siteLabelsList,
      xdrFirst: xdrFirstLvlList,
      xdrSecond: xdrSecondLvlList,
      suriFirst: suriCataFirstLvlData,
      suriSecond: suriCataSecondLvlData,
      suriThird: suriCataThirdLvlData,
      mitreSecond: mitreSecondLvlData,
      mitreThird: mitreThirdLvlData,
      mitreFourth: mitreFourthLvlData,
      threatHuntSecond: threatHuntSecondLvlData,
      threatHuntFirst: threatHuntFirstLvlData,
      mitreFirst: mitreFirstLvlData,
      topRiskAggregreted:
        agentsListData?.length >= 10
          ? agentsListData?.slice(0, 10)
          : agentsListData,
      hostsInfoData: hostsInfoData,
      eventFirst: eventFirstData,
      eventSec: eventSecData,
      eventThird: eventThirdData,
      ipaDdrinfo: ipaDdrInfoData,
      clouds: cloudsData,
      clientIp: clientIpData,
      cloudsRules: clientRulesData,
      cloudsOpration: clientOprationData,
      cloudsProccess: isCloudsLoading,
    };
  }
);
