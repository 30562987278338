import { combineReducers } from "redux";

import { gdpr } from "./gdpr";
import { mitre } from "./mitre";
import { hippa } from "./hippa";
import { alerts } from "./alerts";
import { pcidss } from "./pcidss";
import { network } from "./network";
import { allSite } from "./allSites";
import { overView } from "./overview";
import { loginReducer } from "./user";
import { vulnstats } from "./vulnStats";
import { reportDetails } from "./report";
import { ntaReportDetails } from "./ntaReport";
import { cloudsDetails } from "./clouds";
import { inventoryReducer } from "./inventoryReducer";

const rootReducer = combineReducers({
  user: loginReducer,
  report: reportDetails,
  overview: overView,
  mitre: mitre,
  vulnstats: vulnstats,
  network: network,
  pcidss: pcidss,
  hippa: hippa,
  gdpr: gdpr,
  alerts: alerts,
  allSite: allSite,
  ntaReportDetails: ntaReportDetails,
  clouds: cloudsDetails,
  inventory: inventoryReducer,
});

export default rootReducer;
