/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Col, Row } from "antd";
import { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";

import {  topReqs } from "../../../Actions/hippa";
import { useDispatch, useSelector } from "react-redux";

import styles from "./index.module.scss";

function TopReqs() {
  const dispatch = useDispatch();
  const isProcess = useSelector((state) => state.hippa?.isProcess);
  const topAgentsData = useSelector(
    (state) => state.hippa.topreqs?.[2]?.buckets
  ); 

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(topReqs());
  };

  return (
    <div className={styles.mainDiv}>
      
      {topAgentsData?.map((item, index) => {
        return (
          <Card className={styles.progressCard} key={index}>
            <div className={styles.cardMain}>
              <div className={styles.salesMain}>
                <p className={styles.salesTxt}>{item.key}</p>
              </div>
              {isProcess ? (
                <Row justify="center">
                  <Col>
                    <ThreeDots color="#00000" height={50} width={50} />
                  </Col>
                </Row>
              ) : (
                <div className={styles.alertMain}>{item.doc_count}</div>
              )}
            </div>
          </Card>
        );
      })}
    </div>
  );
}

export default TopReqs;
