/* eslint-disable react-hooks/exhaustive-deps */

import moment from "moment";
import { Modal } from "antd";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Select, Row, Spin, DatePicker } from "antd";

import {
  getCloudsApi,
  getTopClientIpApi,
  getTopOperationApi,
} from "../../Actions/clouds";
import TableCom from "../../common/TableCom";
import { integration } from "../../staticData";
import { allFilterData } from "../../reducers/alerts";
import { getTopRulesApi } from "../../Actions/clouds";
import { getFullEventsApi } from "../../Actions/clouds";
import workingProgress from "../../Assets/png/working1.png";

import styles from "./index.module.scss";
// import { getFilterTableDataApi } from "../../Actions/allSite";
import { agentList } from "../../Actions/overView";
import { getSiteLabels } from "../../Actions/alert";
import { toast } from "react-toastify";

const Clouds = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => allFilterData(state));

  const isCloudSiteLoading = useSelector((state) => state.clouds.isLoader);
  const isAgentSelected = useSelector((state) => state.overview.isProcess);
  const fullEventsData = useSelector((state) => state.clouds.fullEventsData);
  const siteLabelsData = useSelector((state) => state.alerts.siteLabelsData);
  const isSiteLableLoading = useSelector((state) => state.alerts.isProcess);

  const [clouds, setClouds] = useState("");
  const [visible, setVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [cloudsName, setCloudsName] = useState("");
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedSite, setSelectedSite] = useState("");
  const [endDate, setEndDate] = useState(
    moment().utc().format("yyyy-MM-DDTHH:mm:ss")
  );
  const [startDate, setStartDate] = useState(
    moment().utc().subtract(1, "days").format("yyyy-MM-DDTHH:mm:ss")
  );
  useEffect(() => {
    // dispatch(getFilterTableDataApi("", "", startDate, endDate));
    dispatch(getSiteLabels(startDate, endDate));
  }, []);

  const handleSiteChange = (item) => {
    dispatch(agentList(item, "", startDate, endDate));
    setSelectedSite(item);
    setSelectedAgent(null);
    dispatch(getCloudsApi(item, "", moment(startDate), moment(endDate)));
  };

  const handleCloudsChange = (values) => {
    setClouds(values);
  };

  const handleUserIDChange = (value) => {
    setSelectedAgent(value);
  };
  const handleCancel = () => {
    setIsVisible(!isVisible);
  };
  const handleOpen = (item) => {
    setIsVisible(!isVisible);
    dispatch(getFullEventsApi(selectedSite, item, startDate, endDate));
  };

  const handleClick = () => {
    if (!selectedSite) {
      return toast.error("Please Select Host");
    }
    if (!clouds) {
      return toast.error("Please Select Cloud");
    }
    setVisible(true);
    setCloudsName(clouds);
    if (clouds === "O365") {
      dispatch(getCloudsApi(selectedSite, selectedAgent, startDate, endDate));
      dispatch(getTopRulesApi(selectedSite, selectedAgent, startDate, endDate));
      dispatch(
        getTopClientIpApi(selectedSite, selectedAgent, startDate, endDate)
      );
      dispatch(
        getTopOperationApi(selectedSite, selectedAgent, startDate, endDate)
      );
    }
  };

  const onChange = (value) => {
    setStartDate(moment(value).format("yyyy-MM-DDTHH:mm:ss"));
  };

  const onSecondChange = (value) => {
    setEndDate(moment(value).format("yyyy-MM-DDTHH:mm:ss"));
  };

  const filterDropdown = (arr, label, value) => {
    const dropdownArray = [];
    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i];
      dropdownArray.push({
        value: obj[value],
        label: obj[label],
      });
    }
    return dropdownArray;
  };

  // const handleReset = () => {
  //   setSelectedSite("");
  //   // setSelectedAgent(null);
  // };

  const dropdownOverlay = (
    <Row justify="center">
      <Spin size="small" />
    </Row>
  );

  const modifiedSiteList = siteLabelsData?.map((value, index) => ({
    namespace: value,
  }));

  return (
    <div className={styles.ntaHeaderView}>
      <Row justify="center" gutter={20} className={styles.filterTableRow}>
        <Col span={6} className={styles.filterContent}>
          <div className={`${styles.filterType} ${styles.siteDropdown}`}>
            <b>Network:</b>
            <Select
              placeholder="Select Network"
              style={{ width: "75%" }}
              className={styles.selectDropdown}
              onSelect={handleSiteChange}
              dropdownRender={(menu) =>
                isSiteLableLoading ? dropdownOverlay : menu
              }
              options={
                modifiedSiteList?.length
                  ? filterDropdown(modifiedSiteList, "namespace", "namespace")
                  : []
              }
            />
          </div>
        </Col>
        <Col span={7} className={styles.filterContent}>
          <div className={styles.filterType}>
            <b>UserID:</b>
            <Select
              placeholder="Search UserID"
              style={{ width: "90%" }}
              value={selectedAgent}
              onSelect={handleUserIDChange}
              className={styles.selectDropdown}
              showSearch
              dropdownRender={(menu) =>
                isAgentSelected ? dropdownOverlay : menu
              }
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              optionFilterProp="children"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .toUpperCase()
                  .localeCompare(
                    (optionB?.label ?? "").toLowerCase().toUpperCase()
                  )
              }
              options={
                data?.clouds?.length
                  ? filterDropdown(data?.clouds, "key", "key")
                  : []
              }
            />
          </div>
        </Col>

        <Col span={10} className={styles.filterContent}>
          <div className={styles.timeContent}>
            <b>UTC Time:</b>
            <DatePicker
              showTime
              onChange={onChange}
              defaultValue={moment(startDate)}
              value={moment(startDate)}
            />
            <b>to</b>
            <DatePicker
              showTime
              onChange={onSecondChange}
              defaultValue={moment(endDate)}
              value={moment(endDate)}
            />
          </div>
        </Col>
      </Row>
      <Row justify="center" gutter={20} className={styles.filterTableRow}>
        <Col span={10} className={styles.filterContent}>
          <div className={`${styles.filterType} ${styles.siteDropdown}`}>
            <b>Cloud Integration:</b>
            <Select
              placeholder="Select integration"
              style={{ width: "73%" }}
              className={styles.selectDropdown}
              onSelect={handleCloudsChange}
              options={integration}
            />
          </div>
        </Col>
        <Col xl={3} span={3} className={styles.filterContent}>
          <Button onClick={() => handleClick()} className={styles.applyBtn}>
            Apply
          </Button>
        </Col>
        {/* <Col xl={3} span={3} className={styles.filterContent}>
          <Button
            disabled={disable}
            onClick={handleReset}
            className={styles.applyBtn}
          >
            Reset
          </Button>
        </Col> */}
        <Col xs={22} md={22} lg={22} xl={22} xxl={23}>
          {visible ? (
            data.cloudsProccess ? (
              <div className={styles.spinnerRow}>
                <Spin size="large" />
              </div>
            ) : cloudsName === "O365" ? (
              <>
                <div className={styles.mainTableDiv}>
                  <TableCom
                    headerName={["User ID", "Count"]}
                    data={data?.clouds}
                    isModal={true}
                    isUser={true}
                    handleOpen={handleOpen}
                  />
                </div>

                {/* client Ip Table */}
                <div className={styles.mainTableDiv}>
                  <TableCom
                    headerName={["Client IP", "Count"]}
                    data={data?.clientIp}
                  />
                </div>

                {/* Rules Table */}
                <div className={styles.mainTableDiv}>
                  <TableCom
                    headerName={["Top Rules", "Count"]}
                    data={data?.cloudsRules}
                  />
                </div>

                {/* Opration Table */}
                <div className={styles.mainTableDiv}>
                  <TableCom
                    headerName={["Top Operations", "Count"]}
                    data={data?.cloudsOpration}
                  />
                </div>
              </>
            ) : (
              <Row className={styles.noRecordWorking} justify="center">
                <img
                  height="150px"
                  width="150px"
                  src={workingProgress}
                  alt="working"
                />
              </Row>
            )
          ) : (
            <div className={styles.noDataText}>
              <p>Please select network</p>
            </div>
          )}
        </Col>
      </Row>
      <Modal
        title={"Event Security Alerts"}
        open={isVisible}
        className={styles.CriticalFirstModalWidth}
        footer={false}
        onCancel={handleCancel}
      >
        <div className={styles.critModalContent}>
          {isCloudSiteLoading ? (
            <Row justify="center" className={styles.loaderContent}>
              <Spin size="large" />
            </Row>
          ) : (
            <div className={styles.critThirdAlertModal}>
              {fullEventsData?.length > 0 ? (
                fullEventsData?.map((item, index) => (
                  <table className={styles.hostListInfoTable}>
                    <tbody>
                      <>
                        <tr
                          key={index}
                          className={index % 2 ? "" : styles.suricataTable}
                        >
                          <th>site</th>
                          <td style={{ color: "black" }}>
                            {item?._source?.data?.office365?.Site || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>User Key</th>
                          <td>
                            {item?._source?.data?.office365?.UserKey || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Item Type</th>
                          <td>
                            {item?._source?.data?.office365?.ItemType || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Manage Device</th>
                          <td>
                            {item?._source?.data?.office365?.IsManagedDevice
                              ? "True"
                              : "False"}
                          </td>
                        </tr>
                        <tr>
                          <th>Operation</th>
                          <td>
                            {item?._source?.data?.office365?.Operation || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Organization Id</th>
                          <td>
                            {item?._source?.data?.office365?.OrganizationId ||
                              "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Record Type</th>
                          <td>
                            {item?._source?.data?.office365?.RecordType || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>List Id</th>
                          <td>
                            {item?._source?.data?.office365?.ListId || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Version</th>
                          <td>
                            {item?._source?.data?.office365?.Version || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Web Id</th>
                          <td>
                            {item?._source?.data?.office365?.WebId || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>User Agent</th>
                          <td>
                            {item?._source?.data?.office365?.UserAgent || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Correlation Id</th>
                          <td>
                            {item?._source?.data?.office365?.CorrelationId ||
                              "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Do Not Distribute Event</th>
                          <td>
                            {item?._source?.data?.office365?.CorrelationId
                              ? "True"
                              : "False"}
                          </td>
                        </tr>
                        <tr>
                          <th>Subscription</th>
                          <td>
                            {item?._source?.data?.office365?.Subscription ||
                              "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>List Item Unique Id</th>
                          <td>
                            {item?._source?.data?.office365?.ListItemUniqueId ||
                              "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Object Id</th>
                          <td>
                            {item?._source?.data?.office365?.ObjectId || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Source File Name</th>
                          <td>
                            {item?._source?.data?.office365?.SourceFileName ||
                              "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Site Url</th>
                          <td>
                            {item?._source?.data?.office365?.SiteUrl || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Client App Id</th>
                          <td>
                            {item?._source?.data?.office365?.AppAccessContext
                              ?.ClientAppId || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Correlation Id</th>
                          <td>
                            {item?._source?.data?.office365?.AppAccessContext
                              ?.CorrelationId || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Client IP</th>
                          <td>
                            {item?._source?.data?.office365?.ClientIP || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Source File Extension</th>
                          <td>
                            {item?._source?.data?.office365
                              ?.SourceFileExtension || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Workload</th>
                          <td>
                            {item?._source?.data?.office365?.Workload || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Source Relative Url</th>
                          <td>
                            {item?._source?.data?.office365
                              ?.SourceRelativeUrl || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Event Source</th>
                          <td>
                            {item?._source?.data?.office365?.EventSource || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>High Priority Media Processing</th>
                          <td>
                            {item?._source?.data?.office365
                              ?.HighPriorityMediaProcessing || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>User Id</th>
                          <td>
                            {item?._source?.data?.office365?.UserId || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Creation Time</th>
                          <td>
                            {item?._source?.data?.office365?.CreationTime ||
                              "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Creation Time</th>
                          <td>
                            {item?._source?.data?.office365?.CreationTime ||
                              "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Id</th>
                          <td>{item?._source?.data?.office365?.Id || "-"}</td>
                        </tr>
                        <tr>
                          <th>Application Id</th>
                          <td>
                            {item?._source?.data?.office365?.ApplicationId ||
                              "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>UserType</th>
                          <td>
                            {item?._source?.data?.office365?.UserType || "-"}
                          </td>
                        </tr>
                      </>
                    </tbody>
                  </table>
                ))
              ) : (
                <Row justify="center"> NO DATA FOUND </Row>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Clouds;
