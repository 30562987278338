export const LOG_OUT = "LOG_OUT";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const LOGIN_USER_DETAILS_FAIL = "LOGIN_USER_DETAILS_FAIL";
export const LOGIN_USER_DETAILS_REQUEST = "LOGIN_USER_DETAILS_REQUEST";
export const LOGIN_USER_DETAILS_SUCCESS = "LOGIN_USER_DETAILS_SUCCESS";

//OVERVIEW

export const ADD_WEEKLY_REPORT_REQUEST = "ADD_WEEKLY_REPORT_REQUEST";
export const ADD_WEEKLY_REPORT_SUCCESS = "ADD_WEEKLY_REPORT_SUCCESS";
export const ADD_WEEKLY_REPORT_FAIL = "ADD_WEEKLY_REPORT_FAIL";

export const FILTER_TABLE_DASHBOARD_REQUEST = "FILTER_TABLE_DASHBOARD_REQUEST";
export const FILTER_TABLE_DASHBOARD_SUCCESS = "FILTER_TABLE_DASHBOARD_SUCCESS";
export const FILTER_TABLE_DASHBOARD_FAIL = "FILTER_TABLE_DASHBOARD_FAIL";

export const GET_IP_IFO_REQUEST = "GET_IP_IFO_REQUEST";
export const GET_IP_IFO_SUCCESS = "GET_IP_IFO_SUCCESS";
export const GET_IP_IFO_FAIL = "GET_IP_IFO_FAIL";

export const TOP_ALERTS_FAIL = "TOP_ALERTS_FAIL";
export const TOP_ALERTS_REQUEST = "TOP_ALERTS_REQUEST";
export const TOP_ALERTS_SUCCESS = "TOP_ALERTS_SUCCESS";

export const TOP_CRIT_ALERT_FAIL = "TOP_CRIT_ALERT_FAIL";
export const TOP_CRIT_ALERT_REQUEST = "TOP_CRIT_ALERT_REQUEST";
export const TOP_CRIT_ALERT_SUCCESS = "TOP_CRIT_ALERT_SUCCESS";

export const CRIT_ALERTS_FAIL = "CRIT_ALERTS_FAIL";
export const CRIT_ALERTS_REQUEST = "CRIT_ALERTS_REQUEST";
export const CRIT_ALERTS_SUCCESS = "CRIT_ALERTS_SUCCESS";

export const HOSTS_UNIQUE_FAIL = "HOSTS_UNIQUE_FAIL";
export const HOSTS_UNIQUE_REQUEST = "HOSTS_UNIQUE_REQUEST";
export const HOSTS_UNIQUE_SUCCESS = "HOSTS_UNIQUE_SUCCESS";

export const AGENTS_LIST_REQUEST = "AGENTS_LIST_REQUEST";
export const AGENTS_LIST_SUCCESS = "AGENTS_LIST_SUCCESS";
export const AGENTS_LIST_FAIL = "AGENTS_INFO_FAIL";

export const HOSTS_INFO_FAIL = "HOSTS_INFO_FAIL";
export const HOSTS_INFO_REQUEST = "HOSTS_INFO_REQUEST";
export const HOSTS_INFO_SUCCESS = "HOSTS_INFO_SUCCESS";

export const UNIQUE_HOSTS_FAIL = "UNIQUE_HOSTS_FAIL";
export const UNIQUE_HOSTS_REQUEST = "UNIQUE_HOSTS_REQUEST";
export const UNIQUE_HOSTS_SUCCESS = "UNIQUE_HOSTS_SUCCESS";

export const VULAN_TOTAL_FAIL = "VULAN_TOTAL_FAIL";
export const VULAN_TOTAL_REQUEST = "VULAN_TOTAL_REQUEST";
export const VULAN_TOTAL_SUCCESS = "VULAN_TOTAL_SUCCESS";

export const RECENT_EVENT_FAIL = "RECENT_EVENT_FAIL";
export const RECENT_EVENT_REQUEST = "RECENT_EVENT_REQUEST";
export const RECENT_EVENT_SUCCESS = "RECENT_EVENT_SUCCESS";

export const UNIQUE_IPS_FAIL = "UNIQUE_IPS_FAIL";
export const UNIQUE_IPS_SUCCESS = "UNIQUE_IPS_SUCCESS";
export const UNIQUE_IPS_REQUEST = "UNIQUE_IPS_REQUEST";

//MITRE

export const TOP_TACTICS_FAIL = "TOP_TACTICS_FAIL";
export const TOP_TACTICS_REQUEST = "TOP_TACTICS_REQUEST";
export const TOP_TACTICS_SUCCESS = "TOP_TACTICS_SUCCESS";

export const ALERT_VOLVE_FAIL = "ALERT_VOLVE_FAIL";
export const ALERT_VOLVE_REQUST = "ALERT_VOLVE_REQUST";
export const ALERT_VOLVE_SUCCESS = "ALERT_VOLVE_SUCCESS";

export const ATTACK_ACTICS_FAIL = "ATTACK_ACTICS_FAIL";
export const ATTACK_ACTICS_REQUEST = "ATTACK_ACTICS_REQUEST ";
export const ATTACK_ACTICS_SUCCESS = "ATTACK_ACTICS_SUCCESS";

export const RULE_LVL_ATTACK_FAIL = "RULE_LVL_ATTACK_FAIL";
export const RULE_LVL_ATTACK_REQUEST = "RULE_LVL_ATTACK_REQUEST ";
export const RULE_LVL_ATTACK_SUCCESS = "RULE_LVL_ATTACK_SUCCESS";

export const RULE_LVL_TACTIC_FAIL = "RULE_LVL_TACTIC_FAIL";
export const RULE_LVL_TACTIC_REQUEST = "RULE_LVL_TACTIC_REQUEST ";
export const RULE_LVL_TACTIC_SUCCESS = "RULE_LVL_TACTIC_SUCCESS";

export const ALERT_COUNT_MITRE_FAIL = "ALERT_COUNT_MITRE_FAIL";
export const ALERT_COUNT_MITRE_REQUEST = "ALERT_COUNT_MITRE_REQUEST ";
export const ALERT_COUNT_MITRE_SUCCESS = "ALERT_COUNT_MITRE_SUCCESS";

//VULAN

export const VULNPIE_FAIL = "VULNPIE_FAIL";
export const VULNPIE_REQUEST = "VULNPIE_REQUEST ";
export const VULNPIE_SUCCESS = "VULNPIE_SUCCESS";

export const ACTIVE_VULNS_FAIL = "ACTIVE_VULNS_FAIL";
export const ACTIVE_VULNS_REQUEST = "ACTIVE_VULNS_REQUEST ";
export const ACTIVE_VULNS_SUCCESS = "ACTIVE_VULNS_SUCCESS";

export const VULNTABLE_FAIL = "VULNTABLE_FAIL";
export const VULNTABLE_REQUEST = "VULNTABLE_REQUEST ";
export const VULNTABLE_SUCCESS = "VULNTABLE_SUCCESS";

//NETWORK

export const NETWORK_EVTS_FAIL = "NETWORK_EVTS_FAIL";
export const NETWORK_EVTS_REQUEST = "NETWORK_EVTS_REQUEST ";
export const NETWORK_EVTS_SUCCESS = "NETWORK_EVTS_SUCCESS";

export const DNS_QUERIES_FAIL = "DNS_QUERIES_FAIL";
export const DNS_QUERIES_REQUEST = "DNS_QUERIES_REQUEST ";
export const DNS_QUERIES_SUCCESS = "DNS_QUERIES_SUCCESS";

export const UNIQ_SRC_DEST_FAIL = "UNIQ_SRC_DEST_FAIL";
export const UNIQ_SRC_DEST_REQUEST = "UNIQ_SRC_DEST_REQUEST ";
export const UNIQ_SRC_DEST_SUCCESS = "UNIQ_SRC_DEST_SUCCESS";

export const TOP_SRCIP_FAIL = "TOP_SRCIP_FAIL";
export const TOP_SRCIP_REQUEST = "TOP_SRCIP_REQUEST ";
export const TOP_SRCIP_SUCCESS = "TOP_SRCIP_SUCCESS";

export const TOP_DEST_IP_FAIL = "TOP_DEST_IP_FAIL";
export const TOP_DEST_IP_REQUEST = "TOP_DEST_IP_REQUEST ";
export const TOP_DEST_IP_SUCCESS = "TOP_DEST_IP_SUCCESS";

export const CONNECTION_COUNTRIES_FAIL = "CONNECTION_COUNTRIES_FAIL";
export const CONNECTION_COUNTRIES_REQUEST = "CONNECTION_COUNTRIES_REQUEST ";
export const CONNECTION_COUNTRIES_SUCCESS = "CONNECTION_COUNTRIES_SUCCESS";

export const TOP_DNS_QUERY_FAIL = "TOP_DNS_QUERY_FAIL";
export const TOP_DNS_QUERY_REQUEST = "TOP_DNS_QUERY_REQUEST ";
export const TOP_DNS_QUERY_SUCCESS = "TOP_DNS_QUERY_SUCCESS";

export const NETWORK_TABLE_FAIL = "NETWORK_TABLE_FAIL";
export const NETWORK_TABLE_REQUEST = "NETWORK_TABLE_REQUEST ";
export const NETWORK_TABLE_SUCCESS = "NETWORK_TABLE_SUCCESS";

export const SRC_TO_DEST_FAIL = "SRC_TO_DEST_FAIL";
export const SRC_TO_DEST_REQUEST = "SRC_TO_DEST_REQUEST ";
export const SRC_TO_DEST_SUCCESS = "SRC_TO_DEST_SUCCESS";

export const TOP_QUERY_NAME_FAIL = "TOP_QUERY_NAME_FAIL";
export const TOP_QUERY_NAME_REQUEST = "TOP_QUERY_NAME_REQUEST ";
export const TOP_QUERY_NAME_SUCCESS = "TOP_QUERY_NAME_SUCCESS";

//PCIDSS

export const REQUIREMENTS_FAIL = "REQUIREMENTS_FAIL";
export const REQUIREMENTS_REQUEST = "REQUIREMENTS_REQUEST ";
export const REQUIREMENTS_SUCCESS = "REQUIREMENTS_SUCCESS";

export const TOP_AGENTS_FAIL = "TOP_AGENTS_FAIL";
export const TOP_AGENTS_REQUEST = "TOP_AGENTS_REQUEST ";
export const TOP_AGENTS_SUCCESS = "TOP_AGENTS_SUCCESS";

export const TOP_REQUIREMENTS_FAIL = "TOP_REQUIREMENTS_FAIL";
export const TOP_REQUIREMENTS_REQUEST = "TOP_REQUIREMENTS_REQUEST ";
export const TOP_REQUIREMENTS_SUCCESS = "TOP_REQUIREMENTS_SUCCESS";

export const REQS_AGENT_FAIL = "REQS_AGENT_FAIL";
export const REQS_AGENT_REQUEST = "REQS_AGENT_REQUEST ";
export const REQS_AGENT_SUCCESS = "REQS_AGENT_SUCCESS";

//HIPPA

export const COMMON_ALERTS_FAIL = "COMMON_ALERTS_FAIL";
export const COMMON_ALERTS_REQUEST = "COMMON_ALERTS_REQUEST ";
export const COMMON_ALERTS_SUCCESS = "COMMON_ALERTS_SUCCESS";

export const TOP_REQS_FAIL = "TOP_REQS_FAIL";
export const TOP_REQS_REQUEST = "TOP_REQS_REQUEST ";
export const TOP_REQS_SUCCESS = "TOP_REQS_SUCCESS";

export const MOST_ACTIVE_FAIL = "MOST_ACTIVE_FAIL";
export const MOST_ACTIVE_REQUEST = "MOST_ACTIVE_REQUEST ";
export const MOST_ACTIVE_SUCCESS = "MOST_ACTIVE_SUCCESS";

export const REQS_EVOLUTION_FAIL = "REQS_EVOLUTION_FAIL";
export const REQS_EVOLUTION_REQUEST = "REQS_EVOLUTION_REQUEST ";
export const REQS_EVOLUTION_SUCCESS = "REQS_EVOLUTION_SUCCESS";

export const REQS_DISTRIBUTION_FAIL = "REQS_DISTRIBUTION_FAIL";
export const REQS_DISTRIBUTION_REQUEST = "REQS_DISTRIBUTION_REQUEST ";
export const REQS_DISTRIBUTION_SUCCESS = "REQS_DISTRIBUTION_SUCCESS";

//GDPR

export const GDPR_TOPAGENTS_FAIL = "GDPR_TOPAGENTS_FAIL";
export const GDPR_TOPAGENTS_REQUEST = "GDPR_TOPAGENTS_REQUEST ";
export const GDPR_TOPAGENTS_SUCCESS = "GDPR_TOPAGENTS_SUCCESS";

export const GDPR_REQS_FAIL = "GDPR_REQS_FAIL";
export const GDPR_REQS_REQUEST = "GDPR_REQS_REQUEST ";
export const GDPR_REQS_SUCCESS = "GDPR_REQS_SUCCESS";

export const GDPR_TOPREQS_FAIL = "GDPR_TOPREQS_FAIL";
export const GDPR_TOPREQS_REQUEST = "GDPR_TOPREQS_REQUEST ";
export const GDPR_TOPREQS_SUCCESS = "GDPR_TOPREQS_SUCCESS";

export const GDPR_REQSBY_AGENTS_FAIL = "GDPR_REQSBY_AGENTS_FAIL";
export const GDPR_REQSBY_AGENTS_REQUEST = "GDPR_REQSBY_AGENTS_REQUEST ";
export const GDPR_REQSBY_AGENTS_SUCCESS = "GDPR_REQSBY_AGENTS_SUCCESS";

//Alert

export const SURICATA_REQUEST = "SURICATA_REQUEST";
export const SURICATA_SUCCESS = "SURICATA_SUCCESS";
export const SURICATA_FAIL = "SURICATA_FAIL";

export const SURICATA_SECOND_REQUEST = "SURICATA_SECOND_REQUEST";
export const SURICATA_SECOND_SUCCESS = "SURICATA_SECOND_SUCCESS";
export const SURICATA_SECOND_FAIL = "SURICATA_SECOND_FAIL";

export const SURICATA_THIRD_REQUEST = "SURICATA_THIRD_REQUEST";
export const SURICATA_THIRD_SUCCESS = "SURICATA_THIRD_SUCCESS";
export const SURICATA_THIRD_FAIL = "SURICATA_THIRD_FAIL";

export const CRITICAL_FIRST_LVL_REQUEST = "CRITICAL_FIRST_LVL_REQUEST";
export const CRITICAL_FIRST_LVL_SUCCESS = "CRITICAL_FIRST_LVL_SUCCESS";
export const CRITICAL_FIRST_LVL_FAIL = "CRITICAL_FIRST_LVL_FAIL";

export const CRITICAL_SEC_LVL_REQUEST = "CRITICAL_SEC_LVL_REQUEST";
export const CRITICAL_SEC_LVL_SUCCESS = "CRITICAL_SEC_LVL_SUCCESS";
export const CRITICAL_SEC_LVL_FAIL = "CRITICAL_SEC_LVL_FAIL";

export const CRITICAL_THIRD_LVL_REQUEST = "CRITICAL_THIRD_LVL_REQUEST";
export const CRITICAL_THIRD_LVL_SUCCESS = "CRITICAL_THIRD_LVL_SUCCESS";
export const CRITICAL_THIRD_LVL_FAIL = "CRITICAL_THIRD_LVL_FAIL";

export const CRITICAL_FOURTH_LVL_REQUEST = "CRITICAL_FOURTH_LVL_REQUEST";
export const CRITICAL_FOURTH_LVL_SUCCESS = "CRITICAL_FOURTH_LVL_SUCCESS";
export const CRITICAL_FOURTH_LVL_FAIL = "CRITICAL_FOURTH_LVL_FAIL";

export const SITE_LABELS_REQUEST = "SITE_LABELS_REQUEST";
export const SITE_LABELS_SUCCESS = "SITE_LABELS_SUCCESS";
export const SITE_LABELS_FAIL = "SITE_LABELS_FAIL";

export const XDR_FIRST_LVL_REQUEST = "XDR_FIRST_LVL_REQUEST";
export const XDR_FIRST_LVL_SUCCESS = "XDR_FIRST_LVL_SUCCESS";
export const XDR_FIRST_LVL_FAIL = "XDR_FIRST_LVL_FAIL";

export const XDR_SECOND_LVL_REQUEST = "XDR_SECOND_LVL_REQUEST";
export const XDR_SECOND_LVL_SUCCESS = "XDR_SECOND_LVL_SUCCESS";
export const XDR_SECOND_LVL_FAIL = "XDR_SECOND_LVL_FAIL";

export const MITRE_FIRST_LVL_REQUEST = "MITRE_FIRST_LVL_REQUEST";
export const MITRE_FIRST_LVL_SUCCESS = "MITRE_FIRST_LVL_SUCCESS";
export const MITRE_FIRST_LVL_FAIL = "MITRE_FIRST_LVL_FAIL";

export const MITRE_SECOND_LVL_REQUEST = "MITRE_SECOND_LVL_REQUEST";
export const MITRE_SECOND_LVL_SUCCESS = "MITRE_SECOND_LVL_SUCCESS";
export const MITRE_SECOND_LVL_FAIL = "MITRE_SECOND_LVL_FAIL";

export const THREAT_FIRST_LVL_REQUEST = "THREAT_FIRST_LVL_REQUEST";
export const THREAT_FIRST_LVL_SUCCESS = "THREAT_FIRST_LVL_SUCCESS";
export const THREAT_FIRST_LVL_FAIL = "THREAT_FIRST_LVL_FAIL";

export const THREAT_SECOND_LVL_REQUEST = "THREAT_SECOND_LVL_REQUEST";
export const THREAT_SECOND_LVL_SUCCESS = "THREAT_SECOND_LVL_SUCCESS";
export const THREAT_SECOND_LVL_FAIL = "THREAT_SECOND_LVL_FAIL";

export const EVENT_FIRST_LVL_REQUEST = "EVENT_FIRST_LVL_REQUEST";
export const EVENT_FIRST_LVL_SUCCESS = "EVENT_FIRST_LVL_SUCCESS";
export const EVENT_FIRST_LVL_FAIL = "EVENT_FIRST_LVL_FAIL";

export const EVENT_SEC_LVL_REQUEST = "EVENT_SEC_LVL_REQUEST";
export const EVENT_SEC_LVL_SUCCESS = "EVENT_SEC_LVL_SUCCESS";
export const EVENT_SEC_LVL_FAIL = "EVENT_SEC_LVL_FAIL";

export const EVENT_THIRD_LVL_REQUEST = "EVENT_THIRD_LVL_REQUEST";
export const EVENT_THIRD_LVL_SUCCESS = "EVENT_THIRD_LVL_SUCCESS";
export const EVENT_THIRD_LVL_FAIL = "EVENT_THIRD_LVL_FAIL";

export const GET_NTA_REPORT_REQUEST = "GET_NTA_REPORT_REQUEST";
export const GET_NTA_REPORT_SUCCESS = "GET_NTA_REPORT_SUCCESS";
export const GET_NTA_REPORT_FAIL = "GET_NTA_REPORT_FAIL";

export const GET_CLOUDS_REQUEST = "GET_CLOUDS_REQUEST";
export const GET_CLOUDS_SUCCESS = "GET_CLOUDS_SUCCESS";
export const GET_CLOUDS_FAIL = "GET_CLOUDS_FAIL";

export const GET_TOP_CLIENT_IP_REQUEST = "GET_TOP_CLIENT_IP_REQUEST";
export const GET_TOP_CLIENT_IP_SUCCESS = "GET_TOP_CLIENT_IP_SUCCESS";
export const GET_TOP_CLIENT_IP_FAIL = "GET_TOP_CLIENT_IP_FAIL";

export const GET_TOP_RULES_REQUEST = "GET_TOP_RULES_REQUEST";
export const GET_TOP_RULES_SUCCESS = "GET_TOP_RULES_SUCCESS";
export const GET_TOP_RULES_FAIL = "GET_TOP_RULES_FAIL";

export const GET_TOP_OPERATION_REQUEST = "GET_TOP_OPERATION_REQUEST";
export const GET_TOP_OPERATION_SUCCESS = "GET_TOP_OPERATION_SUCCESS";
export const GET_TOP_OPERATION_FAIL = "GET_TOP_RULES_FAIL";

export const GET_FULL_EVENTS_REQUEST = "GET_FULL_EVENTS_REQUEST";
export const GET_FULL_EVENTS_SUCCESS = "GET_FULL_EVENTS_SUCCESS";
export const GET_FULL_EVENTS_FAIL = "GET_FULL_EVENTS_FAIL";

export const GET_SENTINELONE_AGENT_REQUEST = "GET_SENTINELONE_AGENT_REQUEST";
export const GET_SENTINELONE_AGENT_SUCCESS = "GET_SENTINELONE_AGENT_SUCCESS";
export const GET_SENTINELONE_AGENT_FAIL = "GET_SENTINELONE_AGENT_FAIL";

export const GET_INVENTORY_AGENT_REQUEST = "GET_INVENTORY_AGENT_REQUEST";
export const GET_INVENTORY_AGENT_SUCCESS = "GET_INVENTORY_AGENT_SUCCESS";
export const GET_INVENTORY_AGENT_FAIL = "GET_INVENTORY_AGENT_FAIL";

export const SITE_LOADING = "SITE_LOADING";
export const ALL_SITE = "ALL_SITE";
