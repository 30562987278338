import { notification } from "antd";

import {
  CRITICAL_SEC_LVL_FAIL,
  CRITICAL_SEC_LVL_REQUEST,
  CRITICAL_SEC_LVL_SUCCESS,
  CRITICAL_THIRD_LVL_FAIL,
  CRITICAL_THIRD_LVL_REQUEST,
  CRITICAL_THIRD_LVL_SUCCESS,
  SITE_LABELS_FAIL,
  SITE_LABELS_REQUEST,
  SITE_LABELS_SUCCESS,
  SURICATA_FAIL,
  SURICATA_REQUEST,
  SURICATA_SECOND_FAIL,
  SURICATA_SECOND_REQUEST,
  SURICATA_SECOND_SUCCESS,
  SURICATA_SUCCESS,
  SURICATA_THIRD_FAIL,
  SURICATA_THIRD_REQUEST,
  SURICATA_THIRD_SUCCESS,
  CRITICAL_FIRST_LVL_FAIL,
  CRITICAL_FIRST_LVL_REQUEST,
  CRITICAL_FIRST_LVL_SUCCESS,
  XDR_FIRST_LVL_FAIL,
  XDR_FIRST_LVL_REQUEST,
  XDR_FIRST_LVL_SUCCESS,
  XDR_SECOND_LVL_FAIL,
  XDR_SECOND_LVL_REQUEST,
  XDR_SECOND_LVL_SUCCESS,
  TOP_CRIT_ALERT_REQUEST,
  TOP_CRIT_ALERT_SUCCESS,
  TOP_CRIT_ALERT_FAIL,
  THREAT_SECOND_LVL_REQUEST,
  THREAT_SECOND_LVL_SUCCESS,
  THREAT_FIRST_LVL_FAIL,
  THREAT_FIRST_LVL_SUCCESS,
  THREAT_FIRST_LVL_REQUEST,
  THREAT_SECOND_LVL_FAIL,
  MITRE_FIRST_LVL_FAIL,
  MITRE_FIRST_LVL_SUCCESS,
  MITRE_FIRST_LVL_REQUEST,
  MITRE_SECOND_LVL_REQUEST,
  MITRE_SECOND_LVL_SUCCESS,
  MITRE_SECOND_LVL_FAIL,
  EVENT_FIRST_LVL_REQUEST,
  EVENT_FIRST_LVL_SUCCESS,
  EVENT_FIRST_LVL_FAIL,
  EVENT_SEC_LVL_REQUEST,
  EVENT_SEC_LVL_SUCCESS,
  EVENT_SEC_LVL_FAIL,
  EVENT_THIRD_LVL_REQUEST,
  EVENT_THIRD_LVL_SUCCESS,
  EVENT_THIRD_LVL_FAIL,
  CRITICAL_FOURTH_LVL_REQUEST,
  CRITICAL_FOURTH_LVL_SUCCESS,
  CRITICAL_FOURTH_LVL_FAIL,
  GET_IP_IFO_REQUEST,
  GET_IP_IFO_SUCCESS,
  GET_IP_IFO_FAIL,
} from "./actionConstant";
import {
  CRITICAL_THIRD_LVL,
  CRITICAL_SECOND_LVL,
  SITE_LABELS_URL,
  SURICATA_FIRST_LVL,
  SURICATA_SECOND_LVL,
  SURICATA_THIRD_LVL,
  CRITICAL_FIRST_LVL,
  XDR_FIRST_LVL_URL,
  XDR_SECOND_LVL_URL,
  TOP_CRIT_ALERT,
  THREAT_HUNT_FIRST_LVL_URL,
  THREAT_HUNT_SEC_LVL_URL,
  EVENTS_FIRST_LVL_URL,
  EVENTS_SECOND_LVL_URL,
  EVENTS_THIRD_LVL_URL,
  MITRE_FIRST_LVL_URL,
  MITRE_SECOND_LVL_URL,
  CRITICAL_FOURTH_LVL,
  GET_IP_INFO,
} from "../apiUrl"; 
import { getLoginUser } from "../common/localStorage";
import { functionalityApiCall } from "../common/connect";
import moment from "moment";

const topCritAlertRequest = () => ({
  type: TOP_CRIT_ALERT_REQUEST,
});
const topCritAlertSuccess = (data) => ({
  type: TOP_CRIT_ALERT_SUCCESS,
  data,
});
const topCritAlertFailure = () => ({
  type: TOP_CRIT_ALERT_FAIL,
});

const criticalShotsFirstLvlRequest = () => ({
  type: CRITICAL_FIRST_LVL_REQUEST,
});
const criticalShotsFirstLvlSuccess = (data) => ({
  type: CRITICAL_FIRST_LVL_SUCCESS,
  data,
});
const criticalShotsFirstLvlFailure = () => ({
  type: CRITICAL_FIRST_LVL_FAIL,
});

const suriCataLvlFirstRequest = () => ({
  type: SURICATA_REQUEST,
});
const suriCataLvlFirstSuccess = (data) => ({
  type: SURICATA_SUCCESS,
  data,
});
const suriCataLvlFirstFailure = () => ({
  type: SURICATA_FAIL,
});

const suriCataSecondLvlRequest = () => ({
  type: SURICATA_SECOND_REQUEST,
});
const suriCataSecondLvlSuccess = (data) => ({
  type: SURICATA_SECOND_SUCCESS,
  data,
});
const suriCataSecondLvlFailure = () => ({
  type: SURICATA_SECOND_FAIL,
});

const suriCataThirdLvlRequest = () => ({
  type: SURICATA_THIRD_REQUEST,
});
const suriCataThirdLvlSuccess = (data) => ({
  type: SURICATA_THIRD_SUCCESS,
  data,
});
const suriCataThirdLvlFailure = () => ({
  type: SURICATA_THIRD_FAIL,
});

const criticalShotsSecLvlRequest = () => ({
  type: CRITICAL_SEC_LVL_REQUEST,
});
const criticalShotsSecLvlSuccess = (data) => ({
  type: CRITICAL_SEC_LVL_SUCCESS,
  data,
});
const criticalShotsSecLvlFailure = () => ({
  type: CRITICAL_SEC_LVL_FAIL,
});

const siteLabelsRequest = () => ({
  type: SITE_LABELS_REQUEST,
});
const siteLabelsSuccess = (data) => ({
  type: SITE_LABELS_SUCCESS,
  data,
});
const siteLabelsFailure = () => ({
  type: SITE_LABELS_FAIL,
});

const criticalsTHirdLvlRequest = () => ({
  type: CRITICAL_THIRD_LVL_REQUEST,
});
const criticalsTHirdLvlSuccess = (data) => ({
  type: CRITICAL_THIRD_LVL_SUCCESS,
  data,
});
const criticalsTHirdLvlFailure = () => ({
  type: CRITICAL_THIRD_LVL_FAIL,
});

const criticalsFourthLvlRequest = () => ({
  type: CRITICAL_FOURTH_LVL_REQUEST,
});
const criticalsFourthLvlSuccess = (data) => ({
  type: CRITICAL_FOURTH_LVL_SUCCESS,
  data,
});
const criticalsFourthLvlFailure = () => ({
  type: CRITICAL_FOURTH_LVL_FAIL,
});

const xdrFirstLvlRequest = () => ({
  type: XDR_FIRST_LVL_REQUEST,
});
const xdrFirstLvlSuccess = (data) => ({
  type: XDR_FIRST_LVL_SUCCESS,
  data,
});
const xdrFirstLvlFailure = () => ({
  type: XDR_FIRST_LVL_FAIL,
});

const xdrSecondLvlRequest = () => ({
  type: XDR_SECOND_LVL_REQUEST,
});
const xdrSecondLvlSuccess = (data) => ({
  type: XDR_SECOND_LVL_SUCCESS,
  data,
});
const xdrSecondLvlFailure = () => ({
  type: XDR_SECOND_LVL_FAIL,
});

const ThreatFirstLvlRequest = () => ({
  type: THREAT_FIRST_LVL_REQUEST,
});
const ThreatFirstLvlSuccess = (data) => ({
  type: THREAT_FIRST_LVL_SUCCESS,
  data,
});
const ThreatFirstLvlFailure = () => ({
  type: THREAT_FIRST_LVL_FAIL,
});

const ThreatSecondLvlRequest = () => ({
  type: THREAT_SECOND_LVL_REQUEST,
});
const ThreatSecondLvlSuccess = (data) => ({
  type: THREAT_SECOND_LVL_SUCCESS,
  data,
});
const ThreatSecondLvlFailure = () => ({
  type: THREAT_SECOND_LVL_FAIL,
});

const mitreSecLvlRequest = () => ({
  type: MITRE_SECOND_LVL_REQUEST,
});

const mitreSecLvlSuccess = (data) => ({
  type: MITRE_SECOND_LVL_SUCCESS,
  data,
});

const mitreSecLvlFail = () => ({
  type: MITRE_SECOND_LVL_FAIL,
});

const mitreFirLvlRequest = () => ({
  type: MITRE_FIRST_LVL_REQUEST,
});

const mitreFirstLvlSuccess = (data) => ({
  type: MITRE_FIRST_LVL_SUCCESS,
  data,
});

const mitreFirstLvlFail = () => ({
  type: MITRE_FIRST_LVL_FAIL,
});

const eventFirstLvlRequest = () => ({
  type: EVENT_FIRST_LVL_REQUEST,
});

const eventFirststLvlSuccess = (data) => ({
  type: EVENT_FIRST_LVL_SUCCESS,
  data,
});

const eventFirststLvlFail = () => ({
  type: EVENT_FIRST_LVL_FAIL,
});

const eventSecLvlRequest = () => ({
  type: EVENT_SEC_LVL_REQUEST,
});

const eventSecstLvlSuccess = (data) => ({
  type: EVENT_SEC_LVL_SUCCESS,
  data,
});

const eventSecstLvlFail = () => ({
  type: EVENT_SEC_LVL_FAIL,
});

const eventThirdLvlRequest = () => ({
  type: EVENT_THIRD_LVL_REQUEST,
});

const eventThirdstLvlSuccess = (data) => ({
  type: EVENT_THIRD_LVL_SUCCESS,
  data,
});

const eventThirdstLvlFail = () => ({
  type: EVENT_THIRD_LVL_FAIL,
});

const ipInfoRequest = () => ({
  type: GET_IP_IFO_REQUEST,
});

const ipInfoSuccess = (data) => ({
  type: GET_IP_IFO_SUCCESS,
  data,
});

const iPinfoFailure = () => ({
  type: GET_IP_IFO_FAIL,
});
export const startDate = moment()
  .subtract(1, "days")
  .utc()
  .format("yyyy-MM-DDTHH:mm:ss");

export const endDate = moment().utc().format("yyyy-MM-DDTHH:mm:ss");
export const data = {
  // namespace: "windows_testing",
  gte: startDate,
  lte: endDate,
  size: "200",
};

export const getIpDdrApi = (namespace, ipDdr) => (dispatch) => {
  dispatch(ipInfoRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(ipInfoSuccess(response));
    };
    const url = GET_IP_INFO;

    const onFailure = (response) => {
      dispatch(iPinfoFailure(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    let body = {
      namespace: namespace,
      ipaddr: ipDdr,
    };

    functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
  }
};

export const getSuriCataFirstLvl =
  (namespace, agentName, startDate, endDate) => (dispatch) => {
    dispatch(suriCataLvlFirstRequest());
    const token = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(suriCataLvlFirstSuccess(response));
      };
      const url = SURICATA_FIRST_LVL;

      const onFailure = (response) => {
        dispatch(suriCataLvlFirstFailure(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };

      let body = { ...data };

      body.namespace = namespace;
      // if (agentName) body.agentfilter = agentName;
      if (startDate && endDate) {
        body.gte = startDate;
        body.lte = endDate;
      }

      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const getSuriCataSecondLvl =
  (label, namespace, agentName, startDate, endDate) => (dispatch) => {
    dispatch(suriCataSecondLvlRequest());
    const token = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(suriCataSecondLvlSuccess(response));
      };
      const url = SURICATA_SECOND_LVL;

      const onFailure = (response) => {
        dispatch(suriCataSecondLvlFailure(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      body.alertcategory = label;
      body.namespace = namespace;
      // if (agentName) body.agentfilter = agentName;
      if (startDate && endDate) {
        body.gte = startDate;
        body.lte = endDate;
      }
      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const getSuriCataThirdLvl =
  (label, namespace, agentName, startDate, endDate) => (dispatch) => {
    dispatch(suriCataThirdLvlRequest());
    const token = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(suriCataThirdLvlSuccess(response));
      };
      const url = SURICATA_THIRD_LVL;

      const onFailure = (response) => {
        dispatch(suriCataThirdLvlFailure(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      body.alertsig = label;
      // if (agentName) body.agentfilter = agentName;
      if (namespace) body.namespace = namespace;
      if (startDate && endDate) {
        body.gte = startDate;
        body.lte = endDate;
      }
      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const getCriticalShotsFirstLvl =
  (namespace, agentName, startDate, endDate) => (dispatch) => {
    dispatch(criticalShotsFirstLvlRequest());
    const token = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(criticalShotsFirstLvlSuccess(response));
      };
      const url = CRITICAL_FIRST_LVL;

      const onFailure = (response) => {
        dispatch(criticalShotsFirstLvlFailure(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      body.namespace = namespace;
      if (agentName) body.agentfilter = agentName;
      if (startDate && endDate) {
        body.gte = startDate;
        body.lte = endDate;
      }
      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const getCriticalShotsSecLvl =
  (label, namespace, agentName, startDate, endDate) => (dispatch) => {
    dispatch(criticalShotsSecLvlRequest());
    const token = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(criticalShotsSecLvlSuccess(response));
      };
      const url = CRITICAL_SECOND_LVL;

      const onFailure = (response) => {
        dispatch(criticalShotsSecLvlFailure(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      body.ruledesc = label;
      body.namespace = namespace;
      if (agentName) body.agentfilter = agentName;
      if (startDate && endDate) {
        body.gte = startDate;
        body.lte = endDate;
      }

      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const getSiteLabels = () => (dispatch) => {
  dispatch(siteLabelsRequest());
  const token = getLoginUser();

  if (token) {
    const onSuccess = (response) => {
      if(response.data){

        dispatch(siteLabelsSuccess(response));
      }
    };
    const url = SITE_LABELS_URL;

    const onFailure = (response) => {
      dispatch(siteLabelsFailure(response));
      // dispatch(setLoader(false));

      notification.error({
        message: response?.response?.data?.message || response?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const getCriticalsThirdLvl =
  (label, agent, namespace, agentName, startDate, endDate) => (dispatch) => {
    dispatch(criticalsTHirdLvlRequest());
    const token = getLoginUser();

    if (token) {
      const onSuccess = (response) => {
        dispatch(criticalsTHirdLvlSuccess(response));
      };
      const url = CRITICAL_THIRD_LVL;

      const onFailure = (response) => {
        dispatch(criticalsTHirdLvlFailure(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      body.ruledesc = label;
      if (agent) body.agentfilter = agent;
      body.namespace = namespace || "windows_testing";
      if (startDate && endDate) {
        body.gte = startDate;
        body.lte = endDate;
      }
      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };
export const getCriticalsFourthLvl =
  (id, timeStamp, agent, namespace, size) => (dispatch) => {
    dispatch(criticalsFourthLvlRequest());
    const token = getLoginUser();

    if (token) {
      const onSuccess = (response) => {
        dispatch(criticalsFourthLvlSuccess(response));
      };
      const url = CRITICAL_FOURTH_LVL;

      const onFailure = (response) => {
        dispatch(criticalsFourthLvlFailure(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };

      if (agent) body.agentfilter = agent;
      body.namespace = namespace || "windows_testing"; 
      body.id = id;
      body.ts = timeStamp;
      body.size = "10";
      delete  body.gte
      delete  body.lte
      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const getEdrFirstLvl =
  (namespace, agent, startDate, endDate) => (dispatch) => {
    dispatch(xdrFirstLvlRequest());
    const token = getLoginUser();

    if (token) {
      const onSuccess = (response) => {
        dispatch(xdrFirstLvlSuccess(response));
      };
      const url = XDR_FIRST_LVL_URL;

      const onFailure = (response) => {
        dispatch(xdrFirstLvlFailure(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      body.namespace = namespace || "windows_testing";
      if (agent) body.agentfilter = agent;
      if (startDate && endDate) {
        body.gte = startDate;
        body.lte = endDate;
      }
      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const getEdrSecLvl =
  (label, siteName, agentname, startDate, endDate) => (dispatch) => {
    dispatch(xdrSecondLvlRequest());
    const token = getLoginUser();

    if (token) {
      const onSuccess = (response) => {
        dispatch(xdrSecondLvlSuccess(response));
      };
      const url = XDR_SECOND_LVL_URL;

      const onFailure = (response) => {
        dispatch(xdrSecondLvlFailure(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      body.ruledesc = label;
      body.namespace = siteName;
      if (agentname) body.agentfilter = agentname;
      body.gte = startDate;
      body.lte = endDate;

      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const getThreatHuntFirstLvl =
  (namespace, agent, startDate, endDate) => (dispatch) => {
    dispatch(ThreatFirstLvlRequest());
    const token = getLoginUser();

    if (token) {
      const onSuccess = (response) => {
        dispatch(ThreatFirstLvlSuccess(response));
      };
      const url = THREAT_HUNT_FIRST_LVL_URL;

      const onFailure = (response) => {
        dispatch(ThreatFirstLvlFailure(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      body.namespace = namespace;

      body.agentfilter = agent;
      if (startDate && endDate) {
        body.gte = startDate;
        body.lte = endDate;
      }
      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const getThreatHuntSecond =
  (label, siteName, agent, startDate, endDate) => (dispatch) => {
    dispatch(ThreatSecondLvlRequest());
    const token = getLoginUser();

    if (token) {
      const onSuccess = (response) => {
        dispatch(ThreatSecondLvlSuccess(response));
      };
      const url = THREAT_HUNT_SEC_LVL_URL;

      const onFailure = (response) => {
        dispatch(ThreatSecondLvlFailure(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      body.ruledesc = label;
      body.namespace = siteName;

      body.gte = startDate;
      body.lte = endDate;
      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const getTopCritAlert =
  (namespace, agentName, startDate, endDate) => (dispatch) => {
    dispatch(topCritAlertRequest());
    const token = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(topCritAlertSuccess(response));
      };
      const url = TOP_CRIT_ALERT;

      const onFailure = (response) => {
        dispatch(topCritAlertFailure(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      body.namespace = namespace || "windows_testing";

      if (startDate && endDate) {
        body.agentfilter = agentName || "";
        body.gte = startDate;
        body.lte = endDate;
      }

      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const getMitreFirstLvl =
  (namespace, agentName, startDate, endDate) => (dispatch) => {
    dispatch(mitreFirLvlRequest());
    const token = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(mitreFirstLvlSuccess(response));
      };
      const url = MITRE_FIRST_LVL_URL;

      const onFailure = (response) => {
        dispatch(mitreFirstLvlFail(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      body.namespace = namespace || "windows_testing";
      body.agentfilter = agentName;
      if (startDate && endDate) {
        body.gte = startDate;
        body.lte = endDate;
      }
      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const getMitreSecondLvl =
  (label, siteName, agentName, startDate, endDate) => (dispatch) => {
    dispatch(mitreSecLvlRequest());
    const token = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(mitreSecLvlSuccess(response));
      };
      const url = MITRE_SECOND_LVL_URL;

      const onFailure = (response) => {
        dispatch(mitreSecLvlFail(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      if (agentName) body.agentfilter = agentName;
      body.mitre_tactic = label;
      body.namespace = siteName;
      body.gte = startDate;
      body.lte = endDate;

      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const getEventFirstLvl =
  (namespace, agent, startDate, endDate) => (dispatch) => {
    dispatch(eventFirstLvlRequest());
    const token = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(eventFirststLvlSuccess(response));
      };
      const url = EVENTS_FIRST_LVL_URL;

      const onFailure = (response) => {
        dispatch(eventFirststLvlFail(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      body.namespace = namespace;
      if (startDate) body.gte = startDate;
      if (endDate) body.lte = endDate;
      if (agent) body.agentfilter = agent;
      body.size = "200";
      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const getEventSecLvl =
  (label, siteName, agent, startDate, endDate) => (dispatch) => {
    dispatch(eventSecLvlRequest());
    const token = getLoginUser();

    if (token) {
      const onSuccess = (response) => {
        dispatch(eventSecstLvlSuccess(response));
      };
      const url = EVENTS_SECOND_LVL_URL;

      const onFailure = (response) => {
        dispatch(eventSecstLvlFail(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      body.gte = startDate;
      body.lte = endDate;
      body.ruledesc = label;
      body.namespace = siteName;
      body.agentfilter = agent;
      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };
export const getEventThirdLvl =
  (label, rule, siteName, agent, startDate, endDate) => (dispatch) => {
    dispatch(eventThirdLvlRequest());
    const token = getLoginUser();

    if (token) {
      const onSuccess = (response) => {
        dispatch(eventThirdstLvlSuccess(response));
      };
      const url = EVENTS_THIRD_LVL_URL;

      const onFailure = (response) => {
        dispatch(eventThirdstLvlFail(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      body.gte = startDate;
      body.lte = endDate;
      body.ruledesc = rule;
      body.namespace = siteName;
      body.agentfilter = label;
      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };
