/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Card, Col, Row } from "antd";
import React, { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import DataTableExtensions from "react-data-table-component-extensions";

import { ruleLvlTactic } from "../../../Actions/mitreDashboard";

import styles from "./index.module.scss";

function RuleLvltActic(props) {
  const dispatch = useDispatch();

  const isProccess = useSelector((state) => state.mitre?.isProcess);
  const [getAttackActics, setGetAttackActics] = useState([]);
  const attackActicsData = useSelector(
    (state) => state.mitre.rulelvltactic?.[2]?.buckets
  );

  const data = getAttackActics;

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(ruleLvlTactic());
  };

  useEffect(() => {
    let arr = [];

    if (attackActicsData?.length > 0) {
      for (let i = 0; i < attackActicsData?.length; i++) {
        let obj = {
          groupName: attackActicsData[i]?.key,
          count: attackActicsData[i]?.doc_count,
          agentName: attackActicsData[i]?.[3]?.buckets[0]?.key,
          docCount: attackActicsData[i]?.[3]?.buckets[0]?.doc_count,
        };
        arr.push(obj);
      }
      setGetAttackActics(arr);
    }
  }, [attackActicsData]);

  const columns = [
    {
      name: "Group Name",

      selector: (row) => row.groupName,
      sortable: true,
    },
    {
      name: "Count ",
      selector: (row) => row.count,
      sortable: true,
    },
    {
      name: "key",
      selector: (row) => row.agentName,
      sortable: true,
    },
    {
      name: "Count",
      selector: (row) => row.docCount,
      sortable: true,
    },
  ];
  const tableData = {
    columns,
    data,
  };

  return (
    <Row justify="center">
      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
        <Card className={styles.sellerCard}>
          <div className={styles.bestSeller}>
            <p>Rule Level Tactic</p>
          </div>

          <DataTableExtensions
      filterDigit={0}
            filterPlaceholder="Search"
            print={false}
            export={false}
            data={data}
            {...tableData}
          >
            {isProccess ? (
              <Row justify="center">
                <Col>
                  <ThreeDots color="#00000" height={50} width={50} />
                </Col>
              </Row>
            ) : (
              <DataTable highlightOnHover data={data} columns={columns} />
            )}
          </DataTableExtensions>
        </Card>
      </Col>
    </Row>
  );
}

export default RuleLvltActic;
