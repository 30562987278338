// export const BASE_URL = "https://siemdev.clirsec.com:9251";
export const BASE_URL = process.env.REACT_APP_BASE_URL;
// https://165.22.230.255:9251/alerts/tablecount

// {
//     "gte": "2023-06-19T17:56:30",
//     "lte": "2023-06-20T17:56:30"
// }
export const LOGIN_URL = `${BASE_URL}/auth/login`;
export const REGISTER_URL = `${BASE_URL}/auth/register`;
export const TOP_ALERTS = `${BASE_URL}/overview/topalerts`;
export const HOSTS_INFO = `${BASE_URL}/overview/hostsinfo`;
export const UNIQUE_IPS = `${BASE_URL}/overview/uniqueips`;
export const VULAN_TOTAL = `${BASE_URL}/overview/vulntotal`;
export const CRIT_ALERTS = `${BASE_URL}/overview/critalerts`;
export const HOTS_UNIQUE = `${BASE_URL}/overview/hostsunique`;
export const HOSTS_UNIQUE = `${BASE_URL}/overview/hostsunique`;
export const RECENT_EVENT = `${BASE_URL}/overview/recentevents`;
export const AGENTS_LIST = `${BASE_URL}/overview/agentslist`;
export const TOP_CRIT_ALERT = `${BASE_URL}/alerts/topcritalerts`;
export const ADD_WEEKLY_REPORT = `${BASE_URL}/overview/srctodest`;

export const TOP_TACTICS = `${BASE_URL}/mitre/toptactics`;
export const ALERT_VOLVE = `${BASE_URL}/mitre/alertevolve`;
export const ATTACK_ACTIC = `${BASE_URL}/mitre/attacktactic`;
export const RULE_LVL_ATTACK = `${BASE_URL}/mitre/rulelvlattack`;
export const RULE_LVL_TACTIC = `${BASE_URL}/mitre/rulelvltactic`;
export const ALERT_COUNT_MITRE = `${BASE_URL}/mitre/alertscount`;

//

export const VULNPIE = `${BASE_URL}/vulnstats/vulnpie`;
export const VULNTABLE = `${BASE_URL}/vulnstats/vulntable`;
export const ACTIVE_VULNS = `${BASE_URL}/vulnstats/activevulns`;

export const TOPSRCIP = `${BASE_URL}/network/topsrcip`;
export const TOPDESTIP = `${BASE_URL}/network/topdestip`;
export const SRCTODEST = `${BASE_URL}/network/srctodest`;
export const DNSQUERIES = `${BASE_URL}/network/dnsqueries`;
export const UNIQSRCDEST = `${BASE_URL}/network/uniqsrcdest`;
export const NETWORKEVTS = `${BASE_URL}/network/networkevts`;
export const TOPDNSQUERY = `${BASE_URL}/network/topdnsquery`;
export const NETWORKTABLE = `${BASE_URL}/network/networktable`;
export const TOPQUERYNAME = `${BASE_URL}/network/topqueryname`;
export const CONNECTIONCOUNTRIES = `${BASE_URL}/network/connectioncountries`;

export const TOPAGENTS = `${BASE_URL}/pcidss/topagents`;
export const REQSAGENT = `${BASE_URL}/pcidss/reqsagent`;
export const REQUIREMENTS = `${BASE_URL}/pcidss/requirements`;
export const TOPREQUIREMENTS = `${BASE_URL}/pcidss/toprequirements`;

export const TOPREQS = `${BASE_URL}/hipaa/topreqs`;
export const MOSTACTIVE = `${BASE_URL}/hipaa/mostactive`;
export const COMMONALERTS = `${BASE_URL}/hipaa/commonalerts`;
export const REQSEVOLUTION = `${BASE_URL}/hipaa/reqsevolution`;
export const REQSDISTRIBUTION = `${BASE_URL}/hipaa/reqsdistribution`;

export const GDPR_REQS = `${BASE_URL}/gdpr/reqs`;
export const GDPR_TOPREQS = `${BASE_URL}/gdpr/topreqs`;
export const GDPR_TOPAGENTS = `${BASE_URL}/gdpr/topagents`;
export const TABLE_DATA_COUNT = `${BASE_URL}/alerts/tablecount`;
export const GDPR_REQSBY_AGENTS = `${BASE_URL}/gdpr/reqsbyagents`;

export const XDR_FIRST_LVL_URL = `${BASE_URL}/alerts/xdrlvl1`;
export const XDR_SECOND_LVL_URL = `${BASE_URL}/alerts/xdrlvl2`;
export const SITE_LABELS_URL = `${BASE_URL}/alerts/sitelabels2`;
export const SURICATA_FIRST_LVL = `${BASE_URL}/alerts/suricata1`;
export const SURICATA_THIRD_LVL = `${BASE_URL}/alerts/suricata3`;
export const SURICATA_SECOND_LVL = `${BASE_URL}/alerts/suricata2`;
export const CRITICAL_THIRD_LVL = `${BASE_URL}/alerts/criticalslvl3`;
export const CRITICAL_FOURTH_LVL = `${BASE_URL}/alerts/surroundevts`;
export const CRITICAL_FIRST_LVL = `${BASE_URL}/alerts/criticalslvl1`;
export const CRITICAL_SECOND_LVL = `${BASE_URL}/alerts/criticalslvl2`;

export const MITRE_FIRST_LVL_URL = `${BASE_URL}/alerts/mitrelvl1`;
export const MITRE_SECOND_LVL_URL = `${BASE_URL}/alerts/mitrelvl2`;

export const EVENTS_FIRST_LVL_URL = `${BASE_URL}/alerts/eventslvl1`;
export const EVENTS_THIRD_LVL_URL = `${BASE_URL}/alerts/eventslvl3`;
export const EVENTS_SECOND_LVL_URL = `${BASE_URL}/alerts/eventslvl2`;

export const THREAT_HUNT_SEC_LVL_URL = `${BASE_URL}/alerts/threathunt2`;
export const THREAT_HUNT_FIRST_LVL_URL = `${BASE_URL}/alerts/threathunt1`;

export const GET_IP_INFO = `${BASE_URL}/alerts/ipinfo`;
export const GET_NTA_REPORT = `${BASE_URL}/alerts/ntareport`;
export const EXPORT_CSV_DATA = `${BASE_URL}/export`;

export const GET_CLOUDS = `${BASE_URL}/cloud/o365/topusers`;
export const GET_TOP_RULES = `${BASE_URL}/cloud/o365/toprules`;
export const GET_FULL_EVENTS = `${BASE_URL}/cloud/o365/fullevt`;
export const GET_TOP_CLIENT_IP = `${BASE_URL}/cloud/o365/topclientip`;
export const GET_TOP_OPRATION = `${BASE_URL}/cloud/o365/topoperations`;

export const GET_SENTINELONE_AGENT_LIST = `${BASE_URL}/inventory/table`;
export const GET_AGENT_INVENTORY_LIST = `${BASE_URL}/inventory/agentinventory`;
