/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Col, Row } from "antd";
import { Chart } from "react-google-charts";
import { ThreeDots } from "react-loader-spinner";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { vulnPie } from "../../../Actions/vulanStats";

import styles from "./index.module.scss";

function VulnPieScreen(props) {
  const dispatch = useDispatch();
  const vulnPieDataProcess = useSelector((state) => state.vulnstats?.isProcess);
  const vulnPieData = useSelector((state) => state.vulnstats?.vulnPie);

  const [vulnPieDataData, setvulnPieDataData] = useState();

  useEffect(() => {
    if (vulnPieData) {
      let data = vulnPieData?.[2]?.buckets;
      let arr = [];
      for (let i = 0; i < data?.length; i++) {
        arr.push([data[i].key, data[i].doc_count]);
      }
      arr.unshift(["date", "value"]);
      setvulnPieDataData([...arr]);
    }
  }, [vulnPieData]);

  useEffect(() => {
    dispatch(vulnPie());
  }, []);

  const options = {
    is3D: true,
  };

  return (
    <div>
      <Card className={styles.progressCard}>
        <div className={styles.cardMain}>
          <div className={styles.salesMain}>
            <p className={styles.salesTxt}>{props.title}</p>
          </div>
          <div className={styles.chartsMain}>
            {vulnPieDataProcess ? (
              <Row justify="center">
                <Col>
                  <ThreeDots color="#00000" height={50} width={50} />
                </Col>
              </Row>
            ) : (
              <Chart
                chartType="PieChart"
                data={vulnPieDataData}
                options={options}
                width="100%"
                height="400px"
                legendToggle={false}
                legend_toggle={false}
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default VulnPieScreen;
