import { useState } from 'react'
import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal'
import { Row, Breadcrumb, Button, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import SiteInfoModal from './SiteInfoModal'
import { hostsInfo } from '../../../Actions/overView'
import { allFilterData } from '../../../reducers/alerts'

import styles from './index.module.scss'

function SiteHostModal({
  isVisible,
  setIsVisible,
  siteName, 
  startDate,
  endDate,
}) {
  const dispatch = useDispatch()
  const data = useSelector((state) => allFilterData(state))   
  const [infoTitle, setInfoTitle] = useState()
  const [isInfoModal, setIsInfoModal] = useState(false)
  const handleCancel = () => {
    setIsVisible(!isVisible)
  }
  let size = 1
  const showHostinfoModal = (item, title) => {
    setIsVisible(false)
    dispatch(hostsInfo(siteName, item, startDate, endDate, size))
    setIsInfoModal(!isInfoModal)
    setInfoTitle(title)
  }
  // const arr = agent
  //   ? filter(data.hostList, function (o) {
  //       return o.key === agent
  //     })
  //   : data.hostList;
  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          title="Site Hosts"
          visible={isVisible}
          footer={null}
          initialWidth={1300}
          initialHeight={700}
          onCancel={handleCancel}
          className={styles.thirdTableModalWidth}
        >
          <div className={styles.critModalContent}>
            <Breadcrumb>
              <Breadcrumb.Item> Host List</Breadcrumb.Item>
            </Breadcrumb>
            {data?.isOverViewLoading ? (
              <Row justify="center" className={styles.loaderContent}>
                <Spin size="large" />
              </Row>
            ) : (
              <div>
                {data?.hostList?.length ? (
                  <table className={styles.surucataSecLevelTable}>
                    <thead>
                      <tr>
                        <th
                          className={styles.leftTr}
                          style={{ cursor: 'pointer' }}
                        >
                          {' '}
                          Agent Name
                        </th>
                        <th className={styles.rightTr}>Agent IP</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.hostList?.map((item, index) => (
                        <tr
                          key={index}
                          className={index % 2 ? '' : styles.suricataTable}
                        > 
                          <td
                            className={styles.leftTr}
                            style={{ cursor: 'pointer' }}
                          >
                            {item?.name ? item.name : '-'}
                          </td>

                          <td className={styles.rightTr}>
                            {item?.ip ? item?.ip : '-'}
                          </td>
                          <td>
                            <Button
                              onClick={() => {
                                showHostinfoModal(item?.id, item?.name)
                              }}
                            >
                              More Info
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Row justify="center">NO DATA FOUND</Row>
                )}
              </div>
            )}
          </div>
        </DraggableModal>
      </DraggableModalProvider>

      <SiteInfoModal
        isVisible={isInfoModal}
        modalTitle={infoTitle}
        setIsVisible={setIsInfoModal}
        isFirstModal={isVisible}
        setIsFirstModal={setIsVisible}
      />
    </>
  )
}

export default SiteHostModal
