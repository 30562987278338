import { useState } from "react";
import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import { useSelector, useDispatch } from "react-redux";
import { Row, Spin, Button, Col, Breadcrumb } from "antd";

import SignatureThirdModal from "./SignatureThirdModal";
import { allFilterData } from "../../../reducers/alerts";
import { getSuriCataThirdLvl } from "../../../Actions/alert";

import styles from "./index.module.scss";

function SignatureSecondModal({
  endDate,
  siteName,
  startDate,
  selectedAgent,
  modalTitle,
  isVisible,
  setIsVisible,
  isSignFirstModal,
  setIsSignFirstModal,
}) {
  const data = useSelector((state) => allFilterData(state));
  const dispatch = useDispatch();
  const [thirdModalTitle, setThirdModalTitle] = useState("");
  const [isThirdModal, setIsThirdModal] = useState(false);

  const handleCancel = () => {
    setIsVisible(!isVisible);
  };

  const handleBack = () => {
    setIsVisible(!isVisible);
    setIsSignFirstModal(!isSignFirstModal);
  };

  const handleModal = (label) => {
    setIsVisible(!isVisible);
    dispatch(
      getSuriCataThirdLvl(label, siteName, selectedAgent, startDate, endDate)
    );
    setThirdModalTitle(label);
    setIsThirdModal(!isThirdModal);
  };

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          title="Signature Second Level Alerts"
          visible={isVisible}
          className={styles.thirdTableModalWidth}
          footer={false}
          initialWidth={1300}
          initialHeight={700}
          onCancel={handleCancel}
        >
          <Breadcrumb>
            <Breadcrumb.Item onClick={handleBack}>
              Signature Alerts{" "}
            </Breadcrumb.Item>
            <Breadcrumb.Item>{modalTitle}</Breadcrumb.Item>
          </Breadcrumb>

          <div className={styles.critModalContent}>
            {data?.isAlertLoading ? (
              <Row justify="center" className={styles.loaderContent}>
                <Spin size="large" />
              </Row>
            ) : data?.suriSecond?.length ? (
              <div>
                <table className={styles.surucataSecLevelTable}>
                  <thead>
                    <tr>
                      <th
                        className={styles.leftTr}
                        style={{ cursor: "pointer" }}
                      >
                        Signature
                      </th>
                      <th className={styles.rightTr}>Source</th>
                      <th className={styles.rightTr}>Destination</th>
                      <th className={styles.leftTr}>Protocol</th>
                      <th className={styles.leftTr}>Action</th>
                      <th className={styles.rightTr}>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.suriSecond?.map((item, index) => (
                      <tr
                        key={index}
                        className={index % 2 ? "" : styles.suricataTable}
                      >
                        <td
                          className={styles.leftTr}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleModal(item.key)}
                        >
                          {item?.key ? item.key : "-"}
                        </td>
                        <td className={styles.rightTr}>
                          {item["2"]?.buckets[0]?.key
                            ? item["2"].buckets[0].key
                            : "-" +
                              item["2"]?.buckets[0]?.["4"]?.buckets[0]?.key
                            ? item["2"].buckets[0]?.["4"].buckets[0].key
                            : "-"}
                        </td>
                        <td className={styles.rightTr}>
                          {item["2"]?.buckets[0]?.["4"]?.buckets[0]?.["3"]
                            ?.buckets[0]?.key
                            ? item["2"].buckets[0]?.["4"].buckets[0]?.["3"]
                                .buckets[0].key
                            : "-" +
                              item["2"]?.buckets[0]?.["4"]?.buckets[0]?.["3"]
                                ?.buckets[0]?.["5"]?.buckets[0]?.key
                            ? item["2"].buckets[0]?.["4"].buckets[0]?.["3"]
                                .buckets[0]?.["5"].buckets[0].key
                            : "-"}
                        </td>
                        <td className={styles.leftTr}>
                          {item["2"]?.buckets[0]?.["4"]?.buckets[0]?.["3"]
                            ?.buckets[0]?.["5"].buckets[0]?.["6"].buckets[0].key
                            ? item["2"]?.buckets[0]?.["4"]?.buckets[0]?.["3"]
                                ?.buckets[0]?.["5"].buckets[0]?.["6"].buckets[0]
                                .key
                            : "-"}
                        </td>
                        <td className={styles.leftTr}>
                          {item["2"]?.buckets[0]?.["4"]?.buckets[0]?.["3"]
                            ?.buckets[0]?.["5"]?.buckets[0]?.["6"]
                            ?.buckets[0]?.["7"]?.buckets[0]?.key
                            ? item["2"]?.buckets[0]?.["4"]?.buckets[0]?.["3"]
                                ?.buckets[0]?.["5"].buckets[0]?.["6"]
                                .buckets[0]?.["7"].buckets[0].key
                            : "-"}
                        </td>
                        <td className={styles.rightTr}>
                          {item?.doc_count ? item.doc_count : "-"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Row className={styles.buttonRow} justify="center">
                  <Col xxl={3}>
                    <Button className={styles.backButton} onClick={handleBack}>
                      Back
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <Row justify="center">NO DATA FOUND</Row>
            )}
          </div>
        </DraggableModal>
      </DraggableModalProvider>
      <SignatureThirdModal
        modalTitle={thirdModalTitle}
        firstModalTitle={modalTitle}
        isVisible={isThirdModal}
        setIsVisible={setIsThirdModal}
        isSecModal={isVisible}
        setIsSecModal={setIsVisible}
        setIsSignFirstModal={setIsSignFirstModal}
      />
    </>
  );
}

export default SignatureSecondModal;
