/* eslint-disable no-unused-vars */
import { notification } from "antd";

import {
  GET_NTA_REPORT_FAIL,
  GET_NTA_REPORT_REQUEST,
  GET_NTA_REPORT_SUCCESS,
} from "./actionConstant";
import { GET_NTA_REPORT } from "../apiUrl";
import { commonBody } from "../common/constant";
import { getLoginUser } from "../common/localStorage";
import { functionalityApiCall } from "../common/connect";

const getNtaReportRequest = () => ({
  type: GET_NTA_REPORT_REQUEST,
});
const getNtaReportReportSuccess = (data) => ({
  type: GET_NTA_REPORT_SUCCESS,
  data,
});
const getNtaReportReportFail = () => ({
  type: GET_NTA_REPORT_FAIL,
});

export const getNtaReportApi =
  (site, agent, startDate, endDate, size) => (dispatch) => {
    dispatch(getNtaReportRequest());
    const token = getLoginUser();
    if (token) {
      const data = commonBody;
      data.namespace = site || "realstar";
      data.gte = startDate || data.gte;
      data.lte = endDate || data.lte;
      if (agent) data.agentfilter = agent;
      data.size = size || "1";
      const onSuccess = (response) => {
        dispatch(getNtaReportReportSuccess(response));
      };
      const url = GET_NTA_REPORT;

      const onFailure = (response) => {
        dispatch(getNtaReportReportFail(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
    }
  };
