import { Col, Row } from "antd";

import TopSrcip from "./topSrcip/TopSrcip";
import TopDestip from "./topDestip/TopDestip";
import SrcToDest from "./srcToDest/SrcToDest";
import DNSQueries from "./dnsQueries/DNSQueries";
import TopDnsQuery from "./topDnsQuery/TopDnsQuery";
import NetworkeVts from "./networkeVts/NetworkeVts";
import UniqSrcDest from "./uniqSrcDest/UniqSrcDest";
import TopQueryName from "./topQueryName/TopQueryName";

import styles from "./index.module.scss";

function Dashboard() {
  return (
    <div>
      <Row justify="center">
        <Col xs={23} md={23} lg={23} xl={23} xxl={23}>
          <Row justify="space-between" className={styles.row}>
            <Col xs={24} md={24} lg={7} xl={7} xxl={7}>
              <NetworkeVts title={"Networke VTS"} />
            </Col>
            <Col xs={24} md={24} lg={7} xl={7} xxl={7}>
              <DNSQueries title={"DNS Queries"} />
            </Col>
            <Col xs={24} md={24} lg={8} xl={8} xxl={8}>
              <UniqSrcDest title={"DNS Queries"} />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify="center">
        <Col xs={23} md={23} lg={23} xl={23} xxl={23}>
          <Row justify="space-between" className={styles.row}>
            <Col xs={24} md={24} lg={11} xl={11} xxl={11}>
              <TopSrcip title={"Top Srcip"} />
            </Col>
            <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
              <TopDestip title={"Top Destip"} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={23} md={23} lg={23} xl={23} xxl={23}>
          <Row justify="space-between" className={styles.row}>
            <Col xs={24} md={24} lg={11} xl={11} xxl={9}>
              <TopDnsQuery title={"Top Srcip"} />
            </Col>

            <Col xs={24} md={24} lg={12} xl={12} xxl={14}>
              <Row justify="space-between">
                <Col xs={24} md={24} lg={11} xl={11} xxl={11}>
                  <SrcToDest title={"DNS Queries"} />
                </Col>
                <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
                  <TopQueryName title={"DNS Queries"} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
