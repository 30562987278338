/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState } from "react";
import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Spin, Breadcrumb } from "antd";

import EventFourthModal from "./EventFourthModal";
import { allFilterData } from "../../../reducers/alerts";
import { getCriticalsFourthLvl } from "../../../Actions/alert";

import styles from "./index.module.scss";
import { InfoCircleOutlined } from "@ant-design/icons";

function EventThirdModal({
  endDate,
  siteName,
  startDate,
  isVisible,
  modalTitle,
  setIsVisible,
  eventFirstTitle,
  isEventSectModal,
  setIsEventSecModal,
  setIsEventFirstModal,
}) {
  const dispatch = useDispatch();

  const [thirdTitle, setThirdTitle] = useState("");
  const [timeStamp, setTimeStamp] = useState("");
  const [fourthModal, setFourthModal] = useState(false);

  const data = useSelector((state) => allFilterData(state));

  const handleBack = () => {
    setIsVisible(false);
    setIsEventSecModal(!isEventSectModal);
  };

  const handleCancel = () => {
    setIsVisible(!isVisible);
  };
  const handleFourthLvl = (id, timeStamp) => {
    setThirdTitle(id);
    setTimeStamp(timeStamp);
    dispatch(
      getCriticalsFourthLvl(
        id,
        timeStamp,
        modalTitle,
        siteName,
        startDate,
        endDate
      )
    );
    setIsVisible(!isVisible);
    setFourthModal(true);
  };
  const handleEventFirst = () => {
    setIsVisible(false);
    setIsEventFirstModal(true);
  };

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          title={"Event Security Alerts"}
          visible={isVisible}
          className={styles.thirdTableModalWidth}
          footer={false}
          initialWidth={1300}
          initialHeight={700}
          onCancel={handleCancel}
        >
          <Breadcrumb>
            <Breadcrumb.Item onClick={handleEventFirst}>
              Event Security Alerts
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={handleBack}>
              {eventFirstTitle}
            </Breadcrumb.Item>
            <Breadcrumb.Item>{modalTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.critModalContent}>
            {data?.isAlertLoading ? (
              <Row justify="center" className={styles.loaderContent}>
                <Spin size="large" />
              </Row>
            ) : (
              <div>
                {data?.eventThird?.length > 0 ? (
                  <div>
                    <table className={styles.xdrLvlSecondTable}>
                      <tbody className={styles.xdrLvlSecondTableBody}>
                        {data.eventThird?.map((item, index) => (
                          <p className={styles.fourthModalColor} key={index}>
                            <div>
                              <div>
                                <span>
                                  Timestamp : {item?._source?.timestamp}
                                </span>
                                <span>
                                  Agent Name :
                                  {item?._source?.agent?.name || "-"}
                                </span>
                                <span>
                                  Agent IP :{" "}
                                  {item?._source?.agent?.ip
                                    ? item?._source?.agent?.ip
                                    : "-"}
                                </span>
                                <span>
                                  Rule Description :{" "}
                                  {item?._source?.rule?.description
                                    ? item?._source?.rule?.description
                                    : "-"}
                                </span>
                              </div>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleFourthLvl(
                                    item?._id,
                                    item?._source?.timestamp
                                  )
                                }
                              >
                                <InfoCircleOutlined />
                              </div>
                            </div>
                            {item?._source?.data?.win?.system?.message
                              ?.split(/\n/)
                              ?.map((item1, index) => {
                                if (item1?.search("RuleName") === 0) {
                                  return;
                                }
                                return (
                                  <tr>
                                    <td>
                                      <span>
                                        {item1
                                          ? item1?.replaceAll(`"`, "")
                                          : "-"}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                          </p>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <Row justify="center"> NO DATA FOUND </Row>
                )}
                <Row className={styles.buttonRow} justify="center">
                  <Col xxl={3}>
                    <Button className={styles.backButton} onClick={handleBack}>
                      Back
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </DraggableModal>
      </DraggableModalProvider>
      <EventFourthModal
        id={thirdTitle}
        timeStamp={timeStamp}
        endDate={endDate}
        startDate={startDate}
        siteName={siteName}
        eventFirstTitle={eventFirstTitle}
        modalTitle={modalTitle}
        setFourthModal={setFourthModal}
        setIsVisible={setIsVisible}
        fourthModal={fourthModal}
        setIsEventFirstModal={setIsEventFirstModal}
        setIsEventSecModal={setIsEventSecModal}
      />
    </>
  );
}

export default EventThirdModal;
