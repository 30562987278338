import moment from 'moment'
import { useState } from 'react'
import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal'
import { Row, Breadcrumb, Spin } from 'antd'
import { useSelector, useDispatch } from 'react-redux'

import EDRSecModal from './EDRSecModal'
import { getEdrSecLvl } from '../../../Actions/alert'
import { allFilterData } from '../../../reducers/alerts'

import styles from './index.module.scss'

function EDRModal({
  isVisible,
  setIsVisible,
  selectedAgent,
  siteName,
  endDate,
  startDate,
}) {
  const data = useSelector((state) => allFilterData(state))
  const dispatch = useDispatch()

  const [isEdrSecModal, setIsEdrSecModal] = useState(false)
  const [edrSecTitle, setEdrSecTitle] = useState('')
  const [edrSiteHostTitle, setEdrSiteHostTitle] = useState('')

  const handleCancel = () => {
    setIsVisible(!isVisible)
  }

  const handleEdrSeModal = (label, hostName) => {
    dispatch(getEdrSecLvl(label, siteName, selectedAgent, startDate, endDate))
    setIsEdrSecModal(!isEdrSecModal)
    setIsVisible(!isVisible)
    setEdrSecTitle(label)
    setEdrSiteHostTitle(hostName)
  }

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          title="EDR First Level"
          visible={isVisible}
          className={styles.thirdTableModalWidth}
          onCancel={handleCancel}
         initialWidth={1300}
          initialHeight={700}
          footer={false}
        >
          <Breadcrumb>
            <Breadcrumb.Item> EDR First Level</Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.critModalContent}>
            {data?.isAlertLoading ? (
              <Row justify="center" className={styles.loaderContent}>
                <Spin size="large" />
              </Row>
            ) : (
              <div>
                {data?.xdrFirst?.length ? (
                  <div>
                    <table className={styles.surucataSecLevelTable}>
                      <thead>
                        <tr>
                          <th
                            className={styles.leftTr}
                            style={{ cursor: 'pointer' }}
                          >
                            Name{' '}
                          </th>
                          <th className={styles.rightTr}>Last Event</th>
                          <th className={styles.rightTr}>Source Host Name</th>
                          <th className={styles.rightTr}>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.xdrFirst?.map((item, index) => (
                          <tr
                            key={index}
                            className={index % 2 ? '' : styles.suricataTable}
                          >
                            <td
                              className={styles.leftTr}
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                handleEdrSeModal(
                                  item.key,
                                  item?.[4]?.buckets?.[0]?.key,
                                )
                              }
                            >
                              {item?.key ? item.key : '-'}
                            </td>
                            <td className={styles.rightTr}>
                              {item?.[4]?.buckets?.[0]?.[1]?.hits?.hits?.[0]?.[
                                '_source'
                              ]?.['@timestamp']
                                ? moment(
                                    item?.[4]?.buckets?.[0]?.[1]?.hits
                                      ?.hits?.[0]?.['_source']?.['@timestamp'],
                                  ).format('MM/DD/YYYY, h:mm:ss a')
                                : '-'}
                            </td>
                            <td className={styles.leftTr}>
                              {item?.[4]?.buckets?.[0]?.key
                                ? item?.[4]?.buckets?.[0]?.key
                                : '-'}
                            </td>
                            <td className={styles.rightTr}>
                              {item?.doc_count ? item?.doc_count : '-'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <Row justify="center">NO DATA FOUND</Row>
                )}
              </div>
            )}
          </div>
        </DraggableModal>
      </DraggableModalProvider>
      <EDRSecModal
        modalTitle={edrSecTitle}
        siteName={siteName}
        selectedAgent={selectedAgent}
        startDate={startDate}
        endDate={endDate}
        sourceHostTitle={edrSiteHostTitle}
        isVisible={isEdrSecModal}
        setIsVisible={setIsEdrSecModal}
        isEdrFirstModal={isVisible}
        setIsEdrFirstModal={setIsVisible}
      />
    </>
  )
}

export default EDRModal
