/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Card, Col, Row } from "antd";
import React, { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import DataTableExtensions from "react-data-table-component-extensions";

import { reqsAgent } from "../../../Actions/Pcidss";

import styles from "./index.module.scss";

function ReqsAgents(props) {
  const dispatch = useDispatch();
  const isProccess = useSelector((state) => state.pcidss?.isProcess);
  const [reqsAgents, setReqsAgents] = useState([]);
  const reqsAgentsData = useSelector(
    (state) => state.pcidss.reqsagent?.[2]?.buckets
  ); 
  const data = reqsAgents;

  useEffect(() => {
    let arr = [];
    if (reqsAgents?.length === 0) {
      for (let i = 0; i < reqsAgentsData?.length; i++) {
        let obj = {
          groupName: reqsAgentsData[i]?.[3]?.buckets[0]?.key,
          count: reqsAgentsData[i]?.[3]?.buckets[0]?.doc_count,
          agentName: reqsAgentsData[i]?.[3]?.sum_other_doc_count,
          date: reqsAgentsData[i]?.key,
        };
        arr.push(obj);
      }
      setReqsAgents(arr);
    }
  }, [reqsAgentsData]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(reqsAgent());
  };

  const columns = [
    {
      name: "Modal Name",

      selector: (row) => row.groupName,
      sortable: true,
    },
    {
      name: "Count",
      selector: (row) => row.count,
      sortable: true,
    },
    {
      name: "Doc Count",
      selector: (row) => row.agentName,
      sortable: true,
    },
    {
      name: "IP Address",
      selector: (row) => row.date,
      sortable: true,
    },
  ];
  const tableData = {
    columns,
    data,
  };

  return (
    <Row justify="center">
      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
        <Card className={styles.sellerCard}>
          <div className={styles.bestSeller}>
            <p>{props.title}</p>
          </div>

          <DataTableExtensions
      filterDigit={0}
            filterPlaceholder="Search"
            print={false}
            export={false}
            data={data}
            {...tableData}
          >
            {isProccess ? (
              <Row justify="center">
                <Col>
                  <ThreeDots color="#00000" height={50} width={50} />
                </Col>
              </Row>
            ) : (
              <DataTable highlightOnHover data={data} columns={columns} />
            )}
          </DataTableExtensions>
        </Card>
      </Col>
    </Row>
  );
}

export default ReqsAgents;
