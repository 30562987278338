/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Col, Row } from "antd";
import React, { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import DataTableExtensions from "react-data-table-component-extensions";

import { srcToDest } from "../../../Actions/network";

import styles from "./index.module.scss";

function SrcToDest() {
  const dispatch = useDispatch();

  const isProccess = useSelector((state) => state.network?.isProcess);
  const SrcToDestData = useSelector(
    (state) => state.network.srctodestData?.[2]?.buckets[0]?.[3]?.buckets
  );

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(srcToDest());
  };

  const columns = [
    {
      name: "Key",
      selector: (row) => (row.key ? row.key : "-"),
      sortable: true,
    },
    {
      name: "Doc Count",
      selector: (row) => (row.doc_count ? row.doc_count : "-"),
      sortable: true,
    },
  ];
  const tableData = {
    columns,
    SrcToDestData,
  };

  return (
    <Row justify="center">
      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
        <Card className={styles.sellerCard}>
          <div className={styles.bestSeller}>
            <p>Src To Dest</p>
          </div>

          <DataTableExtensions
      filterDigit={0}
            filterPlaceholder="Search"
            print={false}
            export={false}
            data={SrcToDestData}
            {...tableData}
          >
            {isProccess ? (
              <Row justify="center">
                <Col>
                  <ThreeDots color="#00000" height={50} width={50} />
                </Col>
              </Row>
            ) : (
              <DataTable
                highlightOnHover
                data={SrcToDestData}
                columns={columns}
              />
            )}
          </DataTableExtensions>
        </Card>
      </Col>
    </Row>
  );
}

export default SrcToDest;
