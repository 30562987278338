/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */

import moment from "moment";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";
import { Col, Dropdown, Layout, Menu, Row, Space } from "antd";
import {
  MenuOutlined,
  SnippetsOutlined,
  UserOutlined,
  CloudSyncOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";

import { logOut } from "../../Actions/user";
import demoUser from "../../Assets/png/download.png";
import companyLogo from "../../Assets/png/company.png";
import { getLoginUser, setLoginUser } from "../../common/localStorage";

import styles from "./index.module.scss";

const { Header, Content, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const SideBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [key, setKey] = useState("1");
  const [dashboardPage, setDashboardpage] = useState("overview");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const userToken = useSelector((state) => state.user.token) || getLoginUser();
  useEffect(() => {
    if (userToken) {
      let data = jwt_decode(userToken);
      if (moment() >= moment(data.exp * 1000)) {
        handleLogOut();
      }
    }
  }, []);
  window.addEventListener("resize", updateDimensions);
  useEffect(() => {
    return (
      () => window.removeEventListener("resize", updateDimensions),
      navigate("/")
    );
  }, []);

  const handleChange = (value) => {
    switch (value) {
      case "Dashboard":
        return setDashboardpage("Dashboard"), navigate("/dashboard");
      case "mitre":
        return setDashboardpage("mitre"), navigate("/MitreDashboard");
      case "vulnstats":
        return setDashboardpage("vulnstats"), navigate("/vulnStats");
      case "network":
        return setDashboardpage("network"), navigate("/network");
      case "pcidss":
        return setDashboardpage("pcidss"), navigate("/pcidss");
      case "hipaa":
        return setDashboardpage("hipaa"), navigate("/hippa");
      case "nta-report":
        return (
          setDashboardpage("nta-report"), setKey("2"), navigate("/nta-report")
        );
      case "clouds":
        return setDashboardpage("clouds"), setKey("3"), navigate("/clouds");
      // case 'graph':
      //   return setDashboardpage('graph'), setKey('5'), navigate('/graph')

      case "inventory":
        return (
          setDashboardpage("inventory"), setKey("4"), navigate("/inventory")
        );
      default:
        return setDashboardpage("overview"), setKey("1"), navigate("/");
    }
  };

  const sidebarItems = [
    getItem(
      <div onClick={() => handleChange("overview")}>Dashboard</div>,
      "1",
      <UserOutlined />
    ),
    getItem(<div>Network</div>, "2", <SnippetsOutlined />, [
      getItem(
        <div onClick={() => handleChange("nta-report")}>NTA-Report</div>,
        "2",
        <SnippetsOutlined />
      ),
      // getItem(
      //   <div onClick={() => handleChange('graph')}>Connection Graph</div>,
      //   '5',
      //   <SnippetsOutlined />,
      // ),
    ]),
    getItem(
      <div onClick={() => handleChange("clouds")}>Cloud</div>,
      "3",
      <CloudSyncOutlined />
    ),

    getItem(
      <div onClick={() => handleChange("inventory")}>Inventory</div>,
      "4",
      <ReconciliationOutlined />
    ),
  ];
  const handleLogOut = () => {
    dispatch(logOut());

    setLoginUser(null);
    navigate("/login");
  };
  const items = [
    {
      key: "0",
      label: <div onClick={handleLogOut}>Log Out</div>,
    },
  ];

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        trigger={null}
        collapsedWidth="0"
        collapsible
        collapsed={collapsed}
        className={styles.sidebarMain}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className={styles.logoName}>
          <img src={companyLogo} alt="" height="75px" width="150px" />
        </div>
        <Menu
          className={styles.menuItem}
          theme="dark"
          defaultSelectedKeys={[key]}
          mode="inline"
          items={sidebarItems}
        />
        {React.createElement(collapsed ? MenuOutlined : MenuOutlined, {
          onClick: () => setCollapsed(!collapsed),
        })}
      </Sider>
      <Layout className="site-layout">
        <Header
          className={styles.headerToagle}
          style={{
            padding: 0,
            backgroundColor: "#fff",
          }}
        >
          {width < 426 ? (
            <Row justify="end">
               
              {/* <Col xs={9} md={6} lg={4} xl={3} xxl={2}>
                <Select
                  className={styles.selectOption}
                  defaultValue={dashboardPage}
                  style={{
                    width: '90%',
                  }}
                >
                  <Option value="overview">Overview</Option>
                  <Option value="nta-report">NTA-Report</Option>
                  <Option value="clouds">Clouds</Option> 
                </Select>
              </Col> */}
              <Col xs={3} md={2} lg={2} xl={2} xxl={2}>
                <div className={styles.profileImgDiv}>
                  <Dropdown menu={{ items }} trigger={["click"]}>
                    <Space>
                      <img
                        className={styles.profileImg}
                        src={demoUser}
                        alt=""
                      />
                    </Space>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          ) : (
            <Row justify="end">
              {/* <Col xs={6} md={6} lg={4} xl={4} xxl={3}>
                <Select
                  className={styles.selectOption}
                  defaultValue={dashboardPage}
                  style={{
                    width: '90%',
                  }}
                  onChange={handleChange}
                >
                  <Option value="overview">Overview</Option>
                  <Option value="nta-report">NTA-Report</Option>
                  <Option value="clouds">Clouds</Option> 
                </Select>
              </Col> */}

              <Col xs={3} md={2} lg={2} xl={2} xxl={2}>
                <div className={styles.profileImgDiv}>
                  <Dropdown menu={{ items }} trigger={["click"]}>
                    <Space>
                      <img
                        className={styles.profileImg}
                        src={demoUser}
                        alt=""
                      />
                    </Space>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          )}
        </Header>
        <Content className={styles.contentMain}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default SideBar;
