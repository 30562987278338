/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SyncOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Row, Col, Select, notification, Spin } from "antd";

import { startDate, dropdownOverlay } from "../../common/constant";
import { agentList } from "../../Actions/overView";
import { allFilterData } from "../../reducers/alerts";
import NtaReportTable from "./ReportTable/NtaReportTable";
import { getNtaReportApi } from "../../Actions/ntaReport";
import { endDate, getSiteLabels } from "../../Actions/alert";
import {
  getAllSiteData,
  getFilterTableDataApi,
  setAllSite,
  setLoader,
} from "../../Actions/allSite";

import styles from "./index.module.scss";
import { Button } from "antd";

function NtaReport() {
  const dispatch = useDispatch();

  const [size, setSize] = useState(null);
  const [selectedSite, setSelectedSite] = useState("");
  const [siteName, setSiteName] = useState("");
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [reportTime, setReportTime] = useState();
  const dayDate = new Date();
  const [yesterDay, setYesterday] = useState(
    new Date(dayDate.getTime() - 1000 * 60 * 60)
  );
  const [today, setToday] = useState(new Date(dayDate.getTime()));
  const data = useSelector((state) => allFilterData(state));
  const siteList = useSelector((state) => state.allSite.filterTableData);
  const ntaReportData = useSelector(
    (state) => state.ntaReportDetails?.ntaReportData
  );
  const siteLabelsData = useSelector((state) => state.alerts.siteLabelsData);
  const isSiteLableLoading = useSelector((state) => state.alerts.isProcess);

  const isSiteLoading = useSelector((state) => state.allSite.isProcess);
  const isNtaReportLoading = useSelector(
    (state) => state.ntaReportDetails.isProcess
  );

  useEffect(() => {
    // dispatch(getFilterTableDataApi("", "", startDate, endDate));
    dispatch(getSiteLabels(startDate, endDate));
  }, []);

  useEffect(() => {
    setReportTime(ntaReportData?.[0]?.["fields"]?.["timestamp"]?.[0]);
  }, [ntaReportData]);

  const filterDropdown = (arr, label, value) => {
    const dropdownArray = [];
    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i];
      dropdownArray.push({
        value: obj[value],
        label: obj[label],
      });
    }
    return dropdownArray;
  };

  const handleClick = () => {
    if (!selectedSite) {
      notification.error({
        message: "Please Select Network",
      });
      return;
    }
    setSiteName(selectedSite);
    setYesterday(new Date(dayDate.getTime() - 1000 * 60 * 60));
    setToday(new Date(dayDate.getTime()));

    dispatch(
      getNtaReportApi(selectedSite, selectedAgent, yesterDay, today, size)
    );
  };

  const handlePrevious = () => {
    if (!selectedSite) {
      notification.error({
        message: "Please Select Network",
      });
      return;
    }

    const yesterdayDate = new Date(yesterDay.getTime() - 1000 * 60 * 60);
    setYesterday(yesterdayDate);
    const todayDate = new Date(today.getTime() - 1000 * 60 * 60);

    setToday(todayDate);

    dispatch(
      getNtaReportApi(
        selectedSite,
        selectedAgent,
        yesterdayDate,
        todayDate,
        size
      )
    );
  };

  const handleNext = () => {
    if (!selectedSite) {
      notification.error({
        message: "Please Select Network",
      });
      return;
    }

    const yesterdayDate = new Date(yesterDay.getTime() + 1000 * 60 * 60);
    setYesterday(yesterdayDate);
    const todayDate = new Date(today.getTime() + 1000 * 60 * 60);

    setToday(todayDate);
    dispatch(
      getNtaReportApi(selectedSite, selectedAgent, yesterDay, today, size)
    );
  };

  const handleSiteChange = (item) => {
    // dispatch(agentList(item, '', startDate, endDate))
    setSelectedSite(item);
    // setSelectedAgent(null)
  };

  const siteFilterDropdown = (arr) => {
    const dropdownArray = [];
    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i];
      dropdownArray.push({
        value: obj,
        label: obj,
      });
    }
    return dropdownArray;
  };

  const dropdownOverlay = (
    <Row justify="center">
      <Spin size="small" />
    </Row>
  );

  const modifiedSiteList = siteLabelsData?.map((value, index) => ({
    namespace: value,
  }));

  return (
    <div className={styles.ntaHeaderView}>
      <Row justify="center" gutter={20} className={styles.filterTableRow}>
        <Col span={3} className={styles.filterContent}>
          <Button onClick={handlePrevious} className={styles.applyBtn}>
            <LeftOutlined className={styles.rightOutLine} /> Previous
          </Button>
        </Col>

        <Col span={3} className={styles.filterContent}>
          <Button onClick={handleNext} className={styles.applyBtn}>
            Next
            <RightOutlined className={styles.rightOutLine} />
          </Button>
        </Col>

        <Col offset={1} span={5} className={styles.filterContent}>
          <div className={`${styles.filterType} ${styles.siteDropdown}`}>
            <b>Network:</b>
            <Select
              placeholder="Select Network"
              style={{ width: "60%" }}
              className={styles.selectDropdown}
              onSelect={(e) => handleSiteChange(e)}
              dropdownRender={(menu) =>
                isSiteLableLoading ? dropdownOverlay : menu
              }
              options={
                modifiedSiteList?.length
                  ? filterDropdown(modifiedSiteList, "namespace", "namespace")
                  : []
              }
            />
          </div>
        </Col>
        <Col xl={3} span={3} className={styles.filterContent}>
          <Button onClick={() => handleClick()} className={styles.applyBtn}>
            Apply
          </Button>
        </Col>
        <Col span={8} className={styles.filterContent}>
          <div className={`${styles.filterType} ${styles.siteDropdown}`}>
            <b>
              Report Timestamp:
              {isNtaReportLoading ? (
                <SyncOutlined spin className={styles.syncOutlineColor} />
              ) : reportTime ? (
                reportTime
              ) : (
                "-"
              )}
            </b>
          </div>
        </Col>

        <Col xs={22} md={22} lg={22} xl={22} xxl={23}>
          {isNtaReportLoading ? (
            <div className={styles.spinnerRow}>
              <Spin size="large" />
            </div>
          ) : (
            <>
              <NtaReportTable
                tableData={ntaReportData}
                selectedSite={siteName}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default NtaReport;
