import { notification } from "antd";

import {
  CRIT_ALERTS,
  TOP_ALERTS,
  HOTS_UNIQUE,
  VULAN_TOTAL,
  RECENT_EVENT,
  HOSTS_UNIQUE,
  AGENTS_LIST,
} from "../apiUrl";
import {
  TOP_ALERTS_FAIL,
  UNIQUE_IPS_FAIL,
  CRIT_ALERTS_FAIL,
  VULAN_TOTAL_FAIL,
  HOSTS_UNIQUE_FAIL,
  UNIQUE_HOSTS_FAIL,
  RECENT_EVENT_FAIL,
  TOP_ALERTS_REQUEST,
  TOP_ALERTS_SUCCESS,
  UNIQUE_IPS_REQUEST,
  UNIQUE_IPS_SUCCESS,
  AGENTS_LIST_SUCCESS,
  AGENTS_LIST_REQUEST,
  AGENTS_LIST_FAIL,
  HOSTS_INFO_REQUEST,
  HOSTS_INFO_SUCCESS,
  HOSTS_INFO_FAIL,
  CRIT_ALERTS_REQUEST,
  CRIT_ALERTS_SUCCESS,
  VULAN_TOTAL_REQUEST,
  VULAN_TOTAL_SUCCESS,
  HOSTS_UNIQUE_REQUEST,
  HOSTS_UNIQUE_SUCCESS,
  UNIQUE_HOSTS_SUCCESS,
  UNIQUE_HOSTS_REQUEST,
  RECENT_EVENT_REQUEST,
  RECENT_EVENT_SUCCESS,
} from "./actionConstant";
import { HOSTS_INFO } from "../apiUrl";
import { commonBody } from "../common/constant";
import { getLoginUser } from "../common/localStorage";
import { functionalityApiCall } from "../common/connect";

const topAlertsRequest = () => ({
  type: TOP_ALERTS_REQUEST,
});
const topAlertsSuccess = (data) => ({
  type: TOP_ALERTS_SUCCESS,
  data,
});
const topAlertsFail = () => ({
  type: TOP_ALERTS_FAIL,
});

const critAlertsRequest = () => ({
  type: CRIT_ALERTS_REQUEST,
});
const critAlertsSuccess = (data) => ({
  type: CRIT_ALERTS_SUCCESS,
  data,
});
const critAlertsFail = () => ({
  type: CRIT_ALERTS_FAIL,
});

const hostsUniqueRequest = () => ({
  type: HOSTS_UNIQUE_REQUEST,
});
const hostsUniqueSuccess = (data) => ({
  type: HOSTS_UNIQUE_SUCCESS,
  data,
});
const hostsUniqueFail = () => ({
  type: HOSTS_UNIQUE_FAIL,
});

const agentsListRequest = () => ({
  type: AGENTS_LIST_REQUEST,
});
const agentsListSuccess = (data) => ({
  type: AGENTS_LIST_SUCCESS,
  data,
});
const agentsListFail = () => ({
  type: AGENTS_LIST_FAIL,
});

const hostsInfoRequest = () => ({
  type: HOSTS_INFO_REQUEST,
});
const hostsInfoSuccess = (data) => ({
  type: HOSTS_INFO_SUCCESS,
  data,
});
const hostsInfoFail = () => ({
  type: HOSTS_INFO_FAIL,
});

const uniqueHostsRequest = () => ({
  type: UNIQUE_HOSTS_REQUEST,
});
const uniqueHostsSuccess = (data) => ({
  type: UNIQUE_HOSTS_SUCCESS,
  data,
});
const uniqueHostsFail = () => ({
  type: UNIQUE_HOSTS_FAIL,
});

const vulnTotalRequest = () => ({
  type: VULAN_TOTAL_REQUEST,
});
const vulnTotalSuccess = (data) => ({
  type: VULAN_TOTAL_SUCCESS,
  data,
});
const vulnTotalFail = () => ({
  type: VULAN_TOTAL_FAIL,
});

const recentEventRequest = () => ({
  type: RECENT_EVENT_REQUEST,
});
const recentEventSuccess = (data) => ({
  type: RECENT_EVENT_SUCCESS,
  data,
});
const recentEventFail = () => ({
  type: RECENT_EVENT_FAIL,
});

const uniqIpsRequest = () => ({
  type: UNIQUE_IPS_REQUEST,
});
const uniqIpsSuccess = (data) => ({
  type: UNIQUE_IPS_SUCCESS,
  data,
});
const uniqIpsFail = () => ({
  type: UNIQUE_IPS_FAIL,
});

const data = commonBody;

export const topAlerts = (requestData) => (dispatch) => {
  dispatch(topAlertsRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(topAlertsSuccess(response));
    };
    const url = TOP_ALERTS;

    const onFailure = (response) => {
      dispatch(topAlertsFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const critAlerts =
  (namespace, agentName, startDate, endDate) => (dispatch) => {
    dispatch(critAlertsRequest());
    const token = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(critAlertsSuccess(response));
      };
      const url = CRIT_ALERTS;

      const onFailure = (response) => {
        dispatch(critAlertsFail(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      body.namespace = namespace || "windows_testing";
      if (startDate && endDate) {
        body.agentfilter = agentName || "";
        body.gte = startDate;
        body.lte = endDate;
        body.size = "5000";
      }
      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };
export const hostsUnique =
  (namespace, agentName, startDate, endDate) => (dispatch) => {
    dispatch(hostsUniqueRequest());
    const token = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(hostsUniqueSuccess(response));
      };
      const url = HOSTS_UNIQUE;

      const onFailure = (response) => {
        dispatch(hostsUniqueFail(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      body.namespace = namespace || "windows_testing";
      if (startDate && endDate) {
        body.agentfilter = agentName || "";
        body.gte = startDate;
        body.lte = endDate;
        body.size = "5000";
      }
      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const agentList =
  (namespace, agentName, startDate, endDate) => (dispatch) => {
    dispatch(agentsListRequest());
    const token = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(agentsListSuccess(response));
      };
      const url = AGENTS_LIST;

      const onFailure = (response) => {
        dispatch(agentsListFail(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = { ...data };
      body.namespace = namespace;
      if (agentName) {
        body.agentname = agentName;
      }
      if (startDate && endDate) {
        body.gte = startDate;
        body.lte = endDate;
      }
      body.size = "1";

      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const hostsInfo =
  (namespace, agentIp, startDate, endDate) => (dispatch) => {
    dispatch(hostsInfoRequest());
    const token = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(hostsInfoSuccess(response));
      };
      const url = HOSTS_INFO;

      const onFailure = (response) => {
        dispatch(hostsInfoFail(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = {};
      body.namespace = namespace;
      if (agentIp) {
        body.agentid = agentIp;
      } else {
        body.agentid = "";
      }
      if (startDate && endDate) {
        body.gte = startDate;
        body.lte = endDate;
      }
      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const uniqueHosts = () => (dispatch) => {
  dispatch(uniqueHostsRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(uniqueHostsSuccess(response));
    };

    const onFailure = (response) => {
      dispatch(uniqueHostsFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall(
      "POST",
      HOTS_UNIQUE,
      data,
      onSuccess,
      onFailure,
      token
    );
  }
};

export const vulnTotal = () => (dispatch) => {
  dispatch(vulnTotalRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(vulnTotalSuccess(response));
    };

    const onFailure = (response) => {
      dispatch(vulnTotalFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall(
      "POST",
      VULAN_TOTAL,
      data,
      onSuccess,
      onFailure,
      token
    );
  }
};

export const recentEvents = () => (dispatch) => {
  dispatch(recentEventRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(recentEventSuccess(response));
    };

    const onFailure = (response) => {
      dispatch(recentEventFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall(
      "POST",
      RECENT_EVENT,
      data,
      onSuccess,
      onFailure,
      token
    );
  }
};

export const uniqueIps = () => (dispatch) => {
  dispatch(uniqIpsRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(uniqIpsSuccess(response));
    };

    const onFailure = (response) => {
      dispatch(uniqIpsFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall(
      "POST",
      RECENT_EVENT,
      data,
      onSuccess,
      onFailure,
      token
    );
  }
};
