export const nodes = [
  {
    id: "143.110.222.40",
    cnt: 12,
    sessions: 237,
    inresult: 1,
    type: 3,
    "network.bytes": 1204932885,
    totDataBytes: 0,
    "network.packets": 1882297,
    node: ["rs-clirsecnta"],
    pos: 0,
  },
  {
    id: "192.168.10.26",
    cnt: 1,
    sessions: 33,
    inresult: 1,
    type: 2,
    "network.bytes": 262234009,
    totDataBytes: 0,
    "network.packets": 327642,
    node: ["rs-clirsecnta"],
    pos: 1,
  },
  {
    id: "172.224.91.9",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 5595501,
    totDataBytes: 5161767,
    "network.packets": 9429,
    node: ["rs-clirsecnta"],
    pos: 2,
  },
  {
    id: "192.168.10.111",
    cnt: 12,
    sessions: 27,
    inresult: 1,
    type: 3,
    "network.bytes": 10512740,
    totDataBytes: 5819027,
    "network.packets": 31180,
    node: ["rs-clirsecnta"],
    pos: 3,
  },
  {
    id: "104.18.24.185",
    cnt: 2,
    sessions: 2,
    inresult: 1,
    type: 3,
    "network.bytes": 38002,
    totDataBytes: 33034,
    "network.packets": 108,
    node: ["rs-clirsecnta"],
    pos: 4,
  },
  {
    id: "17.248.175.228",
    cnt: 1,
    sessions: 36,
    inresult: 1,
    type: 1,
    "network.bytes": 397002705,
    totDataBytes: 0,
    "network.packets": 355396,
    node: ["rs-clirsecnta"],
    pos: 5,
  },
  {
    id: "192.168.10.96",
    cnt: 1,
    sessions: 36,
    inresult: 1,
    type: 2,
    "network.bytes": 397002705,
    totDataBytes: 0,
    "network.packets": 355396,
    node: ["rs-clirsecnta"],
    pos: 6,
  },
  {
    id: "192.168.10.206",
    cnt: 2,
    sessions: 116,
    inresult: 1,
    type: 1,
    "network.bytes": 382189502,
    totDataBytes: 13863,
    "network.packets": 476007,
    node: ["rs-clirsecnta"],
    pos: 7,
  },
  {
    id: "52.115.167.175",
    cnt: 1,
    sessions: 10,
    inresult: 1,
    type: 1,
    "network.bytes": 98776751,
    totDataBytes: 94203569,
    "network.packets": 99417,
    node: ["rs-clirsecnta"],
    pos: 8,
  },
  {
    id: "192.168.19.47",
    cnt: 2,
    sessions: 17,
    inresult: 1,
    type: 3,
    "network.bytes": 101435884,
    totDataBytes: 94203569,
    "network.packets": 104825,
    node: ["rs-clirsecnta"],
    pos: 9,
  },
  {
    id: "40.99.227.114",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 8842,
    totDataBytes: 0,
    "network.packets": 18,
    node: ["rs-clirsecnta"],
    pos: 10,
  },
  {
    id: "192.168.11.34",
    cnt: 2,
    sessions: 8,
    inresult: 1,
    type: 3,
    "network.bytes": 2936135,
    totDataBytes: 0,
    "network.packets": 6355,
    node: ["rs-clirsecnta"],
    pos: 11,
  },
  {
    id: "192.168.11.26",
    cnt: 2,
    sessions: 181,
    inresult: 1,
    type: 1,
    "network.bytes": 650327725,
    totDataBytes: 0,
    "network.packets": 1795238,
    node: ["rs-clirsecnta"],
    pos: 12,
  },
  {
    id: "132.145.98.108",
    cnt: 1,
    sessions: 80,
    inresult: 1,
    type: 2,
    "network.bytes": 129788851,
    totDataBytes: 0,
    "network.packets": 792589,
    node: ["rs-clirsecnta"],
    pos: 13,
  },
  {
    id: "151.101.2.119",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 18904,
    totDataBytes: 0,
    "network.packets": 81,
    node: ["rs-clirsecnta"],
    pos: 14,
  },
  {
    id: "192.168.11.75",
    cnt: 4,
    sessions: 7,
    inresult: 1,
    type: 3,
    "network.bytes": 166794,
    totDataBytes: 16599,
    "network.packets": 425,
    node: ["rs-clirsecnta"],
    pos: 15,
  },
  {
    id: "192.168.11.66",
    cnt: 6,
    sessions: 19,
    inresult: 1,
    type: 3,
    "network.bytes": 15921616,
    totDataBytes: 0,
    "network.packets": 17851,
    node: ["rs-clirsecnta"],
    pos: 16,
  },
  {
    id: "44.196.121.142",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 471037,
    totDataBytes: 0,
    "network.packets": 1095,
    node: ["rs-clirsecnta"],
    pos: 17,
  },
  {
    id: "158.115.133.137",
    cnt: 2,
    sessions: 14,
    inresult: 1,
    type: 1,
    "network.bytes": 4888008,
    totDataBytes: 0,
    "network.packets": 25326,
    node: ["rs-clirsecnta"],
    pos: 18,
  },
  {
    id: "192.168.10.83",
    cnt: 5,
    sessions: 23,
    inresult: 1,
    type: 3,
    "network.bytes": 6091352,
    totDataBytes: 123008,
    "network.packets": 25891,
    node: ["rs-clirsecnta"],
    pos: 19,
  },
  {
    id: "63.140.38.229",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 627,
    totDataBytes: 0,
    "network.packets": 9,
    node: ["rs-clirsecnta"],
    pos: 20,
  },
  {
    id: "192.168.11.49",
    cnt: 10,
    sessions: 35,
    inresult: 1,
    type: 3,
    "network.bytes": 6069284,
    totDataBytes: 0,
    "network.packets": 8875,
    node: ["rs-clirsecnta"],
    pos: 21,
  },
  {
    id: "158.115.141.213",
    cnt: 3,
    sessions: 21,
    inresult: 1,
    type: 3,
    "network.bytes": 4232815,
    totDataBytes: 0,
    "network.packets": 21705,
    node: ["rs-clirsecnta"],
    pos: 22,
  },
  {
    id: "158.115.141.199",
    cnt: 3,
    sessions: 21,
    inresult: 1,
    type: 3,
    "network.bytes": 4204524,
    totDataBytes: 0,
    "network.packets": 21519,
    node: ["rs-clirsecnta"],
    pos: 23,
  },
  {
    id: "192.168.10.113",
    cnt: 3,
    sessions: 15,
    inresult: 1,
    type: 3,
    "network.bytes": 3762695,
    totDataBytes: 122880,
    "network.packets": 20446,
    node: ["rs-clirsecnta"],
    pos: 24,
  },
  {
    id: "192.168.19.89",
    cnt: 3,
    sessions: 21,
    inresult: 1,
    type: 1,
    "network.bytes": 27696143,
    totDataBytes: 0,
    "network.packets": 123649,
    node: ["rs-clirsecnta"],
    pos: 25,
  },
  {
    id: "208.80.52.98",
    cnt: 1,
    sessions: 13,
    inresult: 1,
    type: 2,
    "network.bytes": 27677204,
    totDataBytes: 0,
    "network.packets": 123383,
    node: ["rs-clirsecnta"],
    pos: 26,
  },
  {
    id: "23.73.194.119",
    cnt: 1,
    sessions: 4,
    inresult: 1,
    type: 2,
    "network.bytes": 12301658,
    totDataBytes: 0,
    "network.packets": 11744,
    node: ["rs-clirsecnta"],
    pos: 27,
  },
  {
    id: "192.168.100.46",
    cnt: 2,
    sessions: 8,
    inresult: 1,
    type: 3,
    "network.bytes": 10085284,
    totDataBytes: 35991,
    "network.packets": 40839,
    node: ["rs-clirsecnta"],
    pos: 28,
  },
  {
    id: "69.147.82.61",
    cnt: 1,
    sessions: 2,
    inresult: 1,
    type: 2,
    "network.bytes": 41181,
    totDataBytes: 35991,
    "network.packets": 74,
    node: ["rs-clirsecnta"],
    pos: 29,
  },
  {
    id: "158.115.133.143",
    cnt: 3,
    sessions: 21,
    inresult: 1,
    type: 3,
    "network.bytes": 7530420,
    totDataBytes: 0,
    "network.packets": 37465,
    node: ["rs-clirsecnta"],
    pos: 30,
  },
  {
    id: "192.168.200.82",
    cnt: 1,
    sessions: 3,
    inresult: 1,
    type: 1,
    "network.bytes": 5276471,
    totDataBytes: 0,
    "network.packets": 6940,
    node: ["rs-clirsecnta"],
    pos: 31,
  },
  {
    id: "10.10.20.16",
    cnt: 1,
    sessions: 3,
    inresult: 1,
    type: 2,
    "network.bytes": 5276471,
    totDataBytes: 0,
    "network.packets": 6940,
    node: ["rs-clirsecnta"],
    pos: 32,
  },
  {
    id: "18.204.85.51",
    cnt: 5,
    sessions: 7,
    inresult: 1,
    type: 3,
    "network.bytes": 407468,
    totDataBytes: 48099,
    "network.packets": 548,
    node: ["rs-clirsecnta"],
    pos: 33,
  },
  {
    id: "192.168.11.20",
    cnt: 2,
    sessions: 2,
    inresult: 1,
    type: 3,
    "network.bytes": 48660,
    totDataBytes: 0,
    "network.packets": 59,
    node: ["rs-clirsecnta"],
    pos: 34,
  },
  {
    id: "76.13.33.33",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 3912,
    totDataBytes: 0,
    "network.packets": 38,
    node: ["rs-clirsecnta"],
    pos: 35,
  },
  {
    id: "192.168.100.32",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 3912,
    totDataBytes: 0,
    "network.packets": 38,
    node: ["rs-clirsecnta"],
    pos: 36,
  },
  {
    id: "192.168.10.81",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 972816,
    totDataBytes: 610524,
    "network.packets": 9534,
    node: ["rs-clirsecnta"],
    pos: 37,
  },
  {
    id: "8.8.8.8",
    cnt: 23,
    sessions: 23,
    inresult: 1,
    type: 2,
    "network.bytes": 13068030,
    totDataBytes: 8201370,
    "network.packets": 128070,
    node: ["rs-clirsecnta"],
    pos: 38,
  },
  {
    id: "192.229.211.108",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 3200,
    totDataBytes: 1868,
    "network.packets": 19,
    node: ["rs-clirsecnta"],
    pos: 39,
  },
  {
    id: "172.217.13.131",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 13480,
    totDataBytes: 12284,
    "network.packets": 26,
    node: ["rs-clirsecnta"],
    pos: 40,
  },
  {
    id: "192.168.11.27",
    cnt: 1,
    sessions: 9,
    inresult: 1,
    type: 1,
    "network.bytes": 48876530,
    totDataBytes: 44955076,
    "network.packets": 85249,
    node: ["rs-clirsecnta"],
    pos: 41,
  },
  {
    id: "153.92.40.128",
    cnt: 1,
    sessions: 9,
    inresult: 1,
    type: 2,
    "network.bytes": 48876530,
    totDataBytes: 44955076,
    "network.packets": 85249,
    node: ["rs-clirsecnta"],
    pos: 42,
  },
  {
    id: "104.244.42.67",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 32181,
    totDataBytes: 0,
    "network.packets": 157,
    node: ["rs-clirsecnta"],
    pos: 43,
  },
  {
    id: "192.168.10.109",
    cnt: 3,
    sessions: 4,
    inresult: 1,
    type: 3,
    "network.bytes": 9129856,
    totDataBytes: 0,
    "network.packets": 14328,
    node: ["rs-clirsecnta"],
    pos: 44,
  },
  {
    id: "192.168.11.195",
    cnt: 3,
    sessions: 3,
    inresult: 1,
    type: 3,
    "network.bytes": 9651,
    totDataBytes: 0,
    "network.packets": 48,
    node: ["rs-clirsecnta"],
    pos: 45,
  },
  {
    id: "172.22.79.6",
    cnt: 2,
    sessions: 5,
    inresult: 1,
    type: 2,
    "network.bytes": 10066,
    totDataBytes: 8764,
    "network.packets": 21,
    node: ["rs-clirsecnta"],
    pos: 46,
  },
  {
    id: "172.22.79.3",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 350,
    totDataBytes: 0,
    "network.packets": 5,
    node: ["rs-clirsecnta"],
    pos: 47,
  },
  {
    id: "192.168.11.61",
    cnt: 2,
    sessions: 5,
    inresult: 1,
    type: 3,
    "network.bytes": 104437,
    totDataBytes: 4110,
    "network.packets": 525,
    node: ["rs-clirsecnta"],
    pos: 48,
  },
  {
    id: "142.251.41.74",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 8710,
    totDataBytes: 4110,
    "network.packets": 100,
    node: ["rs-clirsecnta"],
    pos: 49,
  },
  {
    id: "192.168.200.87",
    cnt: 1,
    sessions: 2,
    inresult: 1,
    type: 1,
    "network.bytes": 602,
    totDataBytes: 418,
    "network.packets": 4,
    node: ["rs-clirsecnta"],
    pos: 50,
  },
  {
    id: "192.168.10.205",
    cnt: 6,
    sessions: 10,
    inresult: 1,
    type: 3,
    "network.bytes": 29062,
    totDataBytes: 9840,
    "network.packets": 140,
    node: ["rs-clirsecnta"],
    pos: 51,
  },
  {
    id: "8.8.4.4",
    cnt: 1,
    sessions: 68,
    inresult: 1,
    type: 2,
    "network.bytes": 20763,
    totDataBytes: 13863,
    "network.packets": 150,
    node: ["rs-clirsecnta"],
    pos: 52,
  },
  {
    id: "192.168.10.101",
    cnt: 3,
    sessions: 15,
    inresult: 1,
    type: 3,
    "network.bytes": 3953107,
    totDataBytes: 123008,
    "network.packets": 21577,
    node: ["rs-clirsecnta"],
    pos: 53,
  },
  {
    id: "208.111.182.29",
    cnt: 1,
    sessions: 9,
    inresult: 1,
    type: 1,
    "network.bytes": 72177913,
    totDataBytes: 0,
    "network.packets": 68948,
    node: ["rs-clirsecnta"],
    pos: 54,
  },
  {
    id: "192.168.10.97",
    cnt: 4,
    sessions: 24,
    inresult: 1,
    type: 3,
    "network.bytes": 76984549,
    totDataBytes: 0,
    "network.packets": 90665,
    node: ["rs-clirsecnta"],
    pos: 55,
  },
  {
    id: "192.168.200.79",
    cnt: 1,
    sessions: 13,
    inresult: 1,
    type: 1,
    "network.bytes": 77616353,
    totDataBytes: 0,
    "network.packets": 92903,
    node: ["rs-clirsecnta"],
    pos: 56,
  },
  {
    id: "10.10.20.12",
    cnt: 1,
    sessions: 13,
    inresult: 1,
    type: 2,
    "network.bytes": 77616353,
    totDataBytes: 0,
    "network.packets": 92903,
    node: ["rs-clirsecnta"],
    pos: 57,
  },
  {
    id: "192.168.11.42",
    cnt: 2,
    sessions: 9,
    inresult: 1,
    type: 3,
    "network.bytes": 224923,
    totDataBytes: 0,
    "network.packets": 829,
    node: ["rs-clirsecnta"],
    pos: 58,
  },
  {
    id: "52.250.225.32",
    cnt: 1,
    sessions: 7,
    inresult: 1,
    type: 2,
    "network.bytes": 37027,
    totDataBytes: 0,
    "network.packets": 463,
    node: ["rs-clirsecnta"],
    pos: 59,
  },
  {
    id: "52.96.88.210",
    cnt: 3,
    sessions: 10,
    inresult: 1,
    type: 3,
    "network.bytes": 16652414,
    totDataBytes: 0,
    "network.packets": 15081,
    node: ["rs-clirsecnta"],
    pos: 60,
  },
  {
    id: "192.168.11.44",
    cnt: 4,
    sessions: 14,
    inresult: 1,
    type: 3,
    "network.bytes": 32705349,
    totDataBytes: 0,
    "network.packets": 31015,
    node: ["rs-clirsecnta"],
    pos: 61,
  },
  {
    id: "52.114.159.99",
    cnt: 1,
    sessions: 2,
    inresult: 1,
    type: 1,
    "network.bytes": 62591,
    totDataBytes: 0,
    "network.packets": 190,
    node: ["rs-clirsecnta"],
    pos: 62,
  },
  {
    id: "192.168.11.139",
    cnt: 3,
    sessions: 5,
    inresult: 1,
    type: 2,
    "network.bytes": 262247,
    totDataBytes: 0,
    "network.packets": 679,
    node: ["rs-clirsecnta"],
    pos: 63,
  },
  {
    id: "35.190.10.96",
    cnt: 1,
    sessions: 2,
    inresult: 1,
    type: 2,
    "network.bytes": 19599,
    totDataBytes: 16599,
    "network.packets": 54,
    node: ["rs-clirsecnta"],
    pos: 64,
  },
  {
    id: "192.168.10.92",
    cnt: 2,
    sessions: 14,
    inresult: 1,
    type: 3,
    "network.bytes": 4255851,
    totDataBytes: 0,
    "network.packets": 20481,
    node: ["rs-clirsecnta"],
    pos: 65,
  },
  {
    id: "158.115.141.208",
    cnt: 2,
    sessions: 14,
    inresult: 1,
    type: 2,
    "network.bytes": 3090188,
    totDataBytes: 0,
    "network.packets": 14884,
    node: ["rs-clirsecnta"],
    pos: 66,
  },
  {
    id: "192.168.19.45",
    cnt: 5,
    sessions: 7,
    inresult: 1,
    type: 3,
    "network.bytes": 9377,
    totDataBytes: 4532,
    "network.packets": 95,
    node: ["rs-clirsecnta"],
    pos: 67,
  },
  {
    id: "162.220.222.173",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 140,
    totDataBytes: 0,
    "network.packets": 2,
    node: ["rs-clirsecnta"],
    pos: 68,
  },
  {
    id: "192.168.10.25",
    cnt: 3,
    sessions: 9,
    inresult: 1,
    type: 3,
    "network.bytes": 153112,
    totDataBytes: 48099,
    "network.packets": 1273,
    node: ["rs-clirsecnta"],
    pos: 69,
  },
  {
    id: "192.168.19.84",
    cnt: 2,
    sessions: 2,
    inresult: 1,
    type: 1,
    "network.bytes": 2612,
    totDataBytes: 0,
    "network.packets": 33,
    node: ["rs-clirsecnta"],
    pos: 70,
  },
  {
    id: "52.111.229.3",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 628,
    totDataBytes: 0,
    "network.packets": 9,
    node: ["rs-clirsecnta"],
    pos: 71,
  },
  {
    id: "69.73.181.124",
    cnt: 1,
    sessions: 3,
    inresult: 1,
    type: 1,
    "network.bytes": 576,
    totDataBytes: 0,
    "network.packets": 9,
    node: ["rs-clirsecnta"],
    pos: 72,
  },
  {
    id: "52.96.215.50",
    cnt: 3,
    sessions: 10,
    inresult: 1,
    type: 1,
    "network.bytes": 1044019,
    totDataBytes: 0,
    "network.packets": 1860,
    node: ["rs-clirsecnta"],
    pos: 73,
  },
  {
    id: "34.127.117.150",
    cnt: 1,
    sessions: 2,
    inresult: 1,
    type: 1,
    "network.bytes": 4033125,
    totDataBytes: 0,
    "network.packets": 10329,
    node: ["rs-clirsecnta"],
    pos: 74,
  },
  {
    id: "40.99.226.162",
    cnt: 2,
    sessions: 5,
    inresult: 1,
    type: 3,
    "network.bytes": 238098,
    totDataBytes: 0,
    "network.packets": 570,
    node: ["rs-clirsecnta"],
    pos: 75,
  },
  {
    id: "192.168.100.97",
    cnt: 3,
    sessions: 7,
    inresult: 1,
    type: 3,
    "network.bytes": 142071,
    totDataBytes: 0,
    "network.packets": 773,
    node: ["rs-clirsecnta"],
    pos: 76,
  },
  {
    id: "40.99.226.226",
    cnt: 3,
    sessions: 5,
    inresult: 1,
    type: 1,
    "network.bytes": 1022234,
    totDataBytes: 0,
    "network.packets": 1719,
    node: ["rs-clirsecnta"],
    pos: 77,
  },
  {
    id: "13.33.165.50",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 71453,
    totDataBytes: 0,
    "network.packets": 64,
    node: ["rs-clirsecnta"],
    pos: 78,
  },
  {
    id: "192.168.10.105",
    cnt: 3,
    sessions: 4,
    inresult: 1,
    type: 3,
    "network.bytes": 10741,
    totDataBytes: 0,
    "network.packets": 56,
    node: ["rs-clirsecnta"],
    pos: 79,
  },
  {
    id: "13.248.212.111",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 5320,
    totDataBytes: 0,
    "network.packets": 20,
    node: ["rs-clirsecnta"],
    pos: 80,
  },
  {
    id: "40.99.227.34",
    cnt: 3,
    sessions: 16,
    inresult: 1,
    type: 3,
    "network.bytes": 19022768,
    totDataBytes: 0,
    "network.packets": 19112,
    node: ["rs-clirsecnta"],
    pos: 81,
  },
  {
    id: "192.168.11.111",
    cnt: 5,
    sessions: 19,
    inresult: 1,
    type: 3,
    "network.bytes": 1572828,
    totDataBytes: 0,
    "network.packets": 3366,
    node: ["rs-clirsecnta"],
    pos: 82,
  },
  {
    id: "192.168.10.90",
    cnt: 2,
    sessions: 8,
    inresult: 1,
    type: 3,
    "network.bytes": 2753198,
    totDataBytes: 122880,
    "network.packets": 14572,
    node: ["rs-clirsecnta"],
    pos: 83,
  },
  {
    id: "192.168.200.91",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 17810,
    totDataBytes: 0,
    "network.packets": 114,
    node: ["rs-clirsecnta"],
    pos: 84,
  },
  {
    id: "192.168.200.48",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 421,
    totDataBytes: 329,
    "network.packets": 2,
    node: ["rs-clirsecnta"],
    pos: 85,
  },
  {
    id: "192.168.10.80",
    cnt: 3,
    sessions: 3,
    inresult: 1,
    type: 1,
    "network.bytes": 1871503,
    totDataBytes: 1354235,
    "network.packets": 12902,
    node: ["rs-clirsecnta"],
    pos: 86,
  },
  {
    id: "192.168.19.62",
    cnt: 2,
    sessions: 5,
    inresult: 1,
    type: 2,
    "network.bytes": 323649,
    totDataBytes: 0,
    "network.packets": 568,
    node: ["rs-clirsecnta"],
    pos: 87,
  },
  {
    id: "192.168.10.18",
    cnt: 3,
    sessions: 14,
    inresult: 1,
    type: 1,
    "network.bytes": 6783835,
    totDataBytes: 0,
    "network.packets": 17818,
    node: ["rs-clirsecnta"],
    pos: 88,
  },
  {
    id: "34.149.20.200",
    cnt: 1,
    sessions: 4,
    inresult: 1,
    type: 2,
    "network.bytes": 2652598,
    totDataBytes: 0,
    "network.packets": 4643,
    node: ["rs-clirsecnta"],
    pos: 89,
  },
  {
    id: "192.168.10.73",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 973611,
    totDataBytes: 611053,
    "network.packets": 9541,
    node: ["rs-clirsecnta"],
    pos: 90,
  },
  {
    id: "192.168.200.69",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 124,
    totDataBytes: 78,
    "network.packets": 1,
    node: ["rs-clirsecnta"],
    pos: 91,
  },
  {
    id: "192.168.20.18",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 124,
    totDataBytes: 78,
    "network.packets": 1,
    node: ["rs-clirsecnta"],
    pos: 92,
  },
  {
    id: "192.168.10.77",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 973346,
    totDataBytes: 610864,
    "network.packets": 9539,
    node: ["rs-clirsecnta"],
    pos: 93,
  },
  {
    id: "192.168.11.18",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 46212,
    totDataBytes: 0,
    "network.packets": 67,
    node: ["rs-clirsecnta"],
    pos: 94,
  },
  {
    id: "3.216.168.58",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 46212,
    totDataBytes: 0,
    "network.packets": 67,
    node: ["rs-clirsecnta"],
    pos: 95,
  },
  {
    id: "192.168.200.94",
    cnt: 2,
    sessions: 2,
    inresult: 1,
    type: 1,
    "network.bytes": 7056,
    totDataBytes: 226,
    "network.packets": 46,
    node: ["rs-clirsecnta"],
    pos: 96,
  },
  {
    id: "192.168.254.102",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 975818,
    totDataBytes: 612462,
    "network.packets": 9562,
    node: ["rs-clirsecnta"],
    pos: 97,
  },
  {
    id: "158.115.133.174",
    cnt: 2,
    sessions: 14,
    inresult: 1,
    type: 1,
    "network.bytes": 4795714,
    totDataBytes: 0,
    "network.packets": 25047,
    node: ["rs-clirsecnta"],
    pos: 98,
  },
  {
    id: "52.96.215.82",
    cnt: 1,
    sessions: 4,
    inresult: 1,
    type: 1,
    "network.bytes": 320694,
    totDataBytes: 0,
    "network.packets": 693,
    node: ["rs-clirsecnta"],
    pos: 99,
  },
  {
    id: "192.168.11.205",
    cnt: 4,
    sessions: 19,
    inresult: 1,
    type: 2,
    "network.bytes": 3261431,
    totDataBytes: 0,
    "network.packets": 4548,
    node: ["rs-clirsecnta"],
    pos: 100,
  },
  {
    id: "158.115.141.248",
    cnt: 1,
    sessions: 7,
    inresult: 1,
    type: 2,
    "network.bytes": 1359707,
    totDataBytes: 0,
    "network.packets": 7153,
    node: ["rs-clirsecnta"],
    pos: 101,
  },
  {
    id: "192.168.20.23",
    cnt: 2,
    sessions: 8,
    inresult: 1,
    type: 1,
    "network.bytes": 12510063,
    totDataBytes: 1731572,
    "network.packets": 23101,
    node: ["rs-clirsecnta"],
    pos: 102,
  },
  {
    id: "142.251.33.174",
    cnt: 2,
    sessions: 2,
    inresult: 1,
    type: 3,
    "network.bytes": 2504288,
    totDataBytes: 2159794,
    "network.packets": 7489,
    node: ["rs-clirsecnta"],
    pos: 103,
  },
  {
    id: "192.168.254.101",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 975899,
    totDataBytes: 612581,
    "network.packets": 9561,
    node: ["rs-clirsecnta"],
    pos: 104,
  },
  {
    id: "158.115.133.186",
    cnt: 3,
    sessions: 21,
    inresult: 1,
    type: 1,
    "network.bytes": 7779638,
    totDataBytes: 0,
    "network.packets": 38689,
    node: ["rs-clirsecnta"],
    pos: 105,
  },
  {
    id: "192.168.10.104",
    cnt: 3,
    sessions: 15,
    inresult: 1,
    type: 3,
    "network.bytes": 4453233,
    totDataBytes: 122880,
    "network.packets": 22026,
    node: ["rs-clirsecnta"],
    pos: 106,
  },
  {
    id: "158.115.141.243",
    cnt: 2,
    sessions: 14,
    inresult: 1,
    type: 2,
    "network.bytes": 3329715,
    totDataBytes: 0,
    "network.packets": 15123,
    node: ["rs-clirsecnta"],
    pos: 107,
  },
  {
    id: "172.66.41.9",
    cnt: 1,
    sessions: 3,
    inresult: 1,
    type: 2,
    "network.bytes": 453091,
    totDataBytes: 421505,
    "network.packets": 439,
    node: ["rs-clirsecnta"],
    pos: 108,
  },
  {
    id: "17.253.25.205",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 2497,
    totDataBytes: 0,
    "network.packets": 22,
    node: ["rs-clirsecnta"],
    pos: 109,
  },
  {
    id: "52.96.215.18",
    cnt: 2,
    sessions: 3,
    inresult: 1,
    type: 1,
    "network.bytes": 247417,
    totDataBytes: 0,
    "network.packets": 440,
    node: ["rs-clirsecnta"],
    pos: 110,
  },
  {
    id: "192.168.19.80",
    cnt: 3,
    sessions: 10,
    inresult: 1,
    type: 3,
    "network.bytes": 140171,
    totDataBytes: 0,
    "network.packets": 595,
    node: ["rs-clirsecnta"],
    pos: 111,
  },
  {
    id: "192.168.10.114",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 195942,
    totDataBytes: 122944,
    "network.packets": 1921,
    node: ["rs-clirsecnta"],
    pos: 112,
  },
  {
    id: "72.142.71.74",
    cnt: 2,
    sessions: 13,
    inresult: 1,
    type: 1,
    "network.bytes": 11323003,
    totDataBytes: 0,
    "network.packets": 54985,
    node: ["rs-clirsecnta"],
    pos: 113,
  },
  {
    id: "192.168.11.38",
    cnt: 5,
    sessions: 8,
    inresult: 1,
    type: 1,
    "network.bytes": 5581190,
    totDataBytes: 0,
    "network.packets": 9497,
    node: ["rs-clirsecnta"],
    pos: 114,
  },
  {
    id: "13.33.165.101",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 594,
    totDataBytes: 0,
    "network.packets": 9,
    node: ["rs-clirsecnta"],
    pos: 115,
  },
  {
    id: "213.227.173.132",
    cnt: 1,
    sessions: 7,
    inresult: 1,
    type: 1,
    "network.bytes": 1287677,
    totDataBytes: 0,
    "network.packets": 2643,
    node: ["rs-clirsecnta"],
    pos: 116,
  },
  {
    id: "3.218.191.213",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 158050,
    totDataBytes: 0,
    "network.packets": 812,
    node: ["rs-clirsecnta"],
    pos: 117,
  },
  {
    id: "192.168.11.76",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 158050,
    totDataBytes: 0,
    "network.packets": 812,
    node: ["rs-clirsecnta"],
    pos: 118,
  },
  {
    id: "40.99.227.50",
    cnt: 1,
    sessions: 2,
    inresult: 1,
    type: 1,
    "network.bytes": 187896,
    totDataBytes: 0,
    "network.packets": 366,
    node: ["rs-clirsecnta"],
    pos: 119,
  },
  {
    id: "192.168.11.65",
    cnt: 2,
    sessions: 8,
    inresult: 1,
    type: 2,
    "network.bytes": 1279348,
    totDataBytes: 0,
    "network.packets": 14227,
    node: ["rs-clirsecnta"],
    pos: 120,
  },
  {
    id: "192.168.0.30",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 3968,
    totDataBytes: 2266,
    "network.packets": 37,
    node: ["rs-clirsecnta"],
    pos: 121,
  },
  {
    id: "192.168.2.25",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 3968,
    totDataBytes: 2266,
    "network.packets": 37,
    node: ["rs-clirsecnta"],
    pos: 122,
  },
  {
    id: "52.113.207.2",
    cnt: 1,
    sessions: 6,
    inresult: 1,
    type: 2,
    "network.bytes": 11122,
    totDataBytes: 0,
    "network.packets": 166,
    node: ["rs-clirsecnta"],
    pos: 123,
  },
  {
    id: "192.168.19.33",
    cnt: 1,
    sessions: 2,
    inresult: 1,
    type: 1,
    "network.bytes": 153703,
    totDataBytes: 0,
    "network.packets": 300,
    node: ["rs-clirsecnta"],
    pos: 124,
  },
  {
    id: "40.99.226.194",
    cnt: 2,
    sessions: 9,
    inresult: 1,
    type: 3,
    "network.bytes": 3843442,
    totDataBytes: 0,
    "network.packets": 4185,
    node: ["rs-clirsecnta"],
    pos: 125,
  },
  {
    id: "192.168.10.76",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 973442,
    totDataBytes: 610960,
    "network.packets": 9539,
    node: ["rs-clirsecnta"],
    pos: 126,
  },
  {
    id: "10.10.20.15",
    cnt: 2,
    sessions: 2,
    inresult: 1,
    type: 2,
    "network.bytes": 7179,
    totDataBytes: 349,
    "network.packets": 46,
    node: ["rs-clirsecnta"],
    pos: 127,
  },
  {
    id: "35.201.70.27",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 594,
    totDataBytes: 0,
    "network.packets": 9,
    node: ["rs-clirsecnta"],
    pos: 128,
  },
  {
    id: "192.168.10.102",
    cnt: 1,
    sessions: 7,
    inresult: 1,
    type: 2,
    "network.bytes": 2579786,
    totDataBytes: 0,
    "network.packets": 13140,
    node: ["rs-clirsecnta"],
    pos: 129,
  },
  {
    id: "172.66.42.247",
    cnt: 1,
    sessions: 2,
    inresult: 1,
    type: 2,
    "network.bytes": 88502,
    totDataBytes: 55396,
    "network.packets": 474,
    node: ["rs-clirsecnta"],
    pos: 130,
  },
  {
    id: "192.168.100.74",
    cnt: 1,
    sessions: 7,
    inresult: 1,
    type: 1,
    "network.bytes": 2460011,
    totDataBytes: 0,
    "network.packets": 5476,
    node: ["rs-clirsecnta"],
    pos: 131,
  },
  {
    id: "99.86.63.24",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 594,
    totDataBytes: 0,
    "network.packets": 9,
    node: ["rs-clirsecnta"],
    pos: 132,
  },
  {
    id: "192.168.10.86",
    cnt: 2,
    sessions: 14,
    inresult: 1,
    type: 3,
    "network.bytes": 3957598,
    totDataBytes: 0,
    "network.packets": 20395,
    node: ["rs-clirsecnta"],
    pos: 133,
  },
  {
    id: "52.96.230.226",
    cnt: 2,
    sessions: 2,
    inresult: 1,
    type: 1,
    "network.bytes": 200668,
    totDataBytes: 129361,
    "network.packets": 1379,
    node: ["rs-clirsecnta"],
    pos: 134,
  },
  {
    id: "192.168.19.54",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 189759,
    totDataBytes: 129361,
    "network.packets": 1313,
    node: ["rs-clirsecnta"],
    pos: 135,
  },
  {
    id: "192.168.200.88",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 195,
    totDataBytes: 103,
    "network.packets": 2,
    node: ["rs-clirsecnta"],
    pos: 136,
  },
  {
    id: "104.17.148.64",
    cnt: 1,
    sessions: 3,
    inresult: 1,
    type: 2,
    "network.bytes": 102006,
    totDataBytes: 0,
    "network.packets": 558,
    node: ["rs-clirsecnta"],
    pos: 137,
  },
  {
    id: "192.168.11.41",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 48602,
    totDataBytes: 44828,
    "network.packets": 63,
    node: ["rs-clirsecnta"],
    pos: 138,
  },
  {
    id: "40.99.226.242",
    cnt: 2,
    sessions: 2,
    inresult: 1,
    type: 3,
    "network.bytes": 55066,
    totDataBytes: 44828,
    "network.packets": 141,
    node: ["rs-clirsecnta"],
    pos: 139,
  },
  {
    id: "52.96.50.130",
    cnt: 1,
    sessions: 4,
    inresult: 1,
    type: 1,
    "network.bytes": 95727,
    totDataBytes: 0,
    "network.packets": 425,
    node: ["rs-clirsecnta"],
    pos: 140,
  },
  {
    id: "192.168.10.75",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 973034,
    totDataBytes: 610742,
    "network.packets": 9534,
    node: ["rs-clirsecnta"],
    pos: 141,
  },
  {
    id: "158.115.133.182",
    cnt: 2,
    sessions: 14,
    inresult: 1,
    type: 1,
    "network.bytes": 5079045,
    totDataBytes: 0,
    "network.packets": 25729,
    node: ["rs-clirsecnta"],
    pos: 142,
  },
  {
    id: "192.168.10.103",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 195840,
    totDataBytes: 122880,
    "network.packets": 1920,
    node: ["rs-clirsecnta"],
    pos: 143,
  },
  {
    id: "172.67.75.28",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 594,
    totDataBytes: 0,
    "network.packets": 9,
    node: ["rs-clirsecnta"],
    pos: 144,
  },
  {
    id: "131.253.33.200",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 332,
    totDataBytes: 0,
    "network.packets": 5,
    node: ["rs-clirsecnta"],
    pos: 145,
  },
  {
    id: "192.168.19.35",
    cnt: 4,
    sessions: 4,
    inresult: 1,
    type: 3,
    "network.bytes": 702487,
    totDataBytes: 534649,
    "network.packets": 3628,
    node: ["rs-clirsecnta"],
    pos: 146,
  },
  {
    id: "52.96.215.34",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 3164,
    totDataBytes: 0,
    "network.packets": 38,
    node: ["rs-clirsecnta"],
    pos: 147,
  },
  {
    id: "192.168.11.129",
    cnt: 2,
    sessions: 2,
    inresult: 1,
    type: 2,
    "network.bytes": 3420,
    totDataBytes: 0,
    "network.packets": 42,
    node: ["rs-clirsecnta"],
    pos: 148,
  },
  {
    id: "192.168.19.24",
    cnt: 2,
    sessions: 3,
    inresult: 1,
    type: 3,
    "network.bytes": 209666,
    totDataBytes: 0,
    "network.packets": 391,
    node: ["rs-clirsecnta"],
    pos: 149,
  },
  {
    id: "54.158.238.233",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 2716,
    totDataBytes: 0,
    "network.packets": 21,
    node: ["rs-clirsecnta"],
    pos: 150,
  },
  {
    id: "192.168.10.74",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 972788,
    totDataBytes: 610496,
    "network.packets": 9534,
    node: ["rs-clirsecnta"],
    pos: 151,
  },
  {
    id: "192.168.10.93",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 195840,
    totDataBytes: 122880,
    "network.packets": 1920,
    node: ["rs-clirsecnta"],
    pos: 152,
  },
  {
    id: "198.135.184.1",
    cnt: 1,
    sessions: 6,
    inresult: 1,
    type: 2,
    "network.bytes": 975334,
    totDataBytes: 0,
    "network.packets": 3847,
    node: ["rs-clirsecnta"],
    pos: 153,
  },
  {
    id: "3.211.129.248",
    cnt: 1,
    sessions: 2,
    inresult: 1,
    type: 1,
    "network.bytes": 2924,
    totDataBytes: 0,
    "network.packets": 14,
    node: ["rs-clirsecnta"],
    pos: 154,
  },
  {
    id: "51.222.39.187",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 13406,
    totDataBytes: 9852,
    "network.packets": 51,
    node: ["rs-clirsecnta"],
    pos: 155,
  },
  {
    id: "192.168.11.204",
    cnt: 1,
    sessions: 4,
    inresult: 1,
    type: 1,
    "network.bytes": 473939,
    totDataBytes: 0,
    "network.packets": 752,
    node: ["rs-clirsecnta"],
    pos: 156,
  },
  {
    id: "142.251.41.42",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 131735,
    totDataBytes: 123321,
    "network.packets": 120,
    node: ["rs-clirsecnta"],
    pos: 157,
  },
  {
    id: "40.99.164.82",
    cnt: 1,
    sessions: 2,
    inresult: 1,
    type: 1,
    "network.bytes": 345526,
    totDataBytes: 0,
    "network.packets": 406,
    node: ["rs-clirsecnta"],
    pos: 158,
  },
  {
    id: "213.227.173.150",
    cnt: 2,
    sessions: 14,
    inresult: 1,
    type: 3,
    "network.bytes": 92448,
    totDataBytes: 0,
    "network.packets": 986,
    node: ["rs-clirsecnta"],
    pos: 159,
  },
  {
    id: "192.168.11.192",
    cnt: 1,
    sessions: 7,
    inresult: 1,
    type: 1,
    "network.bytes": 5002404,
    totDataBytes: 0,
    "network.packets": 12141,
    node: ["rs-clirsecnta"],
    pos: 160,
  },
  {
    id: "151.139.128.10",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 371,
    totDataBytes: 0,
    "network.packets": 5,
    node: ["rs-clirsecnta"],
    pos: 161,
  },
  {
    id: "52.211.253.81",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 4554,
    totDataBytes: 0,
    "network.packets": 3,
    node: ["rs-clirsecnta"],
    pos: 162,
  },
  {
    id: "142.251.41.35",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 859,
    totDataBytes: 0,
    "network.packets": 12,
    node: ["rs-clirsecnta"],
    pos: 163,
  },
  {
    id: "209.206.63.24",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 63800,
    totDataBytes: 42456,
    "network.packets": 464,
    node: ["rs-clirsecnta"],
    pos: 164,
  },
  {
    id: "209.206.49.232",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 835136,
    totDataBytes: 701276,
    "network.packets": 2910,
    node: ["rs-clirsecnta"],
    pos: 165,
  },
  {
    id: "192.168.10.108",
    cnt: 2,
    sessions: 14,
    inresult: 1,
    type: 3,
    "network.bytes": 3924734,
    totDataBytes: 0,
    "network.packets": 19794,
    node: ["rs-clirsecnta"],
    pos: 166,
  },
  {
    id: "192.168.100.29",
    cnt: 2,
    sessions: 11,
    inresult: 1,
    type: 3,
    "network.bytes": 66111,
    totDataBytes: 0,
    "network.packets": 640,
    node: ["rs-clirsecnta"],
    pos: 167,
  },
  {
    id: "52.226.139.180",
    cnt: 2,
    sessions: 14,
    inresult: 1,
    type: 2,
    "network.bytes": 85600,
    totDataBytes: 0,
    "network.packets": 642,
    node: ["rs-clirsecnta"],
    pos: 168,
  },
  {
    id: "54.221.216.195",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 448,
    totDataBytes: 0,
    "network.packets": 7,
    node: ["rs-clirsecnta"],
    pos: 169,
  },
  {
    id: "40.99.227.82",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 2174,
    totDataBytes: 0,
    "network.packets": 26,
    node: ["rs-clirsecnta"],
    pos: 170,
  },
  {
    id: "192.168.11.1",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 2174,
    totDataBytes: 0,
    "network.packets": 26,
    node: ["rs-clirsecnta"],
    pos: 171,
  },
  {
    id: "52.96.191.146",
    cnt: 1,
    sessions: 2,
    inresult: 1,
    type: 1,
    "network.bytes": 193192,
    totDataBytes: 0,
    "network.packets": 411,
    node: ["rs-clirsecnta"],
    pos: 172,
  },
  {
    id: "192.168.19.36",
    cnt: 3,
    sessions: 13,
    inresult: 1,
    type: 3,
    "network.bytes": 4801738,
    totDataBytes: 0,
    "network.packets": 10099,
    node: ["rs-clirsecnta"],
    pos: 173,
  },
  {
    id: "192.168.10.84",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 195840,
    totDataBytes: 122880,
    "network.packets": 1920,
    node: ["rs-clirsecnta"],
    pos: 174,
  },
  {
    id: "192.168.11.56",
    cnt: 2,
    sessions: 3,
    inresult: 1,
    type: 1,
    "network.bytes": 221348,
    totDataBytes: 0,
    "network.packets": 437,
    node: ["rs-clirsecnta"],
    pos: 175,
  },
  {
    id: "52.96.215.2",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 22944,
    totDataBytes: 0,
    "network.packets": 77,
    node: ["rs-clirsecnta"],
    pos: 176,
  },
  {
    id: "192.168.10.88",
    cnt: 3,
    sessions: 15,
    inresult: 1,
    type: 3,
    "network.bytes": 4167840,
    totDataBytes: 122880,
    "network.packets": 22047,
    node: ["rs-clirsecnta"],
    pos: 177,
  },
  {
    id: "192.168.10.95",
    cnt: 3,
    sessions: 15,
    inresult: 1,
    type: 3,
    "network.bytes": 4020117,
    totDataBytes: 122752,
    "network.packets": 21903,
    node: ["rs-clirsecnta"],
    pos: 178,
  },
  {
    id: "34.96.70.1",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 1395,
    totDataBytes: 0,
    "network.packets": 20,
    node: ["rs-clirsecnta"],
    pos: 179,
  },
  {
    id: "172.31.255.12",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 979434,
    totDataBytes: 614596,
    "network.packets": 9601,
    node: ["rs-clirsecnta"],
    pos: 180,
  },
  {
    id: "68.67.160.117",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 192,
    totDataBytes: 0,
    "network.packets": 3,
    node: ["rs-clirsecnta"],
    pos: 181,
  },
  {
    id: "142.251.41.48",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 192,
    totDataBytes: 0,
    "network.packets": 3,
    node: ["rs-clirsecnta"],
    pos: 182,
  },
  {
    id: "34.197.192.192",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 192,
    totDataBytes: 0,
    "network.packets": 3,
    node: ["rs-clirsecnta"],
    pos: 183,
  },
  {
    id: "142.251.33.170",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 163329,
    totDataBytes: 106427,
    "network.packets": 1237,
    node: ["rs-clirsecnta"],
    pos: 184,
  },
  {
    id: "192.168.10.94",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 195840,
    totDataBytes: 122880,
    "network.packets": 1920,
    node: ["rs-clirsecnta"],
    pos: 185,
  },
  {
    id: "192.168.11.203",
    cnt: 1,
    sessions: 6,
    inresult: 1,
    type: 1,
    "network.bytes": 48565,
    totDataBytes: 0,
    "network.packets": 436,
    node: ["rs-clirsecnta"],
    pos: 186,
  },
  {
    id: "213.227.173.133",
    cnt: 1,
    sessions: 6,
    inresult: 1,
    type: 2,
    "network.bytes": 48565,
    totDataBytes: 0,
    "network.packets": 436,
    node: ["rs-clirsecnta"],
    pos: 187,
  },
  {
    id: "158.115.133.157",
    cnt: 1,
    sessions: 7,
    inresult: 1,
    type: 1,
    "network.bytes": 3021260,
    totDataBytes: 0,
    "network.packets": 13904,
    node: ["rs-clirsecnta"],
    pos: 188,
  },
  {
    id: "52.111.232.11",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 385,
    totDataBytes: 0,
    "network.packets": 5,
    node: ["rs-clirsecnta"],
    pos: 189,
  },
  {
    id: "192.168.19.58",
    cnt: 2,
    sessions: 2,
    inresult: 1,
    type: 3,
    "network.bytes": 59826,
    totDataBytes: 0,
    "network.packets": 80,
    node: ["rs-clirsecnta"],
    pos: 190,
  },
  {
    id: "192.168.100.27",
    cnt: 2,
    sessions: 9,
    inresult: 1,
    type: 1,
    "network.bytes": 41309,
    totDataBytes: 0,
    "network.packets": 542,
    node: ["rs-clirsecnta"],
    pos: 191,
  },
  {
    id: "169.60.90.37",
    cnt: 1,
    sessions: 7,
    inresult: 1,
    type: 2,
    "network.bytes": 33517,
    totDataBytes: 0,
    "network.packets": 442,
    node: ["rs-clirsecnta"],
    pos: 192,
  },
  {
    id: "198.135.184.2",
    cnt: 1,
    sessions: 4,
    inresult: 1,
    type: 2,
    "network.bytes": 3155903,
    totDataBytes: 0,
    "network.packets": 9328,
    node: ["rs-clirsecnta"],
    pos: 193,
  },
  {
    id: "192.168.10.82",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 974889,
    totDataBytes: 611837,
    "network.packets": 9554,
    node: ["rs-clirsecnta"],
    pos: 194,
  },
  {
    id: "10.10.20.11",
    cnt: 1,
    sessions: 7,
    inresult: 1,
    type: 1,
    "network.bytes": 817799,
    totDataBytes: 0,
    "network.packets": 5907,
    node: ["rs-clirsecnta"],
    pos: 195,
  },
  {
    id: "192.168.200.55",
    cnt: 1,
    sessions: 7,
    inresult: 1,
    type: 2,
    "network.bytes": 817799,
    totDataBytes: 0,
    "network.packets": 5907,
    node: ["rs-clirsecnta"],
    pos: 196,
  },
  {
    id: "192.168.11.126",
    cnt: 1,
    sessions: 2,
    inresult: 1,
    type: 1,
    "network.bytes": 4078898,
    totDataBytes: 0,
    "network.packets": 5560,
    node: ["rs-clirsecnta"],
    pos: 197,
  },
  {
    id: "192.168.11.16",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 396,
    totDataBytes: 0,
    "network.packets": 6,
    node: ["rs-clirsecnta"],
    pos: 198,
  },
  {
    id: "172.253.62.188",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 396,
    totDataBytes: 0,
    "network.packets": 6,
    node: ["rs-clirsecnta"],
    pos: 199,
  },
  {
    id: "23.5.110.15",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 786199,
    totDataBytes: 0,
    "network.packets": 1370,
    node: ["rs-clirsecnta"],
    pos: 200,
  },
  {
    id: "35.227.230.187",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 1395,
    totDataBytes: 0,
    "network.packets": 20,
    node: ["rs-clirsecnta"],
    pos: 201,
  },
  {
    id: "52.111.227.3",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 59441,
    totDataBytes: 0,
    "network.packets": 75,
    node: ["rs-clirsecnta"],
    pos: 202,
  },
  {
    id: "54.243.116.235",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 409,
    totDataBytes: 0,
    "network.packets": 6,
    node: ["rs-clirsecnta"],
    pos: 203,
  },
  {
    id: "104.88.157.46",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 130020,
    totDataBytes: 0,
    "network.packets": 967,
    node: ["rs-clirsecnta"],
    pos: 204,
  },
  {
    id: "162.247.241.2",
    cnt: 1,
    sessions: 4,
    inresult: 1,
    type: 1,
    "network.bytes": 54420,
    totDataBytes: 0,
    "network.packets": 846,
    node: ["rs-clirsecnta"],
    pos: 205,
  },
  {
    id: "151.101.124.159",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 5064550,
    totDataBytes: 0,
    "network.packets": 3842,
    node: ["rs-clirsecnta"],
    pos: 206,
  },
  {
    id: "52.96.88.194",
    cnt: 1,
    sessions: 7,
    inresult: 1,
    type: 2,
    "network.bytes": 805193,
    totDataBytes: 0,
    "network.packets": 1541,
    node: ["rs-clirsecnta"],
    pos: 207,
  },
  {
    id: "188.172.251.36",
    cnt: 1,
    sessions: 4,
    inresult: 1,
    type: 1,
    "network.bytes": 23418,
    totDataBytes: 0,
    "network.packets": 319,
    node: ["rs-clirsecnta"],
    pos: 208,
  },
  {
    id: "52.109.16.87",
    cnt: 2,
    sessions: 4,
    inresult: 1,
    type: 2,
    "network.bytes": 15609,
    totDataBytes: 0,
    "network.packets": 200,
    node: ["rs-clirsecnta"],
    pos: 209,
  },
  {
    id: "52.96.88.242",
    cnt: 1,
    sessions: 2,
    inresult: 1,
    type: 1,
    "network.bytes": 32075,
    totDataBytes: 0,
    "network.packets": 187,
    node: ["rs-clirsecnta"],
    pos: 210,
  },
  {
    id: "52.226.139.185",
    cnt: 1,
    sessions: 7,
    inresult: 1,
    type: 2,
    "network.bytes": 42693,
    totDataBytes: 0,
    "network.packets": 321,
    node: ["rs-clirsecnta"],
    pos: 211,
  },
  {
    id: "172.217.165.10",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 725,
    totDataBytes: 0,
    "network.packets": 10,
    node: ["rs-clirsecnta"],
    pos: 212,
  },
  {
    id: "99.83.202.36",
    cnt: 1,
    sessions: 7,
    inresult: 1,
    type: 1,
    "network.bytes": 100241,
    totDataBytes: 0,
    "network.packets": 1200,
    node: ["rs-clirsecnta"],
    pos: 213,
  },
  {
    id: "13.73.252.129",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 1984,
    totDataBytes: 0,
    "network.packets": 24,
    node: ["rs-clirsecnta"],
    pos: 214,
  },
  {
    id: "192.168.11.198",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 14695,
    totDataBytes: 0,
    "network.packets": 40,
    node: ["rs-clirsecnta"],
    pos: 215,
  },
  {
    id: "40.99.226.210",
    cnt: 2,
    sessions: 10,
    inresult: 1,
    type: 1,
    "network.bytes": 2065670,
    totDataBytes: 0,
    "network.packets": 2453,
    node: ["rs-clirsecnta"],
    pos: 216,
  },
  {
    id: "192.168.20.55",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 3832,
    totDataBytes: 0,
    "network.packets": 17,
    node: ["rs-clirsecnta"],
    pos: 217,
  },
  {
    id: "52.112.95.100",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 3832,
    totDataBytes: 0,
    "network.packets": 17,
    node: ["rs-clirsecnta"],
    pos: 218,
  },
  {
    id: "192.168.10.98",
    cnt: 3,
    sessions: 4,
    inresult: 1,
    type: 1,
    "network.bytes": 704,
    totDataBytes: 198,
    "network.packets": 11,
    node: ["rs-clirsecnta"],
    pos: 219,
  },
  {
    id: "17.133.234.32",
    cnt: 1,
    sessions: 2,
    inresult: 1,
    type: 2,
    "network.bytes": 384,
    totDataBytes: 108,
    "network.packets": 6,
    node: ["rs-clirsecnta"],
    pos: 220,
  },
  {
    id: "17.133.234.33",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 256,
    totDataBytes: 72,
    "network.packets": 4,
    node: ["rs-clirsecnta"],
    pos: 221,
  },
  {
    id: "137.135.220.2",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 51556,
    totDataBytes: 0,
    "network.packets": 127,
    node: ["rs-clirsecnta"],
    pos: 222,
  },
  {
    id: "192.168.200.66",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 441,
    totDataBytes: 349,
    "network.packets": 2,
    node: ["rs-clirsecnta"],
    pos: 223,
  },
  {
    id: "52.109.16.95",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 1,
    "network.bytes": 256,
    totDataBytes: 0,
    "network.packets": 4,
    node: ["rs-clirsecnta"],
    pos: 224,
  },
  {
    id: "207.61.238.141",
    cnt: 1,
    sessions: 1,
    inresult: 1,
    type: 2,
    "network.bytes": 64,
    totDataBytes: 18,
    "network.packets": 1,
    node: ["rs-clirsecnta"],
    pos: 225,
  },
  {
    id: "40.99.226.178",
    cnt: 1,
    sessions: 2,
    inresult: 1,
    type: 1,
    "network.bytes": 173017,
    totDataBytes: 0,
    "network.packets": 358,
    node: ["rs-clirsecnta"],
    pos: 226,
  },
  {
    id: "35.186.224.47",
    cnt: 1,
    sessions: 4,
    inresult: 1,
    type: 2,
    "network.bytes": 29140,
    totDataBytes: 0,
    "network.packets": 376,
    node: ["rs-clirsecnta"],
    pos: 227,
  },
];

export const links = [
  {
    value: 33,
    source: 0,
    target: 1,
    "network.bytes": 262234009,
    totDataBytes: 0,
    "network.packets": 327642,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 2,
    target: 3,
    "network.bytes": 5595501,
    totDataBytes: 5161767,
    "network.packets": 9429,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 4,
    target: 3,
    "network.bytes": 13679,
    totDataBytes: 11563,
    "network.packets": 46,
    node: ["rs-clirsecnta"],
  },
  {
    value: 36,
    source: 5,
    target: 6,
    "network.bytes": 397002705,
    totDataBytes: 0,
    "network.packets": 355396,
    node: ["rs-clirsecnta"],
  },
  {
    value: 48,
    source: 7,
    target: 0,
    "network.bytes": 382168739,
    totDataBytes: 0,
    "network.packets": 475857,
    node: ["rs-clirsecnta"],
  },
  {
    value: 10,
    source: 8,
    target: 9,
    "network.bytes": 98776751,
    totDataBytes: 94203569,
    "network.packets": 99417,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 10,
    target: 11,
    "network.bytes": 8842,
    totDataBytes: 0,
    "network.packets": 18,
    node: ["rs-clirsecnta"],
  },
  {
    value: 80,
    source: 12,
    target: 13,
    "network.bytes": 129788851,
    totDataBytes: 0,
    "network.packets": 792589,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 14,
    target: 15,
    "network.bytes": 18904,
    totDataBytes: 0,
    "network.packets": 81,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 16,
    target: 17,
    "network.bytes": 471037,
    totDataBytes: 0,
    "network.packets": 1095,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 18,
    target: 19,
    "network.bytes": 2375105,
    totDataBytes: 0,
    "network.packets": 12348,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 20,
    target: 21,
    "network.bytes": 627,
    totDataBytes: 0,
    "network.packets": 9,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 22,
    target: 19,
    "network.bytes": 1376358,
    totDataBytes: 0,
    "network.packets": 7171,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 23,
    target: 24,
    "network.bytes": 1279181,
    totDataBytes: 0,
    "network.packets": 6731,
    node: ["rs-clirsecnta"],
  },
  {
    value: 13,
    source: 25,
    target: 26,
    "network.bytes": 27677204,
    totDataBytes: 0,
    "network.packets": 123383,
    node: ["rs-clirsecnta"],
  },
  {
    value: 4,
    source: 16,
    target: 27,
    "network.bytes": 12301658,
    totDataBytes: 0,
    "network.packets": 11744,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 28,
    target: 29,
    "network.bytes": 41181,
    totDataBytes: 35991,
    "network.packets": 74,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 24,
    target: 30,
    "network.bytes": 2287674,
    totDataBytes: 0,
    "network.packets": 11795,
    node: ["rs-clirsecnta"],
  },
  {
    value: 3,
    source: 31,
    target: 32,
    "network.bytes": 5276471,
    totDataBytes: 0,
    "network.packets": 6940,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 33,
    target: 34,
    "network.bytes": 48328,
    totDataBytes: 0,
    "network.packets": 54,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 35,
    target: 36,
    "network.bytes": 3912,
    totDataBytes: 0,
    "network.packets": 38,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 37,
    target: 38,
    "network.bytes": 972816,
    totDataBytes: 610524,
    "network.packets": 9534,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 3,
    target: 39,
    "network.bytes": 3200,
    totDataBytes: 1868,
    "network.packets": 19,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 3,
    target: 40,
    "network.bytes": 13480,
    totDataBytes: 12284,
    "network.packets": 26,
    node: ["rs-clirsecnta"],
  },
  {
    value: 9,
    source: 41,
    target: 42,
    "network.bytes": 48876530,
    totDataBytes: 44955076,
    "network.packets": 85249,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 43,
    target: 44,
    "network.bytes": 32181,
    totDataBytes: 0,
    "network.packets": 157,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 45,
    target: 46,
    "network.bytes": 350,
    totDataBytes: 0,
    "network.packets": 5,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 45,
    target: 47,
    "network.bytes": 350,
    totDataBytes: 0,
    "network.packets": 5,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 48,
    target: 49,
    "network.bytes": 8710,
    totDataBytes: 4110,
    "network.packets": 100,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 50,
    target: 51,
    "network.bytes": 602,
    totDataBytes: 418,
    "network.packets": 4,
    node: ["rs-clirsecnta"],
  },
  {
    value: 68,
    source: 7,
    target: 52,
    "network.bytes": 20763,
    totDataBytes: 13863,
    "network.packets": 150,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 53,
    target: 38,
    "network.bytes": 196044,
    totDataBytes: 123008,
    "network.packets": 1922,
    node: ["rs-clirsecnta"],
  },
  {
    value: 9,
    source: 54,
    target: 55,
    "network.bytes": 72177913,
    totDataBytes: 0,
    "network.packets": 68948,
    node: ["rs-clirsecnta"],
  },
  {
    value: 13,
    source: 56,
    target: 57,
    "network.bytes": 77616353,
    totDataBytes: 0,
    "network.packets": 92903,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 58,
    target: 59,
    "network.bytes": 37027,
    totDataBytes: 0,
    "network.packets": 463,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 60,
    target: 61,
    "network.bytes": 144623,
    totDataBytes: 0,
    "network.packets": 291,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 62,
    target: 63,
    "network.bytes": 62591,
    totDataBytes: 0,
    "network.packets": 190,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 15,
    target: 64,
    "network.bytes": 19599,
    totDataBytes: 16599,
    "network.packets": 54,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 30,
    target: 65,
    "network.bytes": 2685388,
    totDataBytes: 0,
    "network.packets": 13018,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 65,
    target: 66,
    "network.bytes": 1570463,
    totDataBytes: 0,
    "network.packets": 7463,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 67,
    target: 68,
    "network.bytes": 140,
    totDataBytes: 0,
    "network.packets": 2,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 33,
    target: 69,
    "network.bytes": 788,
    totDataBytes: 0,
    "network.packets": 7,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 70,
    target: 71,
    "network.bytes": 628,
    totDataBytes: 0,
    "network.packets": 9,
    node: ["rs-clirsecnta"],
  },
  {
    value: 3,
    source: 72,
    target: 67,
    "network.bytes": 576,
    totDataBytes: 0,
    "network.packets": 9,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 73,
    target: 15,
    "network.bytes": 2339,
    totDataBytes: 0,
    "network.packets": 28,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 11,
    target: 0,
    "network.bytes": 2927293,
    totDataBytes: 0,
    "network.packets": 6337,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 74,
    target: 44,
    "network.bytes": 4033125,
    totDataBytes: 0,
    "network.packets": 10329,
    node: ["rs-clirsecnta"],
  },
  {
    value: 3,
    source: 75,
    target: 76,
    "network.bytes": 39694,
    totDataBytes: 0,
    "network.packets": 210,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 77,
    target: 45,
    "network.bytes": 8951,
    totDataBytes: 0,
    "network.packets": 38,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 3,
    target: 78,
    "network.bytes": 71453,
    totDataBytes: 0,
    "network.packets": 64,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 79,
    target: 80,
    "network.bytes": 5320,
    totDataBytes: 0,
    "network.packets": 20,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 81,
    target: 82,
    "network.bytes": 663138,
    totDataBytes: 0,
    "network.packets": 1003,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 30,
    target: 83,
    "network.bytes": 2557358,
    totDataBytes: 0,
    "network.packets": 12652,
    node: ["rs-clirsecnta"],
  },
  {
    value: 10,
    source: 81,
    target: 16,
    "network.bytes": 1887176,
    totDataBytes: 0,
    "network.packets": 2269,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 84,
    target: 51,
    "network.bytes": 17810,
    totDataBytes: 0,
    "network.packets": 114,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 85,
    target: 51,
    "network.bytes": 421,
    totDataBytes: 329,
    "network.packets": 2,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 86,
    target: 38,
    "network.bytes": 972567,
    totDataBytes: 610503,
    "network.packets": 9528,
    node: ["rs-clirsecnta"],
  },
  {
    value: 3,
    source: 33,
    target: 87,
    "network.bytes": 291574,
    totDataBytes: 0,
    "network.packets": 381,
    node: ["rs-clirsecnta"],
  },
  {
    value: 4,
    source: 88,
    target: 89,
    "network.bytes": 2652598,
    totDataBytes: 0,
    "network.packets": 4643,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 90,
    target: 38,
    "network.bytes": 973611,
    totDataBytes: 611053,
    "network.packets": 9541,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 19,
    target: 38,
    "network.bytes": 196044,
    totDataBytes: 123008,
    "network.packets": 1922,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 91,
    target: 92,
    "network.bytes": 124,
    totDataBytes: 78,
    "network.packets": 1,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 93,
    target: 38,
    "network.bytes": 973346,
    totDataBytes: 610864,
    "network.packets": 9539,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 94,
    target: 95,
    "network.bytes": 46212,
    totDataBytes: 0,
    "network.packets": 67,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 96,
    target: 51,
    "network.bytes": 318,
    totDataBytes: 226,
    "network.packets": 2,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 97,
    target: 38,
    "network.bytes": 975818,
    totDataBytes: 612462,
    "network.packets": 9562,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 98,
    target: 53,
    "network.bytes": 2397356,
    totDataBytes: 0,
    "network.packets": 12502,
    node: ["rs-clirsecnta"],
  },
  {
    value: 4,
    source: 99,
    target: 100,
    "network.bytes": 320694,
    totDataBytes: 0,
    "network.packets": 693,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 53,
    target: 101,
    "network.bytes": 1359707,
    totDataBytes: 0,
    "network.packets": 7153,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 102,
    target: 103,
    "network.bytes": 1967920,
    totDataBytes: 1731572,
    "network.packets": 5138,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 104,
    target: 38,
    "network.bytes": 975899,
    totDataBytes: 612581,
    "network.packets": 9561,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 3,
    target: 4,
    "network.bytes": 24323,
    totDataBytes: 21471,
    "network.packets": 62,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 105,
    target: 106,
    "network.bytes": 2708500,
    totDataBytes: 0,
    "network.packets": 12793,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 106,
    target: 107,
    "network.bytes": 1548893,
    totDataBytes: 0,
    "network.packets": 7313,
    node: ["rs-clirsecnta"],
  },
  {
    value: 3,
    source: 3,
    target: 108,
    "network.bytes": 453091,
    totDataBytes: 421505,
    "network.packets": 439,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 109,
    target: 79,
    "network.bytes": 2497,
    totDataBytes: 0,
    "network.packets": 22,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 110,
    target: 111,
    "network.bytes": 56408,
    totDataBytes: 0,
    "network.packets": 100,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 73,
    target: 111,
    "network.bytes": 41070,
    totDataBytes: 0,
    "network.packets": 174,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 112,
    target: 38,
    "network.bytes": 195942,
    totDataBytes: 122944,
    "network.packets": 1921,
    node: ["rs-clirsecnta"],
  },
  {
    value: 6,
    source: 113,
    target: 28,
    "network.bytes": 10044103,
    totDataBytes: 0,
    "network.packets": 40765,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 114,
    target: 115,
    "network.bytes": 594,
    totDataBytes: 0,
    "network.packets": 9,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 116,
    target: 21,
    "network.bytes": 1287677,
    totDataBytes: 0,
    "network.packets": 2643,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 117,
    target: 118,
    "network.bytes": 158050,
    totDataBytes: 0,
    "network.packets": 812,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 119,
    target: 58,
    "network.bytes": 187896,
    totDataBytes: 0,
    "network.packets": 366,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 113,
    target: 120,
    "network.bytes": 1278900,
    totDataBytes: 0,
    "network.packets": 14220,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 67,
    target: 121,
    "network.bytes": 3968,
    totDataBytes: 2266,
    "network.packets": 37,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 67,
    target: 122,
    "network.bytes": 3968,
    totDataBytes: 2266,
    "network.packets": 37,
    node: ["rs-clirsecnta"],
  },
  {
    value: 6,
    source: 25,
    target: 123,
    "network.bytes": 11122,
    totDataBytes: 0,
    "network.packets": 166,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 124,
    target: 125,
    "network.bytes": 153703,
    totDataBytes: 0,
    "network.packets": 300,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 126,
    target: 38,
    "network.bytes": 973442,
    totDataBytes: 610960,
    "network.packets": 9539,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 96,
    target: 127,
    "network.bytes": 6738,
    totDataBytes: 0,
    "network.packets": 44,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 114,
    target: 128,
    "network.bytes": 594,
    totDataBytes: 0,
    "network.packets": 9,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 105,
    target: 129,
    "network.bytes": 2579786,
    totDataBytes: 0,
    "network.packets": 13140,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 3,
    target: 130,
    "network.bytes": 88502,
    totDataBytes: 55396,
    "network.packets": 474,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 131,
    target: 0,
    "network.bytes": 2460011,
    totDataBytes: 0,
    "network.packets": 5476,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 114,
    target: 132,
    "network.bytes": 594,
    totDataBytes: 0,
    "network.packets": 9,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 18,
    target: 133,
    "network.bytes": 2512903,
    totDataBytes: 0,
    "network.packets": 12978,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 134,
    target: 135,
    "network.bytes": 189759,
    totDataBytes: 129361,
    "network.packets": 1313,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 136,
    target: 51,
    "network.bytes": 195,
    totDataBytes: 103,
    "network.packets": 2,
    node: ["rs-clirsecnta"],
  },
  {
    value: 3,
    source: 76,
    target: 137,
    "network.bytes": 102006,
    totDataBytes: 0,
    "network.packets": 558,
    node: ["rs-clirsecnta"],
  },
  {
    value: 101,
    source: 12,
    target: 0,
    "network.bytes": 520538874,
    totDataBytes: 0,
    "network.packets": 1002649,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 138,
    target: 139,
    "network.bytes": 48602,
    totDataBytes: 44828,
    "network.packets": 63,
    node: ["rs-clirsecnta"],
  },
  {
    value: 4,
    source: 140,
    target: 48,
    "network.bytes": 95727,
    totDataBytes: 0,
    "network.packets": 425,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 141,
    target: 38,
    "network.bytes": 973034,
    totDataBytes: 610742,
    "network.packets": 9534,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 142,
    target: 3,
    "network.bytes": 2584645,
    totDataBytes: 0,
    "network.packets": 13029,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 125,
    target: 21,
    "network.bytes": 3689739,
    totDataBytes: 0,
    "network.packets": 3885,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 133,
    target: 23,
    "network.bytes": 1444695,
    totDataBytes: 0,
    "network.packets": 7417,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 143,
    target: 38,
    "network.bytes": 195840,
    totDataBytes: 122880,
    "network.packets": 1920,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 114,
    target: 144,
    "network.bytes": 594,
    totDataBytes: 0,
    "network.packets": 9,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 34,
    target: 145,
    "network.bytes": 332,
    totDataBytes: 0,
    "network.packets": 5,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 73,
    target: 100,
    "network.bytes": 1000610,
    totDataBytes: 0,
    "network.packets": 1658,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 103,
    target: 146,
    "network.bytes": 536368,
    totDataBytes: 428222,
    "network.packets": 2351,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 147,
    target: 148,
    "network.bytes": 3164,
    totDataBytes: 0,
    "network.packets": 38,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 149,
    target: 150,
    "network.bytes": 2716,
    totDataBytes: 0,
    "network.packets": 21,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 151,
    target: 38,
    "network.bytes": 972788,
    totDataBytes: 610496,
    "network.packets": 9534,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 152,
    target: 38,
    "network.bytes": 195840,
    totDataBytes: 122880,
    "network.packets": 1920,
    node: ["rs-clirsecnta"],
  },
  {
    value: 6,
    source: 88,
    target: 153,
    "network.bytes": 975334,
    totDataBytes: 0,
    "network.packets": 3847,
    node: ["rs-clirsecnta"],
  },
  {
    value: 4,
    source: 51,
    target: 46,
    "network.bytes": 9716,
    totDataBytes: 8764,
    "network.packets": 16,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 154,
    target: 79,
    "network.bytes": 2924,
    totDataBytes: 0,
    "network.packets": 14,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 3,
    target: 66,
    "network.bytes": 1519725,
    totDataBytes: 0,
    "network.packets": 7421,
    node: ["rs-clirsecnta"],
  },
  {
    value: 4,
    source: 61,
    target: 60,
    "network.bytes": 16033852,
    totDataBytes: 0,
    "network.packets": 14038,
    node: ["rs-clirsecnta"],
  },
  {
    value: 4,
    source: 61,
    target: 81,
    "network.bytes": 16472454,
    totDataBytes: 0,
    "network.packets": 15840,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 3,
    target: 155,
    "network.bytes": 13406,
    totDataBytes: 9852,
    "network.packets": 51,
    node: ["rs-clirsecnta"],
  },
  {
    value: 4,
    source: 156,
    target: 60,
    "network.bytes": 473939,
    totDataBytes: 0,
    "network.packets": 752,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 106,
    target: 38,
    "network.bytes": 195840,
    totDataBytes: 122880,
    "network.packets": 1920,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 3,
    target: 157,
    "network.bytes": 131735,
    totDataBytes: 123321,
    "network.packets": 120,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 110,
    target: 21,
    "network.bytes": 191009,
    totDataBytes: 0,
    "network.packets": 340,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 158,
    target: 16,
    "network.bytes": 345526,
    totDataBytes: 0,
    "network.packets": 406,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 77,
    target: 82,
    "network.bytes": 806333,
    totDataBytes: 0,
    "network.packets": 1311,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 159,
    target: 82,
    "network.bytes": 56772,
    totDataBytes: 0,
    "network.packets": 643,
    node: ["rs-clirsecnta"],
  },
  {
    value: 4,
    source: 114,
    target: 0,
    "network.bytes": 5578814,
    totDataBytes: 0,
    "network.packets": 9461,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 160,
    target: 0,
    "network.bytes": 5002404,
    totDataBytes: 0,
    "network.packets": 12141,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 161,
    target: 76,
    "network.bytes": 371,
    totDataBytes: 0,
    "network.packets": 5,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 55,
    target: 162,
    "network.bytes": 4554,
    totDataBytes: 0,
    "network.packets": 3,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 24,
    target: 38,
    "network.bytes": 195840,
    totDataBytes: 122880,
    "network.packets": 1920,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 82,
    target: 159,
    "network.bytes": 35676,
    totDataBytes: 0,
    "network.packets": 343,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 19,
    target: 163,
    "network.bytes": 859,
    totDataBytes: 0,
    "network.packets": 12,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 86,
    target: 164,
    "network.bytes": 63800,
    totDataBytes: 42456,
    "network.packets": 464,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 86,
    target: 165,
    "network.bytes": 835136,
    totDataBytes: 701276,
    "network.packets": 2910,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 142,
    target: 166,
    "network.bytes": 2494400,
    totDataBytes: 0,
    "network.packets": 12700,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 166,
    target: 22,
    "network.bytes": 1430334,
    totDataBytes: 0,
    "network.packets": 7094,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 167,
    target: 168,
    "network.bytes": 42693,
    totDataBytes: 0,
    "network.packets": 321,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 169,
    target: 120,
    "network.bytes": 448,
    totDataBytes: 0,
    "network.packets": 7,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 139,
    target: 63,
    "network.bytes": 6464,
    totDataBytes: 0,
    "network.packets": 78,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 170,
    target: 171,
    "network.bytes": 2174,
    totDataBytes: 0,
    "network.packets": 26,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 172,
    target: 63,
    "network.bytes": 193192,
    totDataBytes: 0,
    "network.packets": 411,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 173,
    target: 0,
    "network.bytes": 4599581,
    totDataBytes: 0,
    "network.packets": 9365,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 77,
    target: 149,
    "network.bytes": 206950,
    totDataBytes: 0,
    "network.packets": 370,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 174,
    target: 38,
    "network.bytes": 195840,
    totDataBytes: 122880,
    "network.packets": 1920,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 175,
    target: 75,
    "network.bytes": 198404,
    totDataBytes: 0,
    "network.packets": 360,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 175,
    target: 176,
    "network.bytes": 22944,
    totDataBytes: 0,
    "network.packets": 77,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 177,
    target: 38,
    "network.bytes": 195840,
    totDataBytes: 122880,
    "network.packets": 1920,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 178,
    target: 38,
    "network.bytes": 195636,
    totDataBytes: 122752,
    "network.packets": 1918,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 146,
    target: 179,
    "network.bytes": 1395,
    totDataBytes: 0,
    "network.packets": 20,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 105,
    target: 177,
    "network.bytes": 2491352,
    totDataBytes: 0,
    "network.packets": 12756,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 177,
    target: 23,
    "network.bytes": 1480648,
    totDataBytes: 0,
    "network.packets": 7371,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 180,
    target: 38,
    "network.bytes": 979434,
    totDataBytes: 614596,
    "network.packets": 9601,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 21,
    target: 181,
    "network.bytes": 192,
    totDataBytes: 0,
    "network.packets": 3,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 21,
    target: 182,
    "network.bytes": 192,
    totDataBytes: 0,
    "network.packets": 3,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 21,
    target: 183,
    "network.bytes": 192,
    totDataBytes: 0,
    "network.packets": 3,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 184,
    target: 146,
    "network.bytes": 163329,
    totDataBytes: 106427,
    "network.packets": 1237,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 185,
    target: 38,
    "network.bytes": 195840,
    totDataBytes: 122880,
    "network.packets": 1920,
    node: ["rs-clirsecnta"],
  },
  {
    value: 6,
    source: 186,
    target: 187,
    "network.bytes": 48565,
    totDataBytes: 0,
    "network.packets": 436,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 188,
    target: 55,
    "network.bytes": 3021260,
    totDataBytes: 0,
    "network.packets": 13904,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 19,
    target: 0,
    "network.bytes": 2142986,
    totDataBytes: 0,
    "network.packets": 4438,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 189,
    target: 190,
    "network.bytes": 385,
    totDataBytes: 0,
    "network.packets": 5,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 191,
    target: 192,
    "network.bytes": 33517,
    totDataBytes: 0,
    "network.packets": 442,
    node: ["rs-clirsecnta"],
  },
  {
    value: 4,
    source: 88,
    target: 193,
    "network.bytes": 3155903,
    totDataBytes: 0,
    "network.packets": 9328,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 194,
    target: 38,
    "network.bytes": 974889,
    totDataBytes: 611837,
    "network.packets": 9554,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 195,
    target: 196,
    "network.bytes": 817799,
    totDataBytes: 0,
    "network.packets": 5907,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 197,
    target: 0,
    "network.bytes": 4078898,
    totDataBytes: 0,
    "network.packets": 5560,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 198,
    target: 199,
    "network.bytes": 396,
    totDataBytes: 0,
    "network.packets": 6,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 16,
    target: 200,
    "network.bytes": 786199,
    totDataBytes: 0,
    "network.packets": 1370,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 83,
    target: 38,
    "network.bytes": 195840,
    totDataBytes: 122880,
    "network.packets": 1920,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 146,
    target: 201,
    "network.bytes": 1395,
    totDataBytes: 0,
    "network.packets": 20,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 190,
    target: 202,
    "network.bytes": 59441,
    totDataBytes: 0,
    "network.packets": 75,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 203,
    target: 100,
    "network.bytes": 409,
    totDataBytes: 0,
    "network.packets": 6,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 16,
    target: 204,
    "network.bytes": 130020,
    totDataBytes: 0,
    "network.packets": 967,
    node: ["rs-clirsecnta"],
  },
  {
    value: 4,
    source: 205,
    target: 61,
    "network.bytes": 54420,
    totDataBytes: 0,
    "network.packets": 846,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 44,
    target: 206,
    "network.bytes": 5064550,
    totDataBytes: 0,
    "network.packets": 3842,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 21,
    target: 207,
    "network.bytes": 805193,
    totDataBytes: 0,
    "network.packets": 1541,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 55,
    target: 107,
    "network.bytes": 1780822,
    totDataBytes: 0,
    "network.packets": 7810,
    node: ["rs-clirsecnta"],
  },
  {
    value: 4,
    source: 208,
    target: 167,
    "network.bytes": 23418,
    totDataBytes: 0,
    "network.packets": 319,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 25,
    target: 209,
    "network.bytes": 7817,
    totDataBytes: 0,
    "network.packets": 100,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 210,
    target: 87,
    "network.bytes": 32075,
    totDataBytes: 0,
    "network.packets": 187,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 102,
    target: 0,
    "network.bytes": 10542143,
    totDataBytes: 0,
    "network.packets": 17963,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 111,
    target: 211,
    "network.bytes": 42693,
    totDataBytes: 0,
    "network.packets": 321,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 67,
    target: 212,
    "network.bytes": 725,
    totDataBytes: 0,
    "network.packets": 10,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 213,
    target: 69,
    "network.bytes": 100241,
    totDataBytes: 0,
    "network.packets": 1200,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 70,
    target: 214,
    "network.bytes": 1984,
    totDataBytes: 0,
    "network.packets": 24,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 21,
    target: 168,
    "network.bytes": 42907,
    totDataBytes: 0,
    "network.packets": 321,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 33,
    target: 215,
    "network.bytes": 14695,
    totDataBytes: 0,
    "network.packets": 40,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 134,
    target: 82,
    "network.bytes": 10909,
    totDataBytes: 0,
    "network.packets": 66,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 98,
    target: 178,
    "network.bytes": 2398358,
    totDataBytes: 0,
    "network.packets": 12545,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 178,
    target: 22,
    "network.bytes": 1426123,
    totDataBytes: 0,
    "network.packets": 7440,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 69,
    target: 33,
    "network.bytes": 52083,
    totDataBytes: 48099,
    "network.packets": 66,
    node: ["rs-clirsecnta"],
  },
  {
    value: 3,
    source: 216,
    target: 15,
    "network.bytes": 125952,
    totDataBytes: 0,
    "network.packets": 262,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 217,
    target: 218,
    "network.bytes": 3832,
    totDataBytes: 0,
    "network.packets": 17,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 191,
    target: 209,
    "network.bytes": 7792,
    totDataBytes: 0,
    "network.packets": 100,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 219,
    target: 220,
    "network.bytes": 384,
    totDataBytes: 108,
    "network.packets": 6,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 219,
    target: 221,
    "network.bytes": 256,
    totDataBytes: 72,
    "network.packets": 4,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 21,
    target: 222,
    "network.bytes": 51556,
    totDataBytes: 0,
    "network.packets": 127,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 9,
    target: 0,
    "network.bytes": 2659133,
    totDataBytes: 0,
    "network.packets": 5408,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 223,
    target: 127,
    "network.bytes": 441,
    totDataBytes: 349,
    "network.packets": 2,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 224,
    target: 148,
    "network.bytes": 256,
    totDataBytes: 0,
    "network.packets": 4,
    node: ["rs-clirsecnta"],
  },
  {
    value: 1,
    source: 219,
    target: 225,
    "network.bytes": 64,
    totDataBytes: 18,
    "network.packets": 1,
    node: ["rs-clirsecnta"],
  },
  {
    value: 2,
    source: 226,
    target: 173,
    "network.bytes": 173017,
    totDataBytes: 0,
    "network.packets": 358,
    node: ["rs-clirsecnta"],
  },
  {
    value: 7,
    source: 216,
    target: 100,
    "network.bytes": 1939718,
    totDataBytes: 0,
    "network.packets": 2191,
    node: ["rs-clirsecnta"],
  },
  {
    value: 4,
    source: 173,
    target: 227,
    "network.bytes": 29140,
    totDataBytes: 0,
    "network.packets": 376,
    node: ["rs-clirsecnta"],
  },
];
