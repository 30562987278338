import { notification } from "antd";

import {
  TOP_TACTICS,
  ALERT_VOLVE,
  ATTACK_ACTIC,
  RULE_LVL_ATTACK,
  RULE_LVL_TACTIC,
  ALERT_COUNT_MITRE,
} from "../apiUrl";
import {
  TOP_TACTICS_FAIL,
  ALERT_VOLVE_FAIL,
  ATTACK_ACTICS_FAIL,
  TOP_TACTICS_SUCCESS,
  TOP_TACTICS_REQUEST,
  ALERT_VOLVE_REQUST,
  ALERT_VOLVE_SUCCESS,
  RULE_LVL_TACTIC_FAIL,
  RULE_LVL_ATTACK_FAIL,
  ATTACK_ACTICS_REQUEST,
  ATTACK_ACTICS_SUCCESS,
  RULE_LVL_ATTACK_SUCCESS,
  RULE_LVL_ATTACK_REQUEST,
  RULE_LVL_TACTIC_REQUEST,
  RULE_LVL_TACTIC_SUCCESS,
  ALERT_COUNT_MITRE_REQUEST,
  ALERT_COUNT_MITRE_SUCCESS,
  ALERT_COUNT_MITRE_FAIL,
} from "./actionConstant";
import { commonBody } from "../common/constant";
import { getLoginUser } from "../common/localStorage";
import { functionalityApiCall } from "../common/connect";

const alertVolveRequest = () => ({
  type: ALERT_VOLVE_REQUST,
});

const alertVolveSuccess = (data) => ({
  type: ALERT_VOLVE_SUCCESS,
  data,
});

const alertVolveFail = () => ({
  type: ALERT_VOLVE_FAIL,
});

const topActicsRequest = () => ({
  type: TOP_TACTICS_REQUEST,
});

const topActicsSuccess = (data) => ({
  type: TOP_TACTICS_SUCCESS,
  data,
});

const topActicsFail = () => ({
  type: TOP_TACTICS_FAIL,
});

const ruleLvlAttackReuest = () => ({
  type: RULE_LVL_ATTACK_REQUEST,
});

const ruleLvlAttackSuccess = (data) => ({
  type: RULE_LVL_ATTACK_SUCCESS,
  data,
});

const ruleLvlAttackFail = () => ({
  type: RULE_LVL_ATTACK_FAIL,
});

const attackActicsReuest = () => ({
  type: ATTACK_ACTICS_REQUEST,
});

const attackActicsSuccess = (data) => ({
  type: ATTACK_ACTICS_SUCCESS,
  data,
});

const attackActicsFail = () => ({
  type: ATTACK_ACTICS_FAIL,
});

const ruleLvlTacticReuest = () => ({
  type: RULE_LVL_TACTIC_REQUEST,
});

const ruleLvlTacticSuccess = (data) => ({
  type: RULE_LVL_TACTIC_SUCCESS,
  data,
});

const ruleLvlTacticFail = () => ({
  type: RULE_LVL_TACTIC_FAIL,
});

const mitreAlertCountRequest = () => ({
  type: ALERT_COUNT_MITRE_REQUEST,
});

const mitreAlertCountSuccess = (data) => ({
  type: ALERT_COUNT_MITRE_SUCCESS,
  data,
});

const mitreAlertCountFail = () => ({
  type: ALERT_COUNT_MITRE_FAIL,
});

const data = commonBody;

export const alertVolve = (requestData) => (dispatch) => {
  dispatch(alertVolveRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(alertVolveSuccess(response));
    };
    const url = ALERT_VOLVE;

    const onFailure = (response) => {
      dispatch(alertVolveFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const mitreAlertCount = () => (dispatch) => {
  dispatch(mitreAlertCountRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(mitreAlertCountSuccess(response));
    };
    const url = ALERT_COUNT_MITRE;

    const onFailure = (response) => {
      dispatch(mitreAlertCountFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };
    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const topTactics =
  (namespace, agentName, startDate, endDate) => (dispatch) => {
    dispatch(topActicsRequest());
    const token = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(topActicsSuccess(response));
      };
      const url = TOP_TACTICS;

      const onFailure = (response) => {
        dispatch(topActicsFail(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      let body = {};
      if (namespace && agentName && startDate && endDate) {
        body.namespace = namespace;
        body.agentfilter = agentName;
        body.gte = startDate;
        body.lte = endDate;
        body.size = "10";
      } else {
        body.namespace = "window_testing";
        body = data;
      }
      functionalityApiCall("POST", url, body, onSuccess, onFailure, token);
    }
  };

export const ruleLvlAttack = (requestData) => (dispatch) => {
  dispatch(ruleLvlAttackReuest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(ruleLvlAttackSuccess(response));
    };
    const url = RULE_LVL_ATTACK;

    const onFailure = (response) => {
      dispatch(ruleLvlAttackFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const ruleLvlTactic = (requestData) => (dispatch) => {
  dispatch(ruleLvlTacticReuest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(ruleLvlTacticSuccess(response));
    };
    const url = RULE_LVL_TACTIC;

    const onFailure = (response) => {
      dispatch(ruleLvlTacticFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const attackActics = (requestData) => (dispatch) => {
  dispatch(attackActicsReuest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(attackActicsSuccess(response));
    };
    const url = ATTACK_ACTIC;

    const onFailure = (response) => {
      dispatch(attackActicsFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};
