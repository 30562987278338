import React from "react";
import { Col, Row } from "antd";

import GdprReqs from "./reqs/Reqs";
import TopReqs from "./topreqs/TopReqs";
import TopAgent from "./topAgent/TopAgent";
import ReqsByAgent from "./reqsbyagents/ReqsByAgents";

import styles from "./index.module.scss";

function Gdpr() {
  return (
    <div>
      <Row justify="center" className={styles.row}>
        <Col xxl={22}>
          <Row justify="space-between">
            <Col xxl={11}>
              <Row>
                <Col xxl={24}>
                  <TopAgent />
                </Col>
                <Col xxl={24} className={styles.row}>
                  <TopReqs />
                </Col>
              </Row>
            </Col>
            <Col xxl={12}>
              <Row>
                <Col xxl={24} className={styles.row}>
                  <GdprReqs />
                </Col>
                <Col xxl={24} className={styles.row}>
                  <ReqsByAgent />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Gdpr;
