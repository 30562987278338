import {
  GDPR_REQSBY_AGENTS_FAIL,
  GDPR_REQSBY_AGENTS_REQUEST,
  GDPR_REQSBY_AGENTS_SUCCESS,
  GDPR_REQS_FAIL,
  GDPR_REQS_REQUEST,
  GDPR_REQS_SUCCESS,
  GDPR_TOPAGENTS_FAIL,
  GDPR_TOPAGENTS_REQUEST,
  GDPR_TOPAGENTS_SUCCESS,
  GDPR_TOPREQS_FAIL,
  GDPR_TOPREQS_REQUEST,
  GDPR_TOPREQS_SUCCESS,
} from "../Actions/actionConstant";

const initialState = {
  status: "",
  message: null,
  topAgent: [],
  reqsData: [],
  topReqs: [],
  reqsByAgent: [],
  isProcess: false,
};
export const gdpr = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case GDPR_TOPAGENTS_REQUEST:
      return {
        ...state,
        isProcess: true,
        topAgent: null,
      };

    case GDPR_TOPAGENTS_SUCCESS:
      return {
        ...state,
        isProcess: false,
        topAgent: data.data,
      };

    case GDPR_TOPAGENTS_FAIL:
      return {
        ...state,
        topAgent: null,
        isProcess: false,
      };
    case GDPR_REQS_REQUEST:
      return {
        ...state,
        isProcess: true,
        reqsData: null,
      };

    case GDPR_REQS_SUCCESS:
      return {
        ...state,
        isProcess: false,
        reqsData: data.data,
      };

    case GDPR_REQS_FAIL:
      return {
        ...state,
        reqsData: null,
        isProcess: false,
      };

    case GDPR_TOPREQS_REQUEST:
      return {
        ...state,
        isProcess: true,
        topReqs: null,
      };

    case GDPR_TOPREQS_SUCCESS:
      return {
        ...state,
        isProcess: false,
        topReqs: data.data,
      };

    case GDPR_TOPREQS_FAIL:
      return {
        ...state,
        topReqs: null,
        isProcess: false,
      };
    case GDPR_REQSBY_AGENTS_REQUEST:
      return {
        ...state,
        isProcess: true,
        reqsByAgent: null,
      };

    case GDPR_REQSBY_AGENTS_SUCCESS:
      return {
        ...state,
        isProcess: false,
        reqsByAgent: data.data,
      };

    case GDPR_REQSBY_AGENTS_FAIL:
      return {
        ...state,
        reqsByAgent: null,
        isProcess: false,
      };

    default:
      return state;
  }
};
