import { Col, Row } from "antd";

import VulnPieScreen from "./Card/Card";
import ActiveVulns from "./activeVulns/ActiveVulns";

import styles from "./index.module.scss";

function Dashboard() {
  return (
    <div>
      <Row justify="center">
        <Col xs={23} md={23} lg={23} xl={23} xxl={23}>
          <Row justify="space-between" className={styles.row}>
            <Col xs={24} md={24} lg={11} xl={11} xxl={11}>
              <VulnPieScreen title={"Vulnpie"} count={""} />
            </Col>
            <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
              <ActiveVulns />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
