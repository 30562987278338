/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal'
import { Row, Spin, Breadcrumb } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import MitreSecModal from './MitreSecModal'
import { allFilterData } from '../../../reducers/alerts'
import { getMitreSecondLvl } from '../../../Actions/alert'

import styles from './index.module.scss'

function MitreFirstModal({
  endDate,
  siteName,
  startDate,
  selectedAgent,
  isVisible,
  setIsVisible,
}) {
  const dispatch = useDispatch()

  const data = useSelector((state) => allFilterData(state))

  const [isMitreSecModal, setIsMitreSecModal] = useState(false)
  const [mitreSecTitle, setMitreSecTitle] = useState()

  const handleCancel = () => {
    setIsVisible(false)
  }

  const handleSecModal = (label) => {
    dispatch(
      getMitreSecondLvl(label, siteName, selectedAgent, startDate, endDate),
    )
    setIsMitreSecModal(!isMitreSecModal)
    setIsVisible(false)
    setMitreSecTitle(label)
  }

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          title="MITRE First Level "
          visible={isVisible}
          className={styles.thirdTableModalWidth}
          onCancel={handleCancel}
         initialWidth={1300}
          initialHeight={700}
          footer={false}
        >
          <div className={styles.critModalContent}>
            <Breadcrumb>
              <Breadcrumb.Item>MITRE Tactics</Breadcrumb.Item>
            </Breadcrumb>
            {data?.isAlertLoading ? (
              <Row justify="center">
                <Spin size="large" />
              </Row>
            ) : data?.mitreFirst?.length ? (
              <div>
                <table className={styles.surucataSecLevelTable}>
                  <thead>
                    <tr>
                      <th
                        className={styles.leftTr}
                        style={{ cursor: 'pointer' }}
                      >
                        MITRE Tactic
                      </th>
                      <th>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.mitreFirst?.map((item, index) => (
                      <tr
                        key={index}
                        className={index % 2 ? '' : styles.suricataTable}
                      >
                        <td
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSecModal(item.key)}
                        >
                          {item?.key ? item.key : '-'}
                        </td>

                        <td>{item?.doc_count ? item?.doc_count : '-'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <Row justify="center">NO DATA FOUND</Row>
            )}
          </div>
        </DraggableModal>
      </DraggableModalProvider>
      <MitreSecModal
        isVisible={isMitreSecModal}
        setIsVisible={setIsMitreSecModal}
        modalTitle={mitreSecTitle}
        isMitreFirstModal={isVisible}
        setIsMitreFirstModal={setIsVisible}
      />
    </>
  )
}

export default MitreFirstModal
