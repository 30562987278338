/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal'
import { Row, Breadcrumb, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import SignatureSecondModal from './SignatureSecondModal'
import { allFilterData } from '../../../reducers/alerts'
import { getSuriCataSecondLvl } from '../../../Actions/alert'

import styles from './index.module.scss'

function SignatureModal({
  endDate,
  startDate,
  siteName,
  selectedAgent,
  isVisible,
  setIsVisible,
}) {
  const dispatch = useDispatch()

  const data = useSelector((state) => allFilterData(state))
  const [isSignatureSecModal, setIsSignatureSecModal] = useState(false)
  const [secModalTitle, setSecModalTitle] = useState('')

  const handleSecondLvl = (label) => {
    setIsVisible(false)
    dispatch(
      getSuriCataSecondLvl(label, siteName, selectedAgent, startDate, endDate),
    )
    setSecModalTitle(label)
    setIsSignatureSecModal(true)
  }

  const handleCancel = () => {
    setIsVisible(!isVisible)
  }
  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          title="Signature Alerts"
          visible={isVisible}
          footer={null}
         initialWidth={1300}
          initialHeight={700}
          className={styles.thirdTableModalWidth}
          onCancel={handleCancel}
        >
          <Breadcrumb>
            <Breadcrumb.Item> Signature Alerts</Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.critModalContent}>
            {data?.isAlertLoading ? (
              <Row justify="center" className={styles.loaderContent}>
                <Spin size="large" />
              </Row>
            ) : (
              <div>
                {data?.suriFirst?.length ? (
                  <table className={styles.surucataSecLevelTable}>
                    <thead>
                      <tr>
                        <th className={styles.leftTr}>Category</th>
                        <th className={styles.rightTr}>Severity</th>
                        <th className={styles.rightTr}>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.suriFirst.map((item, index) => (
                        <tr
                          className={index % 2 ? '' : styles.suricataTable}
                          key={index}
                        >
                          <td
                            className={styles.leftTr}
                            onClick={() => handleSecondLvl(item.key)}
                            style={{ cursor: 'pointer' }}
                          >
                            {item?.key ? item.key : '-'}
                          </td>
                          <td className={styles.rightTr}>
                            {item?.[3]?.buckets?.[0]?.key
                              ? item?.[3]?.buckets?.[0]?.key
                              : '-'}
                          </td>
                          <td className={styles.rightTr}>
                            {item?.doc_count ? item.doc_count : '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Row justify="center">NO DATA FOUND</Row>
                )}
              </div>
            )}
          </div>
        </DraggableModal>
      </DraggableModalProvider>

      <SignatureSecondModal
        isVisible={isSignatureSecModal}
        setIsVisible={setIsSignatureSecModal}
        modalTitle={secModalTitle}
        isSignFirstModal={isVisible}
        setIsSignFirstModal={setIsVisible}
        siteName={siteName}
        endDate={endDate}
        selectedAgent={selectedAgent}
        startDate={startDate}
      />
    </>
  )
}

export default SignatureModal
