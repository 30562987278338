import { Col, Row } from "antd";

import TopAgents from "./topAgents/TopAgents";
import ReqsAgents from "./reqsAgents/ReqsAgents";
import Reqirements from "./requirements/Requirements";
import TopReqirements from "./topRequirements/TopRequirements";

import styles from "./index.module.scss";
function Pcidss() {
  return (
    <Row justify="center" className={styles.mainRow}>
      <Col xs={22} md={22} lg={22} xl={22} xxl={22}>
        <Row justify="space-between">
          <Col xxl={11}>
            <Reqirements title="Requirements" />

            <Col xxl={24} className={styles.mainRow}>
              <ReqsAgents title="Reqs Agents" />
            </Col>
          </Col>
          <Col xxl={12}>
            <TopAgents />
            <Row justify="center" className={styles.mainRow}>
              <Col xxl={24}>
                <TopReqirements title="Top Requirements" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Pcidss;
