/* eslint-disable no-unused-vars */
import { notification } from "antd";

import {
  GET_CLOUDS_REQUEST,
  GET_CLOUDS_SUCCESS,
  GET_CLOUDS_FAIL,
  GET_TOP_CLIENT_IP_REQUEST,
  GET_TOP_CLIENT_IP_SUCCESS,
  GET_TOP_CLIENT_IP_FAIL,
  GET_TOP_RULES_REQUEST,
  GET_TOP_RULES_SUCCESS,
  GET_TOP_RULES_FAIL,
  GET_TOP_OPERATION_REQUEST,
  GET_TOP_OPERATION_SUCCESS,
  GET_TOP_OPERATION_FAIL,
  GET_FULL_EVENTS_REQUEST,
  GET_FULL_EVENTS_SUCCESS,
  GET_FULL_EVENTS_FAIL,
} from "./actionConstant";
import {
  GET_CLOUDS,
  GET_TOP_CLIENT_IP,
  GET_TOP_RULES,
  GET_TOP_OPRATION,
  GET_FULL_EVENTS,
} from "../apiUrl";
import { commonBody } from "../common/constant";
import { getLoginUser } from "../common/localStorage";
import { functionalityApiCall } from "../common/connect";

const getCloudsRequest = () => ({
  type: GET_CLOUDS_REQUEST,
});
const getCloudsSuccess = (data) => ({
  type: GET_CLOUDS_SUCCESS,
  data,
});
const getCloudsFail = () => ({
  type: GET_CLOUDS_FAIL,
});

const getTopClientIpRequest = () => ({
  type: GET_TOP_CLIENT_IP_REQUEST,
});
const getTopClientIpSuccess = (data) => ({
  type: GET_TOP_CLIENT_IP_SUCCESS,
  data,
});
const getTopClientIpFail = () => ({
  type: GET_TOP_CLIENT_IP_FAIL,
});

const getTopRulesRequest = () => ({
  type: GET_TOP_RULES_REQUEST,
});
const getTopRulesSuccess = (data) => ({
  type: GET_TOP_RULES_SUCCESS,
  data,
});
const getTopRulesFail = () => ({
  type: GET_TOP_RULES_FAIL,
});

const getTopOperationRequest = () => ({
  type: GET_TOP_OPERATION_REQUEST,
});
const getTopOperationSuccess = (data) => ({
  type: GET_TOP_OPERATION_SUCCESS,
  data,
});
const getTopOperationFail = () => ({
  type: GET_TOP_OPERATION_FAIL,
});

const getTopFullEventsRequest = () => ({
  type: GET_FULL_EVENTS_REQUEST,
});
const getTopFullEventsSuccess = (data) => ({
  type: GET_FULL_EVENTS_SUCCESS,
  data,
});
const getTopFullEventsFail = () => ({
  type: GET_FULL_EVENTS_FAIL,
});

export const getCloudsApi = (site, agent, start, end, size) => (dispatch) => {
  dispatch(getCloudsRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(getCloudsSuccess(response));
    };
    const url = GET_CLOUDS;

    const onFailure = (response) => {
      dispatch(getCloudsFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };
    const data = { ...commonBody };
    delete data.agentfilter;
    let commonData = data;
    commonData.namespace = site;
    commonData.size = size || "20";
    if (start) commonData.gte = start;
    if (end) commonData.lte = end;
    if (agent) commonData.agentfilter = agent;

    functionalityApiCall("POST", url, commonData, onSuccess, onFailure, token);
  }
};

export const getTopClientIpApi =
  (site, agent, start, end, size) => (dispatch) => {
    dispatch(getTopClientIpRequest());
    const token = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(getTopClientIpSuccess(response));
      };
      const url = GET_TOP_CLIENT_IP;

      const onFailure = (response) => {
        dispatch(getTopClientIpFail(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      const data = { ...commonBody };
      delete data.agentfilter;
      let commonData = data;
      commonData.namespace = site;
      commonData.size = size || "20";
      if (start) commonData.gte = start;
      if (end) commonData.lte = end;
      if (agent) commonData.agentfilter = agent;
      functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
    }
  };

export const getTopRulesApi = (site, agent, start, end, size) => (dispatch) => {
  dispatch(getTopRulesRequest());
  const token = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(getTopRulesSuccess(response));
    };
    const url = GET_TOP_RULES;

    const onFailure = (response) => {
      dispatch(getTopRulesFail(response));
      notification.error({
        message:
          response?.response?.data ||
          response?.message ||
          response?.data?.message,
      });
    };
    const data = { ...commonBody };
    delete data.agentfilter;
    let commonData = data;
    commonData.namespace = site;
    commonData.size = size || "20";
    if (start) commonData.gte = start;
    if (end) commonData.lte = end;
    if (agent) commonData.agentfilter = agent;

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};
export const getTopOperationApi =
  (site, agent, start, end, size) => (dispatch) => {
    dispatch(getTopOperationRequest());
    const token = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(getTopOperationSuccess(response));
      };
      const url = GET_TOP_OPRATION;

      const onFailure = (response) => {
        dispatch(getTopOperationFail(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      const data = { ...commonBody };
      delete data.agentfilter;
      let commonData = data;
      commonData.namespace = site;
      commonData.size = size || "20";
      if (start) commonData.gte = start;
      if (end) commonData.lte = end;
      if (agent) commonData.agentfilter = agent;

      functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
    }
  };
export const getFullEventsApi =
  (site, aegntFilter, start, end, size) => (dispatch) => {
    dispatch(getTopFullEventsRequest());
    const token = getLoginUser();
    if (token) {
      const data = { ...commonBody };
      let commonData = data;
      data.size = size || "20";
      if (start) commonData.gte = start;
      if (start) commonData.gte = start;
      if (end) commonData.lte = end;
      if (aegntFilter) commonData.agentfilter = aegntFilter;
      if (site) commonData.namespace = site;
      const onSuccess = (response) => {
        dispatch(getTopFullEventsSuccess(response));
      };
      const url = GET_FULL_EVENTS;

      const onFailure = (response) => {
        dispatch(getTopFullEventsFail(response));
        notification.error({
          message:
            response?.response?.data ||
            response?.message ||
            response?.data?.message,
        });
      };
      functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
    }
  };

//   export const data =   "Site": "f4729004-7bc9-43c8-85a6-ac130b37f74f",
//   "UserKey": "i:0h.f|membership|10033fffa672c0ae@live.com",
//   "ItemType": "File",
//   "IsManagedDevice": "false",
//   "Operation": "FilePreviewed",
//   "OrganizationId": "3dcb7de6-b742-459e-8b28-320574fdd873",
//   "RecordType": "6",
//   "ListId": "5a0e27a6-de87-49b2-9181-280e772acc07",
//   "Version": "1",
//   "WebId": "8a28d79f-b27d-40fa-9af4-75603815061f",
//   "UserAgent": "OneDriveMpc-Transform_Thumbnail/1.0",
//   "CorrelationId": "7e159aa0-1038-3000-52ed-e0a7be1a88c0",
//   "DoNotDistributeEvent": "true",
//   "Subscription": "Audit.SharePoint",
//   "ListItemUniqueId": "d795c23e-718d-46d1-9f04-dbe045190239",
//   "ObjectId": "https://realstarmanagement.sharepoint.com/sites/One255YorkMills/Shared Documents/General/Leasing/One225 Hot List and Leasing Tracker - Template.xlsx",
//   "SourceFileName": "One225 Hot List and Leasing Tracker - Template.xlsx",
//   "SiteUrl": "https://realstarmanagement.sharepoint.com/sites/One255YorkMills/",
//   "AppAccessContext": {
//       "ClientAppId": "35d54a08-36c9-4847-9018-93934c62740c",
//       "CorrelationId": "7e159aa0-1038-3000-52ed-e0a7be1a88c0"
//   },
//   "ClientIP": "52.104.76.130",
//   "SourceFileExtension": "xlsx",
//   "Workload": "SharePoint",
//   "SourceRelativeUrl": "Shared Documents/General/Leasing",
//   "EventSource": "SharePoint",
//   "HighPriorityMediaProcessing": "false",
//   "UserId": "travine@realstar.ca",
//   "CreationTime": "2023-02-26T19:20:08",
//   "Id": "efab57ed-102e-4b74-a697-08db182e7937",
//   "ApplicationId": "35d54a08-36c9-4847-9018-93934c62740c",
//   "UserType": "0"
// }
