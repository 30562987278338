import moment from "moment";
import { useSelector } from "react-redux";
import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import { Row, Spin, Button, Col, Breadcrumb } from "antd";

import { allFilterData } from "../../../reducers/alerts";

import styles from "./index.module.scss";

function SignatureThirdModal({
  modalTitle,
  firstModalTitle,
  isVisible,
  setIsVisible,
  isSecModal,
  setIsSecModal,
  setIsSignFirstModal,
}) {
  const data = useSelector((state) => allFilterData(state));

  const handleBack = () => {
    setIsSecModal(!isSecModal);
    setIsVisible(!isVisible);
  };

  const handleCancel = () => {
    setIsVisible(!isVisible);
  };

  const handleSignatureFirst = () => {
    setIsVisible(!isVisible);
    setIsSignFirstModal(true);
  };
  return (
    <DraggableModalProvider>
      <DraggableModal
        title="Signature Third Level Alerts"
        visible={isVisible}
        className={styles.thirdTableModalWidth}
        footer={false}
        initialWidth={1300}
        initialHeight={700}
        onCancel={handleCancel}
      >
        <Breadcrumb>
          <Breadcrumb.Item onClick={handleSignatureFirst}>
            {" "}
            Signature Alerts
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={handleBack}>
            {firstModalTitle}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{modalTitle}</Breadcrumb.Item>
        </Breadcrumb>

        <div className={styles.critModalContent}>
          {data?.isAlertLoading ? (
            <Row justify="center" className={styles.loaderContent}>
              <Spin size="large" />
            </Row>
          ) : (
            <div>
              <table className={styles.surucataSecLevelTable}>
                <thead>
                  <tr>
                    <th className={styles.rightTr}>TimeStamp</th>
                    <th className={styles.rightTr}>Severity</th>
                    <th className={styles.rightTr}>Source</th>
                    <th className={styles.rightTr}>Destination</th>
                    <th className={styles.leftTr}>Protocol</th>
                    <th className={styles.leftTr}>DNS Query</th>
                    <th className={styles.rightTr}>Bytes to Client</th>
                    <th className={styles.rightTr}>Bytes to server</th>
                  </tr>
                </thead>

                <tbody>
                  {data?.suriThird?.length > 0 ? (
                    data?.suriThird?.map((item, index) => (
                      <tr
                        key={index}
                        className={index % 2 ? "" : styles.suricataTable}
                      >
                        <td
                          className={styles.rightTr}
                          style={{ cursor: "pointer", color: "black" }}
                        >
                          {item?.["_source"]?.timestamp
                            ? moment(item?.["_source"]?.timestamp).format(
                                "MM/DD/YYYY, h:mm:ss a"
                              )
                            : "-"}
                        </td>
                        <td className={styles.rightTr}>
                          {item?.["_source"]?.data?.alert?.severity
                            ? item?.["_source"].data.alert.severity
                            : "-"}
                        </td>
                        <td className={styles.rightTr}>{`${
                          item?.["_source"]?.data?.src_ip
                            ? item?.["_source"].data.src_ip
                            : "-"
                        } : ${
                          item?.["_source"]?.data?.src_port
                            ? item?.["_source"].data.src_port
                            : "-"
                        }`}</td>
                        <td className={styles.rightTr}>{`${
                          item?.["_source"]?.data?.dest_ip
                            ? item?.["_source"].data.dest_ip
                            : "-"
                        } : ${
                          item?.["_source"]?.data?.dest_port
                            ? item?.["_source"].data.dest_port
                            : "-"
                        }`}</td>
                        <td className={styles.leftTr}>
                          {item?.["_source"]?.data?.proto
                            ? item?.["_source"].data.proto
                            : "-"}
                        </td>
                        <td className={styles.leftTr}>
                          {item?.["_source"]?.data?.dns?.query[0]?.rrname
                            ? item?.["_source"].data.dns.query[0].rrname
                            : "-"}
                        </td>
                        <td className={styles.rightTr}>
                          {item?.["_source"]?.data?.flow?.bytes_toclient
                            ? item?.["_source"].data.flow.bytes_toclient
                            : "-"}
                        </td>
                        <td className={styles.rightTr}>
                          {item?.["_source"]?.data?.flow?.bytes_toserver
                            ? item?.["_source"].data.flow.bytes_toserver
                            : "-"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <Row justify="center">NO DATA FOUND</Row>
                  )}
                </tbody>
              </table>
              <Row className={styles.buttonRow} justify="center">
                <Col xxl={3}>
                  <Button className={styles.backButton} onClick={handleBack}>
                    Back
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </DraggableModal>
    </DraggableModalProvider>
  );
}

export default SignatureThirdModal;
