/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Card, Col, Row } from "antd";
import React, { useEffect } from "react";
import Chart from "react-google-charts";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";

import { attackActics } from "../../../Actions/mitreDashboard";

import styles from "./index.module.scss";

function AttacktActic(props) {
  const dispatch = useDispatch();

  const isProccess = useSelector((state) => state.mitre?.isProcess);
  const [getAttackActics, setGetAttackActics] = useState([]);
  const attackActicsData = useSelector(
    (state) => state.mitre.attackActics?.[2]?.buckets
  );

  const data = getAttackActics;

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(attackActics());
  };

  useEffect(() => {
    if (attackActicsData) {
      let data = attackActicsData;
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        arr.push([data[i]?.[3]?.buckets[2]?.key, data[i].doc_count]);
      }
      arr.unshift(["key_as_string", "value"]);
      setGetAttackActics([...arr]);
    }
  }, [attackActicsData]);

  const options = {
    title: "Attack Tactics",
  };

  return (
    <Row justify="center">
      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
        <Card className={styles.sellerCard}>
          {isProccess ? (
            <Row justify="center">
              <Col>
                <ThreeDots color="#00000" height={50} width={50} />
              </Col>
            </Row>
          ) : (
            <Chart
              chartType="PieChart"
              data={data}
              options={options}
              width={"100%"}
              height={"400px"}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default AttacktActic;
