/* eslint-disable jsx-a11y/anchor-is-valid */

import { toast } from "react-toastify";
import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Spin, Breadcrumb, Select } from "antd";

import { sizeFilter } from "../../../staticData";
import { allFilterData } from "../../../reducers/alerts";
import { getCriticalsFourthLvl } from "../../../Actions/alert";
import { downloadReadableStreamFile } from "../../../common/connect";

import styles from "./index.module.scss";

function CriticalFourthModal({
  isCritFourthModal,
  modalTitle,
  critFirstTitle,
  setIsCritFourthModal,
  setIsVisible,
  thirdTItle,
  setCritFirstModal,
  setIsCritSecModal,
  timeStamp,
  siteName,
  startDate,
  endDate,
}) {
  const dispatch = useDispatch();

  const data = useSelector((state) => allFilterData(state));
  const [isDownloadLoader, setIsDownloadLoader] = useState(false);

  const handleFourthBack = () => {
    setIsCritFourthModal(false);
    setIsVisible(true);
  };
  useEffect(() => {
    document
      .getElementById("main-root")
      ?.scrollIntoView({ behavior: "smooth" });
  });

  const handleThirdClose = () => {
    setIsCritFourthModal(false);
  };

  const handleCritFirst = () => {
    setIsCritFourthModal(false);
    setCritFirstModal(true);
  };
  const handleCritSecond = () => {
    setIsCritFourthModal(false);
    setIsCritSecModal(true);
  };
  const handleChange = (value) => {
    dispatch(
      getCriticalsFourthLvl(
        thirdTItle,
        timeStamp,
        modalTitle,
        siteName,
        startDate,
        endDate,
        value
      )
    );
  };
  const handleDownload = () => {
    if (siteName === undefined) {
      toast.error("Select Host Name");
    } else {
      setIsDownloadLoader(true);

      const onSuccess = (link) => {
        setIsDownloadLoader(false);
        window.URL.revokeObjectURL(link);
      };
      downloadReadableStreamFile(
        siteName,
        modalTitle,
        "surroundingevts",
        startDate,
        endDate,
        "csv",
        onSuccess,
        "",
        thirdTItle,
        timeStamp
      );
    }
  };
  return (
    <DraggableModalProvider>
      <DraggableModal
        title={"Critical Security Alerts"}
        open={isCritFourthModal}
        className={styles.suricataFirstLvlModal}
        initialWidth={1300}
        initialHeight={700}
        footer={false}
        onCancel={handleThirdClose}
      >
        <Breadcrumb>
          <Breadcrumb.Item onClick={handleCritFirst}>
            {" "}
            Critical Security Alerts
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={handleCritSecond}>
            {JSON.stringify(critFirstTitle)?.replaceAll(`"`, "")}
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={handleFourthBack}>
            {modalTitle}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{thirdTItle}</Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.critModalContent}>
          <Row justify="space-between" className={styles.selectSize}>
            <Col xl={8} lg={8} md={10} xs={24} sm={24} xxl={6}>
              <div className={`${styles.filterType} ${styles.siteDropdown}`}>
                <b>Select Size:</b>
                <Select
                  defaultValue="10"
                  allowClear
                  onChange={handleChange}
                  options={sizeFilter}
                />
              </div>
            </Col>
            <Col>
              <Button
                loading={isDownloadLoader}
                className={styles.exportBtnModal}
                onClick={handleDownload}
              >
                Quick Export
              </Button>
            </Col>
          </Row>
          {data?.isAlertLoading ? (
            <Row justify="center" className={styles.loaderContent}>
              <Spin size="large" />
            </Row>
          ) : (
            <div>
              {data?.critFourth?.length > 0 ? (
                <div>
                  <table className={styles.xdrLvlSecondTable}>
                    <tbody className={styles.xdrLvlSecondTableBody}>
                      {data.critFourth?.map((item, index) => (
                        <p
                          id={item?._id === thirdTItle ? "main-root" : ""}
                          // ref={item?._id === thirdTItle ? divRef : null}
                          className={
                            item?._id === thirdTItle
                              ? styles.highlightColor
                              : item?._source?.data?.win?.system?.message
                              ? styles.fourthModalColor
                              : null
                          }
                          key={index}
                        >
                          {item?._source?.data?.win?.system?.message
                            ? item?._source?.data?.win?.system?.message
                                ?.split(/\n/)
                                .map((item1, index) => (
                                  <tr key={index}>
                                    <td>
                                      {item1 ? item1.replaceAll(`"`, "") : "-"}
                                    </td>
                                  </tr>
                                ))
                            : null}
                        </p>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <Row justify="center"> NO DATA FOUND </Row>
              )}
              <Row className={styles.buttonRow} justify="center">
                <Col xxl={3}>
                  <Button
                    className={styles.backButton}
                    onClick={handleFourthBack}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </DraggableModal>
    </DraggableModalProvider>
  );
}

export default CriticalFourthModal;
