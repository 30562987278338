/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState } from "react";
import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Spin, Breadcrumb } from "antd";

import CriticalThirdModal from "./CriticalThirdModal";
import { allFilterData } from "../../../reducers/alerts";
import { getCriticalsThirdLvl } from "../../../Actions/alert";

import styles from "./index.module.scss";

function CriticalSecondModal({
  modalTitle,
  siteName,
  selectedAgent,
  startDate,
  endDate,
  isVisible,
  setIsVisible,
  isCritFirstModal,
  setCritFirstModal,
}) {
  const dispatch = useDispatch();

  const data = useSelector((state) => allFilterData(state));
  const [isCritThirdModal, setIsCritThirdModal] = useState(false);
  const [critSecLabel, setCrirtSecLabel] = useState("");

  const handleCritThirdModal = (label) => {
    setCrirtSecLabel(label);
    dispatch(
      getCriticalsThirdLvl(
        modalTitle,
        label,
        siteName,
        selectedAgent,
        startDate,
        endDate
      )
    );
    setIsVisible(!isVisible);
    setIsCritThirdModal(true);
  };

  const handleCritSecondClose = () => {
    setIsVisible(!isVisible);
    setCritFirstModal(!isCritFirstModal);
  };

  const handleCritShotsCancel = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <DraggableModalProvider>
        <DraggableModal
          title="Critical Second Level"
          visible={isVisible}
          className={styles.thirdTableModalWidth}
          initialWidth={1300}
          initialHeight={700}
          footer={false}
          onCancel={handleCritShotsCancel}
        >
          <Breadcrumb>
            <Breadcrumb.Item onClick={handleCritSecondClose}>
              {" "}
              Critical Security Alerts
            </Breadcrumb.Item>
            <Breadcrumb.Item>{modalTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.critModalContent}>
            {data?.isAlertLoading ? (
              <Row justify="center" className={styles.loaderContent}>
                <Spin size="large" />
              </Row>
            ) : (
              <div className={styles.critSecAlert}>
                <div className={styles.headerContent}>
                  <span className={styles.leftTr}>
                    <b>Observed by</b>
                  </span>
                  <span className={styles.rightTr}>
                    <b>IP Address</b>
                  </span>
                  <span className={styles.rightTr}>
                    <b>Count</b>
                  </span>
                </div>
                {data?.critSec?.length > 0 ? (
                  data.critSec?.map((item, index) => (
                    <div
                      className={`${styles.criticalShotsModal} ${
                        index % 2 ? "" : styles.criticalShotsModalBg
                      }`}
                      key={index}
                    >
                      <span className={styles.leftTr}>
                        <a
                          className={styles.aTagColor}
                          onClick={() => handleCritThirdModal(item?.key)}
                        >
                          {item?.key ? item.key : "-"}
                        </a>
                      </span>

                      <span className={styles.rightTr}>
                        {item?.["3"]?.buckets[0]?.key
                          ? item?.["3"]?.buckets[0]?.key
                          : "-"}
                      </span>
                      <span className={styles.rightTr}>
                        {item?.doc_count ? item.doc_count : "-"}
                      </span>
                    </div>
                  ))
                ) : (
                  <Row justify="center">NO DATA FOUND</Row>
                )}
                <Row className={styles.buttonRow} justify="center">
                  <Col xxl={3}>
                    <Button
                      className={styles.backButton}
                      onClick={handleCritSecondClose}
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </DraggableModal>
      </DraggableModalProvider>
      <CriticalThirdModal
        critFirstTitle={modalTitle}
        modalTitle={critSecLabel}
        isVisible={isCritThirdModal}
        setIsVisible={setIsCritThirdModal}
        setIsCritSecModal={setIsVisible}
        isCritSecModal={isVisible}
        siteName={siteName}
        selectedAgent={selectedAgent}
        startDate={startDate}
        endDate={endDate}
        setCritFirstModal={setCritFirstModal}
      />
    </>
  );
}

export default CriticalSecondModal;
