import {
  GET_SENTINELONE_AGENT_REQUEST,
  GET_SENTINELONE_AGENT_SUCCESS,
  GET_SENTINELONE_AGENT_FAIL,
  GET_INVENTORY_AGENT_REQUEST,
  GET_INVENTORY_AGENT_SUCCESS,
  GET_INVENTORY_AGENT_FAIL,
} from "../Actions/actionConstant";

const initialState = {
  sentineloneAgentList: [],
  agentInventoryList: [],
};

export const inventoryReducer = (state = initialState, action) => {
  const { data } = action;

  switch (action.type) {
    case GET_SENTINELONE_AGENT_REQUEST:
      return {
        ...state,
        sentineloneAgentList: null,
        isLoader: true,
      };

    case GET_SENTINELONE_AGENT_SUCCESS:
      return {
        ...state,
        sentineloneAgentList: data.data,
        isLoader: false,
      };

    case GET_SENTINELONE_AGENT_FAIL:
      return {
        ...state,
        sentineloneAgentList: null,
        isLoader: false,
      };

    case GET_INVENTORY_AGENT_REQUEST:
      return {
        ...state,
        agentInventoryList: null,
      };

    case GET_INVENTORY_AGENT_SUCCESS:
      return {
        ...state,
        agentInventoryList: data.data,
      };

    case GET_INVENTORY_AGENT_FAIL:
      return {
        ...state,
        agentInventoryList: null,
      };

    default:
      return state;
  }
};
