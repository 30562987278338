/* eslint-disable   */
import { Fragment, useEffect } from "react";

import {
  getEdrFirstLvl,
  getEventFirstLvl,
  getMitreFirstLvl,
  getSuriCataFirstLvl,
  getThreatHuntFirstLvl,
  getCriticalShotsFirstLvl,
} from "../../Actions/alert";
import { agentList } from "../../Actions/overView";
import { useDispatch, useSelector } from "react-redux";

import styles from "./index.module.scss";

function SiteTable({
  data,
  defaultData,
  setIsSiteModal,
  isSiteModal,
  setTblSelectedSite,
  setMitre,
  isMitreModal,
  setMitreFirstModalData,
  setIsCRTModal,
  isCRTModal,
  setThreatHunt,
  isThreatHunt,
  setIsEdr,
  isEdrModal,
  setIsTopRisk,
  isTopRisk,
  isSignatureModal,
  setIsSignatureModal,
  selectedAgent,
  startDate,
  endDate,
  setIsEventModal,
  isEventModal,
}) {
  const dispatch = useDispatch();

  const handleSiteModal = (site) => {
    setIsSiteModal(!isSiteModal);
    setTblSelectedSite(site);
    dispatch(agentList(site, selectedAgent));
  };
  const showMitreFirstModal = (data, site) => {
    setMitre(!isMitreModal);
    setTblSelectedSite(site);
    setMitreFirstModalData(data);
    dispatch(getMitreFirstLvl(site, selectedAgent, startDate, endDate));
  };

  const showTopRiskFirstModal = (data, site) => {
    setIsTopRisk(!isTopRisk);
    setTblSelectedSite(site);
    dispatch(agentList(site, "", "", ""));
  };

  const handleCritFirstModal = (site) => {
    setIsCRTModal(!isCRTModal);
    setTblSelectedSite(site);
    dispatch(getCriticalShotsFirstLvl(site, selectedAgent, startDate, endDate));
  };
  const showSignatureModal = (site) => {
    setIsSignatureModal(!isSignatureModal);
    dispatch(getSuriCataFirstLvl(site, selectedAgent, startDate, endDate));
    setTblSelectedSite(site);
  };
  const showThreatHuntFirstModal = (site) => {
    setThreatHunt(!isThreatHunt);
    setTblSelectedSite(site);
    dispatch(getThreatHuntFirstLvl(site, selectedAgent, startDate, endDate));
  };

  const showEdrModal = (site) => {
    setIsEdr(!isEdrModal);
    setTblSelectedSite(site);
    dispatch(getEdrFirstLvl(site, selectedAgent, startDate, endDate));
  };

  const showEventModal = (site) => {
    setIsEventModal(!isEventModal);
    setTblSelectedSite(site);
    dispatch(getEventFirstLvl(site, selectedAgent, startDate, endDate));
  };

  return (
    <Fragment>
      <table className={styles.mainTable}>
        <thead>
          <tr>
            <th className={styles.siteTitle} rowSpan="2">
              Network
            </th>
            <th className={styles.crtAlertTitle} rowSpan="2">
              Threat Hunting <br></br> (Critical Severity)
            </th>
            <th className={styles.edrTitle} rowSpan="2">
              EDR/XDR
            </th>

            <th className={styles.endPointTitle} colSpan="2">
              Endpoint Alerts
            </th>
            <th className={styles.networkTitle} colSpan="1">
              Network Alerts
            </th>
            <th className={styles.topRiksTitle} rowSpan="2">
              Most Active Endpoints
            </th>
          </tr>
          <tr>
            {/* <th className={styles.endPointTitle}>MITRE Framework Detections</th> */}
            <th className={styles.endPointTitle}>
              Endpoint Security Alerts <br></br> (High Severity, 12+)
            </th>
            <th className={styles.networkTitle}>
              Endpoint Security Notices <br></br> (Level 3-11)
            </th>
            <th className={styles.networkTitle}>Signature Alerts </th>
            {/* <th className={styles.networkTitle}>Top ML Score Lists </th> */}
          </tr>
        </thead>
        <tbody>
          {defaultData?.map((item, index) => {
            return (
              <tr key={index}>
                <td
                  className={styles.networkTitle}
                  onClick={() => {
                    if (item?.namespace) handleSiteModal(item.namespace);
                  }}
                >
                  <span> {item.namespace || "windows_testing"}</span>
                  <br />({item?.agents || 0}) Hosts
                </td>
                <td
                  className={styles.crtAlertData}
                  onClick={() => {
                    if (item?.threathunt)
                      showThreatHuntFirstModal(item.namespace);
                  }}
                >
                  {item?.threathunt || 0}
                </td>
                <td
                  className={styles.networkTitle}
                  onClick={() => {
                    if (item?.xdr) showEdrModal(item.namespace);
                  }}
                >
                  {item?.xdr || 0}
                </td>
                <td
                  className={styles.networkTitle}
                  onClick={() => {
                    if (item?.criticals) handleCritFirstModal(item.namespace);
                  }}
                >
                  {item?.criticals || 0}
                </td>
                <td
                  className={styles.networkTitle}
                  onClick={() => {
                    if (item?.events) showEventModal(item.namespace);
                  }}
                >
                  {item?.events || 0}
                </td>
                <td
                  className={styles.networkData}
                  onClick={() => {
                    if (item?.suricata) showSignatureModal(item.namespace);
                  }}
                >
                  {item?.suricata || 0}
                </td>

                <td
                  className={styles.networkData}
                  onClick={() => {
                    if (data?.topRiskAggregreted) {
                      showTopRiskFirstModal(
                        data?.topRiskAggregreted,
                        item?.namespace
                      );
                    }
                  }}
                >
                  {"Top " + (item?.agents > 10 ? 10 : item?.agents)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
}

export default SiteTable;
