import { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Row, Spin, Col, Button, Breadcrumb, Popover } from "antd";

import { allFilterData } from "../../../reducers/alerts";
import { downloadReadableStreamFile } from "../../../common/connect";

import styles from "./index.module.scss";

function EDRSecModal({
  modalTitle,
  siteName,
  startDate,
  endDate,
  sourceHostTitle,
  isVisible,
  setIsVisible,
  isEdrFirstModal,
  setIsEdrFirstModal,
}) {
  const [isDownloadLoader, setIsDownloadLoader] = useState(false);
  const data = useSelector((state) => allFilterData(state));

  const handleCancel = () => {
    setIsVisible(false);
  };

  const handleBack = () => {
    setIsVisible(false);
    setIsEdrFirstModal(!isEdrFirstModal);
  };
  const handleDownload = () => {
    if (sourceHostTitle === undefined) {
      toast.error("Select Host Name");
    } else {
      setIsDownloadLoader(true);

      const onSuccess = (link) => {
        setIsDownloadLoader(false);
        window.URL.revokeObjectURL(link);
      };

      downloadReadableStreamFile(
        siteName,
        sourceHostTitle,
        "csv",
        startDate,
        endDate,
        "csv",
        onSuccess
      );
    }
  };

  const content = (
    <p>
      The quick export function will retrieve agent's events around S1
      detection.
    </p>
  );

  return (
    <DraggableModalProvider>
      <DraggableModal
        title="EDR/XDR Second Level"
        visible={isVisible}
        onCancel={handleCancel}
        className={styles.thirdTableModalWidth}
        initialWidth={1300}
        initialHeight={700}
        footer={false}
      >
        <div className={styles.critModalContent}>
          <Breadcrumb>
            <Breadcrumb.Item onClick={handleBack}>
              {" "}
              EDR sec Level
            </Breadcrumb.Item>
            <Breadcrumb.Item>{modalTitle}</Breadcrumb.Item>
          </Breadcrumb>
          {data?.isAlertLoading ? (
            <Row justify="center" className={styles.loaderContent}>
              <Spin size="large" />
            </Row>
          ) : data.xdrSecond?.length ? (
            <div>
              <table className={styles.xdrLvlSecondTable}>
                <tbody className={styles.xdrLvlSecondTableBody}>
                  {data.xdrSecond?.map((item, index) => (
                    <tr className={styles.fourthModalColor} key={index}>
                      {item._source.full_log
                        ?.split("|")
                        ?.map((item1, index) => (
                          <tr key={index}>
                            <td>{item1 ? item1 : "-"}</td>
                          </tr>
                        ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <Row className={styles.buttonRow} justify="center">
                <Col xxl={3}>
                  <Button className={styles.backButton} onClick={handleBack}>
                    Back
                  </Button>
                </Col>

                <Col xxl={3} className={styles.exportContent}>
                  <Button
                    loading={isDownloadLoader}
                    className={styles.backButton}
                    onClick={handleDownload}
                  >
                    Quick Export
                  </Button>
                </Col>
                <Col xxl={1}>
                  <Popover content={content} trigger="hover">
                    <InfoCircleOutlined className={styles.infoButton} />
                  </Popover>
                </Col>
              </Row>
            </div>
          ) : (
            <Row justify="center">NO DATA FOUND</Row>
          )}
        </div>
      </DraggableModal>
    </DraggableModalProvider>
  );
}

export default EDRSecModal;
