/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal'
import { Row, Breadcrumb, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { allFilterData } from '../../../reducers/alerts'
import { getThreatHuntSecond } from '../../../Actions/alert'
import ThreatHuntSecondModal from './ThreatHuntingSecondModal'

import styles from './index.module.scss'

function ThreatHuntingFirstModal({
  isVisible,
  siteName,
  setIsVisible,
  selectedAgent,
  startDate,
  endDate,
}) {
  const dispatch = useDispatch()

  const data = useSelector((state) => allFilterData(state))
  const [isThreatSecModal, setIsThreatSecModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const handleThreatHuntSecondModal = (label) => {
    dispatch(
      getThreatHuntSecond(label, siteName, selectedAgent, startDate, endDate),
    )
    setIsThreatSecModal(!isThreatSecModal)
    setIsVisible(false)
    setModalTitle(label)
  }
  const handleClose = () => {
    setIsVisible(false)
  }
  return (
    <div>
      <DraggableModalProvider>
        <DraggableModal
          title="Threat Hunting Alerts"
          visible={isVisible}
          footer={null}
         initialWidth={1300}
          initialHeight={700}
          onCancel={handleClose}
          className={styles.thirdTableModalWidth}
        >
          <Breadcrumb>
            <Breadcrumb.Item> Threat Hunting First Level</Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.critModalContent}>
            {data?.isAlertLoading ? (
              <Row justify="center" className={styles.loaderContent}>
                <Spin size="large" />
              </Row>
            ) : (
              <div>
                <div className={styles.headerContent}>
                  <div>
                    <b>Name</b>
                  </div>
                  <div>
                    <b>Severity</b>
                  </div>
                  <div>
                    <b>Count</b>
                  </div>
                </div>
                {data?.threatHuntFirst?.length ? (
                  <div>
                    {data?.threatHuntFirst.map((item, index) => (
                      <div
                        className={`${styles.modalDetail} ${
                          index % 2 ? '' : styles.tableBg
                        }`}
                        key={index}
                      >
                        <div>
                          <a
                            className={styles.aTagColor}
                            onClick={() =>
                              handleThreatHuntSecondModal(item.key)
                            }
                          >
                            <b> {item?.key ? item.key : '-'}</b>
                          </a>
                        </div>
                        <div>
                          <span>High</span>
                        </div>
                        <div>
                          <span>{item?.doc_count ? item.doc_count : '-'}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <Row justify="center">NO DATA FOUND</Row>
                )}
              </div>
            )}
          </div>
        </DraggableModal>
      </DraggableModalProvider>
      <ThreatHuntSecondModal
        isVisible={isThreatSecModal}
        setIsVisible={setIsThreatSecModal}
        modalTitle={modalTitle}
        isFirstModal={isVisible}
        setIsFirstModal={setIsVisible}
      />
    </div>
  )
}

export default ThreatHuntingFirstModal
