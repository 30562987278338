/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState } from 'react'

import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal'
import { Row, Breadcrumb, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import CriticalSecondModal from './CriticalSecondModal'
import { allFilterData } from '../../../reducers/alerts'
import { getCriticalShotsSecLvl } from '../../../Actions/alert'

import styles from './index.module.scss'

function CriticalFirstModal({
  endDate,
  siteName,
  startDate,
  isVisible,
  selectedAgent,
  setIsVisible,
}) {
  const dispatch = useDispatch()

  const data = useSelector((state) => allFilterData(state))
  const [critSecAlertTitle, setCritSecAlertTitle] = useState('')
  const [isCritSecModal, setIsCritSecModal] = useState(false)

  const handleCritFirstModal = () => {
    setIsVisible(!isVisible)
  }
  const handleCritSecondModal = (item) => {
    setCritSecAlertTitle(item)
    setIsVisible(!isVisible)
    dispatch(
      getCriticalShotsSecLvl(item, siteName, selectedAgent, startDate, endDate),
    )
    setIsCritSecModal(!isCritSecModal)
  }

  return (
    <div>
      <DraggableModalProvider>
        <DraggableModal
          initialWidth={1300}
          initialHeight={700}
          title="Critical Security Alerts"
          visible={isVisible}
          footer={false}
          onOk={handleCritFirstModal}
          onCancel={handleCritFirstModal}
          className={styles.thirdTableModalWidth}
        >
          <Breadcrumb>
            <Breadcrumb.Item>Critical Security Alerts</Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.critModalContent}>
            {data?.isAlertLoading ? (
              <Row justify="center" className={styles.loaderContent}>
                <Spin size="large" />
              </Row>
            ) : (
              <div>
                <table className={styles.surucataSecLevelTable}>
                  <thead>
                    <tr>
                      <th className={styles.leftTr}>Name</th>
                      <th className={styles.rightTr}>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.critFirst?.length ? (
                      data?.critFirst.map((item, index) => {
                        return (
                          <tr
                            className={index % 2 ? '' : styles.suricataTable}
                            key={index}
                          >
                            <td
                              style={{ cursor: 'pointer', color: 'blue' }}
                              className={styles.leftTr}
                              onClick={() => handleCritSecondModal(item.key)}
                            >
                              {item?.key
                                ? JSON.stringify(item.key).replaceAll(`"`, '')
                                : '-'}
                            </td>
                            <td className={styles.rightTr}>
                              {item?.doc_count ? item.doc_count : '-'}
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <Row justify="center">NO DATA FOUND</Row>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </DraggableModal>
      </DraggableModalProvider>
      <CriticalSecondModal
        modalTitle={critSecAlertTitle}
        siteName={siteName}
        selectedAgent={selectedAgent}
        startDate={startDate}
        endDate={endDate}
        isVisible={isCritSecModal}
        setIsVisible={setIsCritSecModal}
        isCritFirstModal={isVisible}
        setCritFirstModal={setIsVisible}
      />
    </div>
  )
}

export default CriticalFirstModal
